import React from 'react'
import Section5 from "../../knowGood/Section5/Section5";


function Section4() {
  return (
    <div>
      <Section5/>
    </div>
  )
}

export default Section4