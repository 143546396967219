import MainBG from "../../assets/credvestGroup/mainPic.webp";
import About from "../../assets/credvestGroup/about.webp";
import FadeInOnScroll from "../../components/fadeInOnScroll/FadeInOnScroll";
import styles from "./style.module.scss";
import Section6 from "../../AllSections/Home/Section6/Section6";
import Pic1 from "../../assets/credvestGroup/1.webp";
import Pic2 from "../../assets/credvestGroup/2.webp";
import Pic3 from "../../assets/credvestGroup/3.webp";
import Pic4 from "../../assets/credvestGroup/4.webp";
import Pic5 from "../../assets/credvestGroup/5.webp";
import Pic6 from "../../assets/credvestGroup/6.webp";
import Pic7 from "../../assets/credvestGroup/7.webp";
import Pic8 from "../../assets/credvestGroup/8.webp";
import arrowRight from "../../assets/icons/arrowRight.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const CredvestGroup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  return (
    <>
      <section
        className="max-md:bg-cover max-md:bg-center md:bg-cover md:bg-center grid place-items-center h-screen relative bg-no-repeat"
        style={{ backgroundImage: `url('${MainBG}')` }}
      >
        <div className="grid place-items-center max-md:px-5">
          <div className="">
            <h1 className="text-white text-2xl font-semibold md:text-5xl">
              Mastering an Integrated Ecosystem in Real Estate
            </h1>
            <h2 className="text-xl font-bold font-oxygen md:text-3xl text-white mt-5">
              Connecting Ventures, Enhancing Value, Driving Success
            </h2>
          </div>
        </div>
      </section>
      <div className="pl-16 py-3 max-md:pl-6 flex flex-col md:flex-row gap-x-20 gap-y-10">
        <div className="md:w-1/2 flex flex-col justify-center">
          <FadeInOnScroll>
            <h3 className="text-2xl md:text-start md:text-5xl font-oxygen font-bold">
              About the&nbsp;
              <span className={styles.gayColor}>Credvest Group</span>
            </h3>
          </FadeInOnScroll>
          <p className="mt-10 text-justify font-oxygen md:text-lg">
            The Credvest Group has masterfully created an integrated ecosystem
            where each venture amplifies and complements the others. Through
            strategic partnerships and a commitment to excellence, we seamlessly
            connect our ventures to offer a comprehensive suite of services,
            ensuring every aspect of real estate, from development to lifestyle
            enhancement, is covered.
          </p>
        </div>
        <div className="md:w-1/2">
          <img src={About} alt="" />
        </div>
      </div>
      <div className="py-3 px-16 max-md:px-3 my-12">
        <FadeInOnScroll>
          <h3 className="text-2xl text-center md:text-5xl font-oxygen font-bold">
            Our&nbsp;
            <span className={styles.gayColor}>Ventures</span>
          </h3>
        </FadeInOnScroll>
        <div className="grid grid-cols-4 gap-6 my-16 max-lg:grid-cols-2 max-md:grid-cols-1">
          <a href="/ourVentures/#a">
            <img
              src={Pic1}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>
          <a href="/ourVentures/#d">
            <img
              src={Pic4}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>

          <a href="/ourVentures/#c">
            <img
              src={Pic3}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>
          <a href="/ourVentures/#b">
            <img
              src={Pic2}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>

          <a href="/ourVentures/#e">
            <img
              src={Pic5}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>
          <a href="/ourVentures/#g">
            <img
              src={Pic7}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>

          <a href="/ourVentures/#f">
            <img
              src={Pic6}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>
          <a href="/ourVentures/#h">
            <img
              src={Pic8}
              className="w-full h-64 object-cover rounded-md"
              alt=""
            />
          </a>

        </div>
        <div className="mt-20">
          <h4 className="font-semibold italic md:text-2xl text-[#071013]">
            Discover how the Credvest Group is reshaping real estate and
            lifestyle.
            <br />
            Ready to be a part of our success story?
          </h4>
          <div className="grid place-items-center">
            <button
              style={{ fontFamily: "Source Sans Pro" }}
              className="mt-14 flex flex-row gap-x-3 font-semibold bg-primary w-fit px-5 py-3 rounded-xl text-white"
            >
              Contact Us
              <img src={arrowRight} alt="Client Icon" />
            </button>
          </div>
        </div>
      </div>
      <Section6 />
    </>
  );
};

export default CredvestGroup;
