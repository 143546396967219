import React from "react";

const KGSec4DP2 = ({ imageSrc }) => {
  return (
    <svg width="225" height="246" viewBox="0 0 225 246" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1148_8937" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="225" height="246">
<rect width="225" height="246" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1148_8937)">
<mask id="mask1_1148_8937" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="14" y="8" width="167" height="188">
<path d="M28.592 42.0856C47.1705 9.90667 88.3878 -1.24042 120.567 17.3381L137.796 27.2856C178.945 51.0426 193.043 103.659 169.286 144.807L159.519 161.724C140.841 194.076 99.473 205.16 67.1213 186.482C16.6301 157.331 -0.559171 92.5768 28.592 42.0856Z" fill="url(#paint0_linear_1148_8937)"/>
</mask>
<g mask="url(#mask1_1148_8937)">
<path d="M72.504 43.0608C72.3701 43.2926 72.4491 43.5899 72.6809 43.7238L72.805 43.7955C73.1015 43.9666 73.4805 43.865 73.6517 43.5686L73.7221 43.4467C73.8566 43.2136 73.7768 42.9156 73.5437 42.7811C73.1799 42.571 72.714 42.697 72.504 43.0608Z" fill="#FF4A1C"/>
<path d="M153.122 123.677C152.989 123.909 153.067 124.206 153.299 124.34L153.423 124.412C153.72 124.583 154.099 124.481 154.27 124.185L154.34 124.063C154.475 123.83 154.395 123.532 154.162 123.397C153.798 123.187 153.332 123.313 153.122 123.677Z" fill="#FF4A1C"/>
<path d="M68.8199 47.5813C68.686 47.8131 68.7649 48.1105 68.9968 48.2443L69.1209 48.316C69.4174 48.4871 69.7964 48.3856 69.9676 48.0891L70.0379 47.9672C70.1725 47.7342 70.0926 47.4361 69.8596 47.3016C69.4958 47.0915 69.0299 47.2176 68.8199 47.5813Z" fill="#FF4A1C"/>
<path d="M149.438 128.198C149.304 128.43 149.383 128.727 149.615 128.861L149.739 128.933C150.036 129.104 150.415 129.002 150.586 128.706L150.656 128.584C150.791 128.351 150.711 128.053 150.478 127.918C150.114 127.708 149.648 127.834 149.438 128.198Z" fill="#FF4A1C"/>
<path d="M66.4541 36.2927C66.3203 36.5246 66.3992 36.8219 66.631 36.9557L66.7552 37.0274C67.0516 37.1986 67.4307 37.097 67.6018 36.8005L67.6722 36.6787C67.8068 36.4456 67.7269 36.1476 67.4938 36.013C67.1301 35.803 66.6641 35.929 66.4541 36.2927Z" fill="#FF4A1C"/>
<path d="M147.065 116.907C146.931 117.139 147.01 117.437 147.242 117.57L147.366 117.642C147.663 117.813 148.042 117.712 148.213 117.415L148.283 117.293C148.418 117.06 148.338 116.762 148.105 116.628C147.741 116.418 147.275 116.544 147.065 116.907Z" fill="#FF4A1C"/>
<path d="M54.6034 39.7776C54.4696 40.0094 54.5485 40.3067 54.7803 40.4406L54.9045 40.5123C55.2009 40.6834 55.58 40.5818 55.7511 40.2854L55.8215 40.1635C55.9561 39.9304 55.8762 39.6324 55.6431 39.4978C55.2794 39.2878 54.8135 39.4138 54.6034 39.7776Z" fill="#FF4A1C"/>
<path d="M135.222 120.394C135.088 120.626 135.167 120.923 135.399 121.057L135.523 121.128C135.819 121.3 136.198 121.198 136.37 120.902L136.44 120.78C136.574 120.547 136.495 120.249 136.262 120.114C135.898 119.904 135.432 120.03 135.222 120.394Z" fill="#FF4A1C"/>
<path d="M55.5776 56.0852C55.4437 56.317 55.5226 56.6144 55.7545 56.7482L55.8786 56.8199C56.175 56.991 56.5541 56.8895 56.7253 56.593L56.7956 56.4711C56.9302 56.2381 56.8503 55.94 56.6173 55.8055C56.2535 55.5955 55.7876 55.7215 55.5776 56.0852Z" fill="#FF4A1C"/>
<path d="M136.196 136.701C136.062 136.933 136.141 137.231 136.373 137.364L136.497 137.436C136.793 137.607 137.173 137.506 137.344 137.209L137.414 137.087C137.549 136.854 137.469 136.556 137.236 136.422C136.872 136.212 136.406 136.338 136.196 136.701Z" fill="#FF4A1C"/>
<path d="M41.498 43.4861C41.6318 43.2542 41.5529 42.9569 41.3211 42.8231L41.1969 42.7514C40.9005 42.5803 40.5214 42.6818 40.3503 42.9783L40.2799 43.1002C40.1453 43.3332 40.2252 43.6313 40.4583 43.7658C40.822 43.9758 41.288 43.8498 41.498 43.4861Z" fill="#FF4A1C"/>
<path d="M122.116 124.103C122.25 123.871 122.171 123.574 121.939 123.44L121.815 123.368C121.519 123.197 121.14 123.299 120.969 123.595L120.898 123.717C120.764 123.95 120.844 124.248 121.077 124.383C121.44 124.593 121.906 124.467 122.116 124.103Z" fill="#FF4A1C"/>
<path d="M45.1821 38.9651C45.3159 38.7332 45.237 38.4359 45.0052 38.3021L44.881 38.2304C44.5846 38.0593 44.2055 38.1608 44.0344 38.4573L43.964 38.5792C43.8294 38.8122 43.9093 39.1103 44.1424 39.2448C44.5061 39.4548 44.9721 39.3288 45.1821 38.9651Z" fill="#FF4A1C"/>
<path d="M125.8 119.582C125.934 119.35 125.855 119.053 125.624 118.919L125.499 118.847C125.203 118.676 124.824 118.778 124.653 119.074L124.582 119.196C124.448 119.429 124.528 119.727 124.761 119.862C125.125 120.072 125.59 119.946 125.8 119.582Z" fill="#FF4A1C"/>
<path d="M47.5552 50.2556C47.689 50.0238 47.6101 49.7265 47.3783 49.5926L47.2541 49.5209C46.9577 49.3498 46.5786 49.4514 46.4075 49.7478L46.3371 49.8697C46.2025 50.1028 46.2824 50.4008 46.5155 50.5354C46.8792 50.7454 47.3451 50.6194 47.5552 50.2556Z" fill="#FF4A1C"/>
<path d="M128.166 130.872C128.3 130.64 128.221 130.343 127.989 130.209L127.865 130.138C127.569 129.966 127.19 130.068 127.019 130.365L126.948 130.486C126.814 130.719 126.893 131.017 127.127 131.152C127.49 131.362 127.956 131.236 128.166 130.872Z" fill="#FF4A1C"/>
<path d="M59.3985 46.7693C59.5324 46.5374 59.4535 46.2401 59.2216 46.1063L59.0975 46.0346C58.801 45.8635 58.422 45.965 58.2508 46.2615L58.1805 46.3834C58.0459 46.6164 58.1258 46.9145 58.3588 47.049C58.7226 47.259 59.1885 47.133 59.3985 46.7693Z" fill="#FF4A1C"/>
<path d="M140.017 127.386C140.151 127.154 140.072 126.857 139.84 126.723L139.716 126.651C139.419 126.48 139.04 126.582 138.869 126.878L138.799 127C138.664 127.233 138.744 127.531 138.977 127.666C139.341 127.876 139.807 127.75 140.017 127.386Z" fill="#FF4A1C"/>
<path d="M58.4244 30.4636C58.5582 30.2318 58.4793 29.9345 58.2475 29.8006L58.1234 29.729C57.8269 29.5578 57.4478 29.6594 57.2767 29.9558L57.2063 30.0777C57.0718 30.3108 57.1516 30.6088 57.3847 30.7434C57.7484 30.9534 58.2144 30.8274 58.4244 30.4636Z" fill="#FF4A1C"/>
<path d="M139.035 111.08C139.169 110.848 139.09 110.551 138.859 110.417L138.734 110.346C138.438 110.174 138.059 110.276 137.888 110.573L137.817 110.694C137.683 110.927 137.763 111.225 137.996 111.36C138.36 111.57 138.825 111.444 139.035 111.08Z" fill="#FF4A1C"/>
<path d="M32.0204 20.9075C32.1542 20.6756 32.0753 20.3783 31.8435 20.2445L31.7193 20.1728C31.4229 20.0016 31.0438 20.1032 30.8727 20.3997L30.8023 20.5215C30.6677 20.7546 30.7476 21.0526 30.9807 21.1872C31.3444 21.3972 31.8103 21.2712 32.0204 20.9075Z" fill="#FF4A1C"/>
<path d="M112.639 101.524C112.773 101.292 112.694 100.995 112.462 100.861L112.338 100.789C112.041 100.618 111.662 100.719 111.491 101.016L111.421 101.138C111.286 101.371 111.366 101.669 111.599 101.803C111.963 102.013 112.429 101.887 112.639 101.524Z" fill="#FF4A1C"/>
<path d="M35.7045 16.3865C35.8383 16.1546 35.7594 15.8573 35.5276 15.7235L35.4034 15.6518C35.107 15.4806 34.7279 15.5822 34.5568 15.8787L34.4864 16.0005C34.3518 16.2336 34.4317 16.5316 34.6648 16.6662C35.0285 16.8762 35.4945 16.7502 35.7045 16.3865Z" fill="#FF4A1C"/>
<path d="M116.323 97.0032C116.457 96.7713 116.378 96.474 116.146 96.3402L116.022 96.2685C115.725 96.0973 115.346 96.1989 115.175 96.4954L115.105 96.6172C114.97 96.8503 115.05 97.1483 115.283 97.2829C115.647 97.4929 116.113 97.3669 116.323 97.0032Z" fill="#FF4A1C"/>
<path d="M38.0702 27.675C38.2041 27.4432 38.1251 27.1459 37.8933 27.012L37.7692 26.9404C37.4727 26.7692 37.0937 26.8708 36.9225 27.1672L36.8521 27.2891C36.7176 27.5222 36.7974 27.8202 37.0305 27.9548C37.3943 28.1648 37.8602 28.0388 38.0702 27.675Z" fill="#FF4A1C"/>
<path d="M118.689 108.291C118.822 108.059 118.744 107.762 118.512 107.628L118.388 107.557C118.091 107.385 117.712 107.487 117.541 107.783L117.471 107.905C117.336 108.138 117.416 108.436 117.649 108.571C118.013 108.781 118.479 108.655 118.689 108.291Z" fill="#FF4A1C"/>
<path d="M49.9209 24.1921C50.0547 23.9603 49.9758 23.663 49.744 23.5291L49.6199 23.4575C49.3234 23.2863 48.9443 23.3879 48.7732 23.6843L48.7028 23.8062C48.5683 24.0393 48.6481 24.3373 48.8812 24.4719C49.245 24.6819 49.7109 24.5559 49.9209 24.1921Z" fill="#FF4A1C"/>
<path d="M130.539 104.807C130.673 104.575 130.594 104.278 130.362 104.144L130.238 104.072C129.942 103.901 129.563 104.003 129.392 104.299L129.321 104.421C129.187 104.654 129.267 104.952 129.5 105.087C129.863 105.297 130.329 105.171 130.539 104.807Z" fill="#FF4A1C"/>
<path d="M48.9468 7.88451C49.0806 7.65268 49.0017 7.35536 48.7699 7.22152L48.6457 7.14985C48.3493 6.9787 47.9702 7.08027 47.7991 7.37672L47.7287 7.49859C47.5941 7.73166 47.674 8.0297 47.9071 8.16426C48.2708 8.37428 48.7368 8.24827 48.9468 7.88451Z" fill="#FF4A1C"/>
<path d="M129.558 88.5012C129.692 88.2694 129.613 87.9721 129.381 87.8382L129.257 87.7665C128.96 87.5954 128.581 87.697 128.41 87.9934L128.34 88.1153C128.205 88.3484 128.285 88.6464 128.518 88.781C128.882 88.991 129.348 88.865 129.558 88.5012Z" fill="#FF4A1C"/>
<path d="M63.0264 20.4817C62.8925 20.7135 62.9714 21.0108 63.2033 21.1447L63.3274 21.2164C63.6238 21.3875 64.0029 21.2859 64.1741 20.9895L64.2444 20.8676C64.379 20.6345 64.2991 20.3365 64.0661 20.202C63.7023 19.9919 63.2364 20.1179 63.0264 20.4817Z" fill="#FF4A1C"/>
<path d="M143.645 101.098C143.511 101.33 143.59 101.628 143.822 101.761L143.946 101.833C144.242 102.004 144.621 101.903 144.792 101.606L144.863 101.484C144.997 101.251 144.918 100.953 144.684 100.819C144.321 100.609 143.855 100.735 143.645 101.098Z" fill="#FF4A1C"/>
<path d="M59.3423 25.0027C59.2084 25.2345 59.2873 25.5318 59.5192 25.6657L59.6433 25.7374C59.9397 25.9085 60.3188 25.8069 60.49 25.5105L60.5603 25.3886C60.6949 25.1555 60.615 24.8575 60.382 24.7229C60.0182 24.5129 59.5523 24.6389 59.3423 25.0027Z" fill="#FF4A1C"/>
<path d="M139.961 105.619C139.827 105.851 139.906 106.148 140.138 106.282L140.262 106.354C140.558 106.525 140.937 106.423 141.108 106.127L141.179 106.005C141.313 105.772 141.233 105.474 141 105.339C140.637 105.129 140.171 105.255 139.961 105.619Z" fill="#FF4A1C"/>
<path d="M56.9692 13.7141C56.8353 13.946 56.9143 14.2433 57.1461 14.3771L57.2702 14.4488C57.5667 14.6199 57.9457 14.5184 58.1169 14.2219L58.1872 14.1C58.3218 13.867 58.242 13.5689 58.0089 13.4344C57.6451 13.2244 57.1792 13.3504 56.9692 13.7141Z" fill="#FF4A1C"/>
<path d="M137.588 94.3303C137.454 94.5622 137.533 94.8595 137.764 94.9933L137.889 95.065C138.185 95.2361 138.564 95.1346 138.735 94.8381L138.806 94.7163C138.94 94.4832 138.86 94.1851 138.627 94.0506C138.263 93.8406 137.798 93.9666 137.588 94.3303Z" fill="#FF4A1C"/>
<path d="M45.1258 17.1985C44.992 17.4303 45.0709 17.7276 45.3027 17.8615L45.4268 17.9332C45.7233 18.1043 46.1024 18.0027 46.2735 17.7063L46.3439 17.5844C46.4784 17.3513 46.3986 17.0533 46.1655 16.9187C45.8018 16.7087 45.3358 16.8347 45.1258 17.1985Z" fill="#FF4A1C"/>
<path d="M125.744 97.8132C125.61 98.0451 125.689 98.3424 125.921 98.4762L126.045 98.5479C126.342 98.7191 126.721 98.6175 126.892 98.321L126.962 98.1992C127.097 97.9661 127.017 97.6681 126.784 97.5335C126.42 97.3235 125.954 97.4495 125.744 97.8132Z" fill="#FF4A1C"/>
<path d="M46.0999 33.5042C45.9661 33.736 46.045 34.0333 46.2768 34.1672L46.401 34.2388C46.6974 34.41 47.0765 34.3084 47.2476 34.012L47.318 33.8901C47.4526 33.657 47.3727 33.359 47.1396 33.2244C46.7759 33.0144 46.3099 33.1404 46.0999 33.5042Z" fill="#FF4A1C"/>
<path d="M126.718 114.121C126.584 114.353 126.663 114.65 126.895 114.784L127.019 114.856C127.316 115.027 127.695 114.925 127.866 114.629L127.936 114.507C128.071 114.274 127.991 113.976 127.758 113.841C127.394 113.631 126.928 113.757 126.718 114.121Z" fill="#FF4A1C"/>
<path d="M102.746 27.5916C102.612 27.8234 102.691 28.1207 102.923 28.2546L103.047 28.3262C103.343 28.4974 103.723 28.3958 103.894 28.0994L103.964 27.9775C104.099 27.7444 104.019 27.4464 103.786 27.3118C103.422 27.1018 102.956 27.2278 102.746 27.5916Z" fill="#FF4A1C"/>
<path d="M99.0619 32.1126C98.928 32.3444 99.0069 32.6417 99.2388 32.7756L99.3629 32.8472C99.6593 33.0184 100.038 32.9168 100.21 32.6204L100.28 32.4985C100.414 32.2654 100.335 31.9674 100.102 31.8328C99.7378 31.6228 99.2719 31.7488 99.0619 32.1126Z" fill="#FF4A1C"/>
<path d="M179.68 112.727C179.546 112.959 179.625 113.256 179.857 113.39L179.981 113.462C180.278 113.633 180.657 113.532 180.828 113.235L180.898 113.113C181.033 112.88 180.953 112.582 180.72 112.448C180.356 112.238 179.89 112.364 179.68 112.727Z" fill="#FF4A1C"/>
<path d="M96.6961 20.822C96.5623 21.0539 96.6412 21.3512 96.873 21.485L96.9971 21.5567C97.2936 21.7278 97.6727 21.6263 97.8438 21.3298L97.9142 21.208C98.0487 20.9749 97.9689 20.6768 97.7358 20.5423C97.3721 20.3323 96.9061 20.4583 96.6961 20.822Z" fill="#FF4A1C"/>
<path d="M177.314 101.439C177.181 101.671 177.26 101.968 177.491 102.102L177.616 102.173C177.912 102.345 178.291 102.243 178.462 101.947L178.533 101.825C178.667 101.592 178.587 101.294 178.354 101.159C177.99 100.949 177.524 101.075 177.314 101.439Z" fill="#FF4A1C"/>
<path d="M84.8454 24.3069C84.7115 24.5387 84.7905 24.836 85.0223 24.9699L85.1464 25.0416C85.4429 25.2127 85.8219 25.1111 85.9931 24.8147L86.0635 24.6928C86.198 24.4597 86.1182 24.1617 85.8851 24.0271C85.5213 23.8171 85.0554 23.9431 84.8454 24.3069Z" fill="#FF4A1C"/>
<path d="M165.464 104.923C165.33 105.155 165.409 105.452 165.641 105.586L165.765 105.658C166.061 105.829 166.44 105.727 166.612 105.431L166.682 105.309C166.816 105.076 166.737 104.778 166.504 104.643C166.14 104.433 165.674 104.559 165.464 104.923Z" fill="#FF4A1C"/>
<path d="M85.8269 40.614C85.693 40.8459 85.7719 41.1432 86.0038 41.277L86.1279 41.3487C86.4243 41.5198 86.8034 41.4183 86.9746 41.1218L87.0449 40.9999C87.1795 40.7669 87.0996 40.4688 86.8666 40.3343C86.5028 40.1243 86.0369 40.2503 85.8269 40.614Z" fill="#FF4A1C"/>
<path d="M166.438 121.231C166.304 121.463 166.383 121.76 166.615 121.894L166.739 121.965C167.035 122.137 167.414 122.035 167.586 121.739L167.656 121.617C167.791 121.384 167.711 121.086 167.478 120.951C167.114 120.741 166.648 120.867 166.438 121.231Z" fill="#FF4A1C"/>
<path d="M71.7399 28.0154C71.8738 27.7835 71.7949 27.4862 71.563 27.3524L71.4389 27.2807C71.1425 27.1096 70.7634 27.2111 70.5922 27.5076L70.5219 27.6294C70.3873 27.8625 70.4672 28.1606 70.7002 28.2951C71.064 28.5051 71.5299 28.3791 71.7399 28.0154Z" fill="#FF4A1C"/>
<path d="M152.358 108.632C152.492 108.4 152.413 108.103 152.181 107.969L152.057 107.897C151.761 107.726 151.382 107.828 151.211 108.124L151.14 108.246C151.006 108.479 151.086 108.777 151.319 108.912C151.682 109.122 152.148 108.996 152.358 108.632Z" fill="#FF4A1C"/>
<path d="M75.4241 23.4944C75.5579 23.2625 75.479 22.9652 75.2472 22.8314L75.123 22.7597C74.8266 22.5886 74.4475 22.6901 74.2764 22.9866L74.206 23.1085C74.0714 23.3415 74.1513 23.6396 74.3844 23.7741C74.7481 23.9841 75.2141 23.8581 75.4241 23.4944Z" fill="#FF4A1C"/>
<path d="M156.042 104.109C156.176 103.877 156.097 103.58 155.866 103.446L155.741 103.374C155.445 103.203 155.066 103.305 154.895 103.601L154.824 103.723C154.69 103.956 154.77 104.254 155.003 104.389C155.367 104.599 155.832 104.473 156.042 104.109Z" fill="#FF4A1C"/>
<path d="M77.7971 34.7829C77.931 34.5511 77.8521 34.2538 77.6202 34.12L77.4961 34.0483C77.1997 33.8771 76.8206 33.9787 76.6494 34.2752L76.5791 34.397C76.4445 34.6301 76.5244 34.9281 76.7574 35.0627C77.1212 35.2727 77.5871 35.1467 77.7971 34.7829Z" fill="#FF4A1C"/>
<path d="M158.416 115.4C158.549 115.168 158.47 114.87 158.239 114.737L158.114 114.665C157.818 114.494 157.439 114.595 157.268 114.892L157.197 115.014C157.063 115.247 157.143 115.545 157.376 115.679C157.74 115.889 158.206 115.763 158.416 115.4Z" fill="#FF4A1C"/>
<path d="M89.6405 31.2986C89.7744 31.0667 89.6954 30.7694 89.4636 30.6356L89.3395 30.5639C89.043 30.3928 88.664 30.4943 88.4928 30.7908L88.4224 30.9127C88.2879 31.1457 88.3677 31.4438 88.6008 31.5783C88.9646 31.7883 89.4305 31.6623 89.6405 31.2986Z" fill="#FF4A1C"/>
<path d="M170.259 111.915C170.393 111.683 170.314 111.386 170.082 111.252L169.958 111.181C169.661 111.009 169.282 111.111 169.111 111.407L169.041 111.529C168.906 111.762 168.986 112.06 169.219 112.195C169.583 112.405 170.049 112.279 170.259 111.915Z" fill="#FF4A1C"/>
<path d="M88.6664 14.991C88.8002 14.7591 88.7213 14.4618 88.4895 14.328L88.3653 14.2563C88.0689 14.0851 87.6898 14.1867 87.5187 14.4832L87.4483 14.605C87.3137 14.8381 87.3936 15.1361 87.6267 15.2707C87.9904 15.4807 88.4564 15.3547 88.6664 14.991Z" fill="#FF4A1C"/>
<path d="M169.285 95.6077C169.419 95.3758 169.34 95.0785 169.108 94.9447L168.984 94.873C168.687 94.7018 168.308 94.8034 168.137 95.0999L168.067 95.2217C167.932 95.4548 168.012 95.7528 168.245 95.8874C168.609 96.0974 169.075 95.9714 169.285 95.6077Z" fill="#FF4A1C"/>
<path d="M142.881 86.053C143.015 85.8211 142.936 85.5238 142.704 85.39L142.58 85.3183C142.283 85.1472 141.904 85.2487 141.733 85.5452L141.663 85.667C141.528 85.9001 141.608 86.1982 141.841 86.3327C142.205 86.5427 142.671 86.4167 142.881 86.053Z" fill="#FF4A1C"/>
<path d="M146.565 81.532C146.699 81.3001 146.62 81.0028 146.388 80.869L146.264 80.7973C145.967 80.6262 145.588 80.7277 145.417 81.0242L145.347 81.1461C145.212 81.3791 145.292 81.6772 145.525 81.8117C145.889 82.0217 146.355 81.8957 146.565 81.532Z" fill="#FF4A1C"/>
<path d="M68.3195 12.2043C68.4534 11.9725 68.3744 11.6752 68.1426 11.5413L68.0185 11.4697C67.722 11.2985 67.343 11.4001 67.1718 11.6965L67.1014 11.8184C66.9669 12.0515 67.0467 12.3495 67.2798 12.4841C67.6436 12.6941 68.1095 12.5681 68.3195 12.2043Z" fill="#FF4A1C"/>
<path d="M148.931 92.8205C149.064 92.5887 148.985 92.2914 148.754 92.1576L148.63 92.0859C148.333 91.9147 147.954 92.0163 147.783 92.3128L147.713 92.4346C147.578 92.6677 147.658 92.9657 147.891 93.1003C148.255 93.3103 148.721 93.1843 148.931 92.8205Z" fill="#FF4A1C"/>
<path d="M80.1629 8.71947C80.2967 8.48764 80.2178 8.19032 79.986 8.05648L79.8619 7.98481C79.5654 7.81366 79.1863 7.91523 79.0152 8.21168L78.9448 8.33355C78.8103 8.56663 78.8901 8.86466 79.1232 8.99922C79.4869 9.20924 79.9529 9.08323 80.1629 8.71947Z" fill="#FF4A1C"/>
<path d="M160.781 89.3362C160.915 89.1043 160.836 88.807 160.604 88.6732L160.48 88.6015C160.184 88.4304 159.805 88.5319 159.634 88.8284L159.563 88.9503C159.429 89.1833 159.509 89.4814 159.742 89.6159C160.105 89.8259 160.571 89.6999 160.781 89.3362Z" fill="#FF4A1C"/>
<path d="M159.807 73.0305C159.941 72.7987 159.862 72.5014 159.63 72.3675L159.506 72.2958C159.21 72.1247 158.831 72.2263 158.659 72.5227L158.589 72.6446C158.455 72.8777 158.534 73.1757 158.767 73.3103C159.131 73.5203 159.597 73.3943 159.807 73.0305Z" fill="#FF4A1C"/>
<path d="M173.887 85.6277C173.753 85.8595 173.832 86.1568 174.064 86.2907L174.188 86.3624C174.484 86.5335 174.863 86.4319 175.034 86.1355L175.105 86.0136C175.239 85.7805 175.16 85.4825 174.926 85.3479C174.563 85.1379 174.097 85.2639 173.887 85.6277Z" fill="#FF4A1C"/>
<path d="M89.5842 9.53346C89.4504 9.76529 89.5293 10.0626 89.7611 10.1965L89.8853 10.2681C90.1817 10.4393 90.5608 10.3377 90.7319 10.0413L90.8023 9.91938C90.9369 9.6863 90.857 9.38827 90.6239 9.25371C90.2602 9.04369 89.7943 9.1697 89.5842 9.53346Z" fill="#FF4A1C"/>
<path d="M170.203 90.1482C170.069 90.38 170.148 90.6774 170.38 90.8112L170.504 90.8829C170.8 91.054 171.179 90.9524 171.35 90.656L171.421 90.5341C171.555 90.3011 171.475 90.003 171.242 89.8685C170.879 89.6584 170.413 89.7844 170.203 90.1482Z" fill="#FF4A1C"/>
<path d="M167.837 78.8596C167.703 79.0915 167.782 79.3888 168.014 79.5226L168.138 79.5943C168.434 79.7654 168.813 79.6639 168.985 79.3674L169.055 79.2456C169.19 79.0125 169.11 78.7144 168.877 78.5799C168.513 78.3699 168.047 78.4959 167.837 78.8596Z" fill="#FF4A1C"/>
<path d="M155.986 82.3425C155.852 82.5744 155.931 82.8717 156.163 83.0055L156.287 83.0772C156.584 83.2484 156.963 83.1468 157.134 82.8503L157.204 82.7285C157.339 82.4954 157.259 82.1974 157.026 82.0628C156.662 81.8528 156.196 81.9788 155.986 82.3425Z" fill="#FF4A1C"/>
<path d="M76.3492 18.0354C76.2154 18.2672 76.2943 18.5646 76.5261 18.6984L76.6503 18.7701C76.9467 18.9412 77.3258 18.8397 77.4969 18.5432L77.5673 18.4213C77.7019 18.1883 77.622 17.8902 77.3889 17.7557C77.0252 17.5456 76.5592 17.6717 76.3492 18.0354Z" fill="#FF4A1C"/>
<path d="M156.96 98.6502C156.826 98.882 156.905 99.1793 157.137 99.3132L157.261 99.3848C157.558 99.556 157.937 99.4544 158.108 99.158L158.178 99.0361C158.313 98.803 158.233 98.505 158 98.3704C157.636 98.1604 157.17 98.2864 156.96 98.6502Z" fill="#FF4A1C"/>
<path d="M104.841 86.2683C104.707 86.5002 104.786 86.7975 105.018 86.9313L105.142 87.003C105.438 87.1741 105.817 87.0726 105.988 86.7761L106.059 86.6542C106.193 86.4212 106.113 86.1231 105.88 85.9886C105.517 85.7786 105.051 85.9046 104.841 86.2683Z" fill="#FF4A1C"/>
<path d="M101.157 90.7893C101.023 91.0211 101.102 91.3185 101.333 91.4523L101.458 91.524C101.754 91.6951 102.133 91.5936 102.304 91.2971L102.375 91.1752C102.509 90.9422 102.429 90.6441 102.196 90.5096C101.833 90.2996 101.367 90.4256 101.157 90.7893Z" fill="#FF4A1C"/>
<path d="M98.7835 79.4988C98.6497 79.7306 98.7286 80.0279 98.9604 80.1618L99.0845 80.2335C99.381 80.4046 99.7601 80.303 99.9312 80.0066L100.002 79.8847C100.136 79.6516 100.056 79.3536 99.8232 79.219C99.4595 79.009 98.9935 79.135 98.7835 79.4988Z" fill="#FF4A1C"/>
<path d="M179.402 160.115C179.268 160.347 179.347 160.645 179.579 160.778L179.703 160.85C179.999 161.021 180.378 160.92 180.55 160.623L180.62 160.501C180.755 160.268 180.675 159.97 180.442 159.836C180.078 159.626 179.612 159.752 179.402 160.115Z" fill="#FF4A1C"/>
<path d="M86.9401 82.9837C86.8063 83.2155 86.8852 83.5128 87.117 83.6466L87.2412 83.7183C87.5376 83.8895 87.9167 83.7879 88.0878 83.4914L88.1582 83.3696C88.2928 83.1365 88.2129 82.8385 87.9798 82.7039C87.6161 82.4939 87.1502 82.6199 86.9401 82.9837Z" fill="#FF4A1C"/>
<path d="M167.559 163.6C167.425 163.832 167.504 164.129 167.735 164.263L167.86 164.335C168.156 164.506 168.535 164.404 168.706 164.108L168.777 163.986C168.911 163.753 168.831 163.455 168.598 163.32C168.235 163.11 167.769 163.236 167.559 163.6Z" fill="#FF4A1C"/>
<path d="M87.9143 99.2908C87.7804 99.5226 87.8594 99.8199 88.0912 99.9538L88.2153 100.025C88.5118 100.197 88.8908 100.095 89.062 99.7986L89.1323 99.6767C89.2669 99.4436 89.1871 99.1456 88.954 99.011C88.5902 98.801 88.1243 98.927 87.9143 99.2908Z" fill="#FF4A1C"/>
<path d="M168.533 179.907C168.399 180.139 168.478 180.437 168.71 180.57L168.834 180.642C169.13 180.813 169.509 180.712 169.68 180.415L169.751 180.293C169.885 180.06 169.805 179.762 169.572 179.628C169.209 179.418 168.743 179.544 168.533 179.907Z" fill="#FF4A1C"/>
<path d="M73.8347 86.6941C73.9685 86.4622 73.8896 86.1649 73.6578 86.0311L73.5337 85.9594C73.2372 85.7883 72.8581 85.8898 72.687 86.1863L72.6166 86.3082C72.4821 86.5412 72.5619 86.8393 72.795 86.9738C73.1587 87.1838 73.6247 87.0578 73.8347 86.6941Z" fill="#FF4A1C"/>
<path d="M154.446 167.309C154.58 167.077 154.501 166.78 154.269 166.646L154.145 166.574C153.848 166.403 153.469 166.505 153.298 166.801L153.228 166.923C153.093 167.156 153.173 167.454 153.406 167.589C153.77 167.799 154.236 167.673 154.446 167.309Z" fill="#FF4A1C"/>
<path d="M77.5188 82.1711C77.6527 81.9393 77.5737 81.642 77.3419 81.5081L77.2178 81.4365C76.9213 81.2653 76.5423 81.3669 76.3711 81.6633L76.3007 81.7852C76.1662 82.0183 76.246 82.3163 76.4791 82.4509C76.8429 82.6609 77.3088 82.5349 77.5188 82.1711Z" fill="#FF4A1C"/>
<path d="M158.13 162.788C158.264 162.556 158.185 162.259 157.953 162.125L157.829 162.053C157.532 161.882 157.153 161.984 156.982 162.28L156.912 162.402C156.777 162.635 156.857 162.933 157.09 163.068C157.454 163.278 157.92 163.152 158.13 162.788Z" fill="#FF4A1C"/>
<path d="M79.8846 93.4617C80.0184 93.2298 79.9395 92.9325 79.7076 92.7987L79.5835 92.727C79.2871 92.5558 78.908 92.6574 78.7368 92.9539L78.6665 93.0757C78.5319 93.3088 78.6118 93.6068 78.8448 93.7414C79.2086 93.9514 79.6745 93.8254 79.8846 93.4617Z" fill="#FF4A1C"/>
<path d="M160.503 174.078C160.637 173.847 160.558 173.549 160.326 173.415L160.202 173.344C159.905 173.173 159.526 173.274 159.355 173.571L159.285 173.692C159.15 173.926 159.23 174.224 159.463 174.358C159.827 174.568 160.293 174.442 160.503 174.078Z" fill="#FF4A1C"/>
<path d="M91.7352 89.9773C91.8691 89.7455 91.7902 89.4481 91.5583 89.3143L91.4342 89.2426C91.1377 89.0715 90.7587 89.173 90.5875 89.4695L90.5172 89.5914C90.3826 89.8244 90.4625 90.1225 90.6955 90.257C91.0593 90.467 91.5252 90.341 91.7352 89.9773Z" fill="#FF4A1C"/>
<path d="M172.346 170.593C172.48 170.362 172.401 170.064 172.169 169.931L172.045 169.859C171.749 169.688 171.37 169.789 171.199 170.086L171.128 170.208C170.994 170.441 171.074 170.739 171.307 170.873C171.67 171.083 172.136 170.957 172.346 170.593Z" fill="#FF4A1C"/>
<path d="M90.7611 73.6697C90.895 73.4378 90.816 73.1405 90.5842 73.0067L90.4601 72.935C90.1636 72.7639 89.7846 72.8654 89.6134 73.1619L89.543 73.2837C89.4085 73.5168 89.4883 73.8149 89.7214 73.9494C90.0852 74.1594 90.5511 74.0334 90.7611 73.6697Z" fill="#FF4A1C"/>
<path d="M171.372 154.286C171.506 154.055 171.427 153.757 171.195 153.623L171.071 153.552C170.775 153.381 170.396 153.482 170.224 153.779L170.154 153.9C170.02 154.134 170.099 154.432 170.332 154.566C170.696 154.776 171.162 154.65 171.372 154.286Z" fill="#FF4A1C"/>
<path d="M64.3571 64.115C64.4909 63.8831 64.412 63.5858 64.1802 63.452L64.056 63.3803C63.7596 63.2092 63.3805 63.3107 63.2094 63.6072L63.139 63.7291C63.0044 63.9621 63.0843 64.2602 63.3174 64.3947C63.6811 64.6047 64.1471 64.4787 64.3571 64.115Z" fill="#FF4A1C"/>
<path d="M144.975 144.73C145.109 144.498 145.03 144.201 144.799 144.067L144.674 143.995C144.378 143.824 143.999 143.925 143.828 144.222L143.757 144.344C143.623 144.577 143.703 144.875 143.936 145.009C144.3 145.219 144.765 145.093 144.975 144.73Z" fill="#FF4A1C"/>
<path d="M68.0412 59.594C68.175 59.3622 68.0961 59.0648 67.8643 58.931L67.7401 58.8593C67.4437 58.6882 67.0646 58.7897 66.8935 59.0862L66.8231 59.2081C66.6885 59.4411 66.7684 59.7392 67.0015 59.8737C67.3652 60.0837 67.8312 59.9577 68.0412 59.594Z" fill="#FF4A1C"/>
<path d="M148.652 140.209C148.786 139.977 148.707 139.68 148.475 139.546L148.351 139.474C148.055 139.303 147.676 139.404 147.505 139.701L147.434 139.823C147.3 140.056 147.379 140.354 147.613 140.488C147.976 140.698 148.442 140.572 148.652 140.209Z" fill="#FF4A1C"/>
<path d="M70.4069 70.8826C70.5408 70.6507 70.4618 70.3534 70.23 70.2196L70.1059 70.1479C69.8094 69.9767 69.4304 70.0783 69.2592 70.3748L69.1889 70.4966C69.0543 70.7297 69.1341 71.0277 69.3672 71.1623C69.731 71.3723 70.1969 71.2463 70.4069 70.8826Z" fill="#FF4A1C"/>
<path d="M151.025 151.497C151.159 151.265 151.08 150.968 150.848 150.834L150.724 150.763C150.428 150.591 150.049 150.693 149.878 150.99L149.807 151.111C149.673 151.344 149.753 151.642 149.986 151.777C150.349 151.987 150.815 151.861 151.025 151.497Z" fill="#FF4A1C"/>
<path d="M82.2576 67.3982C82.3915 67.1664 82.3125 66.869 82.0807 66.7352L81.9566 66.6635C81.6601 66.4924 81.2811 66.5939 81.1099 66.8904L81.0395 67.0123C80.905 67.2453 80.9848 67.5434 81.2179 67.6779C81.5817 67.8879 82.0476 67.7619 82.2576 67.3982Z" fill="#FF4A1C"/>
<path d="M162.876 148.015C163.01 147.783 162.931 147.486 162.699 147.352L162.575 147.28C162.279 147.109 161.899 147.211 161.728 147.507L161.658 147.629C161.523 147.862 161.603 148.16 161.836 148.295C162.2 148.505 162.666 148.379 162.876 148.015Z" fill="#FF4A1C"/>
<path d="M81.2835 51.0906C81.4173 50.8587 81.3384 50.5614 81.1066 50.4276L80.9825 50.3559C80.686 50.1848 80.3069 50.2863 80.1358 50.5828L80.0654 50.7046C79.9309 50.9377 80.0107 51.2357 80.2438 51.3703C80.6075 51.5803 81.0735 51.4543 81.2835 51.0906Z" fill="#FF4A1C"/>
<path d="M161.895 131.707C162.028 131.475 161.949 131.178 161.718 131.044L161.594 130.973C161.297 130.801 160.918 130.903 160.747 131.199L160.676 131.321C160.542 131.554 160.622 131.852 160.855 131.987C161.219 132.197 161.685 132.071 161.895 131.707Z" fill="#FF4A1C"/>
<path d="M95.3631 63.6892C95.2292 63.9211 95.3082 64.2184 95.54 64.3522L95.6641 64.4239C95.9606 64.595 96.3396 64.4935 96.5108 64.197L96.5812 64.0751C96.7157 63.8421 96.6359 63.544 96.4028 63.4095C96.039 63.1995 95.5731 63.3255 95.3631 63.6892Z" fill="#FF4A1C"/>
<path d="M175.981 144.306C175.848 144.538 175.927 144.835 176.158 144.969L176.283 145.041C176.579 145.212 176.958 145.11 177.129 144.814L177.2 144.692C177.334 144.459 177.254 144.161 177.021 144.026C176.657 143.816 176.191 143.942 175.981 144.306Z" fill="#FF4A1C"/>
<path d="M91.679 68.2102C91.5451 68.442 91.624 68.7394 91.8559 68.8732L91.98 68.9449C92.2764 69.116 92.6555 69.0145 92.8267 68.718L92.897 68.5961C93.0316 68.3631 92.9517 68.065 92.7187 67.9305C92.3549 67.7205 91.889 67.8465 91.679 68.2102Z" fill="#FF4A1C"/>
<path d="M172.297 148.827C172.164 149.059 172.242 149.356 172.474 149.49L172.598 149.562C172.895 149.733 173.274 149.631 173.445 149.335L173.515 149.213C173.65 148.98 173.57 148.682 173.337 148.547C172.973 148.337 172.507 148.463 172.297 148.827Z" fill="#FF4A1C"/>
<path d="M89.3059 56.9202C89.172 57.152 89.251 57.4493 89.4828 57.5832L89.6069 57.6548C89.9034 57.826 90.2824 57.7244 90.4536 57.428L90.524 57.3061C90.6585 57.073 90.5787 56.775 90.3456 56.6404C89.9818 56.4304 89.5159 56.5564 89.3059 56.9202Z" fill="#FF4A1C"/>
<path d="M169.924 137.536C169.79 137.768 169.869 138.066 170.101 138.199L170.225 138.271C170.522 138.442 170.901 138.341 171.072 138.044L171.142 137.922C171.277 137.689 171.197 137.391 170.964 137.257C170.6 137.047 170.134 137.173 169.924 137.536Z" fill="#FF4A1C"/>
<path d="M77.4625 60.4046C77.3287 60.6364 77.4076 60.9337 77.6394 61.0675L77.7635 61.1392C78.06 61.3104 78.4391 61.2088 78.6102 60.9123L78.6806 60.7905C78.8151 60.5574 78.7353 60.2594 78.5022 60.1248C78.1385 59.9148 77.6725 60.0408 77.4625 60.4046Z" fill="#FF4A1C"/>
<path d="M158.074 141.021C157.94 141.253 158.019 141.55 158.251 141.684L158.375 141.756C158.671 141.927 159.05 141.825 159.221 141.529L159.292 141.407C159.426 141.174 159.346 140.876 159.113 140.742C158.75 140.531 158.284 140.657 158.074 141.021Z" fill="#FF4A1C"/>
<path d="M78.4366 76.7122C78.3028 76.944 78.3817 77.2413 78.6136 77.3752L78.7377 77.4468C79.0341 77.618 79.4132 77.5164 79.5844 77.22L79.6547 77.0981C79.7893 76.865 79.7094 76.567 79.4763 76.4324C79.1126 76.2224 78.6467 76.3484 78.4366 76.7122Z" fill="#FF4A1C"/>
<path d="M159.055 157.328C158.921 157.56 159 157.858 159.232 157.991L159.356 158.063C159.653 158.234 160.032 158.133 160.203 157.836L160.273 157.714C160.408 157.481 160.328 157.183 160.095 157.049C159.731 156.839 159.265 156.965 159.055 157.328Z" fill="#FF4A1C"/>
<path d="M135.083 70.7957C134.949 71.0275 135.028 71.3248 135.26 71.4587L135.384 71.5303C135.68 71.7015 136.059 71.5999 136.23 71.3035L136.301 71.1816C136.435 70.9485 136.355 70.6505 136.122 70.5159C135.759 70.3059 135.293 70.4319 135.083 70.7957Z" fill="#FF4A1C"/>
<path d="M131.399 75.3186C131.265 75.5504 131.344 75.8478 131.575 75.9816L131.7 76.0533C131.996 76.2244 132.375 76.1229 132.546 75.8264L132.617 75.7045C132.751 75.4715 132.671 75.1734 132.438 75.0389C132.074 74.8288 131.609 74.9549 131.399 75.3186Z" fill="#FF4A1C"/>
<path d="M129.033 64.0281C128.899 64.2599 128.978 64.5572 129.21 64.6911L129.334 64.7627C129.63 64.9339 130.009 64.8323 130.181 64.5359L130.251 64.414C130.385 64.1809 130.306 63.8829 130.073 63.7483C129.709 63.5383 129.243 63.6643 129.033 64.0281Z" fill="#FF4A1C"/>
<path d="M117.182 67.5144C117.048 67.7462 117.127 68.0436 117.359 68.1774L117.483 68.2491C117.78 68.4202 118.159 68.3187 118.33 68.0222L118.4 67.9003C118.535 67.6673 118.455 67.3692 118.222 67.2347C117.858 67.0246 117.392 67.1507 117.182 67.5144Z" fill="#FF4A1C"/>
<path d="M118.164 83.8201C118.03 84.0519 118.109 84.3492 118.34 84.4831L118.465 84.5547C118.761 84.7259 119.14 84.6243 119.311 84.3279L119.382 84.206C119.516 83.9729 119.436 83.6749 119.203 83.5403C118.84 83.3303 118.374 83.4563 118.164 83.8201Z" fill="#FF4A1C"/>
<path d="M104.077 71.2214C104.21 70.9896 104.132 70.6923 103.9 70.5584L103.776 70.4868C103.479 70.3156 103.1 70.4172 102.929 70.7136L102.859 70.8355C102.724 71.0686 102.804 71.3666 103.037 71.5012C103.401 71.7112 103.867 71.5852 104.077 71.2214Z" fill="#FF4A1C"/>
<path d="M107.761 66.7004C107.895 66.4686 107.816 66.1713 107.584 66.0374L107.46 65.9658C107.163 65.7946 106.784 65.8962 106.613 66.1926L106.543 66.3145C106.408 66.5476 106.488 66.8456 106.721 66.9802C107.085 67.1902 107.551 67.0642 107.761 66.7004Z" fill="#FF4A1C"/>
<path d="M110.134 77.991C110.268 77.7591 110.189 77.4618 109.957 77.328L109.833 77.2563C109.536 77.0851 109.157 77.1867 108.986 77.4832L108.916 77.605C108.781 77.8381 108.861 78.1361 109.094 78.2707C109.458 78.4807 109.924 78.3547 110.134 77.991Z" fill="#FF4A1C"/>
<path d="M121.977 74.5066C122.111 74.2747 122.032 73.9774 121.8 73.8436L121.676 73.7719C121.38 73.6008 121.001 73.7023 120.829 73.9988L120.759 74.1207C120.625 74.3537 120.704 74.6518 120.938 74.7863C121.301 74.9963 121.767 74.8703 121.977 74.5066Z" fill="#FF4A1C"/>
<path d="M121.003 58.199C121.137 57.9671 121.058 57.6698 120.826 57.536L120.702 57.4643C120.406 57.2931 120.027 57.3947 119.855 57.6912L119.785 57.813C119.65 58.0461 119.73 58.3441 119.963 58.4787C120.327 58.6887 120.793 58.5627 121.003 58.199Z" fill="#FF4A1C"/>
<path d="M94.599 48.6423C94.7329 48.4105 94.654 48.1132 94.4221 47.9793L94.298 47.9077C94.0015 47.7365 93.6225 47.8381 93.4513 48.1345L93.381 48.2564C93.2464 48.4895 93.3263 48.7875 93.5593 48.9221C93.9231 49.1321 94.389 49.0061 94.599 48.6423Z" fill="#FF4A1C"/>
<path d="M175.217 129.259C175.351 129.027 175.272 128.73 175.041 128.596L174.916 128.524C174.62 128.353 174.241 128.455 174.07 128.751L173.999 128.873C173.865 129.106 173.945 129.404 174.178 129.539C174.541 129.749 175.007 129.623 175.217 129.259Z" fill="#FF4A1C"/>
<path d="M98.2831 44.1218C98.417 43.89 98.3381 43.5927 98.1062 43.4588L97.9821 43.3872C97.6857 43.216 97.3066 43.3176 97.1354 43.614L97.0651 43.7359C96.9305 43.969 97.0104 44.267 97.2434 44.4016C97.6072 44.6116 98.0731 44.4856 98.2831 44.1218Z" fill="#FF4A1C"/>
<path d="M178.902 124.738C179.035 124.506 178.956 124.209 178.725 124.075L178.601 124.003C178.304 123.832 177.925 123.934 177.754 124.23L177.683 124.352C177.549 124.585 177.629 124.883 177.862 125.018C178.226 125.228 178.692 125.102 178.902 124.738Z" fill="#FF4A1C"/>
<path d="M100.656 55.4119C100.79 55.18 100.711 54.8827 100.479 54.7489L100.355 54.6772C100.059 54.506 99.6797 54.6076 99.5085 54.9041L99.4381 55.0259C99.3036 55.259 99.3834 55.557 99.6165 55.6916C99.9803 55.9016 100.446 55.7756 100.656 55.4119Z" fill="#FF4A1C"/>
<path d="M181.267 136.029C181.401 135.797 181.322 135.499 181.09 135.366L180.966 135.294C180.67 135.123 180.291 135.224 180.12 135.521L180.049 135.643C179.915 135.876 179.995 136.174 180.228 136.308C180.591 136.518 181.057 136.392 181.267 136.029Z" fill="#FF4A1C"/>
<path d="M112.5 51.9275C112.633 51.6956 112.555 51.3983 112.323 51.2645L112.199 51.1928C111.902 51.0217 111.523 51.1232 111.352 51.4197L111.282 51.5416C111.147 51.7746 111.227 52.0727 111.46 52.2072C111.824 52.4172 112.29 52.2912 112.5 51.9275Z" fill="#FF4A1C"/>
<path d="M111.525 35.6199C111.659 35.388 111.58 35.0907 111.349 34.9569L111.224 34.8852C110.928 34.714 110.549 34.8156 110.378 35.1121L110.307 35.2339C110.173 35.467 110.253 35.765 110.486 35.8996C110.85 36.1096 111.315 35.9836 111.525 35.6199Z" fill="#FF4A1C"/>
<path d="M125.605 48.2171C125.471 48.4489 125.55 48.7462 125.782 48.88L125.906 48.9517C126.203 49.1229 126.582 49.0213 126.753 48.7248L126.823 48.603C126.958 48.3699 126.878 48.0719 126.645 47.9373C126.281 47.7273 125.815 47.8533 125.605 48.2171Z" fill="#FF4A1C"/>
<path d="M121.921 52.7395C121.787 52.9713 121.866 53.2687 122.098 53.4025L122.222 53.4742C122.518 53.6453 122.897 53.5438 123.069 53.2473L123.139 53.1254C123.274 52.8924 123.194 52.5943 122.961 52.4598C122.597 52.2497 122.131 52.3758 121.921 52.7395Z" fill="#FF4A1C"/>
<path d="M119.555 41.449C119.421 41.6808 119.5 41.9781 119.732 42.112L119.856 42.1836C120.153 42.3548 120.532 42.2532 120.703 41.9568L120.773 41.8349C120.908 41.6018 120.828 41.3038 120.595 41.1692C120.231 40.9592 119.765 41.0852 119.555 41.449Z" fill="#FF4A1C"/>
<path d="M107.705 44.9358C107.571 45.1676 107.65 45.4649 107.881 45.5988L108.006 45.6705C108.302 45.8416 108.681 45.74 108.852 45.4436L108.923 45.3217C109.057 45.0886 108.977 44.7906 108.744 44.6561C108.38 44.446 107.915 44.572 107.705 44.9358Z" fill="#FF4A1C"/>
<path d="M108.679 61.2415C108.545 61.4733 108.624 61.7706 108.856 61.9045L108.98 61.9761C109.276 62.1473 109.655 62.0457 109.826 61.7493L109.897 61.6274C110.031 61.3943 109.951 61.0963 109.718 60.9617C109.355 60.7517 108.889 60.8777 108.679 61.2415Z" fill="#FF4A1C"/>
<path d="M135.222 12.3972C135.088 12.6291 135.167 12.9264 135.399 13.0602L135.523 13.1319C135.819 13.303 136.198 13.2015 136.37 12.905L136.44 12.7831C136.574 12.5501 136.495 12.252 136.262 12.1175C135.898 11.9075 135.432 12.0335 135.222 12.3972Z" fill="#FF4A1C"/>
<path d="M24.0172 186.494C23.8834 186.726 23.9623 187.023 24.1941 187.157L24.3182 187.229C24.6147 187.4 24.9938 187.298 25.1649 187.002L25.2353 186.88C25.3698 186.647 25.29 186.349 25.0569 186.214C24.6932 186.004 24.2272 186.13 24.0172 186.494Z" fill="#FF4A1C"/>
<path d="M131.538 16.9202C131.404 17.152 131.483 17.4493 131.715 17.5832L131.839 17.6548C132.135 17.826 132.514 17.7244 132.685 17.428L132.756 17.3061C132.89 17.073 132.81 16.775 132.577 16.6404C132.214 16.4304 131.748 16.5564 131.538 16.9202Z" fill="#FF4A1C"/>
<path d="M20.3331 191.017C20.1993 191.249 20.2782 191.546 20.51 191.68L20.6341 191.752C20.9306 191.923 21.3096 191.821 21.4808 191.525L21.5512 191.403C21.6857 191.17 21.6059 190.872 21.3728 190.737C21.009 190.527 20.5431 190.653 20.3331 191.017Z" fill="#FF4A1C"/>
<path d="M17.9674 179.726C17.8335 179.958 17.9124 180.255 18.1443 180.389L18.2684 180.461C18.5648 180.632 18.9439 180.531 19.1151 180.234L19.1854 180.112C19.32 179.879 19.2401 179.581 19.0071 179.447C18.6433 179.237 18.1774 179.363 17.9674 179.726Z" fill="#FF4A1C"/>
<path d="M117.321 9.11452C117.187 9.34634 117.266 9.64366 117.498 9.77751L117.622 9.84917C117.919 10.0203 118.298 9.91876 118.469 9.62231L118.539 9.50043C118.674 9.26736 118.594 8.96933 118.361 8.83476C117.997 8.62475 117.531 8.75076 117.321 9.11452Z" fill="#FF4A1C"/>
<path d="M118.295 25.4216C118.162 25.6535 118.24 25.9508 118.472 26.0846L118.596 26.1563C118.893 26.3275 119.272 26.2259 119.443 25.9294L119.513 25.8076C119.648 25.5745 119.568 25.2765 119.335 25.1419C118.971 24.9319 118.505 25.0579 118.295 25.4216Z" fill="#FF4A1C"/>
<path d="M104.216 12.823C104.35 12.5912 104.271 12.2938 104.039 12.16L103.915 12.0883C103.618 11.9172 103.239 12.0187 103.068 12.3152L102.998 12.4371C102.863 12.6701 102.943 12.9682 103.176 13.1027C103.54 13.3128 104.006 13.1867 104.216 12.823Z" fill="#FF4A1C"/>
<path d="M107.9 8.30199C108.034 8.07016 107.955 7.77284 107.723 7.639L107.599 7.56733C107.302 7.39618 106.923 7.49775 106.752 7.7942L106.682 7.91607C106.547 8.14914 106.627 8.44718 106.86 8.58174C107.224 8.79176 107.69 8.66575 107.9 8.30199Z" fill="#FF4A1C"/>
<path d="M110.273 19.5925C110.407 19.3607 110.328 19.0634 110.096 18.9295L109.972 18.8579C109.676 18.6867 109.296 18.7883 109.125 19.0847L109.055 19.2066C108.92 19.4397 109 19.7377 109.233 19.8723C109.597 20.0823 110.063 19.9563 110.273 19.5925Z" fill="#FF4A1C"/>
<path d="M122.116 16.1081C122.25 15.8763 122.171 15.579 121.939 15.4451L121.815 15.3735C121.519 15.2023 121.14 15.3039 120.969 15.6003L120.898 15.7222C120.764 15.9553 120.844 16.2533 121.077 16.3879C121.44 16.5979 121.906 16.4719 122.116 16.1081Z" fill="#FF4A1C"/>
<path d="M175.357 70.8606C175.49 70.6288 175.412 70.3314 175.18 70.1976L175.056 70.1259C174.759 69.9548 174.38 70.0563 174.209 70.3528L174.139 70.4747C174.004 70.7077 174.084 71.0058 174.317 71.1403C174.681 71.3504 175.147 71.2243 175.357 70.8606Z" fill="#FF4A1C"/>
<path d="M179.041 66.3396C179.175 66.1078 179.096 65.8104 178.864 65.6766L178.74 65.6049C178.443 65.4338 178.064 65.5353 177.893 65.8318L177.823 65.9537C177.688 66.1867 177.768 66.4848 178.001 66.6193C178.365 66.8294 178.831 66.7033 179.041 66.3396Z" fill="#FF4A1C"/>
<path d="M181.406 77.6282C181.54 77.3963 181.461 77.099 181.23 76.9652L181.105 76.8935C180.809 76.7223 180.43 76.8239 180.259 77.1204L180.188 77.2422C180.054 77.4753 180.134 77.7733 180.367 77.9079C180.731 78.1179 181.196 77.9919 181.406 77.6282Z" fill="#FF4A1C"/>
<path d="M14.5396 163.915C14.4057 164.147 14.4847 164.444 14.7165 164.578L14.8406 164.649C15.1371 164.821 15.5161 164.719 15.6873 164.423L15.7577 164.301C15.8922 164.068 15.8124 163.77 15.5793 163.635C15.2155 163.425 14.7496 163.551 14.5396 163.915Z" fill="#FF4A1C"/>
<path d="M54.2665 171.025C54.1327 171.257 54.2116 171.554 54.4434 171.688L54.5675 171.76C54.864 171.931 55.2431 171.829 55.4142 171.533L55.4846 171.411C55.6191 171.178 55.5393 170.88 55.3062 170.745C54.9425 170.535 54.4765 170.661 54.2665 171.025Z" fill="#FF4A1C"/>
<path d="M50.5824 175.546C50.4485 175.777 50.5275 176.075 50.7593 176.209L50.8834 176.28C51.1799 176.451 51.5589 176.35 51.7301 176.053L51.8005 175.932C51.935 175.699 51.8552 175.4 51.6221 175.266C51.2583 175.056 50.7924 175.182 50.5824 175.546Z" fill="#FF4A1C"/>
<path d="M48.2093 164.256C48.0755 164.487 48.1544 164.785 48.3862 164.919L48.5104 164.99C48.8068 165.161 49.1859 165.06 49.357 164.763L49.4274 164.642C49.562 164.408 49.4821 164.11 49.249 163.976C48.8853 163.766 48.4193 163.892 48.2093 164.256Z" fill="#FF4A1C"/>
<path d="M36.3659 167.74C36.2321 167.972 36.311 168.269 36.5429 168.403L36.667 168.475C36.9634 168.646 37.3425 168.544 37.5137 168.248L37.584 168.126C37.7186 167.893 37.6387 167.595 37.4057 167.46C37.0419 167.25 36.576 167.376 36.3659 167.74Z" fill="#FF4A1C"/>
<path d="M148.545 9.95094C148.411 10.1828 148.49 10.4801 148.722 10.6139L148.846 10.6856C149.142 10.8568 149.521 10.7552 149.692 10.4587L149.763 10.3369C149.897 10.1038 149.818 9.80575 149.584 9.67119C149.221 9.46117 148.755 9.58718 148.545 9.95094Z" fill="#FF4A1C"/>
<path d="M37.3401 184.048C37.2062 184.279 37.2852 184.577 37.517 184.711L37.6411 184.782C37.9376 184.953 38.3166 184.852 38.4878 184.555L38.5582 184.434C38.6927 184.2 38.6129 183.902 38.3798 183.768C38.016 183.558 37.5501 183.684 37.3401 184.048Z" fill="#FF4A1C"/>
<path d="M23.2532 171.449C23.387 171.217 23.3081 170.92 23.0763 170.786L22.9521 170.714C22.6557 170.543 22.2766 170.645 22.1055 170.941L22.0351 171.063C21.9005 171.296 21.9804 171.594 22.2135 171.729C22.5772 171.939 23.0432 171.813 23.2532 171.449Z" fill="#FF4A1C"/>
<path d="M26.9446 166.928C27.0785 166.696 26.9995 166.399 26.7677 166.265L26.6436 166.193C26.3471 166.022 25.9681 166.124 25.7969 166.42L25.7265 166.542C25.592 166.775 25.6718 167.073 25.9049 167.208C26.2687 167.418 26.7346 167.292 26.9446 166.928Z" fill="#FF4A1C"/>
<path d="M29.3104 178.217C29.4442 177.985 29.3653 177.687 29.1335 177.554L29.0093 177.482C28.7129 177.311 28.3338 177.412 28.1627 177.709L28.0923 177.831C27.9577 178.064 28.0376 178.362 28.2707 178.496C28.6344 178.706 29.1003 178.58 29.3104 178.217Z" fill="#FF4A1C"/>
<path d="M41.1611 174.734C41.2949 174.502 41.216 174.204 40.9842 174.071L40.86 173.999C40.5636 173.828 40.1845 173.929 40.0134 174.226L39.943 174.348C39.8084 174.581 39.8883 174.879 40.1214 175.013C40.4851 175.223 40.951 175.097 41.1611 174.734Z" fill="#FF4A1C"/>
<path d="M40.1869 158.426C40.3208 158.194 40.2418 157.897 40.01 157.763L39.8859 157.691C39.5894 157.52 39.2104 157.622 39.0392 157.918L38.9689 158.04C38.8343 158.273 38.9141 158.571 39.1472 158.706C39.511 158.916 39.9769 158.79 40.1869 158.426Z" fill="#FF4A1C"/>
<path d="M17.467 144.349C17.6008 144.117 17.5219 143.82 17.2901 143.686L17.166 143.614C16.8695 143.443 16.4904 143.545 16.3193 143.841L16.2489 143.963C16.1144 144.196 16.1942 144.494 16.4273 144.629C16.7911 144.839 17.257 144.713 17.467 144.349Z" fill="#FF4A1C"/>
<path d="M19.8328 155.639C19.9666 155.408 19.8877 155.11 19.6558 154.976L19.5317 154.905C19.2353 154.734 18.8562 154.835 18.685 155.132L18.6147 155.253C18.4801 155.487 18.56 155.785 18.793 155.919C19.1568 156.129 19.6227 156.003 19.8328 155.639Z" fill="#FF4A1C"/>
<path d="M31.6834 152.153C31.8173 151.921 31.7384 151.624 31.5065 151.49L31.3824 151.418C31.086 151.247 30.7069 151.349 30.5357 151.645L30.4654 151.767C30.3308 152 30.4107 152.298 30.6437 152.433C31.0075 152.643 31.4734 152.517 31.6834 152.153Z" fill="#FF4A1C"/>
<path d="M30.702 135.847C30.8358 135.616 30.7569 135.318 30.5251 135.184L30.4009 135.113C30.1045 134.942 29.7254 135.043 29.5543 135.34L29.4839 135.461C29.3493 135.695 29.4292 135.993 29.6623 136.127C30.026 136.337 30.492 136.211 30.702 135.847Z" fill="#FF4A1C"/>
<path d="M44.7889 148.444C44.655 148.676 44.734 148.973 44.9658 149.107L45.0899 149.179C45.3864 149.35 45.7654 149.248 45.9366 148.952L46.007 148.83C46.1415 148.597 46.0617 148.299 45.8286 148.164C45.4648 147.954 44.9989 148.08 44.7889 148.444Z" fill="#FF4A1C"/>
<path d="M41.1048 152.967C40.9709 153.199 41.0499 153.496 41.2817 153.63L41.4058 153.702C41.7023 153.873 42.0813 153.771 42.2525 153.475L42.3229 153.353C42.4574 153.12 42.3776 152.822 42.1445 152.687C41.7807 152.477 41.3148 152.603 41.1048 152.967Z" fill="#FF4A1C"/>
<path d="M38.7317 141.677C38.5979 141.908 38.6768 142.206 38.9086 142.34L39.0327 142.411C39.3292 142.582 39.7083 142.481 39.8794 142.184L39.9498 142.062C40.0843 141.829 40.0045 141.531 39.7714 141.397C39.4076 141.187 38.9417 141.313 38.7317 141.677Z" fill="#FF4A1C"/>
<path d="M26.8883 145.161C26.7545 145.393 26.8334 145.69 27.0652 145.824L27.1894 145.896C27.4858 146.067 27.8649 145.965 28.036 145.669L28.1064 145.547C28.241 145.314 28.1611 145.016 27.928 144.881C27.5643 144.671 27.0984 144.797 26.8883 145.161Z" fill="#FF4A1C"/>
<path d="M27.8625 161.469C27.7286 161.7 27.8075 161.998 28.0394 162.132L28.1635 162.203C28.46 162.374 28.839 162.273 29.0102 161.976L29.0805 161.854C29.2151 161.621 29.1352 161.323 28.9022 161.189C28.5384 160.979 28.0725 161.105 27.8625 161.469Z" fill="#FF4A1C"/>
<path d="M29.3323 79.6828C29.4662 79.451 29.3873 79.1537 29.1554 79.0199L29.0313 78.9482C28.7349 78.777 28.3558 78.8786 28.1846 79.1751L28.1143 79.2969C27.9797 79.53 28.0596 79.828 28.2926 79.9626C28.6564 80.1726 29.1223 80.0466 29.3323 79.6828Z" fill="#FF4A1C"/>
<path d="M109.951 160.3C110.085 160.068 110.006 159.77 109.774 159.637L109.65 159.565C109.353 159.394 108.974 159.495 108.803 159.792L108.733 159.914C108.598 160.147 108.678 160.445 108.911 160.579C109.275 160.789 109.741 160.663 109.951 160.3Z" fill="#FF4A1C"/>
<path d="M33.0165 75.1619C33.1503 74.93 33.0714 74.6327 32.8395 74.4989L32.7154 74.4272C32.419 74.256 32.0399 74.3576 31.8687 74.6541L31.7984 74.7759C31.6638 75.009 31.7437 75.307 31.9767 75.4416C32.3405 75.6516 32.8064 75.5256 33.0165 75.1619Z" fill="#FF4A1C"/>
<path d="M113.635 155.777C113.769 155.545 113.69 155.247 113.458 155.114L113.334 155.042C113.037 154.871 112.658 154.972 112.487 155.269L112.417 155.391C112.282 155.624 112.362 155.922 112.595 156.056C112.959 156.266 113.425 156.14 113.635 155.777Z" fill="#FF4A1C"/>
<path d="M35.3895 86.4524C35.5234 86.2206 35.4445 85.9232 35.2126 85.7894L35.0885 85.7177C34.792 85.5466 34.413 85.6481 34.2418 85.9446L34.1715 86.0665C34.0369 86.2995 34.1167 86.5976 34.3498 86.7321C34.7136 86.9421 35.1795 86.8161 35.3895 86.4524Z" fill="#FF4A1C"/>
<path d="M116.001 167.067C116.134 166.835 116.056 166.538 115.824 166.404L115.7 166.332C115.403 166.161 115.024 166.263 114.853 166.559L114.783 166.681C114.648 166.914 114.728 167.212 114.961 167.347C115.325 167.557 115.791 167.431 116.001 167.067Z" fill="#FF4A1C"/>
<path d="M47.2329 82.968C47.3667 82.7362 47.2878 82.4389 47.056 82.305L46.9319 82.2333C46.6354 82.0622 46.2563 82.1638 46.0852 82.4602L46.0148 82.5821C45.8803 82.8152 45.9601 83.1132 46.1932 83.2478C46.5569 83.4578 47.0229 83.3318 47.2329 82.968Z" fill="#FF4A1C"/>
<path d="M127.851 163.582C127.985 163.35 127.906 163.053 127.674 162.919L127.55 162.848C127.254 162.676 126.875 162.778 126.704 163.074L126.633 163.196C126.499 163.429 126.579 163.727 126.812 163.862C127.175 164.072 127.641 163.946 127.851 163.582Z" fill="#FF4A1C"/>
<path d="M46.2588 66.6604C46.3926 66.4286 46.3137 66.1312 46.0819 65.9974L45.9577 65.9257C45.6613 65.7546 45.2822 65.8561 45.1111 66.1526L45.0407 66.2745C44.9061 66.5075 44.986 66.8056 45.2191 66.9401C45.5828 67.1502 46.0487 67.0241 46.2588 66.6604Z" fill="#FF4A1C"/>
<path d="M126.877 147.275C127.011 147.043 126.932 146.746 126.7 146.612L126.576 146.54C126.28 146.369 125.901 146.471 125.729 146.767L125.659 146.889C125.525 147.122 125.604 147.42 125.837 147.555C126.201 147.765 126.667 147.639 126.877 147.275Z" fill="#FF4A1C"/>
<path d="M60.3383 79.259C60.2045 79.4909 60.2834 79.7882 60.5153 79.922L60.6394 79.9937C60.9358 80.1649 61.3149 80.0633 61.4861 79.7668L61.5564 79.645C61.691 79.4119 61.6111 79.1139 61.3781 78.9793C61.0143 78.7693 60.5484 78.8953 60.3383 79.259Z" fill="#FF4A1C"/>
<path d="M140.957 159.874C140.823 160.106 140.902 160.403 141.134 160.537L141.258 160.608C141.554 160.78 141.933 160.678 142.104 160.382L142.175 160.26C142.309 160.027 142.23 159.729 141.996 159.594C141.633 159.384 141.167 159.51 140.957 159.874Z" fill="#FF4A1C"/>
<path d="M56.6542 83.7781C56.5204 84.0099 56.5993 84.3072 56.8311 84.4411L56.9553 84.5127C57.2517 84.6839 57.6308 84.5823 57.8019 84.2859L57.8723 84.164C58.0069 83.9309 57.927 83.6329 57.6939 83.4983C57.3302 83.2883 56.8643 83.4143 56.6542 83.7781Z" fill="#FF4A1C"/>
<path d="M137.273 164.395C137.139 164.627 137.218 164.924 137.45 165.058L137.574 165.129C137.87 165.301 138.249 165.199 138.42 164.903L138.491 164.781C138.625 164.548 138.545 164.25 138.312 164.115C137.949 163.905 137.483 164.031 137.273 164.395Z" fill="#FF4A1C"/>
<path d="M54.2885 72.4895C54.1546 72.7213 54.2336 73.0187 54.4654 73.1525L54.5895 73.2242C54.886 73.3953 55.265 73.2938 55.4362 72.9973L55.5066 72.8754C55.6411 72.6424 55.5613 72.3443 55.3282 72.2098C54.9644 71.9997 54.4985 72.1258 54.2885 72.4895Z" fill="#FF4A1C"/>
<path d="M134.9 153.106C134.766 153.338 134.845 153.635 135.076 153.769L135.201 153.841C135.497 154.012 135.876 153.91 136.047 153.614L136.118 153.492C136.252 153.259 136.172 152.961 135.939 152.826C135.576 152.616 135.11 152.742 134.9 153.106Z" fill="#FF4A1C"/>
<path d="M42.4378 75.9739C42.3039 76.2057 42.3829 76.503 42.6147 76.6369L42.7388 76.7085C43.0353 76.8797 43.4143 76.7781 43.5855 76.4817L43.6559 76.3598C43.7904 76.1267 43.7106 75.8287 43.4775 75.6941C43.1137 75.4841 42.6478 75.6101 42.4378 75.9739Z" fill="#FF4A1C"/>
<path d="M123.056 156.591C122.922 156.822 123.001 157.12 123.233 157.254L123.357 157.325C123.654 157.496 124.033 157.395 124.204 157.098L124.274 156.977C124.409 156.743 124.329 156.445 124.096 156.311C123.732 156.101 123.266 156.227 123.056 156.591Z" fill="#FF4A1C"/>
<path d="M43.4119 92.2796C43.2781 92.5114 43.357 92.8087 43.5888 92.9425L43.713 93.0142C44.0094 93.1854 44.3885 93.0838 44.5596 92.7873L44.63 92.6655C44.7646 92.4324 44.6847 92.1344 44.4516 91.9998C44.0879 91.7898 43.6219 91.9158 43.4119 92.2796Z" fill="#FF4A1C"/>
<path d="M124.03 172.896C123.896 173.128 123.975 173.425 124.207 173.559L124.331 173.631C124.628 173.802 125.007 173.7 125.178 173.404L125.248 173.282C125.383 173.049 125.303 172.751 125.07 172.617C124.706 172.406 124.24 172.532 124.03 172.896Z" fill="#FF4A1C"/>
<path d="M69.816 101.836C69.6821 102.068 69.761 102.365 69.9929 102.499L70.117 102.571C70.4134 102.742 70.7925 102.64 70.9637 102.344L71.034 102.222C71.1686 101.989 71.0887 101.691 70.8557 101.556C70.4919 101.346 70.026 101.472 69.816 101.836Z" fill="#FF4A1C"/>
<path d="M150.434 182.453C150.301 182.685 150.379 182.982 150.611 183.116L150.735 183.188C151.032 183.359 151.411 183.257 151.582 182.961L151.652 182.839C151.787 182.606 151.707 182.308 151.474 182.173C151.11 181.963 150.644 182.089 150.434 182.453Z" fill="#FF4A1C"/>
<path d="M66.1319 106.359C65.998 106.59 66.0769 106.888 66.3088 107.022L66.4329 107.093C66.7293 107.264 67.1084 107.163 67.2796 106.866L67.3499 106.745C67.4845 106.511 67.4046 106.213 67.1716 106.079C66.8078 105.869 66.3419 105.995 66.1319 106.359Z" fill="#FF4A1C"/>
<path d="M146.75 186.973C146.616 187.205 146.695 187.503 146.927 187.636L147.051 187.708C147.348 187.879 147.727 187.778 147.898 187.481L147.968 187.359C148.103 187.126 148.023 186.828 147.79 186.694C147.426 186.484 146.96 186.61 146.75 186.973Z" fill="#FF4A1C"/>
<path d="M63.7661 95.0686C63.6323 95.3004 63.7112 95.5978 63.943 95.7316L64.0671 95.8033C64.3636 95.9744 64.7427 95.8729 64.9138 95.5764L64.9842 95.4545C65.1187 95.2215 65.0389 94.9234 64.8058 94.7889C64.4421 94.5788 63.9761 94.7049 63.7661 95.0686Z" fill="#FF4A1C"/>
<path d="M144.385 175.685C144.251 175.917 144.33 176.214 144.561 176.348L144.686 176.419C144.982 176.591 145.361 176.489 145.532 176.193L145.603 176.071C145.737 175.838 145.657 175.54 145.424 175.405C145.06 175.195 144.595 175.321 144.385 175.685Z" fill="#FF4A1C"/>
<path d="M51.9154 98.553C51.7816 98.7848 51.8605 99.0821 52.0923 99.216L52.2165 99.2876C52.5129 99.4588 52.892 99.3572 53.0631 99.0608L53.1335 98.9389C53.2681 98.7058 53.1882 98.4078 52.9551 98.2732C52.5914 98.0632 52.1254 98.1892 51.9154 98.553Z" fill="#FF4A1C"/>
<path d="M132.534 179.17C132.4 179.402 132.479 179.699 132.711 179.833L132.835 179.904C133.131 180.076 133.51 179.974 133.682 179.677L133.752 179.556C133.886 179.323 133.807 179.025 133.574 178.89C133.21 178.68 132.744 178.806 132.534 179.17Z" fill="#FF4A1C"/>
<path d="M52.8895 114.859C52.7557 115.09 52.8346 115.388 53.0664 115.522L53.1906 115.593C53.487 115.764 53.8661 115.663 54.0372 115.366L54.1076 115.245C54.2422 115.011 54.1623 114.713 53.9292 114.579C53.5655 114.369 53.0996 114.495 52.8895 114.859Z" fill="#FF4A1C"/>
<path d="M133.508 195.475C133.374 195.707 133.453 196.005 133.685 196.138L133.809 196.21C134.105 196.381 134.485 196.28 134.656 195.983L134.726 195.861C134.861 195.628 134.781 195.33 134.548 195.196C134.184 194.986 133.718 195.112 133.508 195.475Z" fill="#FF4A1C"/>
<path d="M38.81 102.262C38.9438 102.03 38.8649 101.733 38.633 101.599L38.5089 101.527C38.2125 101.356 37.8334 101.458 37.6622 101.754L37.5919 101.876C37.4573 102.109 37.5372 102.407 37.7702 102.542C38.134 102.752 38.5999 102.626 38.81 102.262Z" fill="#FF4A1C"/>
<path d="M119.428 182.878C119.562 182.646 119.483 182.349 119.251 182.215L119.127 182.144C118.831 181.972 118.452 182.074 118.281 182.37L118.21 182.492C118.076 182.725 118.156 183.023 118.389 183.158C118.752 183.368 119.218 183.242 119.428 182.878Z" fill="#FF4A1C"/>
<path d="M42.4941 97.741C42.6279 97.5091 42.549 97.2118 42.3172 97.078L42.193 97.0063C41.8966 96.8351 41.5175 96.9367 41.3464 97.2332L41.276 97.355C41.1414 97.5881 41.2213 97.8861 41.4544 98.0207C41.8181 98.2307 42.2841 98.1047 42.4941 97.741Z" fill="#FF4A1C"/>
<path d="M123.112 178.356C123.246 178.124 123.167 177.827 122.936 177.693L122.811 177.621C122.515 177.45 122.136 177.551 121.965 177.848L121.894 177.97C121.76 178.203 121.84 178.501 122.073 178.635C122.437 178.845 122.902 178.719 123.112 178.356Z" fill="#FF4A1C"/>
<path d="M44.8671 109.031C45.001 108.799 44.9221 108.502 44.6902 108.368L44.5661 108.296C44.2697 108.125 43.8906 108.227 43.7194 108.523L43.6491 108.645C43.5145 108.878 43.5944 109.176 43.8274 109.311C44.1912 109.521 44.6571 109.395 44.8671 109.031Z" fill="#FF4A1C"/>
<path d="M125.478 189.646C125.612 189.414 125.533 189.117 125.301 188.983L125.177 188.911C124.881 188.74 124.502 188.841 124.331 189.138L124.26 189.26C124.126 189.493 124.205 189.791 124.439 189.925C124.802 190.136 125.268 190.009 125.478 189.646Z" fill="#FF4A1C"/>
<path d="M56.7105 105.547C56.8444 105.315 56.7654 105.017 56.5336 104.884L56.4095 104.812C56.113 104.641 55.734 104.742 55.5628 105.039L55.4924 105.161C55.3579 105.394 55.4377 105.692 55.6708 105.826C56.0346 106.036 56.5005 105.91 56.7105 105.547Z" fill="#FF4A1C"/>
<path d="M137.329 186.161C137.463 185.93 137.384 185.632 137.152 185.498L137.028 185.427C136.731 185.256 136.352 185.357 136.181 185.654L136.111 185.775C135.976 186.009 136.056 186.307 136.289 186.441C136.653 186.651 137.119 186.525 137.329 186.161Z" fill="#FF4A1C"/>
<path d="M55.7364 89.239C55.8702 89.0072 55.7913 88.7099 55.5595 88.576L55.4353 88.5043C55.1389 88.3332 54.7598 88.4348 54.5887 88.7312L54.5183 88.8531C54.3837 89.0862 54.4636 89.3842 54.6967 89.5188C55.0604 89.7288 55.5264 89.6028 55.7364 89.239Z" fill="#FF4A1C"/>
<path d="M136.355 169.854C136.489 169.622 136.41 169.325 136.178 169.191L136.054 169.119C135.757 168.948 135.378 169.05 135.207 169.346L135.137 169.468C135.002 169.701 135.082 169.999 135.315 170.133C135.679 170.344 136.145 170.218 136.355 169.854Z" fill="#FF4A1C"/>
<path d="M79.7014 175.77C79.8353 175.538 79.7564 175.241 79.5245 175.107L79.4004 175.036C79.1039 174.864 78.7249 174.966 78.5537 175.262L78.4834 175.384C78.3488 175.617 78.4287 175.915 78.6617 176.05C79.0255 176.26 79.4914 176.134 79.7014 175.77Z" fill="#FF4A1C"/>
<path d="M83.3929 171.249C83.5267 171.017 83.4478 170.72 83.216 170.586L83.0918 170.515C82.7954 170.343 82.4163 170.445 82.2452 170.741L82.1748 170.863C82.0402 171.096 82.1201 171.394 82.3532 171.529C82.7169 171.739 83.1829 171.613 83.3929 171.249Z" fill="#FF4A1C"/>
<path d="M85.7586 182.538C85.8925 182.306 85.8135 182.009 85.5817 181.875L85.4576 181.803C85.1611 181.632 84.7821 181.734 84.6109 182.03L84.5406 182.152C84.406 182.385 84.4858 182.683 84.7189 182.818C85.0827 183.028 85.5486 182.902 85.7586 182.538Z" fill="#FF4A1C"/>
<path d="M16.9909 98.4387C17.1248 98.2069 17.0458 97.9096 16.814 97.7757L16.6899 97.704C16.3934 97.5329 16.0144 97.6345 15.8432 97.9309L15.7729 98.0528C15.6383 98.2859 15.7181 98.5839 15.9512 98.7185C16.315 98.9285 16.7809 98.8025 16.9909 98.4387Z" fill="#FF4A1C"/>
<path d="M97.6093 179.055C97.7432 178.823 97.6642 178.526 97.4324 178.392L97.3083 178.32C97.0118 178.149 96.6328 178.251 96.4616 178.547L96.3912 178.669C96.2567 178.902 96.3365 179.2 96.5696 179.335C96.9334 179.545 97.3993 179.419 97.6093 179.055Z" fill="#FF4A1C"/>
<path d="M16.0168 82.1311C16.1506 81.8993 16.0717 81.6019 15.8399 81.4681L15.7158 81.3964C15.4193 81.2253 15.0403 81.3268 14.8691 81.6233L14.7987 81.7452C14.6642 81.9782 14.744 82.2763 14.9771 82.4108C15.3409 82.6209 15.8068 82.4948 16.0168 82.1311Z" fill="#FF4A1C"/>
<path d="M96.6279 162.746C96.7617 162.514 96.6828 162.217 96.451 162.083L96.3268 162.011C96.0304 161.84 95.6513 161.942 95.4802 162.238L95.4098 162.36C95.2752 162.593 95.3551 162.891 95.5882 163.026C95.9519 163.236 96.4179 163.11 96.6279 162.746Z" fill="#FF4A1C"/>
<path d="M30.0964 94.7278C29.9625 94.9596 30.0415 95.2569 30.2733 95.3908L30.3974 95.4625C30.6939 95.6336 31.0729 95.532 31.2441 95.2356L31.3144 95.1137C31.449 94.8806 31.3692 94.5826 31.1361 94.448C30.7723 94.238 30.3064 94.364 30.0964 94.7278Z" fill="#FF4A1C"/>
<path d="M110.715 175.344C110.581 175.576 110.66 175.874 110.892 176.007L111.016 176.079C111.312 176.25 111.691 176.149 111.862 175.852L111.933 175.73C112.067 175.497 111.988 175.199 111.754 175.065C111.391 174.855 110.925 174.981 110.715 175.344Z" fill="#FF4A1C"/>
<path d="M26.4123 99.2507C26.2784 99.4826 26.3573 99.7799 26.5892 99.9137L26.7133 99.9854C27.0097 100.157 27.3888 100.055 27.56 99.7585L27.6303 99.6367C27.7649 99.4036 27.685 99.1056 27.452 98.971C27.0882 98.761 26.6223 98.887 26.4123 99.2507Z" fill="#FF4A1C"/>
<path d="M107.031 179.867C106.897 180.099 106.976 180.397 107.208 180.53L107.332 180.602C107.628 180.773 108.007 180.672 108.178 180.375L108.249 180.253C108.383 180.02 108.303 179.722 108.07 179.588C107.707 179.378 107.241 179.504 107.031 179.867Z" fill="#FF4A1C"/>
<path d="M24.0392 87.9602C23.9054 88.192 23.9843 88.4894 24.2161 88.6232L24.3402 88.6949C24.6367 88.866 25.0157 88.7645 25.1869 88.468L25.2573 88.3461C25.3918 88.1131 25.312 87.815 25.0789 87.6805C24.7151 87.4705 24.2492 87.5965 24.0392 87.9602Z" fill="#FF4A1C"/>
<path d="M104.658 168.577C104.524 168.809 104.603 169.106 104.834 169.24L104.959 169.312C105.255 169.483 105.634 169.381 105.805 169.085L105.876 168.963C106.01 168.73 105.93 168.432 105.697 168.297C105.334 168.087 104.868 168.213 104.658 168.577Z" fill="#FF4A1C"/>
<path d="M92.8142 172.061C92.6804 172.293 92.7593 172.59 92.9911 172.724L93.1153 172.796C93.4117 172.967 93.7908 172.866 93.9619 172.569L94.0323 172.447C94.1669 172.214 94.087 171.916 93.8539 171.782C93.4902 171.572 93.0242 171.698 92.8142 172.061Z" fill="#FF4A1C"/>
<path d="M13.17 107.752C13.0361 107.984 13.115 108.281 13.3469 108.415L13.471 108.487C13.7674 108.658 14.1465 108.556 14.3177 108.26L14.388 108.138C14.5226 107.905 14.4427 107.607 14.2097 107.472C13.8459 107.262 13.38 107.388 13.17 107.752Z" fill="#FF4A1C"/>
<path d="M93.7884 188.369C93.6545 188.601 93.7334 188.898 93.9653 189.032L94.0894 189.104C94.3858 189.275 94.7649 189.173 94.9361 188.877L95.0064 188.755C95.141 188.522 95.0611 188.224 94.8281 188.089C94.4643 187.879 93.9984 188.005 93.7884 188.369Z" fill="#FF4A1C"/>
<path d="M39.574 117.307C39.4402 117.539 39.5191 117.836 39.7509 117.97L39.875 118.042C40.1715 118.213 40.5506 118.111 40.7217 117.815L40.7921 117.693C40.9266 117.46 40.8468 117.162 40.6137 117.027C40.25 116.817 39.784 116.943 39.574 117.307Z" fill="#FF4A1C"/>
<path d="M35.8899 121.829C35.756 122.061 35.835 122.359 36.0668 122.492L36.1909 122.564C36.4874 122.735 36.8664 122.634 37.0376 122.337L37.1079 122.215C37.2425 121.982 37.1627 121.684 36.9296 121.55C36.5658 121.34 36.0999 121.466 35.8899 121.829Z" fill="#FF4A1C"/>
<path d="M33.5168 110.539C33.383 110.771 33.4619 111.068 33.6937 111.202L33.8178 111.274C34.1143 111.445 34.4934 111.344 34.6645 111.047L34.7349 110.925C34.8694 110.692 34.7896 110.394 34.5565 110.26C34.1928 110.05 33.7268 110.176 33.5168 110.539Z" fill="#FF4A1C"/>
<path d="M114.135 191.156C114.001 191.388 114.08 191.685 114.312 191.819L114.436 191.891C114.733 192.062 115.112 191.96 115.283 191.664L115.353 191.542C115.488 191.309 115.408 191.011 115.175 190.876C114.811 190.666 114.345 190.792 114.135 191.156Z" fill="#FF4A1C"/>
<path d="M21.6734 114.024C21.5396 114.256 21.6185 114.553 21.8504 114.687L21.9745 114.758C22.2709 114.93 22.65 114.828 22.8212 114.531L22.8915 114.41C23.0261 114.177 22.9462 113.879 22.7131 113.744C22.3494 113.534 21.8835 113.66 21.6734 114.024Z" fill="#FF4A1C"/>
<path d="M102.285 194.64C102.151 194.872 102.23 195.17 102.461 195.303L102.586 195.375C102.882 195.546 103.261 195.445 103.432 195.148L103.503 195.026C103.637 194.793 103.557 194.495 103.324 194.361C102.96 194.151 102.495 194.277 102.285 194.64Z" fill="#FF4A1C"/>
<path d="M22.6476 130.329C22.5137 130.561 22.5927 130.859 22.8245 130.992L22.9486 131.064C23.2451 131.235 23.6241 131.134 23.7953 130.837L23.8657 130.715C24.0002 130.482 23.9204 130.184 23.6873 130.05C23.3235 129.84 22.8576 129.966 22.6476 130.329Z" fill="#FF4A1C"/>
<path d="M92.8705 193.826C93.0043 193.595 92.9254 193.297 92.6936 193.163L92.5695 193.092C92.273 192.921 91.8939 193.022 91.7228 193.319L91.6524 193.44C91.5179 193.674 91.5977 193.972 91.8308 194.106C92.1946 194.316 92.6605 194.19 92.8705 193.826Z" fill="#FF4A1C"/>
<path d="M14.6179 124.5C14.7517 124.268 14.6728 123.971 14.441 123.837L14.3168 123.766C14.0204 123.594 13.6413 123.696 13.4702 123.992L13.3998 124.114C13.2652 124.347 13.3451 124.645 13.5782 124.78C13.9419 124.99 14.4078 124.864 14.6179 124.5Z" fill="#FF4A1C"/>
<path d="M26.4685 121.015C26.6024 120.784 26.5235 120.486 26.2916 120.352L26.1675 120.281C25.8711 120.11 25.492 120.211 25.3208 120.508L25.2505 120.629C25.1159 120.863 25.1958 121.161 25.4288 121.295C25.7926 121.505 26.2585 121.379 26.4685 121.015Z" fill="#FF4A1C"/>
<path d="M25.4944 104.71C25.6283 104.478 25.5493 104.181 25.3175 104.047L25.1934 103.975C24.8969 103.804 24.5179 103.905 24.3467 104.202L24.2763 104.324C24.1418 104.557 24.2216 104.855 24.4547 104.989C24.8185 105.199 25.2844 105.073 25.4944 104.71Z" fill="#FF4A1C"/>
<path d="M106.106 185.324C106.239 185.093 106.16 184.795 105.929 184.661L105.804 184.59C105.508 184.419 105.129 184.52 104.958 184.817L104.887 184.939C104.753 185.172 104.833 185.47 105.066 185.604C105.43 185.814 105.895 185.688 106.106 185.324Z" fill="#FF4A1C"/>
<path d="M77.614 117.092C77.7479 116.86 77.6689 116.562 77.4371 116.429L77.313 116.357C77.0165 116.186 76.6375 116.287 76.4663 116.584L76.3959 116.706C76.2614 116.939 76.3412 117.237 76.5743 117.371C76.9381 117.581 77.404 117.455 77.614 117.092Z" fill="#FF4A1C"/>
<path d="M81.2981 112.571C81.432 112.339 81.3531 112.041 81.1212 111.908L80.9971 111.836C80.7006 111.665 80.3216 111.766 80.1504 112.063L80.0801 112.185C79.9455 112.418 80.0253 112.716 80.2584 112.85C80.6222 113.06 81.0881 112.934 81.2981 112.571Z" fill="#FF4A1C"/>
<path d="M83.6639 123.861C83.7977 123.629 83.7188 123.332 83.487 123.198L83.3628 123.126C83.0664 122.955 82.6873 123.057 82.5162 123.353L82.4458 123.475C82.3112 123.708 82.3911 124.006 82.6242 124.141C82.9879 124.351 83.4539 124.225 83.6639 123.861Z" fill="#FF4A1C"/>
<path d="M14.9035 39.76C15.0373 39.5282 14.9584 39.2309 14.7266 39.097L14.6025 39.0253C14.306 38.8542 13.9269 38.9558 13.7558 39.2522L13.6854 39.3741C13.5509 39.6072 13.6307 39.9052 13.8638 40.0397C14.2275 40.2498 14.6935 40.1238 14.9035 39.76Z" fill="#FF4A1C"/>
<path d="M95.5146 120.377C95.6484 120.145 95.5695 119.848 95.3377 119.714L95.2135 119.642C94.9171 119.471 94.538 119.572 94.3669 119.869L94.2965 119.991C94.1619 120.224 94.2418 120.522 94.4749 120.656C94.8386 120.866 95.3046 120.74 95.5146 120.377Z" fill="#FF4A1C"/>
<path d="M94.5404 104.069C94.6743 103.837 94.5954 103.54 94.3635 103.406L94.2394 103.334C93.943 103.163 93.5639 103.265 93.3927 103.561L93.3224 103.683C93.1878 103.916 93.2677 104.214 93.5007 104.349C93.8645 104.559 94.3304 104.433 94.5404 104.069Z" fill="#FF4A1C"/>
<path d="M28.009 36.051C27.8751 36.2829 27.954 36.5802 28.1859 36.714L28.31 36.7857C28.6064 36.9569 28.9855 36.8553 29.1567 36.5588L29.227 36.437C29.3616 36.2039 29.2817 35.9059 29.0487 35.7713C28.6849 35.5613 28.219 35.6873 28.009 36.051Z" fill="#FF4A1C"/>
<path d="M108.62 116.668C108.486 116.9 108.565 117.197 108.797 117.331L108.921 117.402C109.218 117.574 109.597 117.472 109.768 117.176L109.838 117.054C109.973 116.821 109.893 116.523 109.66 116.388C109.296 116.178 108.83 116.304 108.62 116.668Z" fill="#FF4A1C"/>
<path d="M24.3175 40.572C24.1837 40.8039 24.2626 41.1012 24.4944 41.235L24.6185 41.3067C24.915 41.4778 25.2941 41.3763 25.4652 41.0798L25.5356 40.958C25.6701 40.7249 25.5903 40.4268 25.3572 40.2923C24.9934 40.0823 24.5275 40.2083 24.3175 40.572Z" fill="#FF4A1C"/>
<path d="M104.936 121.189C104.802 121.421 104.881 121.718 105.113 121.852L105.237 121.923C105.533 122.095 105.912 121.993 106.084 121.697L106.154 121.575C106.289 121.342 106.209 121.044 105.976 120.909C105.612 120.699 105.146 120.825 104.936 121.189Z" fill="#FF4A1C"/>
<path d="M21.9518 29.282C21.8179 29.5138 21.8969 29.8111 22.1287 29.945L22.2528 30.0167C22.5493 30.1878 22.9283 30.0862 23.0995 29.7898L23.1699 29.6679C23.3044 29.4348 23.2246 29.1368 22.9915 29.0022C22.6277 28.7922 22.1618 28.9182 21.9518 29.282Z" fill="#FF4A1C"/>
<path d="M102.57 109.898C102.436 110.13 102.515 110.427 102.747 110.561L102.871 110.633C103.168 110.804 103.547 110.702 103.718 110.406L103.788 110.284C103.923 110.051 103.843 109.753 103.61 109.618C103.246 109.408 102.78 109.534 102.57 109.898Z" fill="#FF4A1C"/>
<path d="M90.7195 113.383C90.5856 113.615 90.6646 113.912 90.8964 114.046L91.0205 114.118C91.317 114.289 91.696 114.187 91.8672 113.891L91.9376 113.769C92.0721 113.536 91.9923 113.238 91.7592 113.103C91.3954 112.893 90.9295 113.019 90.7195 113.383Z" fill="#FF4A1C"/>
<path d="M91.6936 129.69C91.5598 129.922 91.6387 130.219 91.8705 130.353L91.9946 130.425C92.2911 130.596 92.6702 130.494 92.8413 130.198L92.9117 130.076C93.0462 129.843 92.9664 129.545 92.7333 129.41C92.3695 129.2 91.9036 129.326 91.6936 129.69Z" fill="#FF4A1C"/>
<path d="M37.4866 58.6301C37.3527 58.862 37.4316 59.1593 37.6635 59.2931L37.7876 59.3648C38.0841 59.536 38.4631 59.4344 38.6343 59.1379L38.7046 59.0161C38.8392 58.783 38.7593 58.485 38.5263 58.3504C38.1625 58.1404 37.6966 58.2664 37.4866 58.6301Z" fill="#FF4A1C"/>
<path d="M118.098 139.247C117.964 139.479 118.043 139.776 118.275 139.91L118.399 139.981C118.695 140.153 119.074 140.051 119.245 139.755L119.316 139.633C119.45 139.4 119.37 139.102 119.137 138.967C118.774 138.757 118.308 138.883 118.098 139.247Z" fill="#FF4A1C"/>
<path d="M33.7951 63.1511C33.6613 63.383 33.7402 63.6803 33.972 63.8141L34.0962 63.8858C34.3926 64.0569 34.7717 63.9554 34.9428 63.6589L35.0132 63.5371C35.1478 63.304 35.0679 63.0059 34.8348 62.8714C34.4711 62.6614 34.0051 62.7874 33.7951 63.1511Z" fill="#FF4A1C"/>
<path d="M114.414 143.766C114.28 143.998 114.359 144.295 114.59 144.429L114.715 144.501C115.011 144.672 115.39 144.57 115.561 144.274L115.632 144.152C115.766 143.919 115.686 143.621 115.453 143.486C115.089 143.276 114.624 143.402 114.414 143.766Z" fill="#FF4A1C"/>
<path d="M31.4294 51.8606C31.2955 52.0924 31.3745 52.3898 31.6063 52.5236L31.7304 52.5953C32.0269 52.7664 32.4059 52.6649 32.5771 52.3684L32.6475 52.2465C32.782 52.0135 32.7022 51.7154 32.4691 51.5809C32.1053 51.3708 31.6394 51.4969 31.4294 51.8606Z" fill="#FF4A1C"/>
<path d="M112.048 132.477C111.914 132.709 111.993 133.006 112.225 133.14L112.349 133.212C112.645 133.383 113.024 133.282 113.195 132.985L113.266 132.863C113.4 132.63 113.321 132.332 113.087 132.198C112.724 131.988 112.258 132.114 112.048 132.477Z" fill="#FF4A1C"/>
<path d="M19.5787 55.3469C19.4449 55.5788 19.5238 55.8761 19.7556 56.0099L19.8797 56.0816C20.1762 56.2527 20.5553 56.1512 20.7264 55.8547L20.7968 55.7329C20.9313 55.4998 20.8515 55.2018 20.6184 55.0672C20.2546 54.8572 19.7887 54.9832 19.5787 55.3469Z" fill="#FF4A1C"/>
<path d="M100.197 135.964C100.063 136.195 100.142 136.493 100.374 136.627L100.498 136.698C100.795 136.869 101.174 136.768 101.345 136.471L101.415 136.35C101.55 136.116 101.47 135.818 101.237 135.684C100.873 135.474 100.407 135.6 100.197 135.964Z" fill="#FF4A1C"/>
<path d="M20.5601 71.6526C20.4263 71.8844 20.5052 72.1817 20.7371 72.3156L20.8612 72.3873C21.1576 72.5584 21.5367 72.4568 21.7079 72.1604L21.7782 72.0385C21.9128 71.8054 21.8329 71.5074 21.5999 71.3728C21.2361 71.1628 20.7702 71.2888 20.5601 71.6526Z" fill="#FF4A1C"/>
<path d="M101.171 152.269C101.037 152.501 101.116 152.798 101.348 152.932L101.472 153.004C101.769 153.175 102.148 153.074 102.319 152.777L102.389 152.655C102.524 152.422 102.444 152.124 102.211 151.99C101.847 151.78 101.381 151.906 101.171 152.269Z" fill="#FF4A1C"/>
<path d="M87.0916 139.673C87.2255 139.441 87.1466 139.143 86.9147 139.01L86.7906 138.938C86.4942 138.767 86.1151 138.868 85.9439 139.165L85.8736 139.287C85.739 139.52 85.8189 139.818 86.0519 139.952C86.4157 140.162 86.8816 140.036 87.0916 139.673Z" fill="#FF4A1C"/>
<path d="M90.7758 135.15C90.9096 134.918 90.8307 134.62 90.5989 134.487L90.4747 134.415C90.1783 134.244 89.7992 134.345 89.6281 134.642L89.5577 134.764C89.4231 134.997 89.503 135.295 89.7361 135.429C90.0998 135.639 90.5657 135.513 90.7758 135.15Z" fill="#FF4A1C"/>
<path d="M93.1415 146.44C93.2754 146.208 93.1964 145.911 92.9646 145.777L92.8405 145.706C92.544 145.534 92.165 145.636 91.9938 145.932L91.9234 146.054C91.7889 146.287 91.8687 146.585 92.1018 146.72C92.4656 146.93 92.9315 146.804 93.1415 146.44Z" fill="#FF4A1C"/>
<path d="M24.3738 62.3391C24.5077 62.1073 24.4287 61.81 24.1969 61.6761L24.0728 61.6044C23.7763 61.4333 23.3973 61.5349 23.2261 61.8313L23.1557 61.9532C23.0212 62.1863 23.101 62.4843 23.3341 62.6189C23.6979 62.8289 24.1638 62.7029 24.3738 62.3391Z" fill="#FF4A1C"/>
<path d="M104.992 142.955C105.126 142.723 105.047 142.426 104.815 142.292L104.691 142.221C104.395 142.049 104.016 142.151 103.844 142.448L103.774 142.569C103.64 142.802 103.719 143.1 103.952 143.235C104.316 143.445 104.782 143.319 104.992 142.955Z" fill="#FF4A1C"/>
<path d="M23.3997 46.0315C23.5335 45.7997 23.4546 45.5023 23.2228 45.3685L23.0986 45.2968C22.8022 45.1257 22.4231 45.2272 22.252 45.5237L22.1816 45.6456C22.047 45.8786 22.1269 46.1767 22.36 46.3112C22.7237 46.5212 23.1896 46.3952 23.3997 46.0315Z" fill="#FF4A1C"/>
<path d="M104.018 126.648C104.152 126.416 104.073 126.119 103.841 125.985L103.717 125.914C103.421 125.742 103.042 125.844 102.87 126.14L102.8 126.262C102.665 126.495 102.745 126.793 102.978 126.928C103.342 127.138 103.808 127.012 104.018 126.648Z" fill="#FF4A1C"/>
<path d="M47.3721 132.564C47.5059 132.332 47.427 132.035 47.1952 131.901L47.071 131.83C46.7746 131.658 46.3955 131.76 46.2244 132.056L46.154 132.178C46.0194 132.411 46.0993 132.709 46.3324 132.844C46.6961 133.054 47.162 132.928 47.3721 132.564Z" fill="#FF4A1C"/>
<path d="M51.0562 128.041C51.19 127.809 51.1111 127.512 50.8793 127.378L50.7551 127.307C50.4587 127.135 50.0796 127.237 49.9085 127.533L49.8381 127.655C49.7035 127.888 49.7834 128.186 50.0165 128.321C50.3802 128.531 50.8462 128.405 51.0562 128.041Z" fill="#FF4A1C"/>
<path d="M53.4219 139.332C53.5558 139.1 53.4768 138.803 53.245 138.669L53.1209 138.597C52.8244 138.426 52.4454 138.528 52.2742 138.824L52.2039 138.946C52.0693 139.179 52.1491 139.477 52.3822 139.612C52.746 139.822 53.2119 139.696 53.4219 139.332Z" fill="#FF4A1C"/>
<path d="M65.2726 135.845C65.4064 135.614 65.3275 135.316 65.0957 135.182L64.9716 135.111C64.6751 134.94 64.296 135.041 64.1249 135.338L64.0545 135.46C63.92 135.693 63.9998 135.991 64.2329 136.125C64.5967 136.335 65.0626 136.209 65.2726 135.845Z" fill="#FF4A1C"/>
<path d="M64.2985 119.54C64.4323 119.308 64.3534 119.011 64.1216 118.877L63.9974 118.805C63.701 118.634 63.3219 118.736 63.1508 119.032L63.0804 119.154C62.9458 119.387 63.0257 119.685 63.2588 119.82C63.6225 120.03 64.0884 119.904 64.2985 119.54Z" fill="#FF4A1C"/>
<path d="M78.3781 132.138C78.2442 132.37 78.3231 132.668 78.555 132.801L78.6791 132.873C78.9755 133.044 79.3546 132.943 79.5258 132.646L79.5961 132.524C79.7307 132.291 79.6508 131.993 79.4178 131.859C79.054 131.649 78.5881 131.775 78.3781 132.138Z" fill="#FF4A1C"/>
<path d="M74.6939 136.659C74.5601 136.891 74.639 137.189 74.8708 137.322L74.995 137.394C75.2914 137.565 75.6705 137.464 75.8416 137.167L75.912 137.045C76.0466 136.812 75.9667 136.514 75.7336 136.38C75.3699 136.17 74.904 136.296 74.6939 136.659Z" fill="#FF4A1C"/>
<path d="M72.3209 125.371C72.187 125.603 72.266 125.9 72.4978 126.034L72.6219 126.106C72.9184 126.277 73.2974 126.175 73.4686 125.879L73.5389 125.757C73.6735 125.524 73.5937 125.226 73.3606 125.091C72.9968 124.881 72.5309 125.007 72.3209 125.371Z" fill="#FF4A1C"/>
<path d="M60.4775 128.854C60.3437 129.086 60.4226 129.383 60.6544 129.517L60.7785 129.588C61.075 129.76 61.4541 129.658 61.6252 129.362L61.6956 129.24C61.8301 129.007 61.7503 128.709 61.5172 128.574C61.1535 128.364 60.6875 128.49 60.4775 128.854Z" fill="#FF4A1C"/>
<path d="M61.4516 145.161C61.3178 145.393 61.3967 145.69 61.6285 145.824L61.7527 145.896C62.0491 146.067 62.4282 145.965 62.5993 145.669L62.6697 145.547C62.8043 145.314 62.7244 145.016 62.4913 144.881C62.1276 144.671 61.6617 144.797 61.4516 145.161Z" fill="#FF4A1C"/>
<path d="M87.8557 154.716C87.7218 154.947 87.8008 155.245 88.0326 155.379L88.1567 155.45C88.4532 155.621 88.8322 155.52 89.0034 155.223L89.0738 155.102C89.2083 154.868 89.1285 154.57 88.8954 154.436C88.5316 154.226 88.0657 154.352 87.8557 154.716Z" fill="#FF4A1C"/>
<path d="M84.1716 159.239C84.0377 159.47 84.1166 159.768 84.3485 159.902L84.4726 159.973C84.7691 160.144 85.1481 160.043 85.3193 159.746L85.3896 159.624C85.5242 159.391 85.4443 159.093 85.2113 158.959C84.8475 158.749 84.3816 158.875 84.1716 159.239Z" fill="#FF4A1C"/>
<path d="M81.7985 147.948C81.6647 148.18 81.7436 148.477 81.9754 148.611L82.0995 148.683C82.396 148.854 82.7751 148.752 82.9462 148.456L83.0166 148.334C83.1511 148.101 83.0713 147.803 82.8382 147.668C82.4745 147.458 82.0085 147.584 81.7985 147.948Z" fill="#FF4A1C"/>
<path d="M69.9551 151.432C69.8213 151.664 69.9002 151.962 70.132 152.095L70.2562 152.167C70.5526 152.338 70.9317 152.237 71.1028 151.94L71.1732 151.818C71.3078 151.585 71.2279 151.287 70.9948 151.153C70.6311 150.943 70.1651 151.069 69.9551 151.432Z" fill="#FF4A1C"/>
<path d="M70.9292 167.74C70.7954 167.972 70.8743 168.269 71.1061 168.403L71.2303 168.475C71.5267 168.646 71.9058 168.544 72.0769 168.248L72.1473 168.126C72.2819 167.893 72.202 167.595 71.9689 167.46C71.6052 167.25 71.1393 167.376 70.9292 167.74Z" fill="#FF4A1C"/>
<path d="M56.8497 155.143C56.9835 154.911 56.9046 154.614 56.6728 154.48L56.5486 154.409C56.2522 154.237 55.8731 154.339 55.702 154.636L55.6316 154.757C55.497 154.99 55.5769 155.288 55.81 155.423C56.1737 155.633 56.6397 155.507 56.8497 155.143Z" fill="#FF4A1C"/>
<path d="M60.5338 150.62C60.6676 150.389 60.5887 150.091 60.3569 149.957L60.2327 149.886C59.9363 149.715 59.5572 149.816 59.3861 150.113L59.3157 150.234C59.1811 150.468 59.261 150.766 59.4941 150.9C59.8578 151.11 60.3238 150.984 60.5338 150.62Z" fill="#FF4A1C"/>
<path d="M62.8995 161.911C63.0334 161.679 62.9544 161.382 62.7226 161.248L62.5985 161.176C62.302 161.005 61.923 161.107 61.7518 161.403L61.6815 161.525C61.5469 161.758 61.6267 162.056 61.8598 162.191C62.2236 162.401 62.6895 162.275 62.8995 161.911Z" fill="#FF4A1C"/>
<path d="M74.7502 158.425C74.8841 158.193 74.8052 157.895 74.5733 157.762L74.4492 157.69C74.1527 157.519 73.7737 157.62 73.6025 157.917L73.5322 158.039C73.3976 158.272 73.4775 158.57 73.7105 158.704C74.0743 158.914 74.5402 158.788 74.7502 158.425Z" fill="#FF4A1C"/>
<path d="M73.7688 142.119C73.9026 141.887 73.8237 141.59 73.5919 141.456L73.4677 141.384C73.1713 141.213 72.7922 141.315 72.6211 141.611L72.5507 141.733C72.4161 141.966 72.496 142.264 72.7291 142.399C73.0928 142.609 73.5588 142.483 73.7688 142.119Z" fill="#FF4A1C"/>
<path d="M158.43 16.866C158.564 16.6341 158.485 16.3368 158.253 16.203L158.129 16.1313C157.833 15.9601 157.454 16.0617 157.282 16.3582L157.212 16.48C157.078 16.7131 157.157 17.0111 157.39 17.1457C157.754 17.3557 158.22 17.2297 158.43 16.866Z" fill="#FF4A1C"/>
<path d="M47.2256 190.963C47.3594 190.731 47.2805 190.433 47.0487 190.3L46.9245 190.228C46.6281 190.057 46.249 190.158 46.0779 190.455L46.0075 190.577C45.8729 190.81 45.9528 191.108 46.1859 191.242C46.5496 191.452 47.0155 191.326 47.2256 190.963Z" fill="#FF4A1C"/>
<path d="M162.122 12.3435C162.255 12.1117 162.177 11.8143 161.945 11.6805L161.821 11.6088C161.524 11.4377 161.145 11.5393 160.974 11.8357L160.904 11.9576C160.769 12.1906 160.849 12.4887 161.082 12.6232C161.446 12.8333 161.912 12.7073 162.122 12.3435Z" fill="#FF4A1C"/>
<path d="M50.917 186.442C51.0509 186.21 50.9719 185.912 50.7401 185.779L50.616 185.707C50.3195 185.536 49.9405 185.637 49.7693 185.934L49.6989 186.056C49.5644 186.289 49.6442 186.587 49.8773 186.721C50.2411 186.931 50.707 186.805 50.917 186.442Z" fill="#FF4A1C"/>
<path d="M164.487 23.6335C164.621 23.4017 164.542 23.1044 164.31 22.9705L164.186 22.8989C163.89 22.7277 163.511 22.8293 163.34 23.1257L163.269 23.2476C163.135 23.4807 163.215 23.7787 163.448 23.9133C163.811 24.1233 164.277 23.9973 164.487 23.6335Z" fill="#FF4A1C"/>
<path d="M176.338 20.1492C176.472 19.9173 176.393 19.62 176.161 19.4862L176.037 19.4145C175.741 19.2433 175.361 19.3449 175.19 19.6414L175.12 19.7632C174.985 19.9963 175.065 20.2943 175.298 20.4289C175.662 20.6389 176.128 20.5129 176.338 20.1492Z" fill="#FF4A1C"/>
<path d="M65.1261 194.246C65.26 194.014 65.181 193.717 64.9492 193.583L64.8251 193.511C64.5286 193.34 64.1496 193.442 63.9784 193.738L63.908 193.86C63.7735 194.093 63.8533 194.391 64.0864 194.526C64.4502 194.736 64.9161 194.61 65.1261 194.246Z" fill="#FF4A1C"/>
<path d="M64.1593 177.94C64.2932 177.708 64.2142 177.411 63.9824 177.277L63.8583 177.205C63.5618 177.034 63.1828 177.135 63.0116 177.432L62.9412 177.554C62.8067 177.787 62.8865 178.085 63.1196 178.219C63.4834 178.429 63.9493 178.303 64.1593 177.94Z" fill="#FF4A1C"/>
<path d="M78.2389 190.537C78.1051 190.769 78.184 191.066 78.4158 191.2L78.5399 191.272C78.8364 191.443 79.2155 191.341 79.3866 191.045L79.457 190.923C79.5915 190.69 79.5117 190.392 79.2786 190.257C78.9149 190.047 78.4489 190.173 78.2389 190.537Z" fill="#FF4A1C"/>
<path d="M74.5548 195.058C74.4209 195.29 74.4999 195.587 74.7317 195.721L74.8558 195.793C75.1523 195.964 75.5313 195.862 75.7025 195.566L75.7728 195.444C75.9074 195.211 75.8276 194.913 75.5945 194.778C75.2307 194.568 74.7648 194.694 74.5548 195.058Z" fill="#FF4A1C"/>
<path d="M72.1817 183.769C72.0479 184.001 72.1268 184.298 72.3586 184.432L72.4827 184.504C72.7792 184.675 73.1583 184.574 73.3294 184.277L73.3998 184.155C73.5343 183.922 73.4545 183.624 73.2214 183.49C72.8576 183.28 72.3917 183.406 72.1817 183.769Z" fill="#FF4A1C"/>
<path d="M171.543 13.1575C171.409 13.3893 171.488 13.6866 171.72 13.8205L171.844 13.8921C172.14 14.0633 172.52 13.9617 172.691 13.6653L172.761 13.5434C172.896 13.3103 172.816 13.0123 172.583 12.8777C172.219 12.6677 171.753 12.7937 171.543 13.1575Z" fill="#FF4A1C"/>
<path d="M60.3383 187.252C60.2045 187.484 60.2834 187.781 60.5153 187.915L60.6394 187.986C60.9358 188.158 61.3149 188.056 61.4861 187.76L61.5564 187.638C61.691 187.405 61.6111 187.107 61.3781 186.972C61.0143 186.762 60.5484 186.888 60.3383 187.252Z" fill="#FF4A1C"/>
<path d="M172.517 29.4631C172.383 29.695 172.462 29.9923 172.694 30.1261L172.818 30.1978C173.115 30.369 173.494 30.2674 173.665 29.9709L173.735 29.8491C173.87 29.616 173.79 29.318 173.557 29.1834C173.193 28.9734 172.727 29.0994 172.517 29.4631Z" fill="#FF4A1C"/>
<path d="M181.021 35.7346C180.887 35.9665 180.966 36.2638 181.198 36.3976L181.322 36.4693C181.618 36.6404 181.997 36.5389 182.168 36.2424L182.239 36.1206C182.373 35.8875 182.293 35.5894 182.06 35.4549C181.697 35.2449 181.231 35.3709 181.021 35.7346Z" fill="#FF4A1C"/>
<path d="M167.915 39.4451C168.049 39.2132 167.97 38.9159 167.738 38.7821L167.614 38.7104C167.318 38.5392 166.939 38.6408 166.767 38.9373L166.697 39.0591C166.562 39.2922 166.642 39.5902 166.875 39.7248C167.239 39.9348 167.705 39.8088 167.915 39.4451Z" fill="#FF4A1C"/>
<path d="M171.599 34.9221C171.733 34.6903 171.654 34.393 171.422 34.2591L171.298 34.1874C171.002 34.0163 170.623 34.1179 170.452 34.4143L170.381 34.5362C170.247 34.7693 170.326 35.0673 170.56 35.2019C170.923 35.4119 171.389 35.2859 171.599 34.9221Z" fill="#FF4A1C"/>
<path d="M173.965 46.2126C174.099 45.9808 174.02 45.6835 173.788 45.5496L173.664 45.478C173.368 45.3068 172.988 45.4084 172.817 45.7048L172.747 45.8267C172.612 46.0598 172.692 46.3578 172.925 46.4924C173.289 46.7024 173.755 46.5764 173.965 46.2126Z" fill="#FF4A1C"/>
<path d="M128.188 32.3367C128.322 32.1048 128.243 31.8075 128.011 31.6737L127.887 31.602C127.591 31.4308 127.212 31.5324 127.041 31.8289L126.97 31.9507C126.836 32.1838 126.915 32.4818 127.149 32.6164C127.512 32.8264 127.978 32.7004 128.188 32.3367Z" fill="#FF4A1C"/>
<path d="M131.872 27.8157C132.006 27.5838 131.927 27.2865 131.695 27.1527L131.571 27.081C131.275 26.9098 130.896 27.0114 130.725 27.3079L130.654 27.4297C130.52 27.6628 130.6 27.9608 130.833 28.0954C131.196 28.3054 131.662 28.1794 131.872 27.8157Z" fill="#FF4A1C"/>
<path d="M134.245 39.1042C134.379 38.8724 134.3 38.5751 134.068 38.4412L133.944 38.3696C133.648 38.1984 133.269 38.3 133.098 38.5964L133.027 38.7183C132.893 38.9514 132.973 39.2494 133.206 39.384C133.569 39.594 134.035 39.468 134.245 39.1042Z" fill="#FF4A1C"/>
<path d="M146.089 35.6199C146.223 35.388 146.144 35.0907 145.912 34.9569L145.788 34.8852C145.491 34.714 145.112 34.8156 144.941 35.1121L144.871 35.2339C144.736 35.467 144.816 35.765 145.049 35.8996C145.413 36.1096 145.879 35.9836 146.089 35.6199Z" fill="#FF4A1C"/>
<path d="M145.115 19.3142C145.248 19.0824 145.17 18.7851 144.938 18.6512L144.814 18.5795C144.517 18.4084 144.138 18.51 143.967 18.8064L143.897 18.9283C143.762 19.1614 143.842 19.4594 144.075 19.5939C144.439 19.804 144.905 19.678 145.115 19.3142Z" fill="#FF4A1C"/>
<path d="M33.91 193.409C34.0439 193.177 33.9649 192.88 33.7331 192.746L33.609 192.674C33.3125 192.503 32.9335 192.605 32.7623 192.901L32.6919 193.023C32.5574 193.256 32.6372 193.554 32.8703 193.689C33.2341 193.899 33.7 193.773 33.91 193.409Z" fill="#FF4A1C"/>
<path d="M159.194 31.9094C159.06 32.1413 159.139 32.4386 159.371 32.5724L159.495 32.6441C159.792 32.8152 160.171 32.7137 160.342 32.4172L160.412 32.2954C160.547 32.0623 160.467 31.7643 160.234 31.6297C159.87 31.4197 159.404 31.5457 159.194 31.9094Z" fill="#FF4A1C"/>
<path d="M155.51 36.4319C155.376 36.6637 155.455 36.961 155.687 37.0949L155.811 37.1666C156.108 37.3377 156.487 37.2361 156.658 36.9397L156.728 36.8178C156.863 36.5847 156.783 36.2867 156.55 36.1521C156.186 35.9421 155.72 36.0681 155.51 36.4319Z" fill="#FF4A1C"/>
<path d="M153.144 25.1419C153.01 25.3737 153.089 25.671 153.321 25.8049L153.445 25.8765C153.742 26.0477 154.121 25.9461 154.292 25.6497L154.362 25.5278C154.497 25.2947 154.417 24.9967 154.184 24.8621C153.82 24.6521 153.354 24.7781 153.144 25.1419Z" fill="#FF4A1C"/>
<path d="M141.294 28.6262C141.16 28.8581 141.239 29.1554 141.471 29.2892L141.595 29.3609C141.891 29.532 142.27 29.4305 142.441 29.134L142.512 29.0122C142.646 28.7791 142.566 28.481 142.333 28.3465C141.97 28.1365 141.504 28.2625 141.294 28.6262Z" fill="#FF4A1C"/>
<path d="M142.275 44.9358C142.141 45.1676 142.22 45.4649 142.452 45.5988L142.576 45.6705C142.873 45.8416 143.252 45.74 143.423 45.4436L143.493 45.3217C143.628 45.0886 143.548 44.7906 143.315 44.6561C142.951 44.446 142.485 44.572 142.275 44.9358Z" fill="#FF4A1C"/>
<path d="M168.679 54.49C168.545 54.7218 168.624 55.0191 168.856 55.153L168.98 55.2247C169.277 55.3958 169.656 55.2942 169.827 54.9978L169.897 54.8759C170.032 54.6428 169.952 54.3448 169.719 54.2103C169.355 54.0002 168.889 54.1262 168.679 54.49Z" fill="#FF4A1C"/>
<path d="M164.988 59.011C164.854 59.2428 164.933 59.5401 165.165 59.674L165.289 59.7457C165.585 59.9168 165.964 59.8152 166.135 59.5188L166.206 59.3969C166.34 59.1638 166.261 58.8658 166.027 58.7312C165.664 58.5212 165.198 58.6472 164.988 59.011Z" fill="#FF4A1C"/>
<path d="M162.622 47.7224C162.488 47.9543 162.567 48.2516 162.799 48.3854L162.923 48.4571C163.219 48.6282 163.599 48.5267 163.77 48.2302L163.84 48.1083C163.975 47.8753 163.895 47.5772 163.662 47.4427C163.298 47.2327 162.832 47.3587 162.622 47.7224Z" fill="#FF4A1C"/>
<path d="M150.771 51.2068C150.637 51.4386 150.716 51.7359 150.948 51.8698L151.072 51.9415C151.369 52.1126 151.748 52.011 151.919 51.7146L151.989 51.5927C152.124 51.3596 152.044 51.0616 151.811 50.927C151.447 50.717 150.981 50.843 150.771 51.2068Z" fill="#FF4A1C"/>
<path d="M151.753 67.5125C151.619 67.7443 151.698 68.0416 151.93 68.1755L152.054 68.2471C152.35 68.4183 152.729 68.3167 152.9 68.0203L152.971 67.8984C153.105 67.6653 153.026 67.3673 152.792 67.2327C152.429 67.0227 151.963 67.1487 151.753 67.5125Z" fill="#FF4A1C"/>
<path d="M137.666 54.9158C137.8 54.6839 137.721 54.3866 137.489 54.2528L137.365 54.1811C137.068 54.0099 136.689 54.1115 136.518 54.408L136.448 54.5298C136.313 54.7629 136.393 55.0609 136.626 55.1955C136.99 55.4055 137.456 55.2795 137.666 54.9158Z" fill="#FF4A1C"/>
<path d="M141.35 50.3948C141.484 50.1629 141.405 49.8656 141.173 49.7318L141.049 49.6601C140.752 49.489 140.373 49.5905 140.202 49.887L140.132 50.0088C139.997 50.2419 140.077 50.5399 140.31 50.6745C140.674 50.8845 141.14 50.7585 141.35 50.3948Z" fill="#FF4A1C"/>
<path d="M143.723 61.6833C143.857 61.4515 143.778 61.1542 143.546 61.0203L143.422 60.9487C143.126 60.7775 142.746 60.8791 142.575 61.1755L142.505 61.2974C142.37 61.5305 142.45 61.8285 142.683 61.9631C143.047 62.1731 143.513 62.0471 143.723 61.6833Z" fill="#FF4A1C"/>
<path d="M155.566 58.199C155.7 57.9671 155.621 57.6698 155.389 57.536L155.265 57.4643C154.969 57.2931 154.59 57.3947 154.419 57.6912L154.348 57.813C154.214 58.0461 154.294 58.3441 154.527 58.4787C154.89 58.6887 155.356 58.5627 155.566 58.199Z" fill="#FF4A1C"/>
<path d="M154.592 41.8913C154.726 41.6595 154.647 41.3622 154.415 41.2284L154.291 41.1567C153.995 40.9855 153.616 41.0871 153.445 41.3836L153.374 41.5054C153.24 41.7385 153.319 42.0365 153.553 42.1711C153.916 42.3811 154.382 42.2551 154.592 41.8913Z" fill="#FF4A1C"/>
</g>
<mask id="mask2_1148_8937" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="49" y="46" width="167" height="188">
<path d="M63.592 80.0861C82.1705 47.9072 123.388 36.7601 155.567 55.3386L172.796 65.2861C213.945 89.0431 228.043 141.659 204.286 182.808L194.519 199.725C175.841 232.076 134.473 243.161 102.121 224.482C51.6301 195.331 34.4408 130.577 63.592 80.0861Z" fill="url(#paint1_linear_1148_8937)"/>
</mask>
<g mask="url(#mask2_1148_8937)">
<path d="M107.504 81.0613C107.37 81.2931 107.449 81.5904 107.681 81.7243L107.805 81.796C108.101 81.9671 108.481 81.8655 108.652 81.5691L108.722 81.4472C108.857 81.2141 108.777 80.9161 108.544 80.7815C108.18 80.5715 107.714 80.6975 107.504 81.0613Z" fill="#9055A2"/>
<path d="M188.122 161.678C187.989 161.909 188.067 162.207 188.299 162.34L188.423 162.412C188.72 162.583 189.099 162.482 189.27 162.185L189.34 162.063C189.475 161.83 189.395 161.532 189.162 161.398C188.798 161.188 188.332 161.314 188.122 161.678Z" fill="#9055A2"/>
<path d="M103.82 85.5818C103.686 85.8136 103.765 86.1109 103.997 86.2448L104.121 86.3165C104.417 86.4876 104.796 86.386 104.968 86.0896L105.038 85.9677C105.173 85.7346 105.093 85.4366 104.86 85.302C104.496 85.092 104.03 85.218 103.82 85.5818Z" fill="#9055A2"/>
<path d="M184.438 166.198C184.304 166.43 184.383 166.728 184.615 166.861L184.739 166.933C185.036 167.104 185.415 167.003 185.586 166.706L185.656 166.584C185.791 166.351 185.711 166.053 185.478 165.919C185.114 165.709 184.648 165.835 184.438 166.198Z" fill="#9055A2"/>
<path d="M101.454 74.2932C101.32 74.5251 101.399 74.8224 101.631 74.9562L101.755 75.0279C102.052 75.199 102.431 75.0975 102.602 74.801L102.672 74.6791C102.807 74.4461 102.727 74.148 102.494 74.0135C102.13 73.8035 101.664 73.9295 101.454 74.2932Z" fill="#9055A2"/>
<path d="M182.065 154.908C181.931 155.14 182.01 155.437 182.242 155.571L182.366 155.643C182.663 155.814 183.042 155.712 183.213 155.416L183.283 155.294C183.418 155.061 183.338 154.763 183.105 154.628C182.741 154.418 182.275 154.544 182.065 154.908Z" fill="#9055A2"/>
<path d="M89.6034 77.7781C89.4696 78.0099 89.5485 78.3072 89.7803 78.4411L89.9045 78.5127C90.2009 78.6839 90.58 78.5823 90.7511 78.2859L90.8215 78.164C90.9561 77.9309 90.8762 77.6329 90.6431 77.4983C90.2794 77.2883 89.8135 77.4143 89.6034 77.7781Z" fill="#9055A2"/>
<path d="M170.222 158.394C170.088 158.626 170.167 158.923 170.399 159.057L170.523 159.129C170.819 159.3 171.198 159.199 171.37 158.902L171.44 158.78C171.574 158.547 171.495 158.249 171.262 158.115C170.898 157.905 170.432 158.031 170.222 158.394Z" fill="#9055A2"/>
<path d="M90.5776 94.0857C90.4437 94.3175 90.5226 94.6149 90.7545 94.7487L90.8786 94.8204C91.175 94.9915 91.5541 94.8899 91.7253 94.5935L91.7956 94.4716C91.9302 94.2386 91.8503 93.9405 91.6173 93.806C91.2535 93.5959 90.7876 93.7219 90.5776 94.0857Z" fill="#9055A2"/>
<path d="M171.196 174.702C171.062 174.934 171.141 175.231 171.373 175.365L171.497 175.437C171.793 175.608 172.173 175.506 172.344 175.21L172.414 175.088C172.549 174.855 172.469 174.557 172.236 174.422C171.872 174.212 171.406 174.338 171.196 174.702Z" fill="#9055A2"/>
<path d="M76.498 81.4866C76.6318 81.2547 76.5529 80.9574 76.3211 80.8236L76.1969 80.7519C75.9005 80.5807 75.5214 80.6823 75.3503 80.9788L75.2799 81.1006C75.1453 81.3337 75.2252 81.6317 75.4583 81.7663C75.822 81.9763 76.288 81.8503 76.498 81.4866Z" fill="#9055A2"/>
<path d="M157.116 162.103C157.25 161.871 157.171 161.574 156.939 161.44L156.815 161.369C156.519 161.197 156.14 161.299 155.969 161.595L155.898 161.717C155.764 161.95 155.844 162.248 156.077 162.383C156.44 162.593 156.906 162.467 157.116 162.103Z" fill="#9055A2"/>
<path d="M80.1821 76.9656C80.3159 76.7337 80.237 76.4364 80.0052 76.3026L79.881 76.2309C79.5846 76.0598 79.2055 76.1613 79.0344 76.4578L78.964 76.5796C78.8294 76.8127 78.9093 77.1107 79.1424 77.2453C79.5061 77.4553 79.9721 77.3293 80.1821 76.9656Z" fill="#9055A2"/>
<path d="M160.8 157.582C160.934 157.35 160.855 157.053 160.624 156.919L160.499 156.848C160.203 156.676 159.824 156.778 159.653 157.074L159.582 157.196C159.448 157.429 159.528 157.727 159.761 157.862C160.125 158.072 160.59 157.946 160.8 157.582Z" fill="#9055A2"/>
<path d="M82.5552 88.2561C82.689 88.0243 82.6101 87.7269 82.3783 87.5931L82.2541 87.5214C81.9577 87.3503 81.5786 87.4518 81.4075 87.7483L81.3371 87.8702C81.2025 88.1032 81.2824 88.4013 81.5155 88.5358C81.8792 88.7459 82.3451 88.6198 82.5552 88.2561Z" fill="#9055A2"/>
<path d="M163.166 168.873C163.3 168.641 163.221 168.344 162.989 168.21L162.865 168.138C162.569 167.967 162.19 168.069 162.019 168.365L161.948 168.487C161.814 168.72 161.893 169.018 162.127 169.153C162.49 169.363 162.956 169.237 163.166 168.873Z" fill="#9055A2"/>
<path d="M94.3985 84.7698C94.5324 84.5379 94.4535 84.2406 94.2216 84.1068L94.0975 84.0351C93.801 83.8639 93.422 83.9655 93.2508 84.262L93.1805 84.3838C93.0459 84.6169 93.1258 84.9149 93.3588 85.0495C93.7226 85.2595 94.1885 85.1335 94.3985 84.7698Z" fill="#9055A2"/>
<path d="M175.017 165.386C175.151 165.155 175.072 164.857 174.84 164.723L174.716 164.652C174.419 164.481 174.04 164.582 173.869 164.879L173.799 165.001C173.664 165.234 173.744 165.532 173.977 165.666C174.341 165.876 174.807 165.75 175.017 165.386Z" fill="#9055A2"/>
<path d="M93.4244 68.4641C93.5582 68.2323 93.4793 67.935 93.2475 67.8011L93.1234 67.7294C92.8269 67.5583 92.4478 67.6599 92.2767 67.9563L92.2063 68.0782C92.0718 68.3113 92.1516 68.6093 92.3847 68.7439C92.7484 68.9539 93.2144 68.8279 93.4244 68.4641Z" fill="#9055A2"/>
<path d="M174.035 149.081C174.169 148.849 174.09 148.552 173.859 148.418L173.734 148.346C173.438 148.175 173.059 148.277 172.888 148.573L172.817 148.695C172.683 148.928 172.763 149.226 172.996 149.361C173.36 149.571 173.825 149.445 174.035 149.081Z" fill="#9055A2"/>
<path d="M67.0204 58.9079C67.1542 58.6761 67.0753 58.3788 66.8435 58.245L66.7193 58.1733C66.4229 58.0021 66.0438 58.1037 65.8727 58.4002L65.8023 58.522C65.6677 58.7551 65.7476 59.0531 65.9807 59.1877C66.3444 59.3977 66.8103 59.2717 67.0204 58.9079Z" fill="#9055A2"/>
<path d="M147.639 139.524C147.773 139.292 147.694 138.995 147.462 138.861L147.338 138.789C147.041 138.618 146.662 138.72 146.491 139.016L146.421 139.138C146.286 139.371 146.366 139.669 146.599 139.804C146.963 140.014 147.429 139.888 147.639 139.524Z" fill="#9055A2"/>
<path d="M70.7045 54.3869C70.8383 54.1551 70.7594 53.8578 70.5276 53.724L70.4034 53.6523C70.107 53.4811 69.7279 53.5827 69.5568 53.8792L69.4864 54.001C69.3518 54.2341 69.4317 54.5321 69.6648 54.6667C70.0285 54.8767 70.4945 54.7507 70.7045 54.3869Z" fill="#9055A2"/>
<path d="M151.323 135.004C151.457 134.772 151.378 134.475 151.146 134.341L151.022 134.269C150.725 134.098 150.346 134.199 150.175 134.496L150.105 134.618C149.97 134.851 150.05 135.149 150.283 135.283C150.647 135.493 151.113 135.367 151.323 135.004Z" fill="#9055A2"/>
<path d="M73.0702 65.6755C73.2041 65.4437 73.1251 65.1464 72.8933 65.0125L72.7692 64.9409C72.4727 64.7697 72.0937 64.8713 71.9225 65.1677L71.8521 65.2896C71.7176 65.5227 71.7974 65.8207 72.0305 65.9553C72.3943 66.1653 72.8602 66.0393 73.0702 65.6755Z" fill="#9055A2"/>
<path d="M153.689 146.292C153.822 146.06 153.744 145.763 153.512 145.629L153.388 145.557C153.091 145.386 152.712 145.487 152.541 145.784L152.471 145.906C152.336 146.139 152.416 146.437 152.649 146.571C153.013 146.782 153.479 146.655 153.689 146.292Z" fill="#9055A2"/>
<path d="M84.9209 62.1926C85.0547 61.9608 84.9758 61.6635 84.744 61.5296L84.6199 61.458C84.3234 61.2868 83.9443 61.3884 83.7732 61.6848L83.7028 61.8067C83.5683 62.0398 83.6481 62.3378 83.8812 62.4724C84.245 62.6824 84.7109 62.5564 84.9209 62.1926Z" fill="#9055A2"/>
<path d="M165.539 142.807C165.673 142.576 165.594 142.278 165.362 142.144L165.238 142.073C164.942 141.902 164.563 142.003 164.392 142.3L164.321 142.421C164.187 142.655 164.267 142.953 164.5 143.087C164.863 143.297 165.329 143.171 165.539 142.807Z" fill="#9055A2"/>
<path d="M83.9468 45.885C84.0806 45.6532 84.0017 45.3559 83.7699 45.222L83.6457 45.1503C83.3493 44.9792 82.9702 45.0808 82.7991 45.3772L82.7287 45.4991C82.5941 45.7322 82.674 46.0302 82.9071 46.1647C83.2708 46.3748 83.7368 46.2488 83.9468 45.885Z" fill="#9055A2"/>
<path d="M164.558 126.502C164.692 126.27 164.613 125.973 164.381 125.839L164.257 125.767C163.96 125.596 163.581 125.697 163.41 125.994L163.34 126.116C163.205 126.349 163.285 126.647 163.518 126.781C163.882 126.991 164.348 126.865 164.558 126.502Z" fill="#9055A2"/>
<path d="M98.0264 58.4822C97.8925 58.714 97.9714 59.0113 98.2033 59.1452L98.3274 59.2168C98.6238 59.388 99.0029 59.2864 99.1741 58.99L99.2444 58.8681C99.379 58.635 99.2991 58.337 99.0661 58.2024C98.7023 57.9924 98.2364 58.1184 98.0264 58.4822Z" fill="#9055A2"/>
<path d="M178.645 139.099C178.511 139.331 178.59 139.628 178.822 139.762L178.946 139.834C179.242 140.005 179.621 139.903 179.792 139.607L179.863 139.485C179.997 139.252 179.918 138.954 179.684 138.819C179.321 138.609 178.855 138.735 178.645 139.099Z" fill="#9055A2"/>
<path d="M94.3423 63.0032C94.2084 63.235 94.2873 63.5323 94.5192 63.6662L94.6433 63.7378C94.9397 63.909 95.3188 63.8074 95.49 63.511L95.5603 63.3891C95.6949 63.156 95.615 62.858 95.382 62.7234C95.0182 62.5134 94.5523 62.6394 94.3423 63.0032Z" fill="#9055A2"/>
<path d="M174.961 143.619C174.827 143.851 174.906 144.149 175.138 144.282L175.262 144.354C175.558 144.525 175.937 144.424 176.108 144.127L176.179 144.005C176.313 143.772 176.233 143.474 176 143.34C175.637 143.13 175.171 143.256 174.961 143.619Z" fill="#9055A2"/>
<path d="M91.9692 51.7146C91.8353 51.9464 91.9143 52.2438 92.1461 52.3776L92.2702 52.4493C92.5667 52.6204 92.9457 52.5189 93.1169 52.2224L93.1873 52.1005C93.3218 51.8675 93.242 51.5694 93.0089 51.4349C92.6451 51.2248 92.1792 51.3509 91.9692 51.7146Z" fill="#9055A2"/>
<path d="M172.588 132.331C172.454 132.563 172.533 132.86 172.764 132.994L172.889 133.065C173.185 133.237 173.564 133.135 173.735 132.839L173.806 132.717C173.94 132.484 173.86 132.186 173.627 132.051C173.264 131.841 172.798 131.967 172.588 132.331Z" fill="#9055A2"/>
<path d="M80.1258 55.199C79.992 55.4308 80.0709 55.7281 80.3027 55.862L80.4268 55.9336C80.7233 56.1048 81.1024 56.0032 81.2735 55.7068L81.3439 55.5849C81.4784 55.3518 81.3986 55.0538 81.1655 54.9192C80.8018 54.7092 80.3358 54.8352 80.1258 55.199Z" fill="#9055A2"/>
<path d="M160.744 135.814C160.61 136.046 160.689 136.343 160.921 136.477L161.045 136.548C161.342 136.72 161.721 136.618 161.892 136.322L161.962 136.2C162.097 135.967 162.017 135.669 161.784 135.534C161.42 135.324 160.954 135.45 160.744 135.814Z" fill="#9055A2"/>
<path d="M81.0999 71.5047C80.9661 71.7365 81.045 72.0338 81.2768 72.1676L81.401 72.2393C81.6974 72.4105 82.0765 72.3089 82.2476 72.0124L82.318 71.8906C82.4526 71.6575 82.3727 71.3595 82.1396 71.2249C81.7759 71.0149 81.31 71.1409 81.0999 71.5047Z" fill="#9055A2"/>
<path d="M161.718 152.121C161.585 152.353 161.663 152.65 161.895 152.784L162.019 152.856C162.316 153.027 162.695 152.926 162.866 152.629L162.936 152.507C163.071 152.274 162.991 151.976 162.758 151.842C162.394 151.632 161.928 151.758 161.718 152.121Z" fill="#9055A2"/>
<path d="M137.746 65.5921C137.612 65.8239 137.691 66.1212 137.923 66.255L138.047 66.3267C138.343 66.4979 138.723 66.3963 138.894 66.0998L138.964 65.978C139.099 65.7449 139.019 65.4469 138.786 65.3123C138.422 65.1023 137.956 65.2283 137.746 65.5921Z" fill="#9055A2"/>
<path d="M134.062 70.1131C133.928 70.3449 134.007 70.6422 134.239 70.776L134.363 70.8477C134.659 71.0189 135.038 70.9173 135.21 70.6208L135.28 70.499C135.414 70.2659 135.335 69.9679 135.102 69.8333C134.738 69.6233 134.272 69.7493 134.062 70.1131Z" fill="#9055A2"/>
<path d="M214.68 150.728C214.546 150.96 214.625 151.257 214.857 151.391L214.981 151.462C215.278 151.634 215.657 151.532 215.828 151.236L215.898 151.114C216.033 150.881 215.953 150.583 215.72 150.448C215.356 150.238 214.89 150.364 214.68 150.728Z" fill="#9055A2"/>
<path d="M131.696 58.8225C131.562 59.0544 131.641 59.3517 131.873 59.4855L131.997 59.5572C132.294 59.7283 132.673 59.6268 132.844 59.3303L132.914 59.2084C133.049 58.9754 132.969 58.6773 132.736 58.5428C132.372 58.3328 131.906 58.4588 131.696 58.8225Z" fill="#9055A2"/>
<path d="M212.315 139.439C212.181 139.671 212.26 139.968 212.491 140.102L212.616 140.174C212.912 140.345 213.291 140.243 213.462 139.947L213.533 139.825C213.667 139.592 213.587 139.294 213.354 139.159C212.99 138.949 212.525 139.075 212.315 139.439Z" fill="#9055A2"/>
<path d="M119.845 62.3074C119.712 62.5392 119.79 62.8365 120.022 62.9704L120.146 63.042C120.443 63.2132 120.822 63.1116 120.993 62.8152L121.063 62.6933C121.198 62.4602 121.118 62.1622 120.885 62.0276C120.521 61.8176 120.055 61.9436 119.845 62.3074Z" fill="#9055A2"/>
<path d="M200.464 142.924C200.33 143.155 200.409 143.453 200.641 143.587L200.765 143.658C201.061 143.829 201.44 143.728 201.612 143.431L201.682 143.31C201.816 143.076 201.737 142.778 201.504 142.644C201.14 142.434 200.674 142.56 200.464 142.924Z" fill="#9055A2"/>
<path d="M120.827 78.6145C120.693 78.8463 120.772 79.1437 121.004 79.2775L121.128 79.3492C121.424 79.5203 121.803 79.4188 121.975 79.1223L122.045 79.0004C122.179 78.7674 122.1 78.4693 121.867 78.3348C121.503 78.1247 121.037 78.2508 120.827 78.6145Z" fill="#9055A2"/>
<path d="M201.438 159.231C201.304 159.463 201.383 159.76 201.615 159.894L201.739 159.966C202.035 160.137 202.414 160.035 202.586 159.739L202.656 159.617C202.791 159.384 202.711 159.086 202.478 158.951C202.114 158.741 201.648 158.867 201.438 159.231Z" fill="#9055A2"/>
<path d="M106.74 66.0159C106.874 65.784 106.795 65.4867 106.563 65.3529L106.439 65.2812C106.142 65.11 105.763 65.2116 105.592 65.5081L105.522 65.6299C105.387 65.863 105.467 66.161 105.7 66.2956C106.064 66.5056 106.53 66.3796 106.74 66.0159Z" fill="#9055A2"/>
<path d="M187.358 146.633C187.492 146.401 187.413 146.103 187.181 145.97L187.057 145.898C186.761 145.727 186.382 145.828 186.211 146.125L186.14 146.247C186.006 146.48 186.086 146.778 186.319 146.912C186.682 147.122 187.148 146.996 187.358 146.633Z" fill="#9055A2"/>
<path d="M110.424 61.4949C110.558 61.263 110.479 60.9657 110.247 60.8319L110.123 60.7602C109.827 60.589 109.448 60.6906 109.276 60.9871L109.206 61.1089C109.071 61.342 109.151 61.64 109.384 61.7746C109.748 61.9846 110.214 61.8586 110.424 61.4949Z" fill="#9055A2"/>
<path d="M191.042 142.11C191.176 141.878 191.097 141.58 190.866 141.447L190.741 141.375C190.445 141.204 190.066 141.305 189.895 141.602L189.824 141.724C189.69 141.957 189.77 142.255 190.003 142.389C190.367 142.599 190.832 142.473 191.042 142.11Z" fill="#9055A2"/>
<path d="M112.797 72.7834C112.931 72.5516 112.852 72.2543 112.62 72.1204L112.496 72.0488C112.2 71.8776 111.821 71.9792 111.649 72.2756L111.579 72.3975C111.445 72.6306 111.524 72.9286 111.757 73.0632C112.121 73.2732 112.587 73.1472 112.797 72.7834Z" fill="#9055A2"/>
<path d="M193.416 153.4C193.549 153.168 193.47 152.871 193.239 152.737L193.114 152.665C192.818 152.494 192.439 152.596 192.268 152.892L192.197 153.014C192.063 153.247 192.143 153.545 192.376 153.68C192.74 153.89 193.206 153.764 193.416 153.4Z" fill="#9055A2"/>
<path d="M124.641 69.2991C124.774 69.0672 124.695 68.7699 124.464 68.6361L124.339 68.5644C124.043 68.3932 123.664 68.4948 123.493 68.7913L123.422 68.9131C123.288 69.1462 123.368 69.4442 123.601 69.5788C123.965 69.7888 124.43 69.6628 124.641 69.2991Z" fill="#9055A2"/>
<path d="M205.259 149.916C205.393 149.684 205.314 149.387 205.082 149.253L204.958 149.181C204.661 149.01 204.282 149.112 204.111 149.408L204.041 149.53C203.906 149.763 203.986 150.061 204.219 150.196C204.583 150.406 205.049 150.28 205.259 149.916Z" fill="#9055A2"/>
<path d="M123.666 52.9914C123.8 52.7596 123.721 52.4623 123.489 52.3285L123.365 52.2568C123.069 52.0856 122.69 52.1872 122.519 52.4836L122.448 52.6055C122.314 52.8386 122.394 53.1366 122.627 53.2712C122.99 53.4812 123.456 53.3552 123.666 52.9914Z" fill="#9055A2"/>
<path d="M204.285 133.608C204.419 133.376 204.34 133.079 204.108 132.945L203.984 132.873C203.687 132.702 203.308 132.804 203.137 133.1L203.067 133.222C202.932 133.455 203.012 133.753 203.245 133.888C203.609 134.098 204.075 133.972 204.285 133.608Z" fill="#9055A2"/>
<path d="M177.881 124.053C178.015 123.822 177.936 123.524 177.704 123.39L177.58 123.319C177.283 123.148 176.904 123.249 176.733 123.546L176.663 123.668C176.528 123.901 176.608 124.199 176.841 124.333C177.205 124.543 177.671 124.417 177.881 124.053Z" fill="#9055A2"/>
<path d="M181.565 119.532C181.699 119.301 181.62 119.003 181.388 118.869L181.264 118.798C180.967 118.627 180.588 118.728 180.417 119.025L180.347 119.147C180.212 119.38 180.292 119.678 180.525 119.812C180.889 120.022 181.355 119.896 181.565 119.532Z" fill="#9055A2"/>
<path d="M103.32 50.2048C103.453 49.973 103.374 49.6757 103.143 49.5418L103.018 49.4702C102.722 49.299 102.343 49.4006 102.172 49.697L102.101 49.8189C101.967 50.052 102.047 50.35 102.28 50.4846C102.644 50.6946 103.109 50.5686 103.32 50.2048Z" fill="#9055A2"/>
<path d="M183.931 130.821C184.064 130.589 183.985 130.292 183.754 130.158L183.63 130.086C183.333 129.915 182.954 130.017 182.783 130.313L182.713 130.435C182.578 130.668 182.658 130.966 182.891 131.101C183.255 131.311 183.721 131.185 183.931 130.821Z" fill="#9055A2"/>
<path d="M115.163 46.72C115.297 46.4881 115.218 46.1908 114.986 46.057L114.862 45.9853C114.565 45.8141 114.186 45.9157 114.015 46.2122L113.945 46.334C113.81 46.5671 113.89 46.8651 114.123 46.9997C114.487 47.2097 114.953 47.0837 115.163 46.72Z" fill="#9055A2"/>
<path d="M195.781 127.337C195.915 127.105 195.836 126.808 195.604 126.674L195.48 126.602C195.184 126.431 194.805 126.532 194.634 126.829L194.563 126.951C194.429 127.184 194.509 127.482 194.742 127.616C195.105 127.826 195.571 127.7 195.781 127.337Z" fill="#9055A2"/>
<path d="M194.807 111.031C194.941 110.799 194.862 110.502 194.63 110.368L194.506 110.296C194.21 110.125 193.831 110.227 193.659 110.523L193.589 110.645C193.455 110.878 193.534 111.176 193.767 111.311C194.131 111.521 194.597 111.395 194.807 111.031Z" fill="#9055A2"/>
<path d="M208.887 123.628C208.753 123.86 208.832 124.157 209.064 124.291L209.188 124.363C209.484 124.534 209.863 124.432 210.034 124.136L210.105 124.014C210.239 123.781 210.16 123.483 209.926 123.348C209.563 123.138 209.097 123.264 208.887 123.628Z" fill="#9055A2"/>
<path d="M124.584 47.5339C124.45 47.7658 124.529 48.0631 124.761 48.1969L124.885 48.2686C125.182 48.4398 125.561 48.3382 125.732 48.0417L125.802 47.9199C125.937 47.6868 125.857 47.3888 125.624 47.2542C125.26 47.0442 124.794 47.1702 124.584 47.5339Z" fill="#9055A2"/>
<path d="M205.203 128.149C205.069 128.381 205.148 128.678 205.38 128.812L205.504 128.883C205.8 129.055 206.179 128.953 206.35 128.656L206.421 128.535C206.555 128.302 206.475 128.004 206.242 127.869C205.879 127.659 205.413 127.785 205.203 128.149Z" fill="#9055A2"/>
<path d="M202.837 116.86C202.703 117.092 202.782 117.389 203.014 117.523L203.138 117.595C203.434 117.766 203.813 117.664 203.985 117.368L204.055 117.246C204.19 117.013 204.11 116.715 203.877 116.58C203.513 116.37 203.047 116.496 202.837 116.86Z" fill="#9055A2"/>
<path d="M190.986 120.343C190.852 120.575 190.931 120.872 191.163 121.006L191.287 121.078C191.584 121.249 191.963 121.147 192.134 120.851L192.204 120.729C192.339 120.496 192.259 120.198 192.026 120.063C191.662 119.853 191.196 119.979 190.986 120.343Z" fill="#9055A2"/>
<path d="M111.349 56.0359C111.215 56.2677 111.294 56.565 111.526 56.6989L111.65 56.7706C111.947 56.9417 112.326 56.8401 112.497 56.5437L112.567 56.4218C112.702 56.1887 112.622 55.8907 112.389 55.7561C112.025 55.5461 111.559 55.6721 111.349 56.0359Z" fill="#9055A2"/>
<path d="M191.96 136.651C191.826 136.882 191.905 137.18 192.137 137.314L192.261 137.385C192.558 137.556 192.937 137.455 193.108 137.158L193.178 137.037C193.313 136.803 193.233 136.505 193 136.371C192.636 136.161 192.17 136.287 191.96 136.651Z" fill="#9055A2"/>
<path d="M139.841 124.269C139.707 124.501 139.786 124.798 140.018 124.932L140.142 125.003C140.438 125.175 140.817 125.073 140.988 124.777L141.059 124.655C141.193 124.422 141.113 124.124 140.88 123.989C140.517 123.779 140.051 123.905 139.841 124.269Z" fill="#9055A2"/>
<path d="M136.157 128.79C136.023 129.022 136.102 129.319 136.333 129.453L136.458 129.524C136.754 129.696 137.133 129.594 137.304 129.298L137.375 129.176C137.509 128.943 137.429 128.645 137.196 128.51C136.833 128.3 136.367 128.426 136.157 128.79Z" fill="#9055A2"/>
<path d="M133.784 117.499C133.65 117.731 133.729 118.028 133.96 118.162L134.085 118.234C134.381 118.405 134.76 118.304 134.931 118.007L135.002 117.885C135.136 117.652 135.056 117.354 134.823 117.22C134.459 117.01 133.994 117.136 133.784 117.499Z" fill="#9055A2"/>
<path d="M214.402 198.116C214.268 198.348 214.347 198.645 214.579 198.779L214.703 198.851C214.999 199.022 215.378 198.92 215.55 198.624L215.62 198.502C215.755 198.269 215.675 197.971 215.442 197.836C215.078 197.626 214.612 197.752 214.402 198.116Z" fill="#9055A2"/>
<path d="M121.94 120.984C121.806 121.216 121.885 121.513 122.117 121.647L122.241 121.719C122.538 121.89 122.917 121.788 123.088 121.492L123.158 121.37C123.293 121.137 123.213 120.839 122.98 120.704C122.616 120.494 122.15 120.62 121.94 120.984Z" fill="#9055A2"/>
<path d="M202.559 201.6C202.425 201.832 202.504 202.13 202.735 202.263L202.86 202.335C203.156 202.506 203.535 202.405 203.706 202.108L203.777 201.986C203.911 201.753 203.831 201.455 203.598 201.321C203.235 201.111 202.769 201.237 202.559 201.6Z" fill="#9055A2"/>
<path d="M122.914 137.291C122.78 137.523 122.859 137.82 123.091 137.954L123.215 138.026C123.512 138.197 123.891 138.096 124.062 137.799L124.132 137.677C124.267 137.444 124.187 137.146 123.954 137.012C123.59 136.802 123.124 136.928 122.914 137.291Z" fill="#9055A2"/>
<path d="M203.533 217.908C203.399 218.14 203.478 218.437 203.71 218.571L203.834 218.643C204.13 218.814 204.509 218.712 204.68 218.416L204.751 218.294C204.885 218.061 204.805 217.763 204.572 217.628C204.209 217.418 203.743 217.544 203.533 217.908Z" fill="#9055A2"/>
<path d="M108.835 124.695C108.969 124.463 108.89 124.165 108.658 124.032L108.534 123.96C108.237 123.789 107.858 123.89 107.687 124.187L107.617 124.309C107.482 124.542 107.562 124.84 107.795 124.974C108.159 125.184 108.625 125.058 108.835 124.695Z" fill="#9055A2"/>
<path d="M189.446 205.309C189.58 205.077 189.501 204.78 189.269 204.646L189.145 204.575C188.848 204.404 188.469 204.505 188.298 204.802L188.228 204.923C188.093 205.156 188.173 205.454 188.406 205.589C188.77 205.799 189.236 205.673 189.446 205.309Z" fill="#9055A2"/>
<path d="M112.519 120.172C112.653 119.94 112.574 119.642 112.342 119.509L112.218 119.437C111.921 119.266 111.542 119.367 111.371 119.664L111.301 119.786C111.166 120.019 111.246 120.317 111.479 120.451C111.843 120.661 112.309 120.535 112.519 120.172Z" fill="#9055A2"/>
<path d="M193.13 200.788C193.264 200.556 193.185 200.259 192.953 200.125L192.829 200.054C192.532 199.883 192.153 199.984 191.982 200.281L191.912 200.402C191.777 200.635 191.857 200.934 192.09 201.068C192.454 201.278 192.92 201.152 193.13 200.788Z" fill="#9055A2"/>
<path d="M114.885 131.462C115.018 131.23 114.939 130.933 114.708 130.799L114.584 130.727C114.287 130.556 113.908 130.658 113.737 130.954L113.666 131.076C113.532 131.309 113.612 131.607 113.845 131.742C114.209 131.952 114.675 131.826 114.885 131.462Z" fill="#9055A2"/>
<path d="M195.503 212.079C195.637 211.847 195.558 211.549 195.326 211.416L195.202 211.344C194.905 211.173 194.526 211.274 194.355 211.571L194.285 211.693C194.15 211.926 194.23 212.224 194.463 212.358C194.827 212.568 195.293 212.442 195.503 212.079Z" fill="#9055A2"/>
<path d="M126.735 127.978C126.869 127.746 126.79 127.449 126.558 127.315L126.434 127.243C126.138 127.072 125.759 127.174 125.588 127.47L125.517 127.592C125.383 127.825 125.462 128.123 125.696 128.258C126.059 128.468 126.525 128.342 126.735 127.978Z" fill="#9055A2"/>
<path d="M207.346 208.594C207.48 208.362 207.401 208.065 207.169 207.931L207.045 207.859C206.749 207.688 206.37 207.79 206.199 208.086L206.128 208.208C205.994 208.441 206.074 208.739 206.307 208.874C206.67 209.084 207.136 208.958 207.346 208.594Z" fill="#9055A2"/>
<path d="M125.761 111.67C125.895 111.438 125.816 111.141 125.584 111.007L125.46 110.935C125.164 110.764 124.785 110.866 124.613 111.162L124.543 111.284C124.408 111.517 124.488 111.815 124.721 111.95C125.085 112.16 125.551 112.034 125.761 111.67Z" fill="#9055A2"/>
<path d="M206.372 192.287C206.506 192.055 206.427 191.758 206.195 191.624L206.071 191.552C205.775 191.381 205.396 191.483 205.224 191.779L205.154 191.901C205.02 192.134 205.099 192.432 205.332 192.567C205.696 192.777 206.162 192.651 206.372 192.287Z" fill="#9055A2"/>
<path d="M99.3571 102.115C99.4909 101.884 99.412 101.586 99.1802 101.452L99.056 101.381C98.7596 101.21 98.3805 101.311 98.2094 101.608L98.139 101.73C98.0044 101.963 98.0843 102.261 98.3174 102.395C98.6811 102.605 99.1471 102.479 99.3571 102.115Z" fill="#9055A2"/>
<path d="M179.975 182.73C180.109 182.498 180.03 182.201 179.799 182.067L179.674 181.996C179.378 181.824 178.999 181.926 178.828 182.222L178.757 182.344C178.623 182.577 178.703 182.875 178.936 183.01C179.3 183.22 179.765 183.094 179.975 182.73Z" fill="#9055A2"/>
<path d="M103.041 97.5945C103.175 97.3626 103.096 97.0653 102.864 96.9315L102.74 96.8598C102.444 96.6887 102.065 96.7902 101.893 97.0867L101.823 97.2086C101.689 97.4416 101.768 97.7397 102.001 97.8742C102.365 98.0842 102.831 97.9582 103.041 97.5945Z" fill="#9055A2"/>
<path d="M183.652 178.209C183.786 177.977 183.707 177.68 183.475 177.546L183.351 177.475C183.055 177.303 182.676 177.405 182.505 177.701L182.434 177.823C182.3 178.056 182.379 178.354 182.613 178.489C182.976 178.699 183.442 178.573 183.652 178.209Z" fill="#9055A2"/>
<path d="M105.407 108.883C105.541 108.651 105.462 108.354 105.23 108.22L105.106 108.148C104.809 107.977 104.43 108.079 104.259 108.375L104.189 108.497C104.054 108.73 104.134 109.028 104.367 109.163C104.731 109.373 105.197 109.247 105.407 108.883Z" fill="#9055A2"/>
<path d="M186.025 189.498C186.159 189.266 186.08 188.969 185.848 188.835L185.724 188.763C185.428 188.592 185.049 188.694 184.878 188.99L184.807 189.112C184.673 189.345 184.753 189.643 184.986 189.778C185.349 189.988 185.815 189.862 186.025 189.498Z" fill="#9055A2"/>
<path d="M117.258 105.399C117.391 105.167 117.313 104.87 117.081 104.736L116.957 104.664C116.66 104.493 116.281 104.594 116.11 104.891L116.04 105.013C115.905 105.246 115.985 105.544 116.218 105.678C116.582 105.888 117.048 105.762 117.258 105.399Z" fill="#9055A2"/>
<path d="M197.876 186.015C198.01 185.784 197.931 185.486 197.699 185.352L197.575 185.281C197.279 185.11 196.899 185.211 196.728 185.508L196.658 185.629C196.523 185.863 196.603 186.161 196.836 186.295C197.2 186.505 197.666 186.379 197.876 186.015Z" fill="#9055A2"/>
<path d="M116.283 89.0911C116.417 88.8592 116.338 88.5619 116.107 88.4281L115.982 88.3564C115.686 88.1852 115.307 88.2868 115.136 88.5833L115.065 88.7051C114.931 88.9382 115.011 89.2362 115.244 89.3708C115.608 89.5808 116.073 89.4548 116.283 89.0911Z" fill="#9055A2"/>
<path d="M196.895 169.708C197.028 169.476 196.949 169.179 196.718 169.045L196.594 168.973C196.297 168.802 195.918 168.904 195.747 169.2L195.676 169.322C195.542 169.555 195.622 169.853 195.855 169.988C196.219 170.198 196.685 170.072 196.895 169.708Z" fill="#9055A2"/>
<path d="M130.363 101.69C130.229 101.922 130.308 102.219 130.54 102.353L130.664 102.424C130.961 102.596 131.34 102.494 131.511 102.198L131.581 102.076C131.716 101.843 131.636 101.545 131.403 101.41C131.039 101.2 130.573 101.326 130.363 101.69Z" fill="#9055A2"/>
<path d="M210.981 182.306C210.848 182.538 210.927 182.836 211.158 182.969L211.283 183.041C211.579 183.212 211.958 183.111 212.129 182.814L212.2 182.692C212.334 182.459 212.254 182.161 212.021 182.027C211.657 181.817 211.191 181.943 210.981 182.306Z" fill="#9055A2"/>
<path d="M126.679 106.211C126.545 106.443 126.624 106.74 126.856 106.874L126.98 106.945C127.276 107.117 127.656 107.015 127.827 106.718L127.897 106.597C128.032 106.364 127.952 106.066 127.719 105.931C127.355 105.721 126.889 105.847 126.679 106.211Z" fill="#9055A2"/>
<path d="M207.297 186.827C207.164 187.059 207.242 187.357 207.474 187.49L207.598 187.562C207.895 187.733 208.274 187.632 208.445 187.335L208.515 187.213C208.65 186.98 208.57 186.682 208.337 186.548C207.973 186.338 207.507 186.464 207.297 186.827Z" fill="#9055A2"/>
<path d="M124.306 94.9207C124.172 95.1525 124.251 95.4498 124.483 95.5837L124.607 95.6553C124.903 95.8265 125.282 95.7249 125.454 95.4285L125.524 95.3066C125.659 95.0735 125.579 94.7755 125.346 94.6409C124.982 94.4309 124.516 94.5569 124.306 94.9207Z" fill="#9055A2"/>
<path d="M204.924 175.537C204.79 175.769 204.869 176.066 205.101 176.2L205.225 176.272C205.522 176.443 205.901 176.341 206.072 176.045L206.142 175.923C206.277 175.69 206.197 175.392 205.964 175.257C205.6 175.047 205.134 175.173 204.924 175.537Z" fill="#9055A2"/>
<path d="M112.463 98.405C112.329 98.6369 112.408 98.9342 112.639 99.068L112.764 99.1397C113.06 99.3109 113.439 99.2093 113.61 98.9128L113.681 98.791C113.815 98.5579 113.735 98.2599 113.502 98.1253C113.138 97.9153 112.673 98.0413 112.463 98.405Z" fill="#9055A2"/>
<path d="M193.074 179.022C192.94 179.254 193.019 179.551 193.251 179.685L193.375 179.756C193.671 179.928 194.05 179.826 194.221 179.53L194.292 179.408C194.426 179.175 194.346 178.877 194.113 178.742C193.75 178.532 193.284 178.658 193.074 179.022Z" fill="#9055A2"/>
<path d="M113.437 114.713C113.303 114.944 113.382 115.242 113.614 115.376L113.738 115.447C114.034 115.618 114.413 115.517 114.584 115.22L114.655 115.099C114.789 114.866 114.709 114.567 114.476 114.433C114.113 114.223 113.647 114.349 113.437 114.713Z" fill="#9055A2"/>
<path d="M194.055 195.329C193.921 195.561 194 195.858 194.232 195.992L194.356 196.064C194.653 196.235 195.032 196.133 195.203 195.837L195.273 195.715C195.408 195.482 195.328 195.184 195.095 195.049C194.731 194.839 194.265 194.965 194.055 195.329Z" fill="#9055A2"/>
<path d="M170.083 108.796C169.949 109.028 170.028 109.325 170.26 109.459L170.384 109.531C170.68 109.702 171.059 109.6 171.23 109.304L171.301 109.182C171.435 108.949 171.355 108.651 171.122 108.516C170.759 108.306 170.293 108.432 170.083 108.796Z" fill="#9055A2"/>
<path d="M166.399 113.319C166.265 113.551 166.344 113.848 166.575 113.982L166.7 114.054C166.996 114.225 167.375 114.123 167.546 113.827L167.617 113.705C167.751 113.472 167.671 113.174 167.438 113.039C167.075 112.829 166.609 112.955 166.399 113.319Z" fill="#9055A2"/>
<path d="M164.033 102.029C163.899 102.26 163.978 102.558 164.21 102.692L164.334 102.763C164.63 102.934 165.009 102.833 165.181 102.536L165.251 102.414C165.385 102.181 165.306 101.883 165.073 101.749C164.709 101.539 164.243 101.665 164.033 102.029Z" fill="#9055A2"/>
<path d="M152.182 105.515C152.048 105.747 152.127 106.044 152.359 106.178L152.483 106.25C152.78 106.421 153.159 106.319 153.33 106.023L153.4 105.901C153.535 105.668 153.455 105.37 153.222 105.235C152.858 105.025 152.392 105.151 152.182 105.515Z" fill="#9055A2"/>
<path d="M153.164 121.821C153.03 122.052 153.109 122.35 153.34 122.484L153.465 122.555C153.761 122.726 154.14 122.625 154.311 122.328L154.382 122.206C154.516 121.973 154.436 121.675 154.203 121.541C153.84 121.331 153.374 121.457 153.164 121.821Z" fill="#9055A2"/>
<path d="M139.077 109.222C139.211 108.99 139.132 108.693 138.9 108.559L138.776 108.487C138.479 108.316 138.1 108.418 137.929 108.714L137.859 108.836C137.724 109.069 137.804 109.367 138.037 109.502C138.401 109.712 138.867 109.586 139.077 109.222Z" fill="#9055A2"/>
<path d="M142.761 104.701C142.895 104.469 142.816 104.172 142.584 104.038L142.46 103.966C142.163 103.795 141.784 103.897 141.613 104.193L141.543 104.315C141.408 104.548 141.488 104.846 141.721 104.981C142.085 105.191 142.551 105.065 142.761 104.701Z" fill="#9055A2"/>
<path d="M145.134 115.991C145.268 115.76 145.189 115.462 144.957 115.328L144.833 115.257C144.536 115.086 144.157 115.187 143.986 115.484L143.916 115.606C143.781 115.839 143.861 116.137 144.094 116.271C144.458 116.481 144.924 116.355 145.134 115.991Z" fill="#9055A2"/>
<path d="M156.977 112.507C157.111 112.275 157.032 111.978 156.8 111.844L156.676 111.772C156.38 111.601 156.001 111.703 155.829 111.999L155.759 112.121C155.625 112.354 155.704 112.652 155.938 112.787C156.301 112.997 156.767 112.871 156.977 112.507Z" fill="#9055A2"/>
<path d="M156.003 96.1994C156.137 95.9676 156.058 95.6703 155.826 95.5365L155.702 95.4648C155.406 95.2936 155.027 95.3952 154.855 95.6917L154.785 95.8135C154.65 96.0466 154.73 96.3446 154.963 96.4792C155.327 96.6892 155.793 96.5632 156.003 96.1994Z" fill="#9055A2"/>
<path d="M129.599 86.6428C129.733 86.411 129.654 86.1137 129.422 85.9798L129.298 85.9082C129.002 85.737 128.622 85.8386 128.451 86.135L128.381 86.2569C128.246 86.49 128.326 86.788 128.559 86.9226C128.923 87.1326 129.389 87.0066 129.599 86.6428Z" fill="#9055A2"/>
<path d="M210.217 167.26C210.351 167.028 210.272 166.73 210.041 166.597L209.916 166.525C209.62 166.354 209.241 166.455 209.07 166.752L208.999 166.874C208.865 167.107 208.945 167.405 209.178 167.539C209.541 167.749 210.007 167.623 210.217 167.26Z" fill="#9055A2"/>
<path d="M133.283 82.1223C133.417 81.8905 133.338 81.5932 133.106 81.4593L132.982 81.3876C132.686 81.2165 132.307 81.3181 132.135 81.6145L132.065 81.7364C131.931 81.9695 132.01 82.2675 132.243 82.4021C132.607 82.6121 133.073 82.4861 133.283 82.1223Z" fill="#9055A2"/>
<path d="M213.902 162.739C214.035 162.507 213.956 162.209 213.725 162.076L213.601 162.004C213.304 161.833 212.925 161.934 212.754 162.231L212.683 162.353C212.549 162.586 212.629 162.884 212.862 163.018C213.226 163.228 213.692 163.102 213.902 162.739Z" fill="#9055A2"/>
<path d="M135.656 93.4123C135.79 93.1805 135.711 92.8832 135.479 92.7493L135.355 92.6777C135.059 92.5065 134.68 92.6081 134.509 92.9045L134.438 93.0264C134.304 93.2595 134.383 93.5575 134.617 93.6921C134.98 93.9021 135.446 93.7761 135.656 93.4123Z" fill="#9055A2"/>
<path d="M216.267 174.029C216.401 173.797 216.322 173.5 216.09 173.366L215.966 173.294C215.67 173.123 215.291 173.225 215.12 173.521L215.049 173.643C214.915 173.876 214.995 174.174 215.228 174.309C215.591 174.519 216.057 174.393 216.267 174.029Z" fill="#9055A2"/>
<path d="M147.5 89.928C147.633 89.6961 147.555 89.3988 147.323 89.265L147.199 89.1933C146.902 89.0222 146.523 89.1237 146.352 89.4202L146.282 89.542C146.147 89.7751 146.227 90.0732 146.46 90.2077C146.824 90.4177 147.29 90.2917 147.5 89.928Z" fill="#9055A2"/>
<path d="M146.525 73.6203C146.659 73.3885 146.58 73.0912 146.349 72.9574L146.224 72.8857C145.928 72.7145 145.549 72.8161 145.378 73.1126L145.307 73.2344C145.173 73.4675 145.253 73.7655 145.486 73.9001C145.85 74.1101 146.315 73.9841 146.525 73.6203Z" fill="#9055A2"/>
<path d="M160.605 86.2175C160.471 86.4494 160.55 86.7467 160.782 86.8805L160.906 86.9522C161.203 87.1234 161.582 87.0218 161.753 86.7253L161.823 86.6035C161.958 86.3704 161.878 86.0724 161.645 85.9378C161.281 85.7278 160.815 85.8538 160.605 86.2175Z" fill="#9055A2"/>
<path d="M156.921 90.74C156.787 90.9718 156.866 91.2691 157.098 91.403L157.222 91.4747C157.518 91.6458 157.897 91.5442 158.069 91.2478L158.139 91.1259C158.274 90.8928 158.194 90.5948 157.961 90.4603C157.597 90.2502 157.131 90.3762 156.921 90.74Z" fill="#9055A2"/>
<path d="M154.555 79.4495C154.421 79.6813 154.5 79.9786 154.732 80.1125L154.856 80.1841C155.153 80.3553 155.532 80.2537 155.703 79.9573L155.773 79.8354C155.908 79.6023 155.828 79.3043 155.595 79.1697C155.231 78.9597 154.765 79.0857 154.555 79.4495Z" fill="#9055A2"/>
<path d="M142.705 82.9363C142.571 83.1681 142.65 83.4654 142.881 83.5993L143.006 83.671C143.302 83.8421 143.681 83.7405 143.852 83.4441L143.923 83.3222C144.057 83.0891 143.977 82.7911 143.744 82.6565C143.38 82.4465 142.915 82.5725 142.705 82.9363Z" fill="#9055A2"/>
<path d="M143.679 99.242C143.545 99.4738 143.624 99.7711 143.856 99.9049L143.98 99.9766C144.276 100.148 144.655 100.046 144.826 99.7497L144.897 99.6279C145.031 99.3948 144.951 99.0968 144.718 98.9622C144.355 98.7522 143.889 98.8782 143.679 99.242Z" fill="#9055A2"/>
<path d="M170.222 50.3977C170.088 50.6295 170.167 50.9269 170.399 51.0607L170.523 51.1324C170.819 51.3035 171.198 51.202 171.37 50.9055L171.44 50.7836C171.574 50.5506 171.495 50.2525 171.262 50.118C170.898 49.908 170.432 50.034 170.222 50.3977Z" fill="#9055A2"/>
<path d="M59.0172 224.494C58.8834 224.726 58.9623 225.024 59.1941 225.157L59.3182 225.229C59.6147 225.4 59.9938 225.299 60.1649 225.002L60.2353 224.88C60.3698 224.647 60.29 224.349 60.0569 224.215C59.6932 224.005 59.2272 224.131 59.0172 224.494Z" fill="#9055A2"/>
<path d="M166.538 54.9207C166.404 55.1525 166.483 55.4498 166.715 55.5837L166.839 55.6553C167.135 55.8265 167.514 55.7249 167.685 55.4285L167.756 55.3066C167.89 55.0735 167.81 54.7755 167.577 54.6409C167.214 54.4309 166.748 54.5569 166.538 54.9207Z" fill="#9055A2"/>
<path d="M55.3331 229.017C55.1993 229.249 55.2782 229.546 55.51 229.68L55.6341 229.752C55.9306 229.923 56.3096 229.821 56.4808 229.525L56.5512 229.403C56.6857 229.17 56.6059 228.872 56.3728 228.737C56.009 228.527 55.5431 228.653 55.3331 229.017Z" fill="#9055A2"/>
<path d="M52.9674 217.727C52.8335 217.959 52.9124 218.256 53.1443 218.39L53.2684 218.461C53.5648 218.633 53.9439 218.531 54.1151 218.235L54.1854 218.113C54.32 217.88 54.2401 217.582 54.0071 217.447C53.6433 217.237 53.1774 217.363 52.9674 217.727Z" fill="#9055A2"/>
<path d="M152.321 47.115C152.187 47.3468 152.266 47.6441 152.498 47.778L152.622 47.8497C152.919 48.0208 153.298 47.9192 153.469 47.6228L153.539 47.5009C153.674 47.2678 153.594 46.9698 153.361 46.8353C152.997 46.6252 152.531 46.7512 152.321 47.115Z" fill="#9055A2"/>
<path d="M153.295 63.4221C153.162 63.654 153.24 63.9513 153.472 64.0851L153.596 64.1568C153.893 64.3279 154.272 64.2264 154.443 63.9299L154.513 63.8081C154.648 63.575 154.568 63.2769 154.335 63.1424C153.971 62.9324 153.505 63.0584 153.295 63.4221Z" fill="#9055A2"/>
<path d="M139.216 50.8235C139.35 50.5916 139.271 50.2943 139.039 50.1605L138.915 50.0888C138.618 49.9177 138.239 50.0192 138.068 50.3157L137.998 50.4376C137.863 50.6706 137.943 50.9687 138.176 51.1032C138.54 51.3132 139.006 51.1872 139.216 50.8235Z" fill="#9055A2"/>
<path d="M142.9 46.3025C143.034 46.0706 142.955 45.7733 142.723 45.6395L142.599 45.5678C142.302 45.3967 141.923 45.4982 141.752 45.7947L141.682 45.9166C141.547 46.1496 141.627 46.4477 141.86 46.5822C142.224 46.7922 142.69 46.6662 142.9 46.3025Z" fill="#9055A2"/>
<path d="M145.273 57.593C145.407 57.3612 145.328 57.0639 145.096 56.93L144.972 56.8583C144.676 56.6872 144.296 56.7888 144.125 57.0852L144.055 57.2071C143.92 57.4402 144 57.7382 144.233 57.8728C144.597 58.0828 145.063 57.9568 145.273 57.593Z" fill="#9055A2"/>
<path d="M157.116 54.1086C157.25 53.8768 157.171 53.5795 156.939 53.4456L156.815 53.374C156.519 53.2028 156.14 53.3044 155.969 53.6008L155.898 53.7227C155.764 53.9558 155.844 54.2538 156.077 54.3884C156.44 54.5984 156.906 54.4724 157.116 54.1086Z" fill="#9055A2"/>
<path d="M210.357 108.861C210.49 108.629 210.412 108.332 210.18 108.198L210.056 108.126C209.759 107.955 209.38 108.057 209.209 108.353L209.139 108.475C209.004 108.708 209.084 109.006 209.317 109.141C209.681 109.351 210.147 109.225 210.357 108.861Z" fill="#9055A2"/>
<path d="M214.041 104.34C214.175 104.108 214.096 103.811 213.864 103.677L213.74 103.605C213.443 103.434 213.064 103.536 212.893 103.832L212.823 103.954C212.688 104.187 212.768 104.485 213.001 104.62C213.365 104.83 213.831 104.704 214.041 104.34Z" fill="#9055A2"/>
<path d="M216.406 115.629C216.54 115.397 216.461 115.1 216.23 114.966L216.105 114.894C215.809 114.723 215.43 114.824 215.259 115.121L215.188 115.243C215.054 115.476 215.134 115.774 215.367 115.908C215.731 116.118 216.196 115.992 216.406 115.629Z" fill="#9055A2"/>
<path d="M49.5396 201.915C49.4057 202.147 49.4847 202.444 49.7165 202.578L49.8406 202.65C50.1371 202.821 50.5161 202.72 50.6873 202.423L50.7577 202.301C50.8922 202.068 50.8124 201.77 50.5793 201.636C50.2155 201.426 49.7496 201.552 49.5396 201.915Z" fill="#9055A2"/>
<path d="M89.2665 209.025C89.1327 209.257 89.2116 209.555 89.4434 209.688L89.5675 209.76C89.864 209.931 90.2431 209.83 90.4142 209.533L90.4846 209.411C90.6191 209.178 90.5393 208.88 90.3062 208.746C89.9425 208.536 89.4765 208.662 89.2665 209.025Z" fill="#9055A2"/>
<path d="M85.5824 213.546C85.4485 213.778 85.5275 214.076 85.7593 214.209L85.8834 214.281C86.1799 214.452 86.5589 214.351 86.7301 214.054L86.8005 213.932C86.935 213.699 86.8552 213.401 86.6221 213.267C86.2583 213.057 85.7924 213.183 85.5824 213.546Z" fill="#9055A2"/>
<path d="M83.2093 202.256C83.0755 202.488 83.1544 202.785 83.3862 202.919L83.5104 202.991C83.8068 203.162 84.1859 203.06 84.357 202.764L84.4274 202.642C84.562 202.409 84.4821 202.111 84.249 201.976C83.8853 201.766 83.4193 201.892 83.2093 202.256Z" fill="#9055A2"/>
<path d="M71.366 205.74C71.2321 205.972 71.311 206.27 71.5429 206.403L71.667 206.475C71.9634 206.646 72.3425 206.545 72.5137 206.248L72.584 206.126C72.7186 205.893 72.6387 205.595 72.4057 205.461C72.0419 205.251 71.576 205.377 71.366 205.74Z" fill="#9055A2"/>
<path d="M183.545 47.9514C183.411 48.1833 183.49 48.4806 183.722 48.6144L183.846 48.6861C184.142 48.8572 184.521 48.7557 184.692 48.4592L184.763 48.3373C184.897 48.1043 184.818 47.8062 184.584 47.6717C184.221 47.4617 183.755 47.5877 183.545 47.9514Z" fill="#9055A2"/>
<path d="M72.3401 222.048C72.2062 222.28 72.2852 222.577 72.517 222.711L72.6411 222.783C72.9376 222.954 73.3166 222.852 73.4878 222.556L73.5582 222.434C73.6927 222.201 73.6129 221.903 73.3798 221.768C73.016 221.558 72.5501 221.684 72.3401 222.048Z" fill="#9055A2"/>
<path d="M58.2532 209.449C58.387 209.217 58.3081 208.92 58.0763 208.786L57.9521 208.715C57.6557 208.543 57.2766 208.645 57.1055 208.941L57.0351 209.063C56.9005 209.296 56.9804 209.594 57.2135 209.729C57.5772 209.939 58.0432 209.813 58.2532 209.449Z" fill="#9055A2"/>
<path d="M61.9446 204.928C62.0785 204.697 61.9996 204.399 61.7677 204.265L61.6436 204.194C61.3471 204.023 60.9681 204.124 60.7969 204.421L60.7266 204.543C60.592 204.776 60.6718 205.074 60.9049 205.208C61.2687 205.418 61.7346 205.292 61.9446 204.928Z" fill="#9055A2"/>
<path d="M64.3104 216.217C64.4442 215.985 64.3653 215.688 64.1335 215.554L64.0093 215.482C63.7129 215.311 63.3338 215.413 63.1627 215.709L63.0923 215.831C62.9577 216.064 63.0376 216.362 63.2707 216.497C63.6344 216.707 64.1003 216.581 64.3104 216.217Z" fill="#9055A2"/>
<path d="M76.1611 212.734C76.2949 212.502 76.216 212.205 75.9842 212.071L75.86 211.999C75.5636 211.828 75.1845 211.93 75.0134 212.226L74.943 212.348C74.8084 212.581 74.8883 212.879 75.1214 213.014C75.4851 213.224 75.951 213.098 76.1611 212.734Z" fill="#9055A2"/>
<path d="M75.1869 196.427C75.3208 196.195 75.2418 195.897 75.01 195.764L74.8859 195.692C74.5894 195.521 74.2104 195.622 74.0392 195.919L73.9689 196.041C73.8343 196.274 73.9141 196.572 74.1472 196.706C74.511 196.916 74.9769 196.79 75.1869 196.427Z" fill="#9055A2"/>
<path d="M52.467 182.349C52.6008 182.118 52.5219 181.82 52.2901 181.686L52.166 181.615C51.8695 181.444 51.4904 181.545 51.3193 181.842L51.2489 181.963C51.1144 182.197 51.1942 182.495 51.4273 182.629C51.7911 182.839 52.257 182.713 52.467 182.349Z" fill="#9055A2"/>
<path d="M54.8328 193.64C54.9666 193.408 54.8877 193.111 54.6558 192.977L54.5317 192.905C54.2353 192.734 53.8562 192.836 53.685 193.132L53.6147 193.254C53.4801 193.487 53.56 193.785 53.793 193.92C54.1568 194.13 54.6227 194.004 54.8328 193.64Z" fill="#9055A2"/>
<path d="M66.6834 190.154C66.8173 189.922 66.7384 189.624 66.5065 189.491L66.3824 189.419C66.086 189.248 65.7069 189.349 65.5357 189.646L65.4654 189.768C65.3308 190.001 65.4107 190.299 65.6437 190.433C66.0075 190.643 66.4734 190.517 66.6834 190.154Z" fill="#9055A2"/>
<path d="M65.702 173.848C65.8358 173.616 65.7569 173.319 65.5251 173.185L65.4009 173.113C65.1045 172.942 64.7254 173.044 64.5543 173.34L64.4839 173.462C64.3493 173.695 64.4292 173.993 64.6623 174.128C65.026 174.338 65.492 174.212 65.702 173.848Z" fill="#9055A2"/>
<path d="M79.7889 186.445C79.655 186.676 79.734 186.974 79.9658 187.108L80.0899 187.179C80.3864 187.35 80.7654 187.249 80.9366 186.952L81.007 186.831C81.1415 186.597 81.0617 186.299 80.8286 186.165C80.4648 185.955 79.9989 186.081 79.7889 186.445Z" fill="#9055A2"/>
<path d="M76.1048 190.968C75.9709 191.199 76.0499 191.497 76.2817 191.631L76.4058 191.702C76.7023 191.873 77.0813 191.772 77.2525 191.475L77.3229 191.353C77.4574 191.12 77.3776 190.822 77.1445 190.688C76.7807 190.478 76.3148 190.604 76.1048 190.968Z" fill="#9055A2"/>
<path d="M73.7317 179.677C73.5979 179.909 73.6768 180.206 73.9086 180.34L74.0327 180.412C74.3292 180.583 74.7083 180.481 74.8794 180.185L74.9498 180.063C75.0843 179.83 75.0045 179.532 74.7714 179.397C74.4076 179.187 73.9417 179.313 73.7317 179.677Z" fill="#9055A2"/>
<path d="M61.8883 183.161C61.7545 183.393 61.8334 183.691 62.0652 183.824L62.1894 183.896C62.4858 184.067 62.8649 183.966 63.036 183.669L63.1064 183.547C63.241 183.314 63.1611 183.016 62.928 182.882C62.5643 182.672 62.0984 182.798 61.8883 183.161Z" fill="#9055A2"/>
<path d="M62.8625 199.469C62.7286 199.701 62.8075 199.998 63.0394 200.132L63.1635 200.204C63.46 200.375 63.839 200.273 64.0102 199.977L64.0805 199.855C64.2151 199.622 64.1352 199.324 63.9022 199.189C63.5384 198.979 63.0725 199.105 62.8625 199.469Z" fill="#9055A2"/>
<path d="M64.3323 117.683C64.4662 117.452 64.3873 117.154 64.1554 117.02L64.0313 116.949C63.7349 116.778 63.3558 116.879 63.1846 117.176L63.1143 117.297C62.9797 117.53 63.0596 117.829 63.2926 117.963C63.6564 118.173 64.1223 118.047 64.3323 117.683Z" fill="#9055A2"/>
<path d="M144.951 198.3C145.085 198.068 145.006 197.771 144.774 197.637L144.65 197.565C144.353 197.394 143.974 197.496 143.803 197.792L143.733 197.914C143.598 198.147 143.678 198.445 143.911 198.58C144.275 198.79 144.741 198.664 144.951 198.3Z" fill="#9055A2"/>
<path d="M68.0165 113.162C68.1503 112.931 68.0714 112.633 67.8396 112.499L67.7154 112.428C67.419 112.257 67.0399 112.358 66.8688 112.655L66.7984 112.776C66.6638 113.009 66.7437 113.308 66.9768 113.442C67.3405 113.652 67.8065 113.526 68.0165 113.162Z" fill="#9055A2"/>
<path d="M148.635 193.777C148.769 193.545 148.69 193.248 148.458 193.114L148.334 193.042C148.037 192.871 147.658 192.973 147.487 193.269L147.417 193.391C147.282 193.624 147.362 193.922 147.595 194.057C147.959 194.267 148.425 194.141 148.635 193.777Z" fill="#9055A2"/>
<path d="M70.3895 124.453C70.5234 124.221 70.4445 123.924 70.2126 123.79L70.0885 123.718C69.792 123.547 69.413 123.649 69.2418 123.945L69.1715 124.067C69.0369 124.3 69.1167 124.598 69.3498 124.733C69.7136 124.943 70.1795 124.817 70.3895 124.453Z" fill="#9055A2"/>
<path d="M151.001 205.068C151.134 204.836 151.056 204.538 150.824 204.405L150.7 204.333C150.403 204.162 150.024 204.263 149.853 204.56L149.783 204.682C149.648 204.915 149.728 205.213 149.961 205.347C150.325 205.557 150.791 205.431 151.001 205.068Z" fill="#9055A2"/>
<path d="M82.2329 120.968C82.3667 120.737 82.2878 120.439 82.056 120.306L81.9319 120.234C81.6354 120.063 81.2563 120.164 81.0852 120.461L81.0148 120.583C80.8803 120.816 80.9601 121.114 81.1932 121.248C81.5569 121.458 82.0229 121.332 82.2329 120.968Z" fill="#9055A2"/>
<path d="M162.851 201.583C162.985 201.351 162.906 201.054 162.674 200.92L162.55 200.848C162.254 200.677 161.875 200.779 161.704 201.075L161.633 201.197C161.499 201.43 161.579 201.728 161.812 201.863C162.175 202.073 162.641 201.947 162.851 201.583Z" fill="#9055A2"/>
<path d="M81.2588 104.661C81.3926 104.429 81.3137 104.132 81.0819 103.998L80.9577 103.926C80.6613 103.755 80.2822 103.857 80.1111 104.153L80.0407 104.275C79.9061 104.508 79.986 104.806 80.2191 104.941C80.5828 105.151 81.0487 105.025 81.2588 104.661Z" fill="#9055A2"/>
<path d="M161.877 185.276C162.011 185.044 161.932 184.746 161.7 184.613L161.576 184.541C161.28 184.37 160.901 184.471 160.729 184.768L160.659 184.89C160.525 185.123 160.604 185.421 160.837 185.555C161.201 185.765 161.667 185.639 161.877 185.276Z" fill="#9055A2"/>
<path d="M95.3383 117.26C95.2045 117.491 95.2834 117.789 95.5153 117.923L95.6394 117.994C95.9358 118.165 96.3149 118.064 96.4861 117.767L96.5564 117.645C96.691 117.412 96.6111 117.114 96.3781 116.98C96.0143 116.77 95.5484 116.896 95.3383 117.26Z" fill="#9055A2"/>
<path d="M175.957 197.874C175.823 198.106 175.902 198.403 176.134 198.537L176.258 198.609C176.554 198.78 176.933 198.679 177.104 198.382L177.175 198.26C177.309 198.027 177.23 197.729 176.996 197.595C176.633 197.385 176.167 197.511 175.957 197.874Z" fill="#9055A2"/>
<path d="M91.6542 121.779C91.5204 122.01 91.5993 122.308 91.8311 122.442L91.9553 122.513C92.2517 122.684 92.6308 122.583 92.8019 122.286L92.8723 122.164C93.0069 121.931 92.927 121.633 92.6939 121.499C92.3302 121.289 91.8643 121.415 91.6542 121.779Z" fill="#9055A2"/>
<path d="M172.273 202.395C172.139 202.627 172.218 202.924 172.45 203.058L172.574 203.13C172.87 203.301 173.249 203.2 173.42 202.903L173.491 202.781C173.625 202.548 173.545 202.25 173.312 202.116C172.949 201.906 172.483 202.032 172.273 202.395Z" fill="#9055A2"/>
<path d="M89.2885 110.49C89.1546 110.722 89.2336 111.019 89.4654 111.153L89.5895 111.225C89.886 111.396 90.265 111.294 90.4362 110.998L90.5066 110.876C90.6411 110.643 90.5613 110.345 90.3282 110.21C89.9644 110 89.4985 110.126 89.2885 110.49Z" fill="#9055A2"/>
<path d="M169.9 191.107C169.766 191.339 169.845 191.636 170.076 191.77L170.201 191.841C170.497 192.013 170.876 191.911 171.047 191.614L171.118 191.493C171.252 191.26 171.172 190.962 170.939 190.827C170.576 190.617 170.11 190.743 169.9 191.107Z" fill="#9055A2"/>
<path d="M77.4378 113.974C77.3039 114.206 77.3829 114.504 77.6147 114.637L77.7388 114.709C78.0353 114.88 78.4143 114.779 78.5855 114.482L78.6559 114.36C78.7904 114.127 78.7106 113.829 78.4775 113.695C78.1137 113.485 77.6478 113.611 77.4378 113.974Z" fill="#9055A2"/>
<path d="M158.056 194.591C157.922 194.823 158.001 195.12 158.233 195.254L158.357 195.326C158.654 195.497 159.033 195.395 159.204 195.099L159.274 194.977C159.409 194.744 159.329 194.446 159.096 194.311C158.732 194.101 158.266 194.227 158.056 194.591Z" fill="#9055A2"/>
<path d="M78.4119 130.28C78.2781 130.512 78.357 130.809 78.5888 130.943L78.713 131.015C79.0094 131.186 79.3885 131.084 79.5596 130.788L79.63 130.666C79.7646 130.433 79.6847 130.135 79.4516 130C79.0879 129.79 78.6219 129.916 78.4119 130.28Z" fill="#9055A2"/>
<path d="M159.03 210.897C158.896 211.129 158.975 211.426 159.207 211.56L159.331 211.631C159.628 211.803 160.007 211.701 160.178 211.405L160.248 211.283C160.383 211.05 160.303 210.752 160.07 210.617C159.706 210.407 159.24 210.533 159.03 210.897Z" fill="#9055A2"/>
<path d="M104.816 139.837C104.682 140.069 104.761 140.366 104.993 140.5L105.117 140.571C105.413 140.742 105.793 140.641 105.964 140.344L106.034 140.223C106.169 139.99 106.089 139.691 105.856 139.557C105.492 139.347 105.026 139.473 104.816 139.837Z" fill="#9055A2"/>
<path d="M185.434 220.453C185.301 220.685 185.379 220.982 185.611 221.116L185.735 221.188C186.032 221.359 186.411 221.257 186.582 220.961L186.652 220.839C186.787 220.606 186.707 220.308 186.474 220.173C186.11 219.963 185.644 220.089 185.434 220.453Z" fill="#9055A2"/>
<path d="M101.132 144.359C100.998 144.591 101.077 144.888 101.309 145.022L101.433 145.094C101.729 145.265 102.108 145.163 102.28 144.867L102.35 144.745C102.485 144.512 102.405 144.214 102.172 144.079C101.808 143.869 101.342 143.995 101.132 144.359Z" fill="#9055A2"/>
<path d="M181.75 224.974C181.616 225.206 181.695 225.503 181.927 225.637L182.051 225.709C182.348 225.88 182.727 225.778 182.898 225.482L182.968 225.36C183.103 225.127 183.023 224.829 182.79 224.694C182.426 224.484 181.96 224.61 181.75 224.974Z" fill="#9055A2"/>
<path d="M98.7661 133.069C98.6323 133.301 98.7112 133.598 98.943 133.732L99.0671 133.804C99.3636 133.975 99.7427 133.873 99.9138 133.577L99.9842 133.455C100.119 133.222 100.039 132.924 99.8058 132.789C99.4421 132.579 98.9761 132.705 98.7661 133.069Z" fill="#9055A2"/>
<path d="M179.385 213.686C179.251 213.917 179.33 214.215 179.561 214.349L179.686 214.42C179.982 214.591 180.361 214.49 180.532 214.193L180.603 214.071C180.737 213.838 180.657 213.54 180.424 213.406C180.06 213.196 179.595 213.322 179.385 213.686Z" fill="#9055A2"/>
<path d="M86.9154 136.553C86.7816 136.785 86.8605 137.083 87.0923 137.216L87.2165 137.288C87.5129 137.459 87.892 137.358 88.0631 137.061L88.1335 136.939C88.2681 136.706 88.1882 136.408 87.9551 136.274C87.5914 136.064 87.1254 136.19 86.9154 136.553Z" fill="#9055A2"/>
<path d="M167.534 217.17C167.4 217.402 167.479 217.699 167.711 217.833L167.835 217.905C168.131 218.076 168.51 217.974 168.682 217.678L168.752 217.556C168.886 217.323 168.807 217.025 168.574 216.89C168.21 216.68 167.744 216.806 167.534 217.17Z" fill="#9055A2"/>
<path d="M87.8896 152.859C87.7557 153.091 87.8346 153.388 88.0665 153.522L88.1906 153.594C88.487 153.765 88.8661 153.663 89.0373 153.367L89.1076 153.245C89.2422 153.012 89.1623 152.714 88.9293 152.579C88.5655 152.369 88.0996 152.495 87.8896 152.859Z" fill="#9055A2"/>
<path d="M168.508 233.476C168.374 233.708 168.453 234.005 168.685 234.139L168.809 234.211C169.105 234.382 169.485 234.28 169.656 233.984L169.726 233.862C169.861 233.629 169.781 233.331 169.548 233.196C169.184 232.986 168.718 233.112 168.508 233.476Z" fill="#9055A2"/>
<path d="M73.81 140.262C73.9438 140.031 73.8649 139.733 73.633 139.599L73.5089 139.528C73.2125 139.357 72.8334 139.458 72.6622 139.755L72.5919 139.877C72.4573 140.11 72.5372 140.408 72.7702 140.542C73.134 140.752 73.5999 140.626 73.81 140.262Z" fill="#9055A2"/>
<path d="M154.428 220.879C154.562 220.647 154.483 220.35 154.251 220.216L154.127 220.144C153.831 219.973 153.452 220.075 153.281 220.371L153.21 220.493C153.076 220.726 153.156 221.024 153.389 221.159C153.752 221.369 154.218 221.243 154.428 220.879Z" fill="#9055A2"/>
<path d="M77.4941 135.741C77.6279 135.51 77.549 135.212 77.3172 135.078L77.193 135.007C76.8966 134.836 76.5175 134.937 76.3464 135.234L76.276 135.356C76.1414 135.589 76.2213 135.887 76.4544 136.021C76.8181 136.231 77.2841 136.105 77.4941 135.741Z" fill="#9055A2"/>
<path d="M158.112 216.356C158.246 216.124 158.167 215.827 157.936 215.693L157.811 215.622C157.515 215.45 157.136 215.552 156.965 215.848L156.894 215.97C156.76 216.203 156.84 216.501 157.073 216.636C157.437 216.846 157.902 216.72 158.112 216.356Z" fill="#9055A2"/>
<path d="M79.8671 147.031C80.001 146.8 79.9221 146.502 79.6902 146.368L79.5661 146.297C79.2697 146.126 78.8906 146.227 78.7194 146.524L78.6491 146.646C78.5145 146.879 78.5944 147.177 78.8274 147.311C79.1912 147.521 79.6571 147.395 79.8671 147.031Z" fill="#9055A2"/>
<path d="M160.478 227.646C160.612 227.415 160.533 227.117 160.301 226.983L160.177 226.912C159.881 226.741 159.502 226.842 159.331 227.139L159.26 227.261C159.126 227.494 159.205 227.792 159.439 227.926C159.802 228.136 160.268 228.01 160.478 227.646Z" fill="#9055A2"/>
<path d="M91.7105 143.547C91.8444 143.315 91.7654 143.018 91.5336 142.884L91.4095 142.812C91.113 142.641 90.734 142.743 90.5628 143.039L90.4924 143.161C90.3579 143.394 90.4377 143.692 90.6708 143.827C91.0346 144.037 91.5005 143.911 91.7105 143.547Z" fill="#9055A2"/>
<path d="M172.329 224.162C172.463 223.93 172.384 223.633 172.152 223.499L172.028 223.427C171.731 223.256 171.352 223.358 171.181 223.654L171.111 223.776C170.976 224.009 171.056 224.307 171.289 224.442C171.653 224.652 172.119 224.526 172.329 224.162Z" fill="#9055A2"/>
<path d="M90.7364 127.239C90.8702 127.008 90.7913 126.71 90.5595 126.576L90.4353 126.505C90.1389 126.334 89.7598 126.435 89.5887 126.732L89.5183 126.854C89.3837 127.087 89.4636 127.385 89.6967 127.519C90.0604 127.729 90.5264 127.603 90.7364 127.239Z" fill="#9055A2"/>
<path d="M171.355 207.854C171.489 207.622 171.41 207.325 171.178 207.191L171.054 207.12C170.757 206.948 170.378 207.05 170.207 207.346L170.137 207.468C170.002 207.701 170.082 207.999 170.315 208.134C170.679 208.344 171.145 208.218 171.355 207.854Z" fill="#9055A2"/>
<path d="M114.701 213.77C114.835 213.539 114.756 213.241 114.525 213.108L114.4 213.036C114.104 212.865 113.725 212.966 113.554 213.263L113.483 213.385C113.349 213.618 113.429 213.916 113.662 214.05C114.026 214.26 114.491 214.134 114.701 213.77Z" fill="#9055A2"/>
<path d="M118.393 209.25C118.527 209.018 118.448 208.721 118.216 208.587L118.092 208.515C117.795 208.344 117.416 208.446 117.245 208.742L117.175 208.864C117.04 209.097 117.12 209.395 117.353 209.529C117.717 209.74 118.183 209.614 118.393 209.25Z" fill="#9055A2"/>
<path d="M120.759 220.538C120.892 220.306 120.814 220.009 120.582 219.875L120.458 219.804C120.161 219.633 119.782 219.734 119.611 220.031L119.541 220.152C119.406 220.385 119.486 220.684 119.719 220.818C120.083 221.028 120.549 220.902 120.759 220.538Z" fill="#9055A2"/>
<path d="M51.9909 136.439C52.1248 136.207 52.0458 135.91 51.814 135.776L51.6899 135.705C51.3934 135.533 51.0144 135.635 50.8432 135.931L50.7729 136.053C50.6383 136.286 50.7181 136.584 50.9512 136.719C51.315 136.929 51.7809 136.803 51.9909 136.439Z" fill="#9055A2"/>
<path d="M132.609 217.056C132.743 216.824 132.664 216.527 132.432 216.393L132.308 216.321C132.012 216.15 131.633 216.251 131.462 216.548L131.391 216.67C131.257 216.903 131.337 217.201 131.57 217.335C131.933 217.545 132.399 217.419 132.609 217.056Z" fill="#9055A2"/>
<path d="M51.0168 120.132C51.1506 119.9 51.0717 119.602 50.8399 119.469L50.7158 119.397C50.4193 119.226 50.0403 119.327 49.8691 119.624L49.7987 119.746C49.6642 119.979 49.744 120.277 49.9771 120.411C50.3409 120.621 50.8068 120.495 51.0168 120.132Z" fill="#9055A2"/>
<path d="M131.628 200.746C131.762 200.514 131.683 200.217 131.451 200.083L131.327 200.012C131.03 199.841 130.651 199.942 130.48 200.239L130.41 200.36C130.275 200.593 130.355 200.892 130.588 201.026C130.952 201.236 131.418 201.11 131.628 200.746Z" fill="#9055A2"/>
<path d="M65.0964 132.728C64.9625 132.96 65.0415 133.257 65.2733 133.391L65.3974 133.463C65.6939 133.634 66.0729 133.533 66.2441 133.236L66.3144 133.114C66.449 132.881 66.3692 132.583 66.1361 132.449C65.7723 132.239 65.3064 132.365 65.0964 132.728Z" fill="#9055A2"/>
<path d="M145.715 213.345C145.581 213.577 145.66 213.874 145.892 214.008L146.016 214.08C146.312 214.251 146.691 214.149 146.862 213.853L146.933 213.731C147.067 213.498 146.988 213.2 146.754 213.065C146.391 212.855 145.925 212.981 145.715 213.345Z" fill="#9055A2"/>
<path d="M61.4123 137.251C61.2784 137.483 61.3573 137.78 61.5892 137.914L61.7133 137.986C62.0097 138.157 62.3888 138.055 62.56 137.759L62.6303 137.637C62.7649 137.404 62.685 137.106 62.452 136.971C62.0882 136.761 61.6223 136.887 61.4123 137.251Z" fill="#9055A2"/>
<path d="M142.031 217.868C141.897 218.1 141.976 218.397 142.208 218.531L142.332 218.602C142.628 218.774 143.007 218.672 143.178 218.375L143.249 218.254C143.383 218.021 143.303 217.723 143.07 217.588C142.707 217.378 142.241 217.504 142.031 217.868Z" fill="#9055A2"/>
<path d="M59.0392 125.961C58.9054 126.193 58.9843 126.49 59.2161 126.624L59.3402 126.695C59.6367 126.867 60.0157 126.765 60.1869 126.468L60.2573 126.347C60.3918 126.114 60.312 125.816 60.0789 125.681C59.7151 125.471 59.2492 125.597 59.0392 125.961Z" fill="#9055A2"/>
<path d="M139.658 206.577C139.524 206.809 139.603 207.107 139.834 207.24L139.959 207.312C140.255 207.483 140.634 207.382 140.805 207.085L140.876 206.963C141.01 206.73 140.93 206.432 140.697 206.298C140.334 206.088 139.868 206.214 139.658 206.577Z" fill="#9055A2"/>
<path d="M127.814 210.062C127.68 210.294 127.759 210.591 127.991 210.725L128.115 210.796C128.412 210.968 128.791 210.866 128.962 210.57L129.032 210.448C129.167 210.215 129.087 209.917 128.854 209.782C128.49 209.572 128.024 209.698 127.814 210.062Z" fill="#9055A2"/>
<path d="M48.17 145.753C48.0361 145.985 48.115 146.282 48.3469 146.416L48.471 146.487C48.7674 146.659 49.1465 146.557 49.3177 146.26L49.388 146.139C49.5226 145.906 49.4427 145.608 49.2097 145.473C48.8459 145.263 48.38 145.389 48.17 145.753Z" fill="#9055A2"/>
<path d="M128.788 226.369C128.655 226.601 128.733 226.899 128.965 227.032L129.089 227.104C129.386 227.275 129.765 227.174 129.936 226.877L130.006 226.755C130.141 226.522 130.061 226.224 129.828 226.09C129.464 225.88 128.998 226.006 128.788 226.369Z" fill="#9055A2"/>
<path d="M74.574 155.307C74.4402 155.539 74.5191 155.837 74.7509 155.97L74.875 156.042C75.1715 156.213 75.5506 156.112 75.7217 155.815L75.7921 155.693C75.9266 155.46 75.8468 155.162 75.6137 155.028C75.25 154.818 74.784 154.944 74.574 155.307Z" fill="#9055A2"/>
<path d="M70.8899 159.83C70.756 160.062 70.835 160.359 71.0668 160.493L71.1909 160.565C71.4874 160.736 71.8664 160.634 72.0376 160.338L72.108 160.216C72.2425 159.983 72.1627 159.685 71.9296 159.55C71.5658 159.34 71.0999 159.466 70.8899 159.83Z" fill="#9055A2"/>
<path d="M68.5168 148.54C68.383 148.772 68.4619 149.069 68.6937 149.203L68.8178 149.274C69.1143 149.446 69.4934 149.344 69.6645 149.048L69.7349 148.926C69.8694 148.693 69.7896 148.395 69.5565 148.26C69.1928 148.05 68.7268 148.176 68.5168 148.54Z" fill="#9055A2"/>
<path d="M149.135 229.156C149.001 229.388 149.08 229.685 149.312 229.819L149.436 229.891C149.733 230.062 150.112 229.961 150.283 229.664L150.353 229.542C150.488 229.309 150.408 229.011 150.175 228.877C149.811 228.666 149.345 228.793 149.135 229.156Z" fill="#9055A2"/>
<path d="M56.6734 152.024C56.5396 152.256 56.6185 152.553 56.8504 152.687L56.9745 152.759C57.2709 152.93 57.65 152.828 57.8212 152.532L57.8915 152.41C58.0261 152.177 57.9462 151.879 57.7131 151.744C57.3494 151.534 56.8835 151.66 56.6734 152.024Z" fill="#9055A2"/>
<path d="M137.285 232.641C137.151 232.873 137.23 233.17 137.461 233.304L137.586 233.376C137.882 233.547 138.261 233.445 138.432 233.149L138.503 233.027C138.637 232.794 138.557 232.496 138.324 232.361C137.96 232.151 137.495 232.277 137.285 232.641Z" fill="#9055A2"/>
<path d="M57.6476 168.33C57.5137 168.562 57.5927 168.859 57.8245 168.993L57.9486 169.065C58.2451 169.236 58.6241 169.134 58.7953 168.838L58.8657 168.716C59.0002 168.483 58.9204 168.185 58.6873 168.05C58.3235 167.84 57.8576 167.966 57.6476 168.33Z" fill="#9055A2"/>
<path d="M127.871 231.827C128.004 231.595 127.925 231.298 127.694 231.164L127.569 231.092C127.273 230.921 126.894 231.023 126.723 231.319L126.652 231.441C126.518 231.674 126.598 231.972 126.831 232.107C127.195 232.317 127.66 232.191 127.871 231.827Z" fill="#9055A2"/>
<path d="M49.6179 162.501C49.7517 162.269 49.6728 161.972 49.441 161.838L49.3168 161.766C49.0204 161.595 48.6413 161.696 48.4702 161.993L48.3998 162.115C48.2652 162.348 48.3451 162.646 48.5782 162.78C48.9419 162.99 49.4078 162.864 49.6179 162.501Z" fill="#9055A2"/>
<path d="M61.4685 159.016C61.6024 158.784 61.5235 158.487 61.2916 158.353L61.1675 158.281C60.8711 158.11 60.492 158.212 60.3208 158.508L60.2505 158.63C60.1159 158.863 60.1958 159.161 60.4288 159.296C60.7926 159.506 61.2585 159.38 61.4685 159.016Z" fill="#9055A2"/>
<path d="M60.4944 142.71C60.6283 142.478 60.5493 142.181 60.3175 142.047L60.1934 141.976C59.8969 141.804 59.5179 141.906 59.3467 142.202L59.2763 142.324C59.1418 142.557 59.2216 142.855 59.4547 142.99C59.8185 143.2 60.2844 143.074 60.4944 142.71Z" fill="#9055A2"/>
<path d="M141.106 223.325C141.239 223.093 141.16 222.796 140.929 222.662L140.804 222.591C140.508 222.419 140.129 222.521 139.958 222.817L139.887 222.939C139.753 223.172 139.833 223.47 140.066 223.605C140.43 223.815 140.895 223.689 141.106 223.325Z" fill="#9055A2"/>
<path d="M112.614 155.092C112.748 154.86 112.669 154.563 112.437 154.429L112.313 154.357C112.017 154.186 111.637 154.288 111.466 154.584L111.396 154.706C111.261 154.939 111.341 155.237 111.574 155.372C111.938 155.582 112.404 155.456 112.614 155.092Z" fill="#9055A2"/>
<path d="M116.298 150.571C116.432 150.339 116.353 150.042 116.121 149.908L115.997 149.836C115.701 149.665 115.322 149.767 115.15 150.063L115.08 150.185C114.945 150.418 115.025 150.716 115.258 150.851C115.622 151.061 116.088 150.935 116.298 150.571Z" fill="#9055A2"/>
<path d="M118.664 161.862C118.798 161.63 118.719 161.332 118.487 161.199L118.363 161.127C118.066 160.956 117.687 161.057 117.516 161.354L117.446 161.476C117.311 161.709 117.391 162.007 117.624 162.141C117.988 162.351 118.454 162.225 118.664 161.862Z" fill="#9055A2"/>
<path d="M49.9035 77.7605C50.0374 77.5287 49.9584 77.2313 49.7266 77.0975L49.6025 77.0258C49.306 76.8547 48.927 76.9562 48.7558 77.2527L48.6854 77.3746C48.5509 77.6076 48.6307 77.9057 48.8638 78.0402C49.2276 78.2503 49.6935 78.1242 49.9035 77.7605Z" fill="#9055A2"/>
<path d="M130.515 158.377C130.648 158.145 130.569 157.848 130.338 157.714L130.214 157.643C129.917 157.471 129.538 157.573 129.367 157.869L129.297 157.991C129.162 158.224 129.242 158.522 129.475 158.657C129.839 158.867 130.305 158.741 130.515 158.377Z" fill="#9055A2"/>
<path d="M129.54 142.07C129.674 141.838 129.595 141.54 129.364 141.407L129.239 141.335C128.943 141.164 128.564 141.265 128.393 141.562L128.322 141.684C128.188 141.917 128.268 142.215 128.501 142.349C128.864 142.559 129.33 142.433 129.54 142.07Z" fill="#9055A2"/>
<path d="M63.009 74.0515C62.8751 74.2834 62.954 74.5807 63.1859 74.7145L63.31 74.7862C63.6064 74.9573 63.9855 74.8558 64.1567 74.5593L64.227 74.4374C64.3616 74.2044 64.2817 73.9063 64.0487 73.7718C63.6849 73.5618 63.219 73.6878 63.009 74.0515Z" fill="#9055A2"/>
<path d="M143.62 154.668C143.486 154.9 143.565 155.197 143.797 155.331L143.921 155.403C144.218 155.574 144.597 155.472 144.768 155.176L144.838 155.054C144.973 154.821 144.893 154.523 144.66 154.388C144.296 154.178 143.83 154.304 143.62 154.668Z" fill="#9055A2"/>
<path d="M59.3175 78.5725C59.1837 78.8044 59.2626 79.1017 59.4944 79.2355L59.6185 79.3072C59.915 79.4783 60.2941 79.3768 60.4652 79.0803L60.5356 78.9584C60.6701 78.7254 60.5903 78.4273 60.3572 78.2928C59.9934 78.0828 59.5275 78.2088 59.3175 78.5725Z" fill="#9055A2"/>
<path d="M139.936 159.189C139.802 159.421 139.881 159.718 140.113 159.852L140.237 159.924C140.533 160.095 140.912 159.993 141.084 159.697L141.154 159.575C141.289 159.342 141.209 159.044 140.976 158.909C140.612 158.699 140.146 158.825 139.936 159.189Z" fill="#9055A2"/>
<path d="M56.9518 67.2825C56.8179 67.5143 56.8969 67.8116 57.1287 67.9455L57.2528 68.0171C57.5493 68.1883 57.9283 68.0867 58.0995 67.7903L58.1699 67.6684C58.3044 67.4353 58.2246 67.1373 57.9915 67.0027C57.6277 66.7927 57.1618 66.9187 56.9518 67.2825Z" fill="#9055A2"/>
<path d="M137.57 147.899C137.436 148.131 137.515 148.428 137.747 148.562L137.871 148.633C138.168 148.805 138.547 148.703 138.718 148.406L138.788 148.285C138.923 148.052 138.843 147.754 138.61 147.619C138.246 147.409 137.78 147.535 137.57 147.899Z" fill="#9055A2"/>
<path d="M125.719 151.384C125.586 151.615 125.665 151.913 125.896 152.047L126.021 152.118C126.317 152.289 126.696 152.188 126.867 151.891L126.938 151.769C127.072 151.536 126.992 151.238 126.759 151.104C126.395 150.894 125.929 151.02 125.719 151.384Z" fill="#9055A2"/>
<path d="M126.694 167.691C126.56 167.923 126.639 168.22 126.871 168.354L126.995 168.425C127.291 168.596 127.67 168.495 127.841 168.198L127.912 168.077C128.046 167.844 127.966 167.546 127.733 167.411C127.37 167.201 126.904 167.327 126.694 167.691Z" fill="#9055A2"/>
<path d="M72.4866 96.6306C72.3527 96.8625 72.4316 97.1598 72.6635 97.2936L72.7876 97.3653C73.0841 97.5364 73.4631 97.4349 73.6343 97.1384L73.7046 97.0165C73.8392 96.7835 73.7593 96.4854 73.5263 96.3509C73.1625 96.1409 72.6966 96.2669 72.4866 96.6306Z" fill="#9055A2"/>
<path d="M153.098 177.247C152.964 177.479 153.043 177.776 153.275 177.91L153.399 177.982C153.695 178.153 154.074 178.052 154.245 177.755L154.316 177.633C154.45 177.4 154.37 177.102 154.137 176.968C153.774 176.758 153.308 176.884 153.098 177.247Z" fill="#9055A2"/>
<path d="M68.7951 101.152C68.6613 101.383 68.7402 101.681 68.972 101.815L69.0962 101.886C69.3926 102.057 69.7717 101.956 69.9428 101.659L70.0132 101.538C70.1478 101.304 70.0679 101.006 69.8348 100.872C69.4711 100.662 69.0051 100.788 68.7951 101.152Z" fill="#9055A2"/>
<path d="M149.414 181.766C149.28 181.998 149.359 182.296 149.59 182.429L149.715 182.501C150.011 182.672 150.39 182.571 150.561 182.274L150.632 182.152C150.766 181.919 150.686 181.621 150.453 181.487C150.089 181.277 149.624 181.403 149.414 181.766Z" fill="#9055A2"/>
<path d="M66.4294 89.8611C66.2955 90.0929 66.3745 90.3902 66.6063 90.5241L66.7304 90.5958C67.0269 90.7669 67.4059 90.6653 67.5771 90.3689L67.6475 90.247C67.782 90.0139 67.7022 89.7159 67.4691 89.5813C67.1053 89.3713 66.6394 89.4973 66.4294 89.8611Z" fill="#9055A2"/>
<path d="M147.048 170.478C146.914 170.71 146.993 171.007 147.225 171.141L147.349 171.212C147.645 171.384 148.024 171.282 148.195 170.986L148.266 170.864C148.4 170.631 148.321 170.333 148.087 170.198C147.724 169.988 147.258 170.114 147.048 170.478Z" fill="#9055A2"/>
<path d="M54.5787 93.3474C54.4449 93.5793 54.5238 93.8766 54.7556 94.0104L54.8797 94.0821C55.1762 94.2532 55.5553 94.1517 55.7264 93.8552L55.7968 93.7333C55.9313 93.5003 55.8515 93.2022 55.6184 93.0677C55.2546 92.8577 54.7887 92.9837 54.5787 93.3474Z" fill="#9055A2"/>
<path d="M135.197 173.964C135.063 174.196 135.142 174.493 135.374 174.627L135.498 174.699C135.795 174.87 136.174 174.768 136.345 174.472L136.415 174.35C136.55 174.117 136.47 173.819 136.237 173.684C135.873 173.474 135.407 173.6 135.197 173.964Z" fill="#9055A2"/>
<path d="M55.5601 109.653C55.4263 109.885 55.5052 110.182 55.7371 110.316L55.8612 110.388C56.1576 110.559 56.5367 110.457 56.7079 110.161L56.7782 110.039C56.9128 109.806 56.8329 109.508 56.5999 109.373C56.2361 109.163 55.7702 109.289 55.5601 109.653Z" fill="#9055A2"/>
<path d="M136.171 190.27C136.037 190.502 136.116 190.799 136.348 190.933L136.472 191.004C136.769 191.176 137.148 191.074 137.319 190.778L137.389 190.656C137.524 190.423 137.444 190.125 137.211 189.99C136.847 189.78 136.381 189.906 136.171 190.27Z" fill="#9055A2"/>
<path d="M122.092 177.673C122.225 177.441 122.147 177.144 121.915 177.01L121.791 176.938C121.494 176.767 121.115 176.869 120.944 177.165L120.874 177.287C120.739 177.52 120.819 177.818 121.052 177.953C121.416 178.163 121.882 178.037 122.092 177.673Z" fill="#9055A2"/>
<path d="M125.776 173.15C125.91 172.918 125.831 172.621 125.599 172.487L125.475 172.415C125.178 172.244 124.799 172.346 124.628 172.642L124.558 172.764C124.423 172.997 124.503 173.295 124.736 173.43C125.1 173.64 125.566 173.514 125.776 173.15Z" fill="#9055A2"/>
<path d="M128.142 184.441C128.275 184.209 128.196 183.912 127.965 183.778L127.84 183.706C127.544 183.535 127.165 183.636 126.994 183.933L126.923 184.055C126.789 184.288 126.869 184.586 127.102 184.72C127.466 184.93 127.931 184.804 128.142 184.441Z" fill="#9055A2"/>
<path d="M59.3738 100.34C59.5077 100.108 59.4287 99.8104 59.1969 99.6766L59.0728 99.6049C58.7763 99.4338 58.3973 99.5353 58.2261 99.8318L58.1557 99.9537C58.0212 100.187 58.101 100.485 58.3341 100.619C58.6979 100.829 59.1638 100.703 59.3738 100.34Z" fill="#9055A2"/>
<path d="M139.992 180.956C140.126 180.724 140.047 180.427 139.815 180.293L139.691 180.221C139.395 180.05 139.016 180.152 138.844 180.448L138.774 180.57C138.64 180.803 138.719 181.101 138.952 181.236C139.316 181.446 139.782 181.32 139.992 180.956Z" fill="#9055A2"/>
<path d="M58.3997 84.032C58.5335 83.8001 58.4546 83.5028 58.2228 83.369L58.0986 83.2973C57.8022 83.1262 57.4231 83.2277 57.252 83.5242L57.1816 83.6461C57.047 83.8791 57.1269 84.1772 57.36 84.3117C57.7237 84.5217 58.1897 84.3957 58.3997 84.032Z" fill="#9055A2"/>
<path d="M139.018 164.649C139.152 164.417 139.073 164.12 138.841 163.986L138.717 163.914C138.421 163.743 138.042 163.844 137.87 164.141L137.8 164.263C137.665 164.496 137.745 164.794 137.978 164.928C138.342 165.138 138.808 165.012 139.018 164.649Z" fill="#9055A2"/>
<path d="M82.3721 170.565C82.5059 170.333 82.427 170.036 82.1952 169.902L82.071 169.83C81.7746 169.659 81.3955 169.76 81.2244 170.057L81.154 170.179C81.0194 170.412 81.0993 170.71 81.3324 170.844C81.6961 171.054 82.162 170.928 82.3721 170.565Z" fill="#9055A2"/>
<path d="M86.0562 166.042C86.19 165.81 86.1111 165.513 85.8793 165.379L85.7551 165.307C85.4587 165.136 85.0796 165.237 84.9085 165.534L84.8381 165.656C84.7035 165.889 84.7834 166.187 85.0165 166.321C85.3802 166.532 85.8462 166.405 86.0562 166.042Z" fill="#9055A2"/>
<path d="M88.4219 177.332C88.5558 177.1 88.4768 176.803 88.245 176.669L88.1209 176.598C87.8244 176.426 87.4454 176.528 87.2742 176.824L87.2039 176.946C87.0693 177.179 87.1491 177.477 87.3822 177.612C87.746 177.822 88.2119 177.696 88.4219 177.332Z" fill="#9055A2"/>
<path d="M100.273 173.846C100.406 173.614 100.328 173.317 100.096 173.183L99.9716 173.111C99.6751 172.94 99.296 173.042 99.1249 173.338L99.0545 173.46C98.92 173.693 98.9998 173.991 99.2329 174.126C99.5967 174.336 100.063 174.21 100.273 173.846Z" fill="#9055A2"/>
<path d="M99.2985 157.54C99.4323 157.308 99.3534 157.011 99.1216 156.877L98.9974 156.806C98.701 156.634 98.3219 156.736 98.1508 157.032L98.0804 157.154C97.9458 157.387 98.0257 157.685 98.2588 157.82C98.6225 158.03 99.0884 157.904 99.2985 157.54Z" fill="#9055A2"/>
<path d="M113.378 170.139C113.244 170.371 113.323 170.668 113.555 170.802L113.679 170.874C113.976 171.045 114.355 170.943 114.526 170.647L114.596 170.525C114.731 170.292 114.651 169.994 114.418 169.859C114.054 169.649 113.588 169.775 113.378 170.139Z" fill="#9055A2"/>
<path d="M109.694 174.66C109.56 174.892 109.639 175.189 109.871 175.323L109.995 175.395C110.291 175.566 110.671 175.464 110.842 175.168L110.912 175.046C111.047 174.813 110.967 174.515 110.734 174.38C110.37 174.17 109.904 174.296 109.694 174.66Z" fill="#9055A2"/>
<path d="M107.321 163.371C107.187 163.603 107.266 163.9 107.498 164.034L107.622 164.106C107.918 164.277 108.297 164.176 108.469 163.879L108.539 163.757C108.674 163.524 108.594 163.226 108.361 163.092C107.997 162.882 107.531 163.008 107.321 163.371Z" fill="#9055A2"/>
<path d="M95.4775 166.854C95.3437 167.086 95.4226 167.383 95.6544 167.517L95.7785 167.589C96.075 167.76 96.4541 167.659 96.6252 167.362L96.6956 167.24C96.8301 167.007 96.7503 166.709 96.5172 166.575C96.1535 166.364 95.6875 166.491 95.4775 166.854Z" fill="#9055A2"/>
<path d="M96.4516 183.161C96.3178 183.393 96.3967 183.691 96.6285 183.824L96.7527 183.896C97.0491 184.067 97.4282 183.966 97.5993 183.669L97.6697 183.547C97.8043 183.314 97.7244 183.016 97.4913 182.882C97.1276 182.672 96.6617 182.798 96.4516 183.161Z" fill="#9055A2"/>
<path d="M122.856 192.716C122.722 192.948 122.801 193.245 123.033 193.379L123.157 193.451C123.453 193.622 123.832 193.52 124.003 193.224L124.074 193.102C124.208 192.869 124.128 192.571 123.895 192.436C123.532 192.226 123.066 192.352 122.856 192.716Z" fill="#9055A2"/>
<path d="M119.172 197.239C119.038 197.471 119.117 197.768 119.348 197.902L119.473 197.974C119.769 198.145 120.148 198.043 120.319 197.747L120.39 197.625C120.524 197.392 120.444 197.094 120.211 196.959C119.848 196.749 119.382 196.875 119.172 197.239Z" fill="#9055A2"/>
<path d="M116.799 185.948C116.665 186.18 116.744 186.478 116.975 186.611L117.1 186.683C117.396 186.854 117.775 186.753 117.946 186.456L118.017 186.334C118.151 186.101 118.071 185.803 117.838 185.669C117.474 185.459 117.009 185.585 116.799 185.948Z" fill="#9055A2"/>
<path d="M104.955 189.433C104.821 189.665 104.9 189.962 105.132 190.096L105.256 190.168C105.553 190.339 105.932 190.237 106.103 189.941L106.173 189.819C106.308 189.586 106.228 189.288 105.995 189.153C105.631 188.943 105.165 189.069 104.955 189.433Z" fill="#9055A2"/>
<path d="M105.929 205.74C105.795 205.972 105.874 206.27 106.106 206.403L106.23 206.475C106.527 206.646 106.906 206.545 107.077 206.248L107.147 206.126C107.282 205.893 107.202 205.595 106.969 205.461C106.605 205.251 106.139 205.377 105.929 205.74Z" fill="#9055A2"/>
<path d="M91.8497 193.144C91.9835 192.912 91.9046 192.615 91.6728 192.481L91.5486 192.409C91.2522 192.238 90.8731 192.34 90.702 192.636L90.6316 192.758C90.497 192.991 90.5769 193.289 90.81 193.424C91.1737 193.634 91.6397 193.508 91.8497 193.144Z" fill="#9055A2"/>
<path d="M95.5338 188.621C95.6676 188.389 95.5887 188.092 95.3569 187.958L95.2328 187.886C94.9363 187.715 94.5572 187.817 94.3861 188.113L94.3157 188.235C94.1812 188.468 94.261 188.766 94.4941 188.901C94.8579 189.111 95.3238 188.985 95.5338 188.621Z" fill="#9055A2"/>
<path d="M97.8995 199.911C98.0334 199.68 97.9544 199.382 97.7226 199.248L97.5985 199.177C97.302 199.006 96.923 199.107 96.7518 199.404L96.6815 199.525C96.5469 199.759 96.6267 200.057 96.8598 200.191C97.2236 200.401 97.6895 200.275 97.8995 199.911Z" fill="#9055A2"/>
<path d="M109.75 196.425C109.884 196.193 109.805 195.896 109.573 195.762L109.449 195.69C109.153 195.519 108.774 195.621 108.603 195.917L108.532 196.039C108.398 196.272 108.477 196.57 108.711 196.705C109.074 196.915 109.54 196.789 109.75 196.425Z" fill="#9055A2"/>
<path d="M108.769 180.119C108.903 179.888 108.824 179.59 108.592 179.456L108.468 179.385C108.171 179.214 107.792 179.315 107.621 179.612L107.551 179.733C107.416 179.967 107.496 180.265 107.729 180.399C108.093 180.609 108.559 180.483 108.769 180.119Z" fill="#9055A2"/>
<path d="M193.43 54.8664C193.564 54.6346 193.485 54.3373 193.253 54.2035L193.129 54.1318C192.833 53.9606 192.454 54.0622 192.282 54.3586L192.212 54.4805C192.078 54.7136 192.157 55.0116 192.39 55.1462C192.754 55.3562 193.22 55.2302 193.43 54.8664Z" fill="#9055A2"/>
<path d="M82.2256 228.963C82.3594 228.731 82.2805 228.434 82.0487 228.3L81.9245 228.228C81.6281 228.057 81.249 228.159 81.0779 228.455L81.0075 228.577C80.8729 228.81 80.9528 229.108 81.1859 229.243C81.5496 229.453 82.0155 229.327 82.2256 228.963Z" fill="#9055A2"/>
<path d="M197.122 50.344C197.255 50.1122 197.177 49.8148 196.945 49.681L196.821 49.6093C196.524 49.4382 196.145 49.5397 195.974 49.8362L195.904 49.9581C195.769 50.1911 195.849 50.4892 196.082 50.6237C196.446 50.8337 196.912 50.7077 197.122 50.344Z" fill="#9055A2"/>
<path d="M85.917 224.442C86.0509 224.21 85.9719 223.913 85.7401 223.779L85.616 223.707C85.3195 223.536 84.9405 223.638 84.7693 223.934L84.6989 224.056C84.5644 224.289 84.6442 224.587 84.8773 224.722C85.2411 224.932 85.707 224.806 85.917 224.442Z" fill="#9055A2"/>
<path d="M199.487 61.634C199.621 61.4022 199.542 61.1049 199.31 60.971L199.186 60.8994C198.89 60.7282 198.511 60.8298 198.34 61.1262L198.269 61.2481C198.135 61.4812 198.215 61.7792 198.448 61.9138C198.811 62.1238 199.277 61.9978 199.487 61.634Z" fill="#9055A2"/>
<path d="M211.338 58.1496C211.472 57.9178 211.393 57.6205 211.161 57.4867L211.037 57.415C210.741 57.2438 210.362 57.3454 210.19 57.6419L210.12 57.7637C209.985 57.9968 210.065 58.2948 210.298 58.4294C210.662 58.6394 211.128 58.5134 211.338 58.1496Z" fill="#9055A2"/>
<path d="M100.126 232.246C100.26 232.014 100.181 231.717 99.9492 231.583L99.8251 231.511C99.5286 231.34 99.1496 231.442 98.9784 231.738L98.908 231.86C98.7735 232.093 98.8533 232.391 99.0864 232.526C99.4502 232.736 99.9161 232.61 100.126 232.246Z" fill="#9055A2"/>
<path d="M99.1593 215.94C99.2932 215.709 99.2142 215.411 98.9824 215.277L98.8583 215.206C98.5618 215.035 98.1828 215.136 98.0116 215.433L97.9412 215.554C97.8067 215.788 97.8865 216.086 98.1196 216.22C98.4834 216.43 98.9493 216.304 99.1593 215.94Z" fill="#9055A2"/>
<path d="M113.239 228.537C113.105 228.769 113.184 229.067 113.416 229.2L113.54 229.272C113.836 229.443 114.215 229.342 114.387 229.045L114.457 228.923C114.592 228.69 114.512 228.392 114.279 228.258C113.915 228.048 113.449 228.174 113.239 228.537Z" fill="#9055A2"/>
<path d="M109.555 233.058C109.421 233.29 109.5 233.588 109.732 233.721L109.856 233.793C110.152 233.964 110.531 233.863 110.702 233.566L110.773 233.444C110.907 233.211 110.828 232.913 110.594 232.779C110.231 232.569 109.765 232.695 109.555 233.058Z" fill="#9055A2"/>
<path d="M107.182 221.77C107.048 222.002 107.127 222.299 107.359 222.433L107.483 222.504C107.779 222.676 108.158 222.574 108.329 222.278L108.4 222.156C108.534 221.923 108.454 221.625 108.221 221.49C107.858 221.28 107.392 221.406 107.182 221.77Z" fill="#9055A2"/>
<path d="M206.543 51.158C206.409 51.3898 206.488 51.6871 206.72 51.821L206.844 51.8926C207.14 52.0638 207.52 51.9622 207.691 51.6658L207.761 51.5439C207.896 51.3108 207.816 51.0128 207.583 50.8782C207.219 50.6682 206.753 50.7942 206.543 51.158Z" fill="#9055A2"/>
<path d="M95.3383 225.252C95.2045 225.484 95.2834 225.782 95.5153 225.915L95.6394 225.987C95.9358 226.158 96.3149 226.057 96.4861 225.76L96.5564 225.638C96.691 225.405 96.6111 225.107 96.3781 224.973C96.0143 224.763 95.5484 224.889 95.3383 225.252Z" fill="#9055A2"/>
<path d="M207.517 67.4636C207.383 67.6955 207.462 67.9928 207.694 68.1266L207.818 68.1983C208.115 68.3694 208.494 68.2679 208.665 67.9714L208.735 67.8496C208.87 67.6165 208.79 67.3184 208.557 67.1839C208.193 66.9739 207.727 67.0999 207.517 67.4636Z" fill="#9055A2"/>
<path d="M216.021 73.7351C215.887 73.9669 215.966 74.2643 216.198 74.3981L216.322 74.4698C216.618 74.6409 216.997 74.5394 217.168 74.2429L217.239 74.121C217.373 73.888 217.293 73.5899 217.06 73.4554C216.697 73.2454 216.231 73.3714 216.021 73.7351Z" fill="#9055A2"/>
<path d="M202.915 77.4455C203.049 77.2137 202.97 76.9164 202.738 76.7826L202.614 76.7109C202.318 76.5397 201.939 76.6413 201.767 76.9378L201.697 77.0596C201.562 77.2927 201.642 77.5907 201.875 77.7253C202.239 77.9353 202.705 77.8093 202.915 77.4455Z" fill="#9055A2"/>
<path d="M206.599 72.9226C206.733 72.6908 206.654 72.3934 206.422 72.2596L206.298 72.1879C206.002 72.0168 205.623 72.1184 205.452 72.4148L205.381 72.5367C205.247 72.7698 205.326 73.0678 205.56 73.2023C205.923 73.4124 206.389 73.2864 206.599 72.9226Z" fill="#9055A2"/>
<path d="M208.965 84.2131C209.099 83.9813 209.02 83.684 208.788 83.5501L208.664 83.4785C208.368 83.3073 207.988 83.4089 207.817 83.7053L207.747 83.8272C207.612 84.0603 207.692 84.3583 207.925 84.4929C208.289 84.7029 208.755 84.5769 208.965 84.2131Z" fill="#9055A2"/>
<path d="M163.188 70.3371C163.322 70.1053 163.243 69.808 163.011 69.6742L162.887 69.6025C162.591 69.4313 162.212 69.5329 162.041 69.8294L161.97 69.9512C161.836 70.1843 161.915 70.4823 162.149 70.6169C162.512 70.8269 162.978 70.7009 163.188 70.3371Z" fill="#9055A2"/>
<path d="M166.872 65.8161C167.006 65.5843 166.927 65.287 166.695 65.1532L166.571 65.0815C166.275 64.9103 165.896 65.0119 165.725 65.3084L165.654 65.4302C165.52 65.6633 165.6 65.9613 165.833 66.0959C166.196 66.3059 166.662 66.1799 166.872 65.8161Z" fill="#9055A2"/>
<path d="M169.245 77.1047C169.379 76.8729 169.3 76.5756 169.068 76.4417L168.944 76.3701C168.648 76.1989 168.269 76.3005 168.098 76.5969L168.027 76.7188C167.893 76.9519 167.973 77.2499 168.206 77.3845C168.569 77.5945 169.035 77.4685 169.245 77.1047Z" fill="#9055A2"/>
<path d="M181.089 73.6203C181.223 73.3885 181.144 73.0912 180.912 72.9574L180.788 72.8857C180.491 72.7145 180.112 72.8161 179.941 73.1126L179.871 73.2344C179.736 73.4675 179.816 73.7655 180.049 73.9001C180.413 74.1101 180.879 73.9841 181.089 73.6203Z" fill="#9055A2"/>
<path d="M180.115 57.3147C180.248 57.0829 180.17 56.7855 179.938 56.6517L179.814 56.58C179.517 56.4089 179.138 56.5104 178.967 56.8069L178.897 56.9288C178.762 57.1618 178.842 57.4599 179.075 57.5944C179.439 57.8045 179.905 57.6784 180.115 57.3147Z" fill="#9055A2"/>
<path d="M68.91 231.409C69.0439 231.178 68.9649 230.88 68.7331 230.746L68.609 230.675C68.3125 230.504 67.9335 230.605 67.7623 230.902L67.6919 231.023C67.5574 231.257 67.6372 231.555 67.8703 231.689C68.2341 231.899 68.7 231.773 68.91 231.409Z" fill="#9055A2"/>
<path d="M194.194 69.9099C194.06 70.1418 194.139 70.4391 194.371 70.5729L194.495 70.6446C194.792 70.8157 195.171 70.7142 195.342 70.4177L195.412 70.2958C195.547 70.0628 195.467 69.7647 195.234 69.6302C194.87 69.4202 194.404 69.5462 194.194 69.9099Z" fill="#9055A2"/>
<path d="M190.51 74.4324C190.376 74.6642 190.455 74.9615 190.687 75.0954L190.811 75.167C191.108 75.3382 191.487 75.2366 191.658 74.9402L191.728 74.8183C191.863 74.5852 191.783 74.2872 191.55 74.1526C191.186 73.9426 190.72 74.0686 190.51 74.4324Z" fill="#9055A2"/>
<path d="M188.144 63.1423C188.01 63.3742 188.089 63.6715 188.321 63.8053L188.445 63.877C188.742 64.0482 189.121 63.9466 189.292 63.6501L189.362 63.5283C189.497 63.2952 189.417 62.9972 189.184 62.8626C188.82 62.6526 188.354 62.7786 188.144 63.1423Z" fill="#9055A2"/>
<path d="M176.294 66.6267C176.16 66.8586 176.239 67.1559 176.471 67.2897L176.595 67.3614C176.891 67.5325 177.27 67.431 177.441 67.1345L177.512 67.0126C177.646 66.7796 177.566 66.4815 177.333 66.347C176.97 66.137 176.504 66.263 176.294 66.6267Z" fill="#9055A2"/>
<path d="M177.275 82.9363C177.141 83.1681 177.22 83.4654 177.452 83.5993L177.576 83.671C177.873 83.8421 178.252 83.7405 178.423 83.4441L178.493 83.3222C178.628 83.0891 178.548 82.7911 178.315 82.6565C177.951 82.4465 177.485 82.5725 177.275 82.9363Z" fill="#9055A2"/>
<path d="M203.679 92.4905C203.545 92.7223 203.624 93.0196 203.856 93.1535L203.98 93.2251C204.277 93.3963 204.656 93.2947 204.827 92.9983L204.897 92.8764C205.032 92.6433 204.952 92.3453 204.719 92.2107C204.355 92.0007 203.889 92.1267 203.679 92.4905Z" fill="#9055A2"/>
<path d="M199.988 97.0115C199.854 97.2433 199.933 97.5406 200.165 97.6745L200.289 97.7461C200.585 97.9173 200.964 97.8157 201.135 97.5193L201.206 97.3974C201.34 97.1643 201.261 96.8663 201.027 96.7317C200.664 96.5217 200.198 96.6477 199.988 97.0115Z" fill="#9055A2"/>
<path d="M197.622 85.7229C197.488 85.9547 197.567 86.2521 197.799 86.3859L197.923 86.4576C198.219 86.6287 198.599 86.5272 198.77 86.2307L198.84 86.1088C198.975 85.8758 198.895 85.5777 198.662 85.4432C198.298 85.2331 197.832 85.3592 197.622 85.7229Z" fill="#9055A2"/>
<path d="M185.771 89.2073C185.637 89.4391 185.716 89.7364 185.948 89.8703L186.072 89.9419C186.369 90.1131 186.748 90.0115 186.919 89.7151L186.989 89.5932C187.124 89.3601 187.044 89.0621 186.811 88.9275C186.447 88.7175 185.981 88.8435 185.771 89.2073Z" fill="#9055A2"/>
<path d="M186.753 105.513C186.619 105.745 186.698 106.042 186.93 106.176L187.054 106.248C187.35 106.419 187.729 106.317 187.9 106.021L187.971 105.899C188.105 105.666 188.026 105.368 187.792 105.233C187.429 105.023 186.963 105.149 186.753 105.513Z" fill="#9055A2"/>
<path d="M172.666 92.9162C172.8 92.6844 172.721 92.3871 172.489 92.2533L172.365 92.1816C172.068 92.0104 171.689 92.112 171.518 92.4085L171.448 92.5303C171.313 92.7634 171.393 93.0614 171.626 93.196C171.99 93.406 172.456 93.28 172.666 92.9162Z" fill="#9055A2"/>
<path d="M176.35 88.3953C176.484 88.1634 176.405 87.8661 176.173 87.7323L176.049 87.6606C175.752 87.4894 175.373 87.591 175.202 87.8875L175.132 88.0093C174.997 88.2424 175.077 88.5404 175.31 88.675C175.674 88.885 176.14 88.759 176.35 88.3953Z" fill="#9055A2"/>
<path d="M178.723 99.6838C178.857 99.452 178.778 99.1547 178.546 99.0208L178.422 98.9492C178.126 98.778 177.746 98.8796 177.575 99.176L177.505 99.2979C177.37 99.531 177.45 99.829 177.683 99.9636C178.047 100.174 178.513 100.048 178.723 99.6838Z" fill="#9055A2"/>
<path d="M190.566 96.1994C190.7 95.9676 190.621 95.6703 190.389 95.5365L190.265 95.4648C189.969 95.2936 189.59 95.3952 189.419 95.6917L189.348 95.8135C189.214 96.0466 189.294 96.3446 189.527 96.4792C189.89 96.6892 190.356 96.5632 190.566 96.1994Z" fill="#9055A2"/>
<path d="M189.592 79.8918C189.726 79.66 189.647 79.3627 189.415 79.2288L189.291 79.1572C188.995 78.986 188.616 79.0876 188.445 79.384L188.374 79.5059C188.24 79.739 188.319 80.037 188.553 80.1716C188.916 80.3816 189.382 80.2556 189.592 79.8918Z" fill="#9055A2"/>
</g>
<path opacity="0.8" d="M161.772 183.543C133.788 211.527 88.4177 211.527 60.4341 183.543L46.4334 169.542C13.057 136.166 13.057 82.0518 46.4334 48.6754L55.8394 39.2694C86.3606 8.74823 135.845 8.74823 166.366 39.2694C171.257 44.1602 175.528 49.4364 179.178 55.0002C204.317 93.3147 200.047 145.268 166.366 178.948L161.772 183.543Z" fill="url(#paint2_linear_1148_8937)"/>
<mask id="mask3_1148_8937" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="29" y="29" width="174" height="188">
<path d="M169.35 195.622C141.525 223.447 96.4119 223.447 68.5868 195.622L54.6654 181.701C21.478 148.514 21.478 94.7062 54.6654 61.5188L64.0182 52.1661C94.3664 21.8178 143.571 21.8178 173.919 52.1661C192.05 70.2969 201.609 93.7532 202.598 117.5C203.701 143.985 194.141 170.832 173.919 191.054L169.35 195.622Z" fill="white"/>
</mask>
<g mask="url(#mask3_1148_8937)">
<rect x="19" y="3.99951" width="184" height="242" fill="url(#pattern00002)"/>
</g>
</g>
<defs>
<pattern id="pattern00002" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlinkHref="#image00002" transform="scale(0.00109649 0.000833696)"/>
</pattern>
<linearGradient id="paint0_linear_1148_8937" x1="137.302" y1="27.0001" x2="50.6991" y2="177.001" gradientUnits="userSpaceOnUse">
<stop stop-color="#9055A2"/>
<stop offset="1" stop-color="#FF4A1C"/>
</linearGradient>
<linearGradient id="paint1_linear_1148_8937" x1="172.302" y1="65.0006" x2="85.6991" y2="215.001" gradientUnits="userSpaceOnUse">
<stop stop-color="#9055A2"/>
<stop offset="1" stop-color="#FF4A1C"/>
</linearGradient>
<linearGradient id="paint2_linear_1148_8937" x1="31.3827" y1="145.885" x2="198.863" y2="86.0128" gradientUnits="userSpaceOnUse">
<stop stop-color="#052247"/>
<stop offset="0.5" stop-color="#9055A2"/>
<stop offset="1" stop-color="#FF4A1C"/>
</linearGradient>
<image id="image00002" width="912" height="1200" xlinkHref={imageSrc} />
</defs>
</svg>


  );
};

export default KGSec4DP2;
