import React from "react";
import styles from "./Section4.module.scss";
import LinkIcon from "../../../assets/icons/LinkedIn.svg";
import KGSec4DP1 from "../../../assets/knowGood/KGSec4DP1";
import KGSec4DP2 from "../../../assets/knowGood/KGSec4DP2";
import KGSec4DP3 from "../../../assets/knowGood/KGSec4DP3";

import RajivDP from "../../../assets/knowGood/Section4/RajivDP.jpeg";
import SumanDP from "../../../assets/knowGood/Section4/sumanDP.jpeg";
import SiddharthDP from "../../../assets/knowGood/Section4/SiddharthDP.jpeg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Card({ imageSrc, component, name, role, description, link }) {
  let ProfileImageComponent;

  switch (component) {
    case "KGSec4DP1":
      ProfileImageComponent = <KGSec4DP1 imageSrc={imageSrc} />;
      break;
    case "KGSec4DP2":
      ProfileImageComponent = <KGSec4DP2 imageSrc={imageSrc} />;
      break;
    case "KGSec4DP3":
      ProfileImageComponent = <KGSec4DP3 imageSrc={imageSrc} />;
      break;
    default:
      ProfileImageComponent = null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.cardInner}>
        {/* Front of the card */}
        <div className={styles.cardFront}>
          <div className={styles.profileImage}>{ProfileImageComponent}</div>
          <h1 className={styles.nameText}>{name}</h1>
          <p className={styles.roleText}>{role}</p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img
              src={LinkIcon}
              alt="LinkedIn Icon"
              className={styles.LinkIcon}
            />
          </a>
          {/* <p className={styles.descText}>{description}</p> */}
        </div>

        {/* Back of the card */}
        {/* <div className={styles.cardBack}>
          <p>{description}</p>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img
              src={LinkIcon}
              alt="LinkedIn Icon"
              className={styles.LinkIcon}
            />
          </a>{" "}
        </div> */}
      </div>
    </div>
  );
}

function Section4() {
  const cardData = [
    {
      component: "KGSec4DP2",
      imageSrc: SumanDP,
      name: "Suman Naidu",
      role: "Founder & Mentor",
      description:
        "Suman is a distinguished mentor, with over a decade of sales and marketing expertise, he brings exceptional organizational skill, self-motivation, creativity and initiative to achieve both personal and professional goals. Suman excels in managing profitability, deploying resources strategically, establishing high-value accounts with unwavering loyalty, and leading diverse teams through the entire business lifecycle, resulting in substantial revenue growth and a robust client base.",
      link: "https://www.linkedin.com/in/suman-naidu-555bb929/",
    },
    {
      component: "KGSec4DP3",
      imageSrc: SiddharthDP,
      name: "Siddharth Renganathan",
      role: "CEO & Co-Founder",
      description:
        "A dynamic leader nearing two decades of diverse expertise in business leadership, marketing, business development, and operations. He's the orchestrator of success, with a knack for boosting sales, forging profitable relationships, and achieving remarkable revenue growth. Siddharth's strategic mastery and unwavering commitment to excellence make him the driving force behind our thriving business landscape.",
      link: "https://www.linkedin.com/in/siddharth-renganathan-88a1369/?original_referer=",
    },
    {
      component: "KGSec4DP1",
      imageSrc: RajivDP,
      name: "Rajiv John",
      role: "MD & Co-Founder",
      description:
        "Rajiv is an original thinker, with a career spanning two decades spent across large enterprises and start-up ecosystems. He has been helping organizations with foresight, strategic thinking, marketing, sales and most importantly, in crafting creative solutions that assists in accelerated growth curves. At Credvest, Rajiv has been instrumental in leading and carving out a cutting edge sales and marketing organization enabling 300% EBITDA YOY. Rajiv's core People Management approach comprises of compassion, vision, motivation and passion.",
      link: "https://www.linkedin.com/in/johnrajiv/",
    },
  ];

  return (
    <div className={styles.parentContainer}>
      <FadeInOnScroll>
        <div className={styles.firstRowText}>
          <span className={styles.key}>First </span>
          <span className={styles.pageTitle}>In Line</span>
        </div>
      </FadeInOnScroll>
      <div className={styles.secondContainer}>
        {cardData.map((data, index) => (
          <Card
            key={index}
            component={data.component}
            imageSrc={data.imageSrc}
            name={data.name}
            role={data.role}
            description={data.description}
            link={data.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Section4;
