import React from "react";
import styles from "./Section2.module.scss";
import CaseStudiesSec2Bg from "../../../assets/caseStudies/CaseStudiesSec2Bg";
import CaseStudiesSec2img from "../../../assets/sales/section2/SalesSec2Bg.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <CaseStudiesSec2Bg imageSrc={CaseStudiesSec2img} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}> <FadeInOnScroll>The key to selling is Optimism </FadeInOnScroll></h1>
        <p className={styles.textBody}>
            With decades of experience in the real estate industry, our team of
            seasoned professionals brings a wealth of knowledge to the table. Our
            dedicated agents take the time to listen and comprehend specific
            requirements, tailoring our services to match the vision and
            preferences perfectly. Over the years, we have cultivated strong
            relationships with an extensive network of buyers, sellers, lenders,
            contractors, and industry experts. This network allows us to tap into
            a wide range of resources, giving you a distinct advantage in the
            competitive real estate market.
        </p>
      </div>
    </div>
  );
}

export default Section2;
