import React, { useEffect } from "react";
import BG from "../../assets/ourVentures/mainBG.webp";
import Pic1 from "../../assets/ourVentures/1.webp";
import Pic2 from "../../assets/ourVentures/2.webp";
import Pic3 from "../../assets/ourVentures/3.webp";
import Pic4 from "../../assets/ourVentures/4.webp";
import Pic1Desktop from "../../assets/ourVentures/1desk.webp";
import Pic2Desktop from "../../assets/ourVentures/2desk.webp";
import Pic3Desktop from "../../assets/ourVentures/3desk.webp";
import Pic4Desktop from "../../assets/ourVentures/4desk.webp";
import Small1 from "../../assets/ourVentures/small1.webp";
import Small2 from "../../assets/ourVentures/small2.webp";
import Small3 from "../../assets/ourVentures/small3.webp";
import Small4 from "../../assets/ourVentures/small4.webp";
import Section6 from "../../AllSections/Home/Section6/Section6";
import arrowRight from "../../assets/icons/arrowRight.svg";
import scrollToElement from "scroll-to-element";
import scrollIntoView from "scroll-into-view";
import { useLocation } from "react-router-dom";
export default function OurVentures() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.hash);
    if (window.location.href.includes("#")) {
      setTimeout(() => {
        document.querySelector(location.hash).scrollIntoView({
          behavior: "smooth",
        });
        // window.scrollBy({
        //   // top: (document.body.getBoundingClientRect().height / 3) * -1,
        // });
      }, 100);
      // console.log(el);
      // const top = el.getBoundingClientRect().bottom;
      // const screen = document.body.getBoundingClientRect().height;
      // console.log(top, screen);
      // window.scrollTo({
      //   top: top + (screen % 2),
      //   behavior: "smooth",
      // });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <section
        style={{
          backgroundImage: `url('${BG}')`,
          fontFamily: "Source Sans Pro",
        }}
        className="min-h-[100vh] bg-no-repeat grid place-items-center px-5 md:bg-contain md:bg-center max-md:bg-cover max-md:bg-start"
      >
        <h1 className="text-3xl md:text-5xl text-center text-white font-bold">
          Our Ventures : Synergy in Action
        </h1>
      </section>
      <div className="space-y-5 md:space-y-20 px-5 md:px-20 mt-20 lg:px-32">
        <div id="a" className="h-full relative">
          <img
            src={Pic1}
            className="w-full h-full object-contain md:hidden"
            alt=""
          />
          <img
            src={Pic1Desktop}
            className="w-full h-full object-contain max-md:hidden"
            alt=""
          />
          <p className="absolute bottom-3 text-white left-5 text-[8px] leading-tight md:left-1/2 right-5 md:text-sm text-center md:text-right font-oxygen">
            Identifies promising micro-markets and provides strategic insights
            that
            <br className="max-md:hidden" /> help developers position, market,
            and sell their properties effectively.
          </p>
        </div>
        <div className="h-full relative">
          <a
            href="https://parkingcapital.in/"
            target="_blank"
            id="b"
            rel="noreferrer"
          >
            <img
              src={Pic2}
              className="w-full h-full object-contain md:hidden"
              alt=""
            />
            <img
              src={Pic2Desktop}
              className="w-full h-full object-contain max-md:hidden"
              alt=""
            />
            <p className="absolute bottom-3 text-white left-5 text-[8px] leading-tight md:left-1/2 right-5 md:text-sm text-center md:text-right font-oxygen">
              Offers exclusive investment opportunities, empowering real estate
              <br className="max-md:hidden" /> partners with the capital they
              need to bring their projects to life.
            </p>
          </a>
        </div>
        <div id="c" className="h-full relative">
          <img
            src={Pic3}
            className="w-full h-full object-contain md:hidden"
            alt=""
          />
          <img
            src={Pic3Desktop}
            className="w-full h-full object-contain max-md:hidden"
            alt=""
          />
          <p className="absolute bottom-3 text-white left-5 md:left-1/2 text-[8px] leading-tight right-5 md:text-sm text-center md:text-right font-oxygen">
            Simplifies property transactions, ensuring a seamless experience for
            <br className="max-md:hidden" /> buyers by leveraging cutting-edge
            prop tech solutions.
          </p>
        </div>
        <div id="d" className="h-full relative">
          <a
            href="https://aitiinterieurs.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="relative">
              <img
                src={Pic4}
                className="w-full h-full object-contain md:hidden"
                alt=""
              />
              <img
                src={Pic4Desktop}
                className="w-full h-full object-contain max-md:hidden"
                alt=""
              />
              <div className="bg-black/50 top-0 left-0 w-full h-full absolute rounded-2xl"></div>
            </div>
            <p className="absolute bottom-3 text-white left-5 md:left-1/2 right-5 text-[8px] leading-tight md:text-sm text-center md:text-right font-oxygen">
              Enhances developed properties with exceptional interior design
              <br className="max-md:hidden" />, transforming houses into
              functional, aesthetically pleasing homes.
            </p>
          </a>
        </div>
      </div>
      <div className="grid px-5 md:px-20 mt-5 lg:px-32 gap-y-5 md:gap-10 grid-cols-1 md:grid-cols-2">
        <div id="e" className="h-full relative">
          <img src={Small1} alt="" className="w-full h-full object-contain" />
          <p className="absolute bottom-3 text-white left-5 right-5 text-[8px] leading-tight md:text-[12px] text-center font-oxygen">
            A celebration of South Indian tradition, offering an authentic, pure
            vegetarian dining experience through a curated thali of exquisite,
            flavor-rich dishes in a warm and homely ambiance
          </p>
        </div>
        <a
          href="https://suvaii.in/"
          target="_blank"
          id="f"
          className="h-full relative"
          rel="noreferrer"
        >
          <img src={Small2} alt="" className="w-full h-full object-contain" />
          <p className="absolute bottom-3 text-white left-5 right-5 text-[8px] leading-tight md:text-[12px] text-center font-oxygen">
            Suvaii brings the authentic flavours of Madurai to life, using
            traditional ingredients and recipes from the Pandyan dynasty.
          </p>
        </a>
        <div id="g" className="h-full relative">
          <img src={Small3} alt="" className="w-full h-full object-contain" />
          <p className="absolute bottom-3 text-white left-5 right-5 text-[8px] leading-tight md:text-[12px] text-center font-oxygen">
            Bengaluru's first QSR serving veg and non-veg cuisine. Tanjore
            Tiffins brings the rich heritage and authentic flavors of South
            India to the heart of Bengaluru, offering a blend of tradition and
            quick-service dining for every taste
          </p>
        </div>
        <div id="h" className="h-full relative">
          <a href="https://vanamo.in/">
            <img src={Small4} alt="" className="w-full h-full object-contain" />
            <p className="absolute bottom-3 text-white left-5 right-5 text-[8px] leading-tight md:text-[12px] text-center font-oxygen">
              Vanamo, Global Eats and Caffeinary, offers an unparalleled
              culinary experience where global flavors, fine craftsmanship, and
              elegant ambiance come together to create a refined and
              unforgettable journey for your sense
            </p>
          </a>
        </div>
      </div>
      <div className="px-5 flex flex-col items-center md:px-20 my-20 lg:px-32">
        <h5
          className="text-[#071013] text-xl md:text-2xl italic font-semibold"
          style={{ fontFamily: "Source Sans Pro" }}
        >
          Explore the full potential of our integrated ecosystem.
          <br />
          Learn how each venture contributes to our success.
        </h5>

        <button
          style={{ fontFamily: "Source Sans Pro" }}
          className="mt-20 flex flex-row gap-x-3 font-semibold bg-primary w-fit px-5 py-3 rounded-xl text-white"
        >
          Get In Touch
          <img src={arrowRight} alt="Client Icon" />
        </button>
      </div>
      <Section6 />
    </>
  );
}
