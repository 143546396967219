import React from 'react'
import styles from './Section5.module.scss'

function Section5() {
  return (
    <div className={styles.parentContainer}>
        <div className={styles.secondContainer}></div>

    </div>  
    )
};

export default Section5