import React from "react";
import styles from "./Section3.module.scss";
import CultureSec3MaskBg from "../../../assets/culture/section3/CultureSec3MaskBg";
import video from "../../../assets/culture/Cofeee.mp4";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section3() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.videoContainer}>
        <CultureSec3MaskBg videoSrc={video} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.title}>Ethos</h1>
        <p className={styles.textBody}>
            Passion, commitment, and desire to grow - are a few things we all have
            in common. What we look to inculcate in all of our people is the
            spirit of entrepreneurship. We don't have employees, we have
            employprenuers. Each of us is trained and skilled to take on the
            mantle of leadership, business acumen, client management, and
            financial modelling. We cultivate a breed independently capable of
            running the entire business units by themselves. Our ethos is to
            liberate our people from the limitation of their perceived abilities
            and make them 5X more potent.
        </p>
      </div>
    </div>
  );
}

export default Section3;
