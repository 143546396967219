import React from "react";
import styles from "./Section2.module.scss";
import CaseStudiesSec2Bg from "../../../assets/caseStudies/CaseStudiesSec2Bg";
import CaseStudiesSec2img from "../../../assets/Branding/Section2/BrandingBgSec2.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <CaseStudiesSec2Bg imageSrc={CaseStudiesSec2img} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}>
          <FadeInOnScroll>The exterior matters as much as the interiors</FadeInOnScroll>
        </h1>
        <p className={styles.textBody}>
            In the highly competitive world of real estate, branding plays a
            crucial role in establishing a strong market presence and building
            trust among potential buyers and sellers. A well-defined brand sets
            you apart from iyour competitors and creates a lasting impression in
            the minds of its target audience. It goes beyond just a logo or a
            catchy slogan; it encompasses the entire identity, values, and
            reputation of the business – and your reputation builds our reputation
            so we take up the responsibility of making you look good because,
            Afterall, Credvest Knows Good! By investing in branding efforts, real
            estate websites can create a strong market presence, establish
            credibility, and forge lasting connections with their target audience.
        </p>
      </div>
    </div>
  );
}

export default Section2;
