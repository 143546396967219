import React from "react";
import styles from "./Section3.module.scss";
import bulletPoint from "../../../assets/home/bulletPoint.svg";
import CultureIcon from "../../../assets/homepage/Section3/CultureIcon.svg";
import GameIcon from "../../../assets/homepage/Section3/GameIcon.svg";
import ResourceIcon from "../../../assets/homepage/Section3/RsourceIcon.svg";
import MultifunctionalIcon from "../../../assets/homepage/Section3/MultifunctionalIcon.svg";
import BackgroundImage from "../../../assets/homepage/Section3/homesec3leftBg.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";
import India from "../../../assets/home/india.webp";
import Ellipse from "../../../assets/home/ellipseBG.png";
import Circle from "../../../assets/home/circle.png";

function Section3() {
  return (
    <div className={styles.homeSection + " py-10"}>
      <div className={styles.secondContainer}>
        <div className="">
          <FadeInOnScroll>
            <h1 className={styles.pageHeader}>
              Navigating&nbsp;
              <br className="md:hidden" />
              <span className={styles.successText}>
                India's Real Estate Market
              </span>
            </h1>
          </FadeInOnScroll>
        </div>
        <div className="flex flex-col max-md:pt-5 md:flex-row gap-y-5 md:justify-between ">
          <div className="md:w-3/5 grid place-items-center relative">
            <div className="absolute top-0 left-0">
              <img
                src={Ellipse}
                className="w-full h-full object-contain"
                alt=""
              />
            </div>
            <div className="absolute md:bottom-[30%] -bottom-[100%] -left-[6%] md:-left-[5%]">
              <img src={Circle} alt="" className="w-1/4 md:w-1/2" />
            </div>
            <div className="md:w-3/4 max-md:px-10">
              <p className="md:text-left md:text-xl">
                India's real estate market, valued at Rs. 27.06 trillion, faces a
                disconnect: developers struggle to find the right buyers, while
                buyers search for the perfect project. Credvest addresses these
                challenges by enabling developers to focus on creating exceptional
                real estate.
              </p>
            </div>
          </div>
          <div className="md:w-2/5">
            <img src={India} alt="" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
