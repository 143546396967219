import React, { useState, useRef } from "react"; // Import useRef
import styles from "./Section5.module.scss";
import CultureSec5LeftBg from "../../../assets/culture/section5/CultureSec5LeftBg";
import image from "../../../assets/culture/section5/studyPerson.jpg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons'


function Section5() {
  const [cvFile, setCvFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // Add this for the popup state

  const fileInputRef = useRef(null); // Reference for the file input

  const handleCvSubmit = async (e) => {
    e.preventDefault();

    if (!cvFile) {
      alert("Please upload your CV first.");
      return;
    }

    setIsLoading(true);

    const base64File = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(cvFile);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "credvestrealty@gmail.com",
      Password: "F75CCEB6585A9A080F8852EB94C25EA8D7DE",
      To: "enquire@credvest.com",
      From: "credvestrealty@gmail.com",
      Subject: "New CV Submitted",
      Body: "A new CV has been submitted. Please check the attachment.",
      Attachments: [
        {
          name: cvFile.name,
          data: base64File,
        },
      ],
      BCC: "testingmail@uchitchakma.com"
    })
    .then((message) => {
      setIsLoading(false);
      if (message === "OK") {
          setShowPopup(true);
          
          // Reset the file input and display text
          setCvFile(null);
          fileInputRef.current.value = ""; // Reset the file input
          document.getElementById("fileNameDisplay").innerText = "No file chosen"; // Reset the display text
      } else {
          alert("An error occurred while submitting your CV. Please try again later.");
      }
  })
  .catch((error) => {
      setIsLoading(false);
      console.error("Email sending error:", error);
      alert("An unexpected error occurred. Please check the console for more details.");
  });
}; 

      

  // The component's return should be outside and at the same level as your function definitions
  return (
    <div className={styles.parentContainer}>
        <div className={styles.imageContainer}>
            <CultureSec5LeftBg imageSrc={image} />
        </div>
        <div className={styles.secondContainer}>
            <div className={styles.thirdContainer}>
                <h1 className={styles.text + " mb-6 "}>
                    <FadeInOnScroll>
                        Are we your vibe? Come belong with your tribe
                    </FadeInOnScroll>
                </h1>
                <form onSubmit={handleCvSubmit}>
                    <div className={styles.formAlignment}>
                      <label htmlFor="fileUpload" className={styles.customFileUpload}>
                          <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon + " mr-3 "} />
                          Upload CV
                      </label>
                      <input
                          type="file"
                          id="fileUpload"
                          ref={fileInputRef}
                          className={styles.hiddenFileInput}
                          onChange={(e) => {
                              setCvFile(e.target.files[0]);
                              document.getElementById("fileNameDisplay").innerText = e.target.files[0].name;
                          }}
                      />
                      <span id="fileNameDisplay" className={styles.fileNameDisplay + " mb-8 "}>
                         No file chosen
                      </span>
                      <FadeInOnScroll>
                          <button type="submit" className={"button"}>
                              <span className={styles.buttonText}>
                                  {isLoading ? "Submiting CV..." : "Submit CV"}
                              </span>
                              <i className={styles.buttonIcon + " fa fa-arrow-right "}></i>
                          </button>
                      </FadeInOnScroll>
                    </div>
                </form>
            </div>
        </div>
        {showPopup && (
    <div className={styles.popup}>
        <div className={styles.popupContent}>
            <span>Your CV has been submitted. Our team will get back to you soon.</span>
            <button className={styles.closeButton} onClick={() => setShowPopup(false)}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    </div>
)}
    </div>
);
}

export default Section5;