import React, { useState, useEffect } from "react";
import styles from "./Section2.module.scss";
import addIcon from "../../../assets/culture/icons/add.svg";
// import icon1 from "../../../assets/icons/recycleIcon.svg"
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PassionIcon from "../../../assets/culture/section2/icons/Passion.svg";
import SolutionIcon from "../../../assets/culture/section2/icons/Solution.svg";
import CollectivismIcon from "../../../assets/culture/section2/icons/Collectivism.svg";
import InovationIcon from "../../../assets/culture/section2/icons/Inovation.svg";
import IntegratedIcon from "../../../assets/culture/section2/icons/Integrated.svg";
import EqualityIcon from "../../../assets/culture/section2/icons/Equality.svg";
import BoldnessIcon from "../../../assets/culture/section2/icons/Boldness.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  const [showValues, setShowValues] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [translateY, setTranslateY] = useState("50px");

  useEffect(() => {
    if (showValues) {
      setOpacity(1);
      setTranslateY("0px");
    } else {
      setOpacity(0);
      setTranslateY("50px");
    }
  }, [showValues]);

  const animatedStyles = {
    opacity: opacity,
    transform: `translateY(${translateY})`,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
    maxHeight: showValues ? "1000px" : "0px", // Conditional rendering of maxHeight
    overflow: "visible",
  };

  const circleData = [
    {
      icon: PassionIcon, // Or you can import multiple icons and use them here
      text: "Passion",
      bgColor: "#9055A2", // or any other color you want
    },
    {
      icon: SolutionIcon,
      text: "Solution Focused",
      bgColor: "#A3538C",
    },
    {
      icon: CollectivismIcon,
      text: "Collectivism",
      bgColor: "#B55175",
    },
    {
      icon: InovationIcon,
      text: "Inovation and Learning",
      bgColor: "#C8505F",
    },
    {
      icon: IntegratedIcon,
      text: "Integrated and Authenticity",
      bgColor: "#DA4E49",
    },
    {
      icon: EqualityIcon,
      text: "Equality and Respect",
      bgColor: "#ED4C32",
    },
    {
      icon: BoldnessIcon,
      text: "Boldness",
      bgColor: "#FF4A1C",
    },
  ];
  return (
    <div className={styles.parentContainer}>
      <div className={styles.secondContainer}>
         
          <div className={styles.titleGroup}>
            <span className={styles.coloredTitle}>Our </span>
            <span className={styles.blackTitle}>Value Wheels</span>
          </div>
         
        <p className={styles.textBody}>
           
            We believe that the road to a successful organization is built by
            people who are in an elevated state of consciousness and consequently
            happiness. While happiness is a relative concept, but keeping a
            workplace to be friendly, encouraging, rewarding, and priming with
            enthusiasm, is a collective effort that we all partake in. Dive right
            in to understand, what is the adhesive that makes us stand by each
            other.
           
        </p>
         
          <span
            className={showValues ? `${styles.hideButton} button` : "button"}
            onClick={() => setShowValues(!showValues)}
          >
            {showValues ? (
              <>
                <FontAwesomeIcon
                  icon={faTimes}
                  className={`${styles.buttonIcon} ${styles.hideButtonIcon}`}
                />
                <span className={styles.buttonText}>Hide</span>
              </>
            ) : (
              <>
                <img
                  src={addIcon}
                  alt="Client Icon"
                  className={styles.buttonIcon}
                />
                <span className={styles.buttonText}>Click to reveal values</span>
              </>
            )}
          </span>
         

        <div className={styles.flexContainer} style={animatedStyles}>
          {circleData.map((circle, index) => (
            <div
              key={index}
              className={`${styles.circle} ${showValues ? styles.visible : ""}`}
              style={{
                backgroundColor: circle.bgColor,
                transitionDelay: `${index * 0.2}s`, // This will stagger the appearance by 0.2s for each circle
              }}
            >
              <img src={circle.icon} alt="icon" className={styles.circleIcon} />
              <h1 className={styles.circleText}> {circle.text} </h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section2;
