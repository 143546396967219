import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Section1 from '../../AllSections/CaseStudies/Section1/Section1'
import Section2 from '../../AllSections/CaseStudies/Section2/Section2'
import Section3 from '../../AllSections/CaseStudies/Section3/Section3'
import Section4 from '../../AllSections/CaseStudies/Section4/Section4'

function CaseStudies() {
  const section3Ref = useRef(null);

  const location = useLocation();

useEffect(() => {
  if (location.state && location.state.fromSection5 && section3Ref.current) {
    section3Ref.current.scrollIntoView({ behavior: 'smooth' });
  }
}, []);

  return (
    <div>
      <Section1 />
      <Section2 />
      <div ref={section3Ref}>
        <Section3 />
      </div>
      <Section4 />
    </div>
  )
}

export default CaseStudies;
