import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function Section5() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const testimonials = [
    {
      name: "Nikhil",
      post: "Regional Head",
      content:
        "Credvest's empowering work culture is what I value the most. It’s an environment that encourages me to push beyond boundaries and take ownership, making me feel like it's my own company. As the Regional Head of Sales, I’m proud to play a pivotal role in driving success across our projects. What truly sets Credvest apart is the immense growth and opportunities it offers.",
    },
    {
      name: "Roopesh S",
      post: "Senior Relationship Manager - Direct Sales, Real Estate",
      content:
        "Credvest's culture is driven by the energy and passion of our young team, making us a force in the real estate industry. In sales, our focus isn’t just on selling but on guiding clients through their home-buying journey as trusted consultants. Credvest redefines the traditional workplace by fostering synergy, encouraging innovation, and promoting continuous learning and mentorship. Here, it feels like being part of a family, with every day bringing opportunities for both intellectual and professional growth.",
    },
    {
      name: "Bhavya Gunasekaran",
      post: "General Manager - CRM",
      content:
        "Working at Credvest is incredibly rewarding due to its dynamic and forward-thinking approach across diverse verticals. In my role, I take pride in guiding clients through complex processes like property legals and banking options, making a real impact on their lives. I also play a crucial role in providing visibility into the sales pipeline, supporting a data-driven approach that enhances business outcomes and client satisfaction. What sets Credvest apart is its holistic strategy, seamlessly integrating various services to offer clients a comprehensive, one-stop solution.",
    },
    {
      name: "Dharti Gowda HS",
      post: "Sr. Relationship Manager - Direct Sales, Real Estate",
      content:
        "At Credvest, the collaborative and innovative culture is truly inspiring. The team is supportive, and there’s a strong emphasis on both personal and professional growth. In my role, I manage client relationships, ensuring our services meet their needs effectively, from strategic planning to day-to-day problem-solving. What sets Credvest apart is our combination of a supportive team, effective leadership, and a commitment to embracing new challenges every day, keeping us ahead in the real estate industry.",
    },
    {
      name: "Aishwarya Lodh",
      post: "Sr. Relationship Manager - Direct Sales, Real Estate",
      content:
        "At Credvest, the opportunity for growth and learning is unparalleled. In just a year, I've trained, learned about the real estate market, and advanced to a Senior Relationship Manager role, all thanks to the incredible support from my team and management. My role involves connecting with clients, understanding their needs, and guiding them through their real estate journey. What sets Credvest apart is its unique approach to training and deep market understanding, which goes beyond the superficial and equips us with real, in-depth knowledge of the industry.",
    },
    {
      name: "Neeti Ajit",
      post: "AGM - Marketing",
      content:
        "At Credvest, the dynamic and forward-thinking culture is what excites me the most. As the AGM of Marketing for our real estate division, I get to drive innovative strategies that set our projects apart in a competitive market. What truly distinguishes Credvest is our seamless integration across real estate, F&B, and investments, delivering unmatched quality and results.",
    },
    {
      name: "Chirag D",
      post: "Assistant Manager - HR",
      content:
        "At Credvest, employees thrive in an environment that values growth and rewards strong performance with well-deserved promotions and salary increases. The company cultivates a culture of innovation and collaboration, empowering every team member to contribute their ideas. Credvest is also committed to recognizing and rewarding hard work, which drives a culture of excellence. What truly sets Credvest apart is its dedication to reinvesting in its people, building trust, and creating a high-quality work environment where everyone can succeed.",
    },
  ];
  return (
    <div className="w-full max-md:px-5 py-10 relative">
      <h3 className="text-center font-oxygen font-bold text-3xl md:text-5xl text-primary">
        Testimonials
      </h3>
      <div className="w-full top-0 left-0 h-full absolute pointer-events-none bg-gradient-to-r opacity-60 z-10 from-white via-transparent to-white"></div>
      <Carousel
        // autoPlay
        // emulateTouch
        // infiniteLoop
        // interval={3000}
        // stopOnHover={false}
        // swipeable
        // centerMode
        // centerSlidePercentage={80}
        // useKeyboardArrows
        className="my-12 relative"
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={2000}
        pauseOnHover={false}
      >
        {testimonials.map((t) => (
          <div
            key={t.content}
            className="mx-6 rounded-xl h-[70vh] bg-[#052247] rounded-b-xl text-start px-10 py-5 font-oxygen select-none flex flex-col justify-between max-md:mx-1"
          >
            <p className="text-white italic text-sm mt-6">{t.content}</p>
            <div className="space-y-1">
              <h5 className="text-white font-semibold mt-5 md:text-xl">{t.name}</h5>
              <h5 className="text-white font-medium">{t.post}</h5>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Section5;
