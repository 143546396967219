import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Section3.module.scss';
import chessImage from '../../../assets/caseStudies/chessPicture.jpg';
import caseStudiesVideoBg from '../../../assets/caseStudies/Cofeee.mp4';
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section3() {
  const storySets = [
    {
      circleImage: chessImage,
      name: "70% Villa inventory sold in 6 months",
      link: "https://credvest.com/web-stories/windchimes/"
    },
    {
      circleImage: chessImage,
      name: "25% apartment Inventory sold in under 2 months",
      link: "https://credvest.com/web-stories/windchimes/"
    },
    {
      circleImage: chessImage,
      name: "100% Apartment Inventory sold in under 6 months",
      link: "https://credvest.com/web-stories/windchimes/"
    },
  ];

  return (
    <div className={styles.parentContainer}>
      <video className={styles.backgroundVideo} autoPlay loop muted playsInline>
        <source src={caseStudiesVideoBg} type="video/mp4" />
      </video>
      <div className={styles.secondContainer}>
        <FadeInOnScroll>
          <div className={styles.firstRowText}>
            <span className={styles.coloredTitle}>Case </span>
            <span className={styles.blackTitle}>Studies</span>
          </div>
        </FadeInOnScroll>
        <div className={styles.parentCircleContainer}>
          {storySets.map((set, index) => (
            <div key={index} className={styles.storyElement}>
              <div
                className={styles.storyCircle}
                onClick={() => window.open(set.link, "_blank")} // Opens link in a new tab
                style={{ backgroundImage: `url(${set.circleImage})` }}
              ></div>
                <div className={styles.text}>{set.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Section3;
