import React from "react";
import styles from "./Section2.module.scss";
import CaseStudiesSec2Bg from "../../../assets/caseStudies/CaseStudiesSec2Bg";
import CaseStudiesSec2img from "../../../assets/CRM/Section2/CRMSec2Bg.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <CaseStudiesSec2Bg imageSrc={CaseStudiesSec2img} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}>
          {" "}
          <FadeInOnScroll>Establishing a streamlined process</FadeInOnScroll>{" "}
        </h1>
        <p className={styles.textBody}>
            Our Customer Relationship Management (CRM) solutions is meticulously
            designed to streamline various aspects of the real estate journey,
            ensuring a seamless experience from the initial client onboarding to
            efficient loan process management, customer delight, engagement, and
            precise cashflow management. With our comprehensive expertise, we aim
            to enhance your our client journies, making them more convenient,
            transparent, and rewarding.
        </p>
      </div>
    </div>
  );
}

export default Section2;
