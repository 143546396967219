import React from "react";
import styles from "./Section3.module.scss";
import AddressIcon from "../../../assets/ContactUs/ContactAddressIcon.svg";
import MarketingIcon from "../../../assets/ContactUs/ContactMarketingIcon.svg";
import PhoneIcon from "../../../assets/ContactUs/ContactPhoneIcon.svg";
import EmailIcon from "../../../assets/ContactUs/ContactEmailIcon.svg";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section3() {
  return (
    <div className="w-full font-oxygen px-5 md:px-10 lg:px-32 gap-x-20 gap-y-10 pb-40">
      <FadeInOnScroll>
        <h3 className=" font-bold text-4xl mb-20">
          Our&nbsp;
          <span className={styles.gayColor}>Offices</span>
        </h3>
      </FadeInOnScroll>
      <div className="flex flex-col gap-x-20 gap-y-10 md:flex-row">
        <div className="md:w-2/3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.823240291791!2d77.63627587499299!3d12.983154787333307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17c735a2d425%3A0xe073ef8aa8f75b55!2sCredvest!5e0!3m2!1sen!2sin!4v1692606936077!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            className="min-h-[50vh]"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="flex flex-col justify-center">
          <h4 className={styles.gayColor + " font-bold text-center text-3xl"}>
            Credvest
          </h4>
          <p className="mt-5 text-center">
            Credvest, 12, HAL Old Airport Rd, Domlur I Stage, 1st Stage, Domlur,
            Bengaluru, Karnataka 560071
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section3;
