import React from "react";
import styles from "./Section1.module.scss";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section1() {
  return (
    <div className={styles.parentContainer + " pt-14 md:pt-20"}>
      <div className={styles.secondContainer + " py-10 max-md:py-5"}>
        <h1 className={styles.knowGood}> Building a Legacy of Excellence </h1>
      </div>
    </div>
  );
}

export default Section1;
