import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Section5.module.scss";
import CultureSec5LeftBg from "../../../assets/culture/section5/CultureSec5LeftBg";
import Image from "../../../assets/homepage/Section5/homeSec5Img.webp";
import OurCultureIcon from "../../../assets/homepage/Section5/OurCultureIcon.svg";
import CurrentOpeningsIcon from "../../../assets/homepage/Section5/CurrentOpeningsIcon.svg";
import PeoplePracticeIcon from "../../../assets/homepage/Section5/PeoplePracticeIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";
import BG from "../../../assets/home/bgwavy.svg";
import arrowRight from "../../../assets/icons/arrowRight.svg";

function Section5() {
  return (
    <div
      style={{ backgroundImage: `url('${BG}')`, backgroundPositionY: "50%" }}
      className={"bg-no-repeat py-32 bg-cover grid place-items-center"}
    >
      <h4 className="text-black font-oxygen text-xl lg:text-4xl">
        Interested in collaborating with us?
      </h4>
      <h4 className="font-oxygen text-xl lg:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-tr from-[#9055A2] via-[#FF4A1C] to-[#052247]">
        Let's build success together.
      </h4>
      <a href="/contactUs" className="mt-20 flex flex-row gap-x-3 bg-primary w-fit px-5 py-3 rounded-xl text-white">
        Contact Us
        <img src={arrowRight} alt="Client Icon" className={styles.icon} />
      </a>
    </div>
  );
}

export default Section5;
