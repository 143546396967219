import React, { useEffect, useRef } from "react";
import styles from "./Home.module.scss";

//PAGES PAGES PAGES PAGES PAGES PAGES PAGES PAGES PAGES PAGES
import Section1 from "../../AllSections/Home/Section1/Section1";
import Section2 from "../../AllSections/Home/Section2/Section2";
import Section3 from "../../AllSections/Home/Section3/Section3";
import Section4 from "../../AllSections/Home/Section4/Section4";
import Section5 from "../../AllSections/Home/Section5/Section5";
import Section6 from "../../AllSections/Home/Section6/Section6";
import Section7 from "../../AllSections/Home/Section7/Section5";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  const section6Ref = useRef(null); // <-- Add this line

  return (
    <div className="">
      <Section1
        scrollToSection6={() =>
          section6Ref.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <Section2 />
      <Section3 />
      <Section4 />

      <Section5 />
      <Section7 />
      <div ref={section6Ref}>
        <Section6 />
      </div>
    </div>
  );
}

export default Home;
