import React from "react";
import styles from "./Section2.module.scss";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <h3 className="font-oxygen bg-clip-text bg-gradient-to-r from-[#FF4A1C] to-[#9055A2] text-transparent my-14 md:my-20 font-bold text-xl md:text-2xl lg:text-4xl">
        Whether you have a question,
        <br />a project in mind,
        <br />
        or want to explore partnership opportunities,
        <br />
        we&apos;d love to hear from you.
      </h3>
      <p className="md:text-xl ">
        Our team is ready to assist with any inquiries you may have. Reach out
        to us via phone, email, or
        <br />
        through our contact form, and we&apos;ll get back to you promptly.
      </p>
    </div>
  );
}

export default Section2;
