import React from 'react'
import styles from './Section4.module.scss'
import Section6 from '../../Home/Section6/Section6'

function Section4() {
  return (
 <div>
    <Section6 />
 </div>
    )
}

export default Section4