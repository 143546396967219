import React from 'react';

const CaseStudiesSec2Bg = ({ imageSrc }) => {  return (
    <svg viewBox="0 0 1981 1297" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1088_25263" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="366" y="710" width="401" height="451">
    <path d="M400.373 791.58C445.038 714.216 544.131 687.417 621.494 732.083L662.917 755.998C761.844 813.114 795.739 939.611 738.624 1038.54L715.142 1079.21C670.237 1156.99 570.782 1183.64 493.003 1138.73C371.615 1068.65 330.289 912.968 400.373 791.58Z" fill="url(#paint0_linear_1088_25263)"/>
    </mask>
    <g mask="url(#mask0_1088_25263)">
    <path d="M505.945 793.922C505.623 794.48 505.813 795.194 506.371 795.516L506.669 795.688C507.382 796.1 508.293 795.856 508.704 795.143L508.874 794.85C509.197 794.29 509.005 793.573 508.445 793.25C507.57 792.745 506.45 793.048 505.945 793.922Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M699.758 987.736C699.436 988.293 699.626 989.008 700.183 989.33L700.481 989.502C701.194 989.913 702.105 989.669 702.517 988.956L702.686 988.663C703.01 988.103 702.818 987.387 702.257 987.063C701.383 986.558 700.263 986.861 699.758 987.736Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M497.082 804.792C496.76 805.35 496.95 806.064 497.507 806.386L497.806 806.559C498.518 806.97 499.43 806.726 499.841 806.013L500.01 805.72C500.334 805.16 500.142 804.443 499.582 804.12C498.707 803.615 497.587 803.918 497.082 804.792Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M690.898 998.607C690.577 999.164 690.766 999.879 691.324 1000.2L691.622 1000.37C692.335 1000.78 693.246 1000.54 693.658 999.828L693.827 999.535C694.15 998.974 693.958 998.258 693.398 997.934C692.523 997.429 691.403 997.732 690.898 998.607Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M491.387 777.651C491.065 778.208 491.255 778.923 491.812 779.245L492.11 779.417C492.823 779.828 493.734 779.584 494.146 778.872L494.315 778.579C494.639 778.018 494.447 777.302 493.886 776.978C493.012 776.473 491.892 776.776 491.387 777.651Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M685.199 971.464C684.877 972.022 685.067 972.736 685.624 973.058L685.923 973.23C686.636 973.642 687.547 973.398 687.958 972.685L688.128 972.392C688.451 971.832 688.259 971.115 687.699 970.792C686.824 970.287 685.704 970.59 685.199 971.464Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M462.906 786.028C462.584 786.585 462.774 787.3 463.331 787.622L463.63 787.794C464.343 788.205 465.254 787.961 465.665 787.248L465.835 786.955C466.158 786.395 465.966 785.679 465.406 785.355C464.531 784.85 463.411 785.153 462.906 786.028Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M656.719 979.843C656.397 980.4 656.587 981.115 657.144 981.437L657.442 981.609C658.155 982.021 659.066 981.777 659.478 981.064L659.647 980.771C659.971 980.211 659.779 979.494 659.218 979.171C658.344 978.666 657.224 978.969 656.719 979.843Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M465.25 825.233C464.928 825.791 465.118 826.505 465.675 826.827L465.974 827C466.686 827.411 467.598 827.167 468.009 826.454L468.178 826.161C468.502 825.601 468.31 824.884 467.749 824.561C466.875 824.056 465.755 824.359 465.25 825.233Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M659.062 1019.05C658.741 1019.6 658.93 1020.32 659.488 1020.64L659.786 1020.81C660.499 1021.22 661.41 1020.98 661.822 1020.27L661.991 1019.97C662.314 1019.41 662.122 1018.7 661.562 1018.37C660.687 1017.87 659.567 1018.17 659.062 1019.05Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M431.395 794.944C431.716 794.387 431.527 793.672 430.969 793.35L430.671 793.178C429.958 792.767 429.047 793.011 428.635 793.724L428.466 794.017C428.143 794.577 428.335 795.293 428.895 795.617C429.77 796.122 430.89 795.819 431.395 794.944Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M625.207 988.759C625.529 988.202 625.339 987.487 624.782 987.165L624.483 986.993C623.771 986.581 622.859 986.825 622.448 987.538L622.279 987.831C621.955 988.392 622.147 989.108 622.708 989.432C623.582 989.936 624.702 989.633 625.207 988.759Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M440.254 784.077C440.576 783.519 440.386 782.804 439.829 782.483L439.53 782.31C438.818 781.899 437.906 782.143 437.495 782.856L437.326 783.149C437.002 783.709 437.194 784.426 437.754 784.749C438.629 785.254 439.749 784.951 440.254 784.077Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M634.067 977.892C634.388 977.335 634.199 976.62 633.641 976.298L633.343 976.126C632.63 975.714 631.719 975.959 631.307 976.671L631.138 976.964C630.815 977.525 631.007 978.241 631.567 978.565C632.441 979.07 633.562 978.767 634.067 977.892Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M445.949 811.219C446.271 810.661 446.081 809.947 445.524 809.625L445.226 809.453C444.513 809.041 443.602 809.285 443.19 809.998L443.021 810.291C442.697 810.851 442.889 811.568 443.45 811.891C444.324 812.396 445.444 812.093 445.949 811.219Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M639.762 1005.03C640.084 1004.48 639.894 1003.76 639.337 1003.44L639.038 1003.27C638.325 1002.86 637.414 1003.1 637.003 1003.81L636.833 1004.11C636.51 1004.67 636.702 1005.38 637.262 1005.71C638.137 1006.21 639.257 1005.91 639.762 1005.03Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M474.434 802.84C474.755 802.283 474.566 801.568 474.008 801.246L473.71 801.074C472.997 800.663 472.086 800.907 471.674 801.619L471.505 801.912C471.182 802.473 471.374 803.189 471.934 803.513C472.809 804.018 473.929 803.715 474.434 802.84Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M668.246 996.655C668.568 996.097 668.378 995.383 667.821 995.061L667.522 994.888C666.81 994.477 665.898 994.721 665.487 995.434L665.318 995.727C664.994 996.287 665.186 997.004 665.747 997.327C666.621 997.832 667.741 997.529 668.246 996.655Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M472.086 763.636C472.408 763.079 472.218 762.364 471.661 762.042L471.362 761.87C470.65 761.458 469.738 761.703 469.327 762.415L469.158 762.708C468.834 763.269 469.026 763.985 469.586 764.309C470.461 764.814 471.581 764.511 472.086 763.636Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M665.899 957.451C666.22 956.893 666.031 956.178 665.473 955.857L665.175 955.684C664.462 955.273 663.551 955.517 663.139 956.23L662.97 956.523C662.647 957.083 662.839 957.8 663.399 958.123C664.273 958.628 665.394 958.325 665.899 957.451Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M408.609 740.663C408.931 740.105 408.742 739.39 408.184 739.069L407.886 738.896C407.173 738.485 406.262 738.729 405.85 739.442L405.681 739.735C405.358 740.295 405.55 741.012 406.11 741.335C406.984 741.84 408.105 741.537 408.609 740.663Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M602.422 934.476C602.744 933.919 602.554 933.204 601.997 932.882L601.698 932.71C600.986 932.298 600.074 932.542 599.663 933.255L599.494 933.548C599.17 934.109 599.362 934.825 599.922 935.149C600.797 935.653 601.917 935.351 602.422 934.476Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M417.469 729.793C417.791 729.236 417.601 728.521 417.044 728.199L416.745 728.027C416.032 727.616 415.121 727.86 414.71 728.573L414.54 728.866C414.217 729.426 414.409 730.142 414.969 730.466C415.844 730.971 416.964 730.668 417.469 729.793Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M611.281 923.607C611.603 923.05 611.413 922.335 610.856 922.013L610.558 921.841C609.845 921.429 608.934 921.673 608.522 922.386L608.353 922.679C608.029 923.239 608.221 923.956 608.782 924.279C609.656 924.784 610.776 924.481 611.281 923.607Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M423.164 756.933C423.486 756.376 423.296 755.661 422.739 755.339L422.44 755.167C421.728 754.755 420.816 755 420.405 755.712L420.236 756.005C419.912 756.566 420.104 757.282 420.665 757.606C421.539 758.111 422.659 757.808 423.164 756.933Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M616.981 950.747C617.302 950.19 617.113 949.475 616.555 949.154L616.257 948.981C615.544 948.57 614.633 948.814 614.221 949.527L614.052 949.82C613.729 950.38 613.921 951.097 614.481 951.42C615.356 951.925 616.476 951.622 616.981 950.747Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M451.649 748.558C451.97 748.001 451.781 747.286 451.223 746.964L450.925 746.792C450.212 746.38 449.301 746.625 448.889 747.337L448.72 747.63C448.397 748.191 448.589 748.907 449.149 749.231C450.023 749.736 451.144 749.433 451.649 748.558Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M645.461 942.372C645.783 941.814 645.593 941.099 645.036 940.778L644.737 940.605C644.025 940.194 643.113 940.438 642.702 941.151L642.533 941.444C642.209 942.004 642.401 942.721 642.961 943.044C643.836 943.549 644.956 943.246 645.461 942.372Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M449.301 709.352C449.623 708.795 449.433 708.08 448.876 707.758L448.577 707.586C447.864 707.174 446.953 707.418 446.542 708.131L446.372 708.424C446.049 708.985 446.241 709.701 446.801 710.025C447.676 710.529 448.796 710.227 449.301 709.352Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M643.113 903.166C643.435 902.609 643.245 901.894 642.688 901.573L642.39 901.4C641.677 900.989 640.766 901.233 640.354 901.946L640.185 902.239C639.861 902.799 640.053 903.515 640.614 903.839C641.488 904.344 642.608 904.041 643.113 903.166Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M483.16 739.64C482.838 740.197 483.028 740.912 483.585 741.234L483.884 741.406C484.596 741.818 485.508 741.573 485.919 740.861L486.088 740.568C486.412 740.007 486.22 739.291 485.66 738.967C484.785 738.462 483.665 738.765 483.16 739.64Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M676.973 933.454C676.651 934.012 676.84 934.727 677.398 935.048L677.696 935.221C678.409 935.632 679.32 935.388 679.732 934.675L679.901 934.382C680.224 933.822 680.032 933.105 679.472 932.782C678.598 932.277 677.477 932.58 676.973 933.454Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M474.301 750.509C473.979 751.066 474.169 751.781 474.726 752.103L475.024 752.275C475.737 752.687 476.648 752.443 477.06 751.73L477.229 751.437C477.553 750.877 477.361 750.16 476.8 749.837C475.926 749.332 474.806 749.635 474.301 750.509Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M668.113 944.322C667.791 944.879 667.981 945.594 668.538 945.916L668.837 946.088C669.55 946.499 670.461 946.255 670.872 945.542L671.042 945.249C671.365 944.689 671.173 943.973 670.613 943.649C669.738 943.144 668.618 943.447 668.113 944.322Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M468.601 723.368C468.28 723.926 468.469 724.641 469.027 724.962L469.325 725.135C470.038 725.546 470.949 725.302 471.361 724.589L471.53 724.296C471.853 723.736 471.661 723.019 471.101 722.696C470.227 722.191 469.106 722.494 468.601 723.368Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M662.414 917.183C662.092 917.74 662.282 918.455 662.839 918.777L663.138 918.949C663.85 919.361 664.762 919.116 665.173 918.404L665.342 918.111C665.666 917.55 665.474 916.834 664.914 916.51C664.039 916.005 662.919 916.308 662.414 917.183Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M440.121 731.744C439.799 732.302 439.989 733.017 440.546 733.338L440.845 733.511C441.557 733.922 442.469 733.678 442.88 732.965L443.049 732.672C443.373 732.112 443.181 731.395 442.621 731.072C441.746 730.567 440.626 730.87 440.121 731.744Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M633.933 925.559C633.612 926.116 633.801 926.831 634.359 927.153L634.657 927.325C635.37 927.737 636.281 927.492 636.693 926.78L636.862 926.487C637.185 925.926 636.993 925.21 636.433 924.886C635.559 924.381 634.438 924.684 633.933 925.559Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M442.469 770.95C442.147 771.507 442.337 772.222 442.894 772.543L443.192 772.716C443.905 773.127 444.816 772.883 445.228 772.17L445.397 771.877C445.721 771.317 445.529 770.6 444.968 770.277C444.094 769.772 442.974 770.075 442.469 770.95Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M636.281 964.763C635.959 965.32 636.149 966.035 636.706 966.357L637.005 966.529C637.718 966.941 638.629 966.697 639.04 965.984L639.21 965.691C639.533 965.13 639.341 964.414 638.781 964.09C637.906 963.586 636.786 963.888 636.281 964.763Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M578.656 756.73C578.334 757.287 578.524 758.002 579.081 758.324L579.38 758.496C580.093 758.908 581.004 758.663 581.415 757.951L581.585 757.658C581.908 757.097 581.716 756.381 581.156 756.057C580.281 755.552 579.161 755.855 578.656 756.73Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M569.797 767.6C569.475 768.157 569.665 768.872 570.222 769.194L570.52 769.366C571.233 769.778 572.145 769.533 572.556 768.821L572.725 768.528C573.049 767.967 572.857 767.251 572.296 766.927C571.422 766.422 570.302 766.725 569.797 767.6Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M763.609 961.413C763.287 961.971 763.477 962.686 764.035 963.007L764.333 963.18C765.046 963.591 765.957 963.347 766.369 962.634L766.538 962.341C766.861 961.781 766.669 961.064 766.109 960.741C765.234 960.236 764.114 960.539 763.609 961.413Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M564.101 740.457C563.78 741.015 563.969 741.729 564.527 742.051L564.825 742.224C565.538 742.635 566.449 742.391 566.861 741.678L567.03 741.385C567.353 740.825 567.161 740.108 566.601 739.785C565.727 739.28 564.606 739.583 564.101 740.457Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M757.918 934.272C757.596 934.829 757.786 935.544 758.343 935.866L758.642 936.038C759.354 936.45 760.266 936.205 760.677 935.493L760.846 935.2C761.17 934.639 760.978 933.923 760.417 933.599C759.543 933.094 758.423 933.397 757.918 934.272Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M535.621 748.834C535.299 749.392 535.489 750.106 536.046 750.428L536.345 750.601C537.057 751.012 537.969 750.768 538.38 750.055L538.549 749.762C538.873 749.202 538.681 748.485 538.121 748.162C537.246 747.657 536.126 747.96 535.621 748.834Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M729.433 942.649C729.112 943.206 729.301 943.921 729.859 944.243L730.157 944.415C730.87 944.826 731.781 944.582 732.193 943.87L732.362 943.577C732.685 943.016 732.493 942.3 731.933 941.976C731.059 941.471 729.938 941.774 729.433 942.649Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M537.965 788.039C537.643 788.597 537.833 789.312 538.39 789.633L538.688 789.806C539.401 790.217 540.313 789.973 540.724 789.26L540.893 788.967C541.217 788.407 541.025 787.69 540.464 787.367C539.59 786.862 538.47 787.165 537.965 788.039Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M731.777 981.854C731.455 982.411 731.645 983.126 732.203 983.448L732.501 983.62C733.214 984.032 734.125 983.787 734.536 983.075L734.706 982.782C735.029 982.221 734.837 981.505 734.277 981.181C733.402 980.676 732.282 980.979 731.777 981.854Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M504.106 757.752C504.427 757.195 504.238 756.48 503.68 756.158L503.382 755.986C502.669 755.575 501.758 755.819 501.346 756.532L501.177 756.825C500.854 757.385 501.046 758.101 501.606 758.425C502.481 758.93 503.601 758.627 504.106 757.752Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M697.918 951.566C698.24 951.008 698.05 950.294 697.493 949.972L697.194 949.8C696.482 949.388 695.57 949.632 695.159 950.345L694.99 950.638C694.666 951.198 694.858 951.915 695.418 952.238C696.293 952.743 697.413 952.44 697.918 951.566Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M512.969 746.881C513.291 746.324 513.101 745.609 512.544 745.287L512.245 745.115C511.532 744.704 510.621 744.948 510.21 745.66L510.04 745.953C509.717 746.514 509.909 747.23 510.469 747.554C511.344 748.059 512.464 747.756 512.969 746.881Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M706.781 940.695C707.103 940.137 706.913 939.423 706.356 939.101L706.058 938.929C705.345 938.517 704.434 938.761 704.022 939.474L703.853 939.767C703.529 940.327 703.721 941.044 704.282 941.367C705.156 941.872 706.276 941.569 706.781 940.695Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M518.664 774.023C518.986 773.466 518.796 772.751 518.239 772.429L517.94 772.257C517.228 771.845 516.316 772.089 515.905 772.802L515.736 773.095C515.412 773.655 515.604 774.372 516.165 774.695C517.039 775.2 518.159 774.897 518.664 774.023Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M712.477 967.836C712.798 967.279 712.609 966.564 712.051 966.242L711.753 966.07C711.04 965.659 710.129 965.903 709.717 966.616L709.548 966.909C709.225 967.469 709.417 968.185 709.977 968.509C710.852 969.014 711.972 968.711 712.477 967.836Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M547.145 765.646C547.466 765.089 547.277 764.374 546.719 764.052L546.421 763.88C545.708 763.468 544.797 763.712 544.385 764.425L544.216 764.718C543.893 765.278 544.085 765.995 544.645 766.318C545.52 766.823 546.64 766.52 547.145 765.646Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M740.961 959.46C741.283 958.903 741.093 958.188 740.536 957.866L740.237 957.694C739.525 957.283 738.613 957.527 738.202 958.24L738.033 958.533C737.709 959.093 737.901 959.809 738.461 960.133C739.336 960.638 740.456 960.335 740.961 959.46Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M544.801 726.441C545.123 725.883 544.933 725.169 544.376 724.847L544.077 724.675C543.364 724.263 542.453 724.507 542.042 725.22L541.872 725.513C541.549 726.073 541.741 726.79 542.301 727.113C543.176 727.618 544.296 727.315 544.801 726.441Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M738.613 920.255C738.935 919.698 738.745 918.983 738.188 918.661L737.89 918.489C737.177 918.078 736.266 918.322 735.854 919.034L735.685 919.327C735.361 919.888 735.553 920.604 736.114 920.928C736.988 921.433 738.108 921.13 738.613 920.255Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M675.133 897.282C675.455 896.724 675.265 896.01 674.708 895.688L674.409 895.515C673.696 895.104 672.785 895.348 672.374 896.061L672.204 896.354C671.881 896.914 672.073 897.631 672.633 897.954C673.508 898.459 674.628 898.156 675.133 897.282Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M683.992 886.413C684.314 885.855 684.124 885.14 683.567 884.819L683.269 884.646C682.556 884.235 681.645 884.479 681.233 885.192L681.064 885.485C680.74 886.045 680.932 886.762 681.493 887.085C682.367 887.59 683.487 887.287 683.992 886.413Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M495.875 719.741C496.197 719.183 496.007 718.469 495.45 718.147L495.151 717.974C494.439 717.563 493.527 717.807 493.116 718.52L492.947 718.813C492.623 719.373 492.815 720.09 493.376 720.413C494.25 720.918 495.37 720.615 495.875 719.741Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M689.692 913.555C690.013 912.998 689.824 912.283 689.266 911.961L688.968 911.789C688.255 911.377 687.344 911.622 686.932 912.334L686.763 912.627C686.44 913.188 686.632 913.904 687.192 914.228C688.066 914.733 689.187 914.43 689.692 913.555Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M524.359 711.362C524.681 710.804 524.492 710.09 523.934 709.768L523.636 709.596C522.923 709.184 522.012 709.428 521.6 710.141L521.431 710.434C521.108 710.994 521.3 711.711 521.86 712.034C522.734 712.539 523.855 712.236 524.359 711.362Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M718.172 905.175C718.494 904.618 718.304 903.903 717.747 903.581L717.448 903.409C716.736 902.998 715.824 903.242 715.413 903.954L715.244 904.247C714.92 904.808 715.112 905.524 715.672 905.848C716.547 906.353 717.667 906.05 718.172 905.175Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M715.828 865.973C716.15 865.416 715.96 864.701 715.403 864.379L715.105 864.207C714.392 863.795 713.48 864.04 713.069 864.752L712.9 865.045C712.576 865.606 712.768 866.322 713.329 866.646C714.203 867.151 715.323 866.848 715.828 865.973Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M749.683 896.259C749.362 896.816 749.551 897.531 750.109 897.853L750.407 898.025C751.12 898.437 752.031 898.193 752.443 897.48L752.612 897.187C752.935 896.627 752.743 895.91 752.183 895.587C751.309 895.082 750.188 895.385 749.683 896.259Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M547.012 713.316C546.69 713.873 546.88 714.588 547.437 714.91L547.735 715.082C548.448 715.493 549.359 715.249 549.771 714.537L549.94 714.244C550.264 713.683 550.072 712.967 549.511 712.643C548.637 712.138 547.517 712.441 547.012 713.316Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M740.828 907.129C740.506 907.687 740.696 908.401 741.253 908.723L741.552 908.895C742.264 909.307 743.176 909.063 743.587 908.35L743.756 908.057C744.08 907.497 743.888 906.78 743.328 906.457C742.453 905.952 741.333 906.255 740.828 907.129Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M735.129 879.989C734.807 880.546 734.997 881.261 735.554 881.583L735.853 881.755C736.565 882.166 737.477 881.922 737.888 881.209L738.057 880.916C738.381 880.356 738.189 879.64 737.628 879.316C736.754 878.811 735.634 879.114 735.129 879.989Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M706.648 888.364C706.327 888.921 706.516 889.636 707.074 889.958L707.372 890.13C708.085 890.541 708.996 890.297 709.408 889.584L709.577 889.291C709.9 888.731 709.708 888.015 709.148 887.691C708.273 887.186 707.153 887.489 706.648 888.364Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M515.18 733.756C514.858 734.314 515.048 735.028 515.605 735.35L515.903 735.522C516.616 735.934 517.527 735.69 517.939 734.977L518.108 734.684C518.432 734.124 518.24 733.407 517.679 733.084C516.805 732.579 515.684 732.882 515.18 733.756Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M708.992 927.57C708.67 928.127 708.86 928.842 709.417 929.164L709.716 929.336C710.429 929.747 711.34 929.503 711.751 928.79L711.921 928.497C712.244 927.937 712.052 927.221 711.492 926.897C710.617 926.392 709.497 926.695 708.992 927.57Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M583.683 897.798C583.362 898.356 583.551 899.07 584.109 899.392L584.407 899.564C585.12 899.976 586.031 899.732 586.443 899.019L586.612 898.726C586.935 898.166 586.743 897.449 586.183 897.126C585.309 896.621 584.188 896.924 583.683 897.798Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M574.824 908.669C574.502 909.227 574.692 909.941 575.249 910.263L575.548 910.435C576.261 910.847 577.172 910.603 577.583 909.89L577.753 909.597C578.076 909.037 577.884 908.32 577.324 907.997C576.449 907.492 575.329 907.795 574.824 908.669Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M569.125 881.528C568.803 882.085 568.993 882.8 569.55 883.122L569.849 883.294C570.561 883.705 571.473 883.461 571.884 882.748L572.053 882.455C572.377 881.895 572.185 881.179 571.624 880.855C570.75 880.35 569.63 880.653 569.125 881.528Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M762.941 1075.34C762.62 1075.9 762.809 1076.61 763.367 1076.94L763.665 1077.11C764.378 1077.52 765.289 1077.27 765.701 1076.56L765.87 1076.27C766.193 1075.71 766.001 1074.99 765.441 1074.67C764.566 1074.16 763.446 1074.47 762.941 1075.34Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M540.644 889.904C540.323 890.461 540.512 891.176 541.07 891.498L541.368 891.67C542.081 892.081 542.992 891.837 543.404 891.124L543.573 890.831C543.896 890.271 543.704 889.555 543.144 889.231C542.269 888.726 541.149 889.029 540.644 889.904Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M734.461 1083.72C734.139 1084.28 734.329 1084.99 734.886 1085.31L735.185 1085.48C735.897 1085.9 736.809 1085.65 737.22 1084.94L737.389 1084.65C737.713 1084.09 737.521 1083.37 736.96 1083.05C736.086 1082.54 734.966 1082.84 734.461 1083.72Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M542.992 929.108C542.67 929.666 542.86 930.38 543.417 930.702L543.716 930.875C544.429 931.286 545.34 931.042 545.751 930.329L545.921 930.036C546.244 929.476 546.052 928.759 545.492 928.436C544.617 927.931 543.497 928.234 542.992 929.108Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M736.805 1122.92C736.483 1123.48 736.673 1124.19 737.23 1124.52L737.528 1124.69C738.241 1125.1 739.152 1124.86 739.564 1124.14L739.733 1123.85C740.057 1123.29 739.865 1122.57 739.304 1122.25C738.43 1121.74 737.309 1122.05 736.805 1122.92Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M509.133 898.821C509.455 898.264 509.265 897.549 508.708 897.227L508.409 897.055C507.696 896.644 506.785 896.888 506.374 897.601L506.204 897.894C505.881 898.454 506.073 899.17 506.633 899.494C507.508 899.999 508.628 899.696 509.133 898.821Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M702.945 1092.63C703.267 1092.08 703.077 1091.36 702.52 1091.04L702.222 1090.87C701.509 1090.46 700.598 1090.7 700.186 1091.41L700.017 1091.71C699.693 1092.27 699.885 1092.98 700.446 1093.31C701.32 1093.81 702.441 1093.51 702.945 1092.63Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M517.992 887.951C518.314 887.394 518.124 886.679 517.567 886.357L517.269 886.185C516.556 885.774 515.645 886.018 515.233 886.73L515.064 887.023C514.74 887.584 514.932 888.3 515.493 888.624C516.367 889.129 517.487 888.826 517.992 887.951Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M711.805 1081.76C712.127 1081.21 711.937 1080.49 711.379 1080.17L711.081 1080C710.368 1079.59 709.457 1079.83 709.046 1080.54L708.876 1080.84C708.553 1081.4 708.745 1082.11 709.305 1082.44C710.18 1082.94 711.3 1082.64 711.805 1081.76Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M523.692 915.094C524.013 914.536 523.824 913.822 523.266 913.5L522.968 913.328C522.255 912.916 521.344 913.16 520.932 913.873L520.763 914.166C520.44 914.726 520.632 915.443 521.192 915.766C522.066 916.271 523.187 915.968 523.692 915.094Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M717.504 1108.91C717.826 1108.35 717.636 1107.64 717.079 1107.32L716.78 1107.14C716.068 1106.73 715.156 1106.98 714.745 1107.69L714.576 1107.98C714.252 1108.54 714.444 1109.26 715.004 1109.58C715.879 1110.09 716.999 1109.78 717.504 1108.91Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M552.172 906.716C552.494 906.159 552.304 905.444 551.747 905.122L551.448 904.95C550.736 904.539 549.824 904.783 549.413 905.495L549.244 905.788C548.92 906.349 549.112 907.065 549.672 907.389C550.547 907.894 551.667 907.591 552.172 906.716Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M745.984 1100.53C746.306 1099.97 746.117 1099.26 745.559 1098.94L745.261 1098.76C744.548 1098.35 743.637 1098.6 743.225 1099.31L743.056 1099.6C742.733 1100.16 742.925 1100.88 743.485 1101.2C744.359 1101.71 745.48 1101.41 745.984 1100.53Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M549.824 867.512C550.146 866.955 549.956 866.24 549.399 865.918L549.101 865.746C548.388 865.334 547.477 865.579 547.065 866.291L546.896 866.584C546.572 867.145 546.764 867.861 547.325 868.185C548.199 868.69 549.319 868.387 549.824 867.512Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M743.641 1061.33C743.963 1060.77 743.773 1060.05 743.215 1059.73L742.917 1059.56C742.204 1059.15 741.293 1059.39 740.881 1060.1L740.712 1060.4C740.389 1060.96 740.581 1061.67 741.141 1062C742.016 1062.5 743.136 1062.2 743.641 1061.33Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M486.348 844.539C486.67 843.981 486.48 843.266 485.922 842.945L485.624 842.772C484.911 842.361 484 842.605 483.589 843.318L483.419 843.611C483.096 844.171 483.288 844.888 483.848 845.211C484.723 845.716 485.843 845.413 486.348 844.539Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M680.164 1038.35C680.486 1037.79 680.296 1037.08 679.739 1036.76L679.44 1036.59C678.728 1036.17 677.816 1036.42 677.405 1037.13L677.236 1037.42C676.912 1037.98 677.104 1038.7 677.665 1039.02C678.539 1039.53 679.659 1039.23 680.164 1038.35Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M495.207 833.669C495.529 833.112 495.339 832.397 494.782 832.075L494.483 831.903C493.771 831.492 492.859 831.736 492.448 832.449L492.279 832.742C491.955 833.302 492.147 834.018 492.708 834.342C493.582 834.847 494.702 834.544 495.207 833.669Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M689.02 1027.48C689.341 1026.93 689.152 1026.21 688.594 1025.89L688.296 1025.72C687.583 1025.31 686.672 1025.55 686.26 1026.26L686.091 1026.56C685.768 1027.12 685.96 1027.83 686.52 1028.16C687.395 1028.66 688.515 1028.36 689.02 1027.48Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M500.906 860.81C501.228 860.252 501.038 859.537 500.481 859.216L500.183 859.043C499.47 858.632 498.559 858.876 498.147 859.589L497.978 859.882C497.654 860.442 497.846 861.159 498.407 861.482C499.281 861.987 500.401 861.684 500.906 860.81Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M694.719 1054.62C695.041 1054.07 694.851 1053.35 694.294 1053.03L693.995 1052.86C693.282 1052.45 692.371 1052.69 691.96 1053.4L691.79 1053.7C691.467 1054.26 691.659 1054.97 692.219 1055.3C693.094 1055.8 694.214 1055.5 694.719 1054.62Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M529.387 852.432C529.709 851.875 529.519 851.16 528.962 850.838L528.663 850.666C527.95 850.254 527.039 850.499 526.628 851.211L526.458 851.504C526.135 852.065 526.327 852.781 526.887 853.105C527.762 853.61 528.882 853.307 529.387 852.432Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M723.199 1046.25C723.521 1045.69 723.331 1044.97 722.774 1044.65L722.476 1044.48C721.763 1044.07 720.852 1044.31 720.44 1045.03L720.271 1045.32C719.947 1045.88 720.139 1046.6 720.7 1046.92C721.574 1047.42 722.694 1047.12 723.199 1046.25Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M527.039 813.228C527.361 812.671 527.171 811.956 526.614 811.634L526.315 811.462C525.603 811.05 524.691 811.294 524.28 812.007L524.111 812.3C523.787 812.861 523.979 813.577 524.54 813.901C525.414 814.405 526.534 814.102 527.039 813.228Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M720.856 1007.04C721.177 1006.48 720.988 1005.77 720.43 1005.45L720.132 1005.28C719.419 1004.86 718.508 1005.11 718.096 1005.82L717.927 1006.11C717.604 1006.67 717.796 1007.39 718.356 1007.71C719.231 1008.22 720.351 1007.92 720.856 1007.04Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M560.898 843.517C560.577 844.074 560.766 844.789 561.324 845.111L561.622 845.283C562.335 845.695 563.246 845.45 563.658 844.738L563.827 844.445C564.15 843.884 563.958 843.168 563.398 842.844C562.523 842.339 561.403 842.642 560.898 843.517Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M754.711 1037.33C754.389 1037.89 754.579 1038.6 755.136 1038.92L755.435 1039.1C756.147 1039.51 757.059 1039.26 757.47 1038.55L757.639 1038.26C757.963 1037.7 757.771 1036.98 757.21 1036.66C756.336 1036.15 755.216 1036.46 754.711 1037.33Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M552.039 854.386C551.717 854.943 551.907 855.658 552.464 855.98L552.763 856.152C553.475 856.564 554.387 856.32 554.798 855.607L554.967 855.314C555.291 854.754 555.099 854.037 554.539 853.713C553.664 853.209 552.544 853.512 552.039 854.386Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M745.851 1048.2C745.53 1048.76 745.719 1049.47 746.277 1049.79L746.575 1049.97C747.288 1050.38 748.199 1050.13 748.611 1049.42L748.78 1049.13C749.103 1048.57 748.911 1047.85 748.351 1047.53C747.477 1047.02 746.356 1047.32 745.851 1048.2Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M546.34 827.243C546.018 827.801 546.208 828.516 546.765 828.837L547.063 829.01C547.776 829.421 548.688 829.177 549.099 828.464L549.268 828.171C549.592 827.611 549.4 826.894 548.839 826.571C547.965 826.066 546.845 826.369 546.34 827.243Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M740.156 1021.06C739.834 1021.62 740.024 1022.33 740.581 1022.65L740.88 1022.82C741.593 1023.24 742.504 1022.99 742.915 1022.28L743.085 1021.99C743.408 1021.43 743.216 1020.71 742.656 1020.39C741.781 1019.88 740.661 1020.18 740.156 1021.06Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M517.859 835.621C517.537 836.179 517.727 836.894 518.285 837.215L518.583 837.388C519.296 837.799 520.207 837.555 520.619 836.842L520.788 836.549C521.111 835.989 520.919 835.272 520.359 834.949C519.484 834.444 518.364 834.747 517.859 835.621Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M711.672 1029.43C711.35 1029.99 711.54 1030.71 712.097 1031.03L712.395 1031.2C713.108 1031.61 714.02 1031.37 714.431 1030.66L714.6 1030.36C714.924 1029.8 714.732 1029.09 714.171 1028.76C713.297 1028.26 712.177 1028.56 711.672 1029.43Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M520.207 874.826C519.885 875.384 520.075 876.099 520.632 876.42L520.931 876.593C521.643 877.004 522.555 876.76 522.966 876.047L523.135 875.754C523.459 875.194 523.267 874.477 522.707 874.154C521.832 873.649 520.712 873.952 520.207 874.826Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M714.019 1068.64C713.698 1069.2 713.887 1069.91 714.445 1070.23L714.743 1070.41C715.456 1070.82 716.367 1070.57 716.779 1069.86L716.948 1069.57C717.271 1069.01 717.079 1068.29 716.519 1067.97C715.644 1067.46 714.524 1067.77 714.019 1068.64Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M656.394 860.604C656.073 861.161 656.262 861.876 656.82 862.198L657.118 862.37C657.831 862.782 658.742 862.537 659.154 861.825L659.323 861.532C659.646 860.971 659.454 860.255 658.894 859.931C658.019 859.426 656.899 859.729 656.394 860.604Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M647.535 871.474C647.213 872.031 647.403 872.746 647.96 873.068L648.259 873.24C648.971 873.652 649.883 873.407 650.294 872.695L650.463 872.402C650.787 871.841 650.595 871.125 650.035 870.801C649.16 870.296 648.04 870.599 647.535 871.474Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M641.844 844.332C641.522 844.89 641.712 845.604 642.269 845.926L642.567 846.099C643.28 846.51 644.191 846.266 644.603 845.553L644.772 845.26C645.096 844.7 644.904 843.983 644.343 843.66C643.469 843.155 642.349 843.458 641.844 844.332Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M613.355 852.711C613.034 853.269 613.223 853.983 613.781 854.305L614.079 854.477C614.792 854.889 615.703 854.645 616.115 853.932L616.284 853.639C616.607 853.079 616.415 852.362 615.855 852.039C614.98 851.534 613.86 851.837 613.355 852.711Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M615.703 891.914C615.381 892.472 615.571 893.187 616.128 893.508L616.427 893.681C617.139 894.092 618.051 893.848 618.462 893.135L618.631 892.842C618.955 892.282 618.763 891.565 618.203 891.242C617.328 890.737 616.208 891.04 615.703 891.914Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M581.848 861.627C582.17 861.07 581.98 860.355 581.422 860.033L581.124 859.861C580.411 859.45 579.5 859.694 579.089 860.407L578.919 860.7C578.596 861.26 578.788 861.976 579.348 862.3C580.223 862.805 581.343 862.502 581.848 861.627Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M590.707 850.758C591.029 850.201 590.839 849.486 590.282 849.164L589.983 848.992C589.271 848.581 588.359 848.825 587.948 849.537L587.779 849.83C587.455 850.391 587.647 851.107 588.208 851.431C589.082 851.936 590.202 851.633 590.707 850.758Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M596.402 877.9C596.724 877.342 596.535 876.628 595.977 876.306L595.679 876.134C594.966 875.722 594.055 875.966 593.643 876.679L593.474 876.972C593.151 877.532 593.343 878.249 593.903 878.572C594.777 879.077 595.898 878.774 596.402 877.9Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M624.883 869.523C625.205 868.966 625.015 868.251 624.458 867.929L624.159 867.757C623.446 867.345 622.535 867.589 622.124 868.302L621.954 868.595C621.631 869.155 621.823 869.872 622.383 870.195C623.258 870.7 624.378 870.397 624.883 869.523Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M622.539 830.318C622.861 829.76 622.671 829.046 622.114 828.724L621.815 828.552C621.103 828.14 620.191 828.384 619.78 829.097L619.611 829.39C619.287 829.95 619.479 830.667 620.04 830.99C620.914 831.495 622.034 831.192 622.539 830.318Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M559.063 807.343C559.384 806.786 559.195 806.071 558.637 805.749L558.339 805.577C557.626 805.165 556.715 805.41 556.303 806.122L556.134 806.415C555.811 806.976 556.003 807.692 556.563 808.016C557.438 808.521 558.558 808.218 559.063 807.343Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M752.875 1001.16C753.197 1000.6 753.007 999.885 752.45 999.563L752.151 999.39C751.439 998.979 750.527 999.223 750.116 999.936L749.947 1000.23C749.623 1000.79 749.815 1001.51 750.376 1001.83C751.25 1002.33 752.37 1002.03 752.875 1001.16Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M567.922 796.474C568.244 795.917 568.054 795.202 567.497 794.88L567.198 794.708C566.486 794.296 565.574 794.541 565.163 795.253L564.994 795.546C564.67 796.107 564.862 796.823 565.422 797.147C566.297 797.652 567.417 797.349 567.922 796.474Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M761.734 990.288C762.056 989.73 761.867 989.015 761.309 988.694L761.011 988.521C760.298 988.11 759.387 988.354 758.975 989.067L758.806 989.36C758.483 989.92 758.675 990.637 759.235 990.96C760.109 991.465 761.23 991.162 761.734 990.288Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M573.617 823.616C573.939 823.058 573.749 822.344 573.192 822.022L572.894 821.849C572.181 821.438 571.27 821.682 570.858 822.395L570.689 822.688C570.365 823.248 570.557 823.965 571.118 824.288C571.992 824.793 573.112 824.49 573.617 823.616Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M767.43 1017.43C767.752 1016.87 767.562 1016.16 767.004 1015.84L766.706 1015.66C765.993 1015.25 765.082 1015.5 764.671 1016.21L764.501 1016.5C764.178 1017.06 764.37 1017.78 764.93 1018.1C765.805 1018.61 766.925 1018.3 767.43 1017.43Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M602.098 815.239C602.42 814.681 602.23 813.967 601.672 813.645L601.374 813.472C600.661 813.061 599.75 813.305 599.339 814.018L599.169 814.311C598.846 814.871 599.038 815.588 599.598 815.911C600.473 816.416 601.593 816.113 602.098 815.239Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M599.754 776.034C600.076 775.476 599.886 774.761 599.329 774.44L599.03 774.267C598.318 773.856 597.406 774.1 596.995 774.813L596.826 775.106C596.502 775.666 596.694 776.383 597.254 776.706C598.129 777.211 599.249 776.908 599.754 776.034Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M633.613 806.322C633.291 806.879 633.481 807.594 634.038 807.916L634.337 808.088C635.05 808.499 635.961 808.255 636.372 807.542L636.542 807.249C636.865 806.689 636.673 805.973 636.113 805.649C635.238 805.144 634.118 805.447 633.613 806.322Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M624.75 817.193C624.428 817.75 624.618 818.465 625.175 818.787L625.474 818.959C626.186 819.37 627.098 819.126 627.509 818.414L627.678 818.12C628.002 817.56 627.81 816.844 627.249 816.52C626.375 816.015 625.255 816.318 624.75 817.193Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M619.055 790.05C618.733 790.607 618.923 791.322 619.48 791.644L619.778 791.816C620.491 792.228 621.402 791.984 621.814 791.271L621.983 790.978C622.307 790.418 622.115 789.701 621.554 789.378C620.68 788.873 619.559 789.176 619.055 790.05Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M590.57 798.427C590.248 798.984 590.438 799.699 590.996 800.021L591.294 800.193C592.007 800.605 592.918 800.361 593.329 799.648L593.499 799.355C593.822 798.795 593.63 798.078 593.07 797.755C592.195 797.25 591.075 797.553 590.57 798.427Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M592.918 837.632C592.596 838.189 592.786 838.904 593.343 839.226L593.642 839.398C594.354 839.81 595.266 839.566 595.677 838.853L595.846 838.56C596.17 838 595.978 837.283 595.417 836.96C594.543 836.455 593.423 836.758 592.918 837.632Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M656.73 720.204C656.409 720.762 656.598 721.477 657.156 721.798L657.454 721.971C658.167 722.382 659.078 722.138 659.49 721.425L659.659 721.132C659.982 720.572 659.79 719.855 659.23 719.532C658.355 719.027 657.235 719.33 656.73 720.204Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M389.379 1138.76C389.057 1139.32 389.247 1140.03 389.804 1140.35L390.103 1140.53C390.815 1140.94 391.727 1140.69 392.138 1139.98L392.307 1139.69C392.631 1139.13 392.439 1138.41 391.878 1138.09C391.004 1137.58 389.884 1137.88 389.379 1138.76Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M647.871 731.076C647.549 731.633 647.739 732.348 648.296 732.669L648.595 732.842C649.307 733.253 650.219 733.009 650.63 732.296L650.799 732.003C651.123 731.443 650.931 730.726 650.371 730.403C649.496 729.898 648.376 730.201 647.871 731.076Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M380.519 1149.63C380.198 1150.19 380.387 1150.9 380.945 1151.22L381.243 1151.4C381.956 1151.81 382.867 1151.56 383.279 1150.85L383.448 1150.56C383.771 1150 383.579 1149.28 383.019 1148.96C382.144 1148.45 381.024 1148.76 380.519 1149.63Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M374.82 1122.49C374.498 1123.04 374.688 1123.76 375.246 1124.08L375.544 1124.25C376.257 1124.67 377.168 1124.42 377.579 1123.71L377.749 1123.42C378.072 1122.85 377.88 1122.14 377.32 1121.81C376.445 1121.31 375.325 1121.61 374.82 1122.49Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M613.695 712.31C613.373 712.867 613.563 713.582 614.121 713.904L614.419 714.076C615.132 714.488 616.043 714.243 616.454 713.531L616.624 713.238C616.947 712.677 616.755 711.961 616.195 711.637C615.32 711.132 614.2 711.435 613.695 712.31Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M616.039 751.515C615.717 752.072 615.907 752.787 616.464 753.109L616.763 753.281C617.475 753.693 618.387 753.448 618.798 752.736L618.967 752.443C619.291 751.882 619.099 751.166 618.539 750.842C617.664 750.337 616.544 750.64 616.039 751.515Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M582.184 721.228C582.505 720.671 582.316 719.956 581.758 719.634L581.46 719.462C580.747 719.05 579.836 719.294 579.424 720.007L579.255 720.3C578.932 720.861 579.124 721.577 579.684 721.901C580.559 722.405 581.679 722.102 582.184 721.228Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M591.043 710.357C591.365 709.8 591.175 709.085 590.618 708.763L590.319 708.591C589.607 708.179 588.695 708.423 588.284 709.136L588.115 709.429C587.791 709.989 587.983 710.706 588.543 711.029C589.418 711.534 590.538 711.231 591.043 710.357Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M596.738 737.499C597.06 736.942 596.87 736.227 596.313 735.906L596.015 735.733C595.302 735.322 594.391 735.566 593.979 736.279L593.81 736.572C593.486 737.132 593.678 737.848 594.239 738.172C595.113 738.677 596.233 738.374 596.738 737.499Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M625.219 729.122C625.541 728.565 625.351 727.85 624.794 727.529L624.495 727.356C623.782 726.945 622.871 727.189 622.46 727.902L622.29 728.195C621.967 728.755 622.159 729.472 622.719 729.795C623.594 730.3 624.714 729.997 625.219 729.122Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M753.211 860.758C753.533 860.201 753.343 859.486 752.786 859.164L752.487 858.992C751.775 858.581 750.863 858.825 750.452 859.537L750.283 859.83C749.959 860.391 750.151 861.107 750.711 861.431C751.586 861.936 752.706 861.633 753.211 860.758Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M762.07 849.888C762.392 849.331 762.202 848.616 761.645 848.294L761.347 848.122C760.634 847.71 759.723 847.955 759.311 848.667L759.142 848.96C758.818 849.521 759.01 850.237 759.571 850.561C760.445 851.066 761.566 850.763 762.07 849.888Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M767.766 877.03C768.088 876.472 767.898 875.758 767.34 875.436L767.042 875.263C766.329 874.852 765.418 875.096 765.006 875.809L764.837 876.102C764.514 876.662 764.706 877.379 765.266 877.702C766.141 878.207 767.261 877.904 767.766 877.03Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M366.594 1084.48C366.272 1085.03 366.462 1085.75 367.019 1086.07L367.317 1086.24C368.03 1086.65 368.941 1086.41 369.353 1085.7L369.522 1085.4C369.846 1084.84 369.654 1084.13 369.093 1083.8C368.219 1083.3 367.099 1083.6 366.594 1084.48Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M462.09 1101.57C461.768 1102.12 461.958 1102.84 462.515 1103.16L462.813 1103.33C463.526 1103.75 464.438 1103.5 464.849 1102.79L465.018 1102.5C465.342 1101.93 465.15 1101.22 464.589 1100.89C463.715 1100.39 462.595 1100.69 462.09 1101.57Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M453.23 1112.44C452.909 1112.99 453.098 1113.71 453.656 1114.03L453.954 1114.2C454.667 1114.61 455.578 1114.37 455.99 1113.66L456.159 1113.36C456.482 1112.8 456.29 1112.09 455.73 1111.76C454.855 1111.26 453.735 1111.56 453.23 1112.44Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M447.535 1085.29C447.213 1085.85 447.403 1086.57 447.96 1086.89L448.259 1087.06C448.971 1087.47 449.883 1087.23 450.294 1086.51L450.463 1086.22C450.787 1085.66 450.595 1084.94 450.035 1084.62C449.16 1084.12 448.04 1084.42 447.535 1085.29Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M419.055 1093.67C418.733 1094.23 418.923 1094.94 419.48 1095.26L419.778 1095.44C420.491 1095.85 421.402 1095.6 421.814 1094.89L421.983 1094.6C422.307 1094.04 422.115 1093.32 421.554 1093C420.68 1092.49 419.559 1092.8 419.055 1093.67Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M688.754 714.32C688.432 714.877 688.622 715.592 689.179 715.914L689.478 716.086C690.19 716.497 691.102 716.253 691.513 715.54L691.682 715.247C692.006 714.687 691.814 713.971 691.253 713.647C690.379 713.142 689.259 713.445 688.754 714.32Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M421.398 1132.87C421.077 1133.43 421.266 1134.15 421.824 1134.47L422.122 1134.64C422.835 1135.05 423.746 1134.81 424.158 1134.1L424.327 1133.8C424.65 1133.24 424.458 1132.53 423.898 1132.2C423.023 1131.7 421.903 1132 421.398 1132.87Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M387.543 1102.59C387.865 1102.03 387.675 1101.32 387.118 1100.99L386.819 1100.82C386.107 1100.41 385.195 1100.65 384.784 1101.37L384.615 1101.66C384.291 1102.22 384.483 1102.94 385.043 1103.26C385.918 1103.77 387.038 1103.46 387.543 1102.59Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M396.402 1091.72C396.724 1091.16 396.535 1090.45 395.977 1090.12L395.679 1089.95C394.966 1089.54 394.055 1089.78 393.643 1090.5L393.474 1090.79C393.151 1091.35 393.343 1092.07 393.903 1092.39C394.777 1092.9 395.898 1092.59 396.402 1091.72Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M402.098 1118.86C402.42 1118.3 402.23 1117.59 401.672 1117.27L401.374 1117.09C400.661 1116.68 399.75 1116.93 399.339 1117.64L399.169 1117.93C398.846 1118.49 399.038 1119.21 399.598 1119.53C400.473 1120.04 401.593 1119.73 402.098 1118.86Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M430.578 1110.48C430.9 1109.93 430.71 1109.21 430.153 1108.89L429.855 1108.72C429.142 1108.31 428.23 1108.55 427.819 1109.26L427.65 1109.56C427.326 1110.12 427.518 1110.83 428.079 1111.16C428.953 1111.66 430.073 1111.36 430.578 1110.48Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M428.234 1071.28C428.556 1070.72 428.367 1070.01 427.809 1069.68L427.511 1069.51C426.798 1069.1 425.887 1069.34 425.475 1070.06L425.306 1070.35C424.983 1070.91 425.175 1071.63 425.735 1071.95C426.609 1072.46 427.73 1072.15 428.234 1071.28Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M373.613 1037.44C373.935 1036.88 373.745 1036.16 373.188 1035.84L372.89 1035.67C372.177 1035.26 371.266 1035.5 370.854 1036.22L370.685 1036.51C370.361 1037.07 370.553 1037.79 371.114 1038.11C371.988 1038.61 373.108 1038.31 373.613 1037.44Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M379.313 1064.58C379.634 1064.02 379.445 1063.31 378.887 1062.98L378.589 1062.81C377.876 1062.4 376.965 1062.64 376.553 1063.36L376.384 1063.65C376.061 1064.21 376.253 1064.93 376.813 1065.25C377.688 1065.76 378.808 1065.45 379.313 1064.58Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M407.793 1056.2C408.115 1055.64 407.925 1054.93 407.368 1054.61L407.069 1054.43C406.357 1054.02 405.445 1054.27 405.034 1054.98L404.865 1055.27C404.541 1055.83 404.733 1056.55 405.293 1056.87C406.168 1057.38 407.288 1057.07 407.793 1056.2Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M405.445 1017C405.767 1016.44 405.577 1015.72 405.02 1015.4L404.722 1015.23C404.009 1014.82 403.098 1015.06 402.686 1015.77L402.517 1016.07C402.193 1016.63 402.385 1017.34 402.946 1017.67C403.82 1018.17 404.941 1017.87 405.445 1017Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M439.305 1047.28C438.983 1047.84 439.173 1048.55 439.73 1048.87L440.028 1049.05C440.741 1049.46 441.652 1049.21 442.064 1048.5L442.233 1048.21C442.557 1047.65 442.365 1046.93 441.804 1046.61C440.93 1046.1 439.809 1046.41 439.305 1047.28Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M430.445 1058.15C430.123 1058.71 430.313 1059.42 430.871 1059.75L431.169 1059.92C431.882 1060.33 432.793 1060.09 433.204 1059.37L433.374 1059.08C433.697 1058.52 433.505 1057.8 432.945 1057.48C432.07 1056.97 430.95 1057.28 430.445 1058.15Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M424.75 1031.01C424.428 1031.57 424.618 1032.28 425.175 1032.6L425.474 1032.78C426.186 1033.19 427.098 1032.94 427.509 1032.23L427.678 1031.94C428.002 1031.38 427.81 1030.66 427.249 1030.34C426.375 1029.83 425.255 1030.14 424.75 1031.01Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M396.269 1039.39C395.948 1039.95 396.137 1040.66 396.695 1040.98L396.993 1041.15C397.706 1041.57 398.617 1041.32 399.029 1040.61L399.198 1040.32C399.521 1039.76 399.329 1039.04 398.769 1038.72C397.894 1038.21 396.774 1038.51 396.269 1039.39Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M398.613 1078.59C398.291 1079.15 398.481 1079.86 399.038 1080.19L399.337 1080.36C400.05 1080.77 400.961 1080.53 401.372 1079.81L401.542 1079.52C401.865 1078.96 401.673 1078.24 401.113 1077.92C400.238 1077.42 399.118 1077.72 398.613 1078.59Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M402.149 881.969C402.47 881.411 402.281 880.697 401.723 880.375L401.425 880.203C400.712 879.791 399.801 880.035 399.389 880.748L399.22 881.041C398.897 881.601 399.089 882.318 399.649 882.641C400.523 883.146 401.644 882.843 402.149 881.969Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M595.965 1075.78C596.287 1075.22 596.097 1074.51 595.54 1074.19L595.241 1074.02C594.529 1073.6 593.617 1073.85 593.206 1074.56L593.037 1074.85C592.713 1075.41 592.905 1076.13 593.465 1076.45C594.34 1076.96 595.46 1076.66 595.965 1075.78Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M411.008 871.098C411.33 870.54 411.14 869.826 410.583 869.504L410.284 869.331C409.571 868.92 408.66 869.164 408.249 869.877L408.079 870.17C407.756 870.73 407.948 871.447 408.508 871.77C409.383 872.275 410.503 871.972 411.008 871.098Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M604.82 1064.91C605.142 1064.35 604.952 1063.64 604.395 1063.32L604.097 1063.14C603.384 1062.73 602.473 1062.98 602.061 1063.69L601.892 1063.98C601.568 1064.54 601.76 1065.26 602.321 1065.58C603.195 1066.09 604.316 1065.79 604.82 1064.91Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M416.707 898.24C417.029 897.683 416.839 896.968 416.282 896.646L415.983 896.474C415.271 896.063 414.359 896.307 413.948 897.02L413.779 897.313C413.455 897.873 413.647 898.589 414.208 898.913C415.082 899.418 416.202 899.115 416.707 898.24Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M610.52 1092.05C610.841 1091.5 610.652 1090.78 610.094 1090.46L609.796 1090.29C609.083 1089.88 608.172 1090.12 607.76 1090.83L607.591 1091.13C607.268 1091.69 607.46 1092.4 608.02 1092.73C608.895 1093.23 610.015 1092.93 610.52 1092.05Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M445.188 889.863C445.509 889.306 445.32 888.591 444.762 888.269L444.464 888.097C443.751 887.686 442.84 887.93 442.428 888.643L442.259 888.936C441.936 889.496 442.128 890.212 442.688 890.536C443.563 891.041 444.683 890.738 445.188 889.863Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M639 1083.68C639.322 1083.12 639.132 1082.4 638.575 1082.08L638.276 1081.91C637.564 1081.5 636.652 1081.74 636.241 1082.46L636.072 1082.75C635.748 1083.31 635.94 1084.02 636.501 1084.35C637.375 1084.85 638.495 1084.55 639 1083.68Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M442.844 850.658C443.166 850.101 442.976 849.386 442.419 849.064L442.12 848.892C441.407 848.481 440.496 848.725 440.085 849.437L439.915 849.73C439.592 850.291 439.784 851.007 440.344 851.331C441.219 851.836 442.339 851.533 442.844 850.658Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M636.656 1044.47C636.978 1043.91 636.788 1043.2 636.231 1042.88L635.933 1042.71C635.22 1042.29 634.309 1042.54 633.897 1043.25L633.728 1043.54C633.404 1044.1 633.596 1044.82 634.157 1045.14C635.031 1045.65 636.151 1045.35 636.656 1044.47Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M476.699 880.946C476.377 881.504 476.567 882.218 477.124 882.54L477.423 882.712C478.136 883.124 479.047 882.88 479.458 882.167L479.628 881.874C479.951 881.314 479.759 880.597 479.199 880.274C478.324 879.769 477.204 880.072 476.699 880.946Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M670.512 1074.76C670.19 1075.32 670.38 1076.03 670.937 1076.35L671.235 1076.53C671.948 1076.94 672.859 1076.69 673.271 1075.98L673.44 1075.69C673.764 1075.13 673.572 1074.41 673.011 1074.09C672.137 1073.58 671.017 1073.89 670.512 1074.76Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M467.84 891.814C467.518 892.372 467.708 893.087 468.265 893.408L468.563 893.581C469.276 893.992 470.188 893.748 470.599 893.035L470.768 892.742C471.092 892.182 470.9 891.465 470.339 891.142C469.465 890.637 468.345 890.94 467.84 891.814Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M661.656 1085.63C661.334 1086.19 661.524 1086.9 662.081 1087.22L662.38 1087.4C663.093 1087.81 664.004 1087.56 664.415 1086.85L664.585 1086.56C664.908 1086 664.716 1085.28 664.156 1084.96C663.281 1084.45 662.161 1084.75 661.656 1085.63Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M462.144 864.672C461.823 865.229 462.012 865.944 462.57 866.266L462.868 866.438C463.581 866.85 464.492 866.605 464.904 865.893L465.073 865.6C465.396 865.039 465.204 864.323 464.644 863.999C463.769 863.494 462.649 863.797 462.144 864.672Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M655.957 1058.49C655.635 1059.04 655.825 1059.76 656.382 1060.08L656.681 1060.25C657.393 1060.66 658.305 1060.42 658.716 1059.71L658.885 1059.41C659.209 1058.85 659.017 1058.14 658.457 1057.81C657.582 1057.31 656.462 1057.61 655.957 1058.49Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M433.66 873.052C433.338 873.609 433.528 874.324 434.085 874.646L434.384 874.818C435.096 875.229 436.008 874.985 436.419 874.273L436.588 873.98C436.912 873.419 436.72 872.703 436.16 872.379C435.285 871.874 434.165 872.177 433.66 873.052Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M627.473 1066.87C627.151 1067.42 627.34 1068.14 627.898 1068.46L628.196 1068.63C628.909 1069.04 629.82 1068.8 630.232 1068.09L630.401 1067.79C630.724 1067.23 630.532 1066.52 629.972 1066.19C629.098 1065.69 627.977 1065.99 627.473 1066.87Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M436.008 912.254C435.686 912.811 435.876 913.526 436.433 913.848L436.731 914.02C437.444 914.432 438.355 914.187 438.767 913.475L438.936 913.182C439.26 912.621 439.068 911.905 438.507 911.581C437.633 911.076 436.513 911.379 436.008 912.254Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M629.82 1106.07C629.498 1106.63 629.688 1107.34 630.246 1107.66L630.544 1107.83C631.257 1108.25 632.168 1108 632.579 1107.29L632.749 1107C633.072 1106.44 632.88 1105.72 632.32 1105.4C631.445 1104.89 630.325 1105.19 629.82 1106.07Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M499.484 935.23C499.162 935.787 499.352 936.502 499.91 936.823L500.208 936.996C500.921 937.407 501.832 937.163 502.244 936.45L502.413 936.157C502.736 935.597 502.544 934.881 501.984 934.557C501.109 934.052 499.989 934.355 499.484 935.23Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M693.301 1129.04C692.979 1129.6 693.169 1130.32 693.726 1130.64L694.024 1130.81C694.737 1131.22 695.648 1130.98 696.06 1130.26L696.229 1129.97C696.553 1129.41 696.361 1128.69 695.8 1128.37C694.926 1127.87 693.806 1128.17 693.301 1129.04Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M490.625 946.099C490.303 946.656 490.493 947.371 491.05 947.693L491.349 947.865C492.061 948.276 492.973 948.032 493.384 947.319L493.553 947.026C493.877 946.466 493.685 945.75 493.124 945.426C492.25 944.921 491.13 945.224 490.625 946.099Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M684.441 1139.91C684.12 1140.47 684.309 1141.18 684.867 1141.51L685.165 1141.68C685.878 1142.09 686.789 1141.85 687.201 1141.13L687.37 1140.84C687.693 1140.28 687.501 1139.56 686.941 1139.24C686.066 1138.73 684.946 1139.04 684.441 1139.91Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M484.93 918.956C484.608 919.513 484.798 920.228 485.355 920.55L485.653 920.722C486.366 921.134 487.277 920.89 487.689 920.177L487.858 919.884C488.182 919.323 487.99 918.607 487.429 918.283C486.555 917.779 485.434 918.081 484.93 918.956Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M678.746 1112.77C678.424 1113.33 678.614 1114.04 679.171 1114.36L679.47 1114.54C680.182 1114.95 681.094 1114.7 681.505 1113.99L681.674 1113.7C681.998 1113.14 681.806 1112.42 681.246 1112.1C680.371 1111.59 679.251 1111.9 678.746 1112.77Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M456.445 927.334C456.123 927.891 456.313 928.606 456.871 928.928L457.169 929.1C457.882 929.512 458.793 929.267 459.204 928.555L459.374 928.262C459.697 927.701 459.505 926.985 458.945 926.661C458.07 926.156 456.95 926.459 456.445 927.334Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M650.262 1121.15C649.94 1121.7 650.13 1122.42 650.687 1122.74L650.985 1122.91C651.698 1123.33 652.609 1123.08 653.021 1122.37L653.19 1122.08C653.514 1121.51 653.322 1120.8 652.761 1120.47C651.887 1119.97 650.767 1120.27 650.262 1121.15Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M458.793 966.538C458.471 967.095 458.661 967.81 459.218 968.132L459.517 968.304C460.229 968.716 461.141 968.472 461.552 967.759L461.721 967.466C462.045 966.905 461.853 966.189 461.292 965.865C460.418 965.361 459.298 965.663 458.793 966.538Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M652.605 1160.35C652.284 1160.91 652.473 1161.62 653.031 1161.95L653.329 1162.12C654.042 1162.53 654.953 1162.29 655.365 1161.57L655.534 1161.28C655.857 1160.72 655.665 1160 655.105 1159.68C654.23 1159.18 653.11 1159.48 652.605 1160.35Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M424.934 936.251C425.255 935.694 425.066 934.979 424.508 934.657L424.21 934.485C423.497 934.073 422.586 934.318 422.174 935.03L422.005 935.323C421.682 935.884 421.874 936.6 422.434 936.924C423.309 937.429 424.429 937.126 424.934 936.251Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M618.75 1130.07C619.072 1129.51 618.882 1128.79 618.325 1128.47L618.026 1128.3C617.314 1127.89 616.402 1128.13 615.991 1128.84L615.822 1129.14C615.498 1129.7 615.69 1130.41 616.251 1130.74C617.125 1131.24 618.245 1130.94 618.75 1130.07Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M433.797 925.381C434.119 924.824 433.929 924.109 433.372 923.787L433.073 923.615C432.361 923.203 431.449 923.447 431.038 924.16L430.869 924.453C430.545 925.013 430.737 925.73 431.297 926.053C432.172 926.558 433.292 926.255 433.797 925.381Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M627.609 1119.19C627.931 1118.64 627.742 1117.92 627.184 1117.6L626.886 1117.43C626.173 1117.02 625.262 1117.26 624.85 1117.97L624.681 1118.27C624.358 1118.83 624.55 1119.54 625.11 1119.87C625.984 1120.37 627.105 1120.07 627.609 1119.19Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M439.492 952.523C439.814 951.965 439.624 951.25 439.067 950.929L438.769 950.756C438.056 950.345 437.145 950.589 436.733 951.302L436.564 951.595C436.24 952.155 436.432 952.872 436.993 953.195C437.867 953.7 438.987 953.397 439.492 952.523Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M633.305 1146.34C633.627 1145.78 633.437 1145.06 632.879 1144.74L632.581 1144.57C631.868 1144.16 630.957 1144.4 630.546 1145.12L630.376 1145.41C630.053 1145.97 630.245 1146.69 630.805 1147.01C631.68 1147.51 632.8 1147.21 633.305 1146.34Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M467.973 944.147C468.295 943.589 468.105 942.874 467.547 942.553L467.249 942.38C466.536 941.969 465.625 942.213 465.214 942.926L465.044 943.219C464.721 943.779 464.913 944.496 465.473 944.819C466.348 945.324 467.468 945.021 467.973 944.147Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M661.785 1137.96C662.107 1137.4 661.917 1136.69 661.36 1136.37L661.062 1136.19C660.349 1135.78 659.437 1136.03 659.026 1136.74L658.857 1137.03C658.533 1137.59 658.725 1138.31 659.286 1138.63C660.16 1139.14 661.28 1138.83 661.785 1137.96Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M465.629 904.94C465.951 904.383 465.761 903.668 465.204 903.347L464.905 903.174C464.193 902.763 463.281 903.007 462.87 903.72L462.701 904.013C462.377 904.573 462.569 905.29 463.129 905.613C464.004 906.118 465.124 905.815 465.629 904.94Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M659.442 1098.75C659.763 1098.2 659.574 1097.48 659.016 1097.16L658.718 1096.99C658.005 1096.58 657.094 1096.82 656.682 1097.53L656.513 1097.83C656.19 1098.39 656.382 1099.1 656.942 1099.43C657.816 1099.93 658.937 1099.63 659.442 1098.75Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M523.25 1112.98C523.572 1112.42 523.382 1111.7 522.825 1111.38L522.526 1111.21C521.814 1110.8 520.902 1111.04 520.491 1111.75L520.322 1112.05C519.998 1112.61 520.19 1113.32 520.751 1113.65C521.625 1114.15 522.745 1113.85 523.25 1112.98Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M532.109 1102.11C532.431 1101.55 532.242 1100.83 531.684 1100.51L531.386 1100.34C530.673 1099.93 529.762 1100.17 529.35 1100.89L529.181 1101.18C528.858 1101.74 529.05 1102.46 529.61 1102.78C530.484 1103.28 531.605 1102.98 532.109 1102.11Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M537.805 1129.25C538.127 1128.69 537.937 1127.98 537.379 1127.65L537.081 1127.48C536.368 1127.07 535.457 1127.31 535.046 1128.03L534.876 1128.32C534.553 1128.88 534.745 1129.6 535.305 1129.92C536.18 1130.43 537.3 1130.12 537.805 1129.25Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M372.473 927.058C372.795 926.5 372.605 925.786 372.047 925.464L371.749 925.291C371.036 924.88 370.125 925.124 369.714 925.837L369.544 926.13C369.221 926.69 369.413 927.407 369.973 927.73C370.848 928.235 371.968 927.932 372.473 927.058Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M566.289 1120.87C566.611 1120.31 566.421 1119.6 565.864 1119.28L565.565 1119.1C564.853 1118.69 563.941 1118.94 563.53 1119.65L563.361 1119.94C563.037 1120.5 563.229 1121.22 563.79 1121.54C564.664 1122.05 565.784 1121.75 566.289 1120.87Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M370.129 887.852C370.451 887.294 370.261 886.579 369.704 886.258L369.405 886.085C368.693 885.674 367.781 885.918 367.37 886.631L367.201 886.924C366.877 887.484 367.069 888.201 367.629 888.524C368.504 889.029 369.624 888.726 370.129 887.852Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M563.942 1081.67C564.263 1081.11 564.074 1080.39 563.516 1080.07L563.218 1079.9C562.505 1079.49 561.594 1079.73 561.182 1080.44L561.013 1080.74C560.69 1081.3 560.882 1082.01 561.442 1082.34C562.316 1082.84 563.437 1082.54 563.942 1081.67Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M403.988 918.138C403.666 918.695 403.856 919.41 404.413 919.732L404.712 919.904C405.425 920.315 406.336 920.071 406.747 919.358L406.917 919.065C407.24 918.505 407.048 917.789 406.488 917.465C405.613 916.96 404.493 917.263 403.988 918.138Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M597.801 1111.95C597.479 1112.51 597.669 1113.23 598.226 1113.55L598.524 1113.72C599.237 1114.13 600.148 1113.89 600.56 1113.17L600.729 1112.88C601.053 1112.32 600.861 1111.6 600.3 1111.28C599.426 1110.78 598.306 1111.08 597.801 1111.95Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M395.125 929.011C394.803 929.568 394.993 930.283 395.55 930.605L395.849 930.777C396.561 931.188 397.473 930.944 397.884 930.231L398.053 929.938C398.377 929.378 398.185 928.662 397.624 928.338C396.75 927.833 395.63 928.136 395.125 929.011Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M588.941 1122.83C588.62 1123.38 588.809 1124.1 589.367 1124.42L589.665 1124.59C590.378 1125 591.289 1124.76 591.701 1124.05L591.87 1123.75C592.193 1123.19 592.001 1122.48 591.441 1122.15C590.566 1121.65 589.446 1121.95 588.941 1122.83Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M389.43 901.868C389.108 902.425 389.298 903.14 389.855 903.462L390.153 903.634C390.866 904.046 391.777 903.802 392.189 903.089L392.358 902.796C392.682 902.236 392.49 901.519 391.929 901.196C391.055 900.691 389.934 900.994 389.43 901.868Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M583.246 1095.68C582.924 1096.24 583.114 1096.95 583.671 1097.28L583.97 1097.45C584.682 1097.86 585.594 1097.62 586.005 1096.9L586.174 1096.61C586.498 1096.05 586.306 1095.33 585.746 1095.01C584.871 1094.5 583.751 1094.81 583.246 1095.68Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M554.762 1104.06C554.44 1104.61 554.63 1105.33 555.187 1105.65L555.485 1105.82C556.198 1106.24 557.109 1105.99 557.521 1105.28L557.69 1104.99C558.014 1104.43 557.822 1103.71 557.261 1103.39C556.387 1102.88 555.267 1103.18 554.762 1104.06Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M363.293 949.451C362.971 950.008 363.161 950.723 363.718 951.045L364.017 951.217C364.729 951.629 365.641 951.385 366.052 950.672L366.221 950.379C366.545 949.819 366.353 949.102 365.792 948.779C364.918 948.274 363.798 948.577 363.293 949.451Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M557.105 1143.26C556.784 1143.82 556.973 1144.54 557.531 1144.86L557.829 1145.03C558.542 1145.44 559.453 1145.2 559.865 1144.49L560.034 1144.19C560.357 1143.63 560.165 1142.92 559.605 1142.59C558.73 1142.09 557.61 1142.39 557.105 1143.26Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M426.769 972.423C426.448 972.98 426.637 973.695 427.195 974.017L427.493 974.189C428.206 974.601 429.117 974.356 429.529 973.644L429.698 973.351C430.021 972.79 429.829 972.074 429.269 971.75C428.394 971.245 427.274 971.548 426.769 972.423Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M417.914 983.293C417.592 983.85 417.782 984.565 418.339 984.887L418.638 985.059C419.35 985.471 420.262 985.226 420.673 984.514L420.842 984.221C421.166 983.66 420.974 982.944 420.414 982.62C419.539 982.115 418.419 982.418 417.914 983.293Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M412.219 956.15C411.897 956.708 412.087 957.422 412.644 957.744L412.942 957.917C413.655 958.328 414.566 958.084 414.978 957.371L415.147 957.078C415.471 956.518 415.279 955.801 414.718 955.478C413.844 954.973 412.724 955.276 412.219 956.15Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M606.031 1149.96C605.709 1150.52 605.899 1151.24 606.456 1151.56L606.755 1151.73C607.468 1152.14 608.379 1151.9 608.79 1151.18L608.96 1150.89C609.283 1150.33 609.091 1149.61 608.531 1149.29C607.656 1148.79 606.536 1149.09 606.031 1149.96Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M383.734 964.527C383.412 965.085 383.602 965.799 384.16 966.121L384.458 966.294C385.171 966.705 386.082 966.461 386.494 965.748L386.663 965.455C386.986 964.895 386.794 964.178 386.234 963.855C385.359 963.35 384.239 963.653 383.734 964.527Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M577.547 1158.34C577.225 1158.9 577.415 1159.61 577.972 1159.94L578.27 1160.11C578.983 1160.52 579.895 1160.28 580.306 1159.56L580.475 1159.27C580.799 1158.71 580.607 1157.99 580.046 1157.67C579.172 1157.16 578.052 1157.47 577.547 1158.34Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M386.082 1003.73C385.76 1004.29 385.95 1005 386.507 1005.33L386.806 1005.5C387.518 1005.91 388.43 1005.67 388.841 1004.95L389.01 1004.66C389.334 1004.1 389.142 1003.38 388.582 1003.06C387.707 1002.55 386.587 1002.86 386.082 1003.73Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M554.895 1156.39C555.216 1155.83 555.027 1155.12 554.469 1154.79L554.171 1154.62C553.458 1154.21 552.547 1154.45 552.135 1155.17L551.966 1155.46C551.643 1156.02 551.835 1156.74 552.395 1157.06C553.27 1157.57 554.39 1157.26 554.895 1156.39Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M366.781 989.717C367.103 989.159 366.913 988.445 366.356 988.123L366.058 987.951C365.345 987.539 364.434 987.783 364.022 988.496L363.853 988.789C363.529 989.349 363.721 990.066 364.282 990.389C365.156 990.894 366.276 990.591 366.781 989.717Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M395.262 981.339C395.584 980.782 395.394 980.067 394.837 979.745L394.538 979.573C393.825 979.161 392.914 979.405 392.503 980.118L392.333 980.411C392.01 980.971 392.202 981.688 392.762 982.011C393.637 982.516 394.757 982.213 395.262 981.339Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M392.914 942.136C393.236 941.578 393.046 940.864 392.489 940.542L392.19 940.37C391.478 939.958 390.566 940.202 390.155 940.915L389.986 941.208C389.662 941.768 389.854 942.485 390.415 942.808C391.289 943.313 392.409 943.01 392.914 942.136Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M586.727 1135.95C587.048 1135.39 586.859 1134.68 586.301 1134.36L586.003 1134.18C585.29 1133.77 584.379 1134.02 583.967 1134.73L583.798 1135.02C583.475 1135.58 583.667 1136.3 584.227 1136.62C585.102 1137.13 586.222 1136.82 586.727 1135.95Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M518.227 971.904C518.548 971.347 518.359 970.632 517.801 970.31L517.503 970.138C516.79 969.727 515.879 969.971 515.467 970.684L515.298 970.977C514.975 971.537 515.167 972.253 515.727 972.577C516.602 973.082 517.722 972.779 518.227 971.904Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M527.086 961.035C527.408 960.478 527.218 959.763 526.661 959.441L526.362 959.269C525.65 958.857 524.738 959.102 524.327 959.814L524.158 960.107C523.834 960.668 524.026 961.384 524.586 961.708C525.461 962.213 526.581 961.91 527.086 961.035Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M532.781 988.177C533.103 987.62 532.913 986.905 532.356 986.583L532.058 986.411C531.345 985.999 530.434 986.243 530.022 986.956L529.853 987.249C529.529 987.809 529.721 988.526 530.282 988.85C531.156 989.354 532.276 989.051 532.781 988.177Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M367.449 785.986C367.771 785.429 367.581 784.714 367.024 784.392L366.726 784.22C366.013 783.809 365.102 784.053 364.69 784.766L364.521 785.059C364.197 785.619 364.389 786.335 364.95 786.659C365.824 787.164 366.944 786.861 367.449 785.986Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M561.262 979.8C561.584 979.243 561.394 978.528 560.837 978.206L560.538 978.034C559.825 977.622 558.914 977.866 558.503 978.579L558.333 978.872C558.01 979.432 558.202 980.149 558.762 980.472C559.637 980.977 560.757 980.674 561.262 979.8Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M558.918 940.597C559.24 940.039 559.05 939.325 558.493 939.003L558.194 938.831C557.482 938.419 556.57 938.663 556.159 939.376L555.99 939.669C555.666 940.229 555.858 940.946 556.418 941.269C557.293 941.774 558.413 941.471 558.918 940.597Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M398.961 777.07C398.639 777.628 398.829 778.342 399.386 778.664L399.685 778.836C400.397 779.248 401.309 779.004 401.72 778.291L401.889 777.998C402.213 777.438 402.021 776.721 401.46 776.398C400.586 775.893 399.466 776.196 398.961 777.07Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M592.773 970.884C592.452 971.441 592.641 972.156 593.199 972.478L593.497 972.65C594.21 973.061 595.121 972.817 595.533 972.105L595.702 971.812C596.025 971.251 595.833 970.535 595.273 970.211C594.398 969.706 593.278 970.009 592.773 970.884Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M390.101 787.939C389.78 788.497 389.969 789.212 390.527 789.533L390.825 789.706C391.538 790.117 392.449 789.873 392.861 789.16L393.03 788.867C393.353 788.307 393.161 787.59 392.601 787.267C391.727 786.762 390.606 787.065 390.101 787.939Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M583.914 981.754C583.592 982.311 583.782 983.026 584.339 983.348L584.638 983.52C585.35 983.932 586.262 983.687 586.673 982.975L586.842 982.682C587.166 982.121 586.974 981.405 586.414 981.081C585.539 980.576 584.419 980.879 583.914 981.754Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M384.402 760.797C384.08 761.354 384.27 762.069 384.828 762.391L385.126 762.563C385.839 762.975 386.75 762.73 387.161 762.018L387.331 761.725C387.654 761.164 387.462 760.448 386.902 760.124C386.027 759.619 384.907 759.922 384.402 760.797Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M578.219 954.611C577.897 955.169 578.087 955.883 578.644 956.205L578.942 956.378C579.655 956.789 580.566 956.545 580.978 955.832L581.147 955.539C581.471 954.979 581.279 954.262 580.718 953.939C579.844 953.434 578.724 953.737 578.219 954.611Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M549.734 962.988C549.412 963.546 549.602 964.26 550.16 964.582L550.458 964.754C551.171 965.166 552.082 964.922 552.494 964.209L552.663 963.916C552.986 963.356 552.794 962.639 552.234 962.316C551.359 961.811 550.239 962.114 549.734 962.988Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M552.082 1002.19C551.76 1002.75 551.95 1003.47 552.507 1003.79L552.806 1003.96C553.518 1004.37 554.43 1004.13 554.841 1003.41L555.01 1003.12C555.334 1002.56 555.142 1001.84 554.582 1001.52C553.707 1001.02 552.587 1001.32 552.082 1002.19Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M421.746 831.354C421.424 831.912 421.614 832.627 422.171 832.948L422.47 833.121C423.182 833.532 424.094 833.288 424.505 832.575L424.674 832.282C424.998 831.722 424.806 831.005 424.246 830.682C423.371 830.177 422.251 830.48 421.746 831.354Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M615.558 1025.17C615.237 1025.73 615.426 1026.44 615.984 1026.76L616.282 1026.93C616.995 1027.35 617.906 1027.1 618.318 1026.39L618.487 1026.1C618.81 1025.54 618.618 1024.82 618.058 1024.5C617.184 1023.99 616.063 1024.29 615.558 1025.17Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M412.887 842.222C412.565 842.779 412.755 843.494 413.312 843.816L413.61 843.988C414.323 844.399 415.234 844.155 415.646 843.442L415.815 843.149C416.139 842.589 415.947 841.873 415.386 841.549C414.512 841.044 413.392 841.347 412.887 842.222Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M606.699 1036.03C606.377 1036.59 606.567 1037.31 607.124 1037.63L607.423 1037.8C608.136 1038.21 609.047 1037.97 609.458 1037.26L609.628 1036.96C609.951 1036.4 609.759 1035.69 609.199 1035.36C608.324 1034.86 607.204 1035.16 606.699 1036.03Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M407.191 815.08C406.87 815.637 407.059 816.352 407.617 816.674L407.915 816.846C408.628 817.258 409.539 817.014 409.951 816.301L410.12 816.008C410.443 815.447 410.251 814.731 409.691 814.407C408.816 813.903 407.696 814.205 407.191 815.08Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M601.004 1008.89C600.682 1009.45 600.872 1010.17 601.429 1010.49L601.728 1010.66C602.44 1011.07 603.352 1010.83 603.763 1010.11L603.932 1009.82C604.256 1009.26 604.064 1008.54 603.503 1008.22C602.629 1007.72 601.509 1008.02 601.004 1008.89Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M378.707 823.459C378.385 824.016 378.575 824.731 379.132 825.053L379.431 825.225C380.143 825.637 381.055 825.392 381.466 824.68L381.635 824.387C381.959 823.826 381.767 823.11 381.207 822.786C380.332 822.281 379.212 822.584 378.707 823.459Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M572.523 1017.27C572.202 1017.83 572.391 1018.55 572.949 1018.87L573.247 1019.04C573.96 1019.45 574.871 1019.21 575.283 1018.49L575.452 1018.2C575.775 1017.64 575.583 1016.92 575.023 1016.6C574.148 1016.1 573.028 1016.4 572.523 1017.27Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M381.055 862.662C380.733 863.219 380.923 863.934 381.48 864.256L381.778 864.428C382.491 864.84 383.402 864.596 383.814 863.883L383.983 863.59C384.307 863.03 384.115 862.313 383.554 861.989C382.68 861.485 381.559 861.788 381.055 862.662Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M574.867 1056.48C574.545 1057.03 574.735 1057.75 575.292 1058.07L575.591 1058.24C576.304 1058.65 577.215 1058.41 577.626 1057.7L577.796 1057.4C578.119 1056.84 577.927 1056.13 577.367 1055.8C576.492 1055.3 575.372 1055.6 574.867 1056.48Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M541.008 1026.19C541.33 1025.63 541.14 1024.92 540.583 1024.6L540.284 1024.43C539.571 1024.01 538.66 1024.26 538.249 1024.97L538.079 1025.26C537.756 1025.82 537.948 1026.54 538.508 1026.86C539.383 1027.37 540.503 1027.07 541.008 1026.19Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M549.871 1015.32C550.193 1014.76 550.003 1014.05 549.446 1013.73L549.147 1013.55C548.435 1013.14 547.523 1013.39 547.112 1014.1L546.943 1014.39C546.619 1014.95 546.811 1015.67 547.372 1015.99C548.246 1016.5 549.366 1016.19 549.871 1015.32Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M555.567 1042.46C555.888 1041.9 555.699 1041.19 555.141 1040.87L554.843 1040.7C554.13 1040.28 553.219 1040.53 552.807 1041.24L552.638 1041.53C552.315 1042.09 552.507 1042.81 553.067 1043.13C553.941 1043.64 555.062 1043.34 555.567 1042.46Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M390.234 840.271C390.556 839.713 390.367 838.998 389.809 838.677L389.511 838.504C388.798 838.093 387.887 838.337 387.475 839.05L387.306 839.343C386.983 839.903 387.175 840.62 387.735 840.943C388.609 841.448 389.73 841.145 390.234 840.271Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M584.047 1034.08C584.369 1033.53 584.179 1032.81 583.622 1032.49L583.323 1032.32C582.611 1031.91 581.699 1032.15 581.288 1032.86L581.119 1033.16C580.795 1033.72 580.987 1034.43 581.547 1034.76C582.422 1035.26 583.542 1034.96 584.047 1034.08Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M387.887 801.065C388.209 800.507 388.019 799.792 387.462 799.471L387.163 799.298C386.45 798.887 385.539 799.131 385.128 799.844L384.958 800.137C384.635 800.697 384.827 801.414 385.387 801.737C386.262 802.242 387.382 801.939 387.887 801.065Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M581.699 994.878C582.021 994.321 581.831 993.606 581.274 993.284L580.976 993.112C580.263 992.7 579.352 992.944 578.94 993.657L578.771 993.95C578.447 994.511 578.639 995.227 579.2 995.551C580.074 996.055 581.194 995.753 581.699 994.878Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M445.512 1009.1C445.834 1008.54 445.644 1007.83 445.087 1007.51L444.788 1007.33C444.075 1006.92 443.164 1007.17 442.753 1007.88L442.583 1008.17C442.26 1008.73 442.452 1009.45 443.012 1009.77C443.887 1010.28 445.007 1009.98 445.512 1009.1Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M454.371 998.232C454.693 997.674 454.503 996.959 453.946 996.638L453.647 996.465C452.935 996.054 452.023 996.298 451.612 997.011L451.443 997.304C451.119 997.864 451.311 998.581 451.872 998.904C452.746 999.409 453.866 999.106 454.371 998.232Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M460.067 1025.37C460.388 1024.81 460.199 1024.1 459.641 1023.78L459.343 1023.6C458.63 1023.19 457.719 1023.44 457.307 1024.15L457.138 1024.44C456.815 1025 457.007 1025.72 457.567 1026.04C458.441 1026.55 459.562 1026.25 460.067 1025.37Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M488.551 1016.99C488.873 1016.44 488.683 1015.72 488.126 1015.4L487.827 1015.23C487.114 1014.82 486.203 1015.06 485.792 1015.77L485.622 1016.07C485.299 1016.63 485.491 1017.34 486.051 1017.67C486.926 1018.17 488.046 1017.87 488.551 1016.99Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M486.203 977.789C486.525 977.232 486.335 976.517 485.778 976.195L485.48 976.023C484.767 975.611 483.855 975.856 483.444 976.568L483.275 976.861C482.951 977.422 483.143 978.138 483.704 978.462C484.578 978.967 485.698 978.664 486.203 977.789Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M520.058 1008.08C519.737 1008.64 519.926 1009.35 520.484 1009.67L520.782 1009.84C521.495 1010.26 522.406 1010.01 522.818 1009.3L522.987 1009.01C523.31 1008.45 523.118 1007.73 522.558 1007.41C521.684 1006.9 520.563 1007.2 520.058 1008.08Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M511.199 1018.95C510.877 1019.5 511.067 1020.22 511.624 1020.54L511.923 1020.71C512.636 1021.12 513.547 1020.88 513.958 1020.17L514.128 1019.88C514.451 1019.31 514.259 1018.6 513.699 1018.27C512.824 1017.77 511.704 1018.07 511.199 1018.95Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M505.504 991.807C505.182 992.364 505.372 993.079 505.929 993.401L506.228 993.573C506.94 993.984 507.852 993.74 508.263 993.028L508.432 992.735C508.756 992.174 508.564 991.458 508.003 991.134C507.129 990.629 506.009 990.932 505.504 991.807Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M477.023 1000.18C476.702 1000.74 476.891 1001.45 477.449 1001.78L477.747 1001.95C478.46 1002.36 479.371 1002.12 479.783 1001.4L479.952 1001.11C480.275 1000.55 480.083 999.834 479.523 999.51C478.648 999.005 477.528 999.308 477.023 1000.18Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M479.367 1039.39C479.045 1039.94 479.235 1040.66 479.792 1040.98L480.091 1041.15C480.804 1041.56 481.715 1041.32 482.126 1040.61L482.296 1040.31C482.619 1039.75 482.427 1039.04 481.867 1038.71C480.992 1038.21 479.872 1038.51 479.367 1039.39Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M542.848 1062.36C542.526 1062.92 542.715 1063.63 543.273 1063.95L543.571 1064.13C544.284 1064.54 545.195 1064.29 545.607 1063.58L545.776 1063.29C546.099 1062.73 545.907 1062.01 545.347 1061.69C544.473 1061.18 543.352 1061.48 542.848 1062.36Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M533.984 1073.23C533.662 1073.79 533.852 1074.5 534.41 1074.83L534.708 1075C535.421 1075.41 536.332 1075.16 536.744 1074.45L536.913 1074.16C537.236 1073.6 537.044 1072.88 536.484 1072.56C535.609 1072.05 534.489 1072.36 533.984 1073.23Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M528.289 1046.09C527.967 1046.65 528.157 1047.36 528.714 1047.68L529.013 1047.86C529.725 1048.27 530.637 1048.02 531.048 1047.31L531.217 1047.02C531.541 1046.46 531.349 1045.74 530.789 1045.42C529.914 1044.91 528.794 1045.21 528.289 1046.09Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M499.808 1054.47C499.487 1055.02 499.676 1055.74 500.234 1056.06L500.532 1056.23C501.245 1056.64 502.156 1056.4 502.568 1055.69L502.737 1055.39C503.06 1054.83 502.868 1054.12 502.308 1053.79C501.434 1053.29 500.313 1053.59 499.808 1054.47Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M502.152 1093.67C501.83 1094.23 502.02 1094.94 502.578 1095.27L502.876 1095.44C503.589 1095.85 504.5 1095.61 504.911 1094.89L505.081 1094.6C505.404 1094.04 505.212 1093.32 504.652 1093C503.777 1092.49 502.657 1092.8 502.152 1093.67Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M468.297 1063.38C468.619 1062.83 468.429 1062.11 467.872 1061.79L467.573 1061.62C466.861 1061.21 465.949 1061.45 465.538 1062.16L465.369 1062.46C465.045 1063.02 465.237 1063.73 465.797 1064.06C466.672 1064.56 467.792 1064.26 468.297 1063.38Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M477.156 1052.51C477.478 1051.96 477.288 1051.24 476.731 1050.92L476.433 1050.75C475.72 1050.34 474.809 1050.58 474.397 1051.29L474.228 1051.58C473.904 1052.15 474.096 1052.86 474.657 1053.19C475.531 1053.69 476.651 1053.39 477.156 1052.51Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M482.852 1079.66C483.173 1079.1 482.984 1078.38 482.426 1078.06L482.128 1077.89C481.415 1077.48 480.504 1077.72 480.092 1078.43L479.923 1078.73C479.6 1079.29 479.792 1080 480.352 1080.33C481.227 1080.83 482.347 1080.53 482.852 1079.66Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M511.336 1071.28C511.658 1070.72 511.468 1070.01 510.911 1069.68L510.612 1069.51C509.9 1069.1 508.988 1069.34 508.577 1070.06L508.408 1070.35C508.084 1070.91 508.276 1071.63 508.836 1071.95C509.711 1072.45 510.831 1072.15 511.336 1071.28Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M508.988 1032.07C509.31 1031.52 509.12 1030.8 508.563 1030.48L508.265 1030.31C507.552 1029.9 506.641 1030.14 506.229 1030.85L506.06 1031.15C505.736 1031.71 505.928 1032.42 506.489 1032.75C507.363 1033.25 508.483 1032.95 508.988 1032.07Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M712.527 730.947C712.849 730.389 712.66 729.675 712.102 729.353L711.804 729.18C711.091 728.769 710.18 729.013 709.768 729.726L709.599 730.019C709.276 730.579 709.468 731.296 710.028 731.619C710.902 732.124 712.023 731.821 712.527 730.947Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M445.176 1149.5C445.498 1148.94 445.308 1148.23 444.751 1147.91L444.452 1147.73C443.739 1147.32 442.828 1147.57 442.417 1148.28L442.247 1148.57C441.924 1149.13 442.116 1149.85 442.676 1150.17C443.551 1150.68 444.671 1150.38 445.176 1149.5Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M721.391 720.076C721.713 719.518 721.523 718.803 720.965 718.482L720.667 718.309C719.954 717.898 719.043 718.142 718.631 718.855L718.462 719.148C718.139 719.708 718.331 720.425 718.891 720.748C719.766 721.253 720.886 720.95 721.391 720.076Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M454.035 1138.63C454.357 1138.07 454.167 1137.36 453.61 1137.04L453.312 1136.86C452.599 1136.45 451.687 1136.7 451.276 1137.41L451.107 1137.7C450.783 1138.26 450.975 1138.98 451.536 1139.3C452.41 1139.81 453.53 1139.51 454.035 1138.63Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M727.086 747.216C727.408 746.659 727.218 745.944 726.661 745.622L726.362 745.45C725.65 745.039 724.738 745.283 724.327 745.995L724.158 746.288C723.834 746.849 724.026 747.565 724.586 747.889C725.461 748.394 726.581 748.091 727.086 747.216Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M755.567 738.839C755.888 738.282 755.699 737.567 755.141 737.245L754.843 737.073C754.13 736.662 753.219 736.906 752.807 737.618L752.638 737.911C752.315 738.472 752.507 739.188 753.067 739.512C753.941 740.017 755.062 739.714 755.567 738.839Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M488.211 1157.39C488.533 1156.84 488.343 1156.12 487.786 1155.8L487.487 1155.63C486.775 1155.22 485.863 1155.46 485.452 1156.17L485.283 1156.47C484.959 1157.03 485.151 1157.74 485.711 1158.07C486.586 1158.57 487.706 1158.27 488.211 1157.39Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M485.867 1118.19C486.189 1117.63 485.999 1116.92 485.442 1116.6L485.144 1116.42C484.431 1116.01 483.52 1116.26 483.108 1116.97L482.939 1117.26C482.615 1117.82 482.807 1118.54 483.368 1118.86C484.242 1119.37 485.362 1119.07 485.867 1118.19Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M519.726 1148.48C519.405 1149.03 519.594 1149.75 520.152 1150.07L520.45 1150.24C521.163 1150.66 522.074 1150.41 522.486 1149.7L522.655 1149.41C522.978 1148.85 522.786 1148.13 522.226 1147.8C521.352 1147.3 520.231 1147.6 519.726 1148.48Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M510.867 1159.35C510.545 1159.9 510.735 1160.62 511.292 1160.94L511.591 1161.11C512.304 1161.52 513.215 1161.28 513.626 1160.57L513.796 1160.27C514.119 1159.71 513.927 1159 513.367 1158.67C512.492 1158.17 511.372 1158.47 510.867 1159.35Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M505.168 1132.21C504.846 1132.76 505.036 1133.48 505.593 1133.8L505.892 1133.97C506.604 1134.38 507.516 1134.14 507.927 1133.43L508.096 1133.13C508.42 1132.57 508.228 1131.86 507.667 1131.53C506.793 1131.03 505.673 1131.33 505.168 1132.21Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M744.043 722.029C743.721 722.586 743.911 723.301 744.468 723.623L744.767 723.795C745.479 724.206 746.391 723.962 746.802 723.249L746.971 722.956C747.295 722.396 747.103 721.68 746.542 721.356C745.668 720.851 744.548 721.154 744.043 722.029Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M476.687 1140.58C476.366 1141.14 476.555 1141.85 477.113 1142.18L477.411 1142.35C478.124 1142.76 479.035 1142.52 479.447 1141.8L479.616 1141.51C479.939 1140.95 479.747 1140.23 479.187 1139.91C478.312 1139.4 477.192 1139.71 476.687 1140.58Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M746.387 761.231C746.065 761.788 746.255 762.503 746.812 762.825L747.11 762.997C747.823 763.408 748.734 763.164 749.146 762.452L749.315 762.159C749.639 761.598 749.447 760.882 748.886 760.558C748.012 760.053 746.892 760.356 746.387 761.231Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M766.828 776.311C766.506 776.868 766.696 777.583 767.253 777.905L767.552 778.077C768.264 778.489 769.176 778.244 769.587 777.532L769.756 777.239C770.08 776.678 769.888 775.962 769.328 775.638C768.453 775.133 767.333 775.436 766.828 776.311Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M735.313 785.228C735.634 784.671 735.445 783.956 734.887 783.634L734.589 783.462C733.876 783.05 732.965 783.294 732.553 784.007L732.384 784.3C732.061 784.861 732.253 785.577 732.813 785.901C733.688 786.405 734.808 786.102 735.313 785.228Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M744.176 774.359C744.498 773.801 744.308 773.087 743.751 772.765L743.452 772.593C742.739 772.181 741.828 772.425 741.417 773.138L741.247 773.431C740.924 773.991 741.116 774.708 741.676 775.031C742.551 775.536 743.671 775.233 744.176 774.359Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M749.871 801.499C750.193 800.942 750.003 800.227 749.446 799.906L749.147 799.733C748.435 799.322 747.523 799.566 747.112 800.279L746.943 800.572C746.619 801.132 746.811 801.848 747.372 802.172C748.246 802.677 749.366 802.374 749.871 801.499Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M639.817 768.139C640.138 767.582 639.949 766.867 639.391 766.545L639.093 766.373C638.38 765.961 637.469 766.206 637.057 766.918L636.888 767.211C636.565 767.772 636.757 768.488 637.317 768.812C638.191 769.317 639.312 769.014 639.817 768.139Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M648.676 757.27C648.998 756.713 648.808 755.998 648.251 755.676L647.952 755.504C647.239 755.092 646.328 755.336 645.917 756.049L645.747 756.342C645.424 756.902 645.616 757.619 646.176 757.943C647.051 758.447 648.171 758.144 648.676 757.27Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M654.371 784.412C654.693 783.854 654.503 783.139 653.946 782.818L653.647 782.645C652.935 782.234 652.023 782.478 651.612 783.191L651.443 783.484C651.119 784.044 651.311 784.761 651.872 785.084C652.746 785.589 653.866 785.286 654.371 784.412Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M682.856 776.035C683.177 775.477 682.988 774.762 682.43 774.441L682.132 774.268C681.419 773.857 680.508 774.101 680.096 774.814L679.927 775.107C679.604 775.667 679.796 776.384 680.356 776.707C681.231 777.212 682.351 776.909 682.856 776.035Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M680.508 736.831C680.83 736.273 680.64 735.558 680.083 735.237L679.784 735.064C679.071 734.653 678.16 734.897 677.749 735.61L677.579 735.903C677.256 736.463 677.448 737.18 678.008 737.503C678.883 738.008 680.003 737.705 680.508 736.831Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M413.156 1155.38C413.478 1154.83 413.288 1154.11 412.731 1153.79L412.433 1153.62C411.72 1153.21 410.809 1153.45 410.397 1154.16L410.228 1154.46C409.904 1155.02 410.096 1155.73 410.657 1156.06C411.531 1156.56 412.651 1156.26 413.156 1155.38Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M714.367 767.116C714.045 767.673 714.235 768.388 714.792 768.709L715.091 768.882C715.804 769.293 716.715 769.049 717.126 768.336L717.296 768.043C717.619 767.483 717.427 766.766 716.867 766.443C715.992 765.938 714.872 766.241 714.367 767.116Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M705.504 777.987C705.182 778.544 705.372 779.259 705.929 779.581L706.228 779.753C706.94 780.164 707.852 779.92 708.263 779.207L708.432 778.914C708.756 778.354 708.564 777.638 708.003 777.314C707.129 776.809 706.009 777.112 705.504 777.987Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M699.808 750.844C699.487 751.401 699.676 752.116 700.234 752.438L700.532 752.61C701.245 753.022 702.156 752.778 702.568 752.065L702.737 751.772C703.06 751.212 702.868 750.495 702.308 750.171C701.434 749.667 700.313 749.97 699.808 750.844Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M671.328 759.22C671.006 759.777 671.196 760.492 671.753 760.814L672.052 760.986C672.764 761.398 673.676 761.154 674.087 760.441L674.256 760.148C674.58 759.587 674.388 758.871 673.828 758.547C672.953 758.043 671.833 758.346 671.328 759.22Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M673.672 798.428C673.35 798.985 673.54 799.7 674.097 800.022L674.395 800.194C675.108 800.606 676.02 800.362 676.431 799.649L676.6 799.356C676.924 798.796 676.732 798.079 676.171 797.755C675.297 797.251 674.177 797.554 673.672 798.428Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M737.148 821.402C736.827 821.959 737.016 822.674 737.574 822.996L737.872 823.168C738.585 823.579 739.496 823.335 739.908 822.622L740.077 822.329C740.4 821.769 740.208 821.053 739.648 820.729C738.773 820.224 737.653 820.527 737.148 821.402Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M728.293 832.269C727.971 832.826 728.161 833.541 728.718 833.863L729.017 834.035C729.729 834.447 730.641 834.202 731.052 833.49L731.221 833.197C731.545 832.636 731.353 831.92 730.792 831.596C729.918 831.091 728.798 831.394 728.293 832.269Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M722.598 805.127C722.276 805.685 722.465 806.399 723.023 806.721L723.321 806.893C724.034 807.305 724.945 807.061 725.357 806.348L725.526 806.055C725.849 805.495 725.657 804.778 725.097 804.455C724.223 803.95 723.102 804.253 722.598 805.127Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M694.113 813.506C693.791 814.064 693.981 814.778 694.538 815.1L694.837 815.272C695.55 815.684 696.461 815.44 696.872 814.727L697.042 814.434C697.365 813.874 697.173 813.157 696.613 812.834C695.738 812.329 694.618 812.632 694.113 813.506Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M696.461 852.71C696.139 853.268 696.329 853.982 696.886 854.304L697.185 854.477C697.897 854.888 698.809 854.644 699.22 853.931L699.389 853.638C699.713 853.078 699.521 852.361 698.96 852.038C698.086 851.533 696.966 851.836 696.461 852.71Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M662.602 822.422C662.923 821.865 662.734 821.15 662.176 820.828L661.878 820.656C661.165 820.245 660.254 820.489 659.842 821.201L659.673 821.494C659.35 822.055 659.542 822.771 660.102 823.095C660.977 823.6 662.097 823.297 662.602 822.422Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M671.461 811.553C671.783 810.996 671.593 810.281 671.036 809.959L670.737 809.787C670.025 809.375 669.113 809.62 668.702 810.332L668.533 810.625C668.209 811.186 668.401 811.902 668.961 812.226C669.836 812.731 670.956 812.428 671.461 811.553Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M677.156 838.695C677.478 838.137 677.288 837.423 676.731 837.101L676.433 836.929C675.72 836.517 674.809 836.761 674.397 837.474L674.228 837.767C673.904 838.327 674.096 839.044 674.657 839.367C675.531 839.872 676.651 839.569 677.156 838.695Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M705.637 830.317C705.959 829.759 705.769 829.045 705.212 828.723L704.913 828.551C704.2 828.139 703.289 828.383 702.878 829.096L702.708 829.389C702.385 829.949 702.577 830.666 703.137 830.989C704.012 831.494 705.132 831.191 705.637 830.317Z" fill="#FF4A1C" fill-opacity="0.5"/>
    <path d="M703.293 791.113C703.615 790.555 703.425 789.841 702.868 789.519L702.569 789.346C701.857 788.935 700.945 789.179 700.534 789.892L700.365 790.185C700.041 790.745 700.233 791.462 700.793 791.785C701.668 792.29 702.788 791.987 703.293 791.113Z" fill="#FF4A1C" fill-opacity="0.5"/>
    </g>
    <path d="M1392.22 93.3079C1584.14 41.8828 1782.14 155.583 1833.56 347.503L1861.1 450.264C1926.86 695.68 1781.22 947.938 1535.8 1013.7L1434.9 1040.73C1241.95 1092.43 1043.62 977.927 991.922 784.976C911.232 483.837 1091.08 173.998 1392.22 93.3079Z" fill="#FF4A1C" fill-opacity="0.08"/>
    <path d="M1510.43 373.511C1626.66 342.366 1746.58 411.226 1777.72 527.46L1794.4 589.696C1834.22 738.329 1746.02 891.105 1597.38 930.931L1536.28 947.304C1419.42 978.617 1299.31 909.268 1267.99 792.409C1219.12 610.029 1328.05 422.379 1510.43 373.511Z" fill="url(#paint1_linear_1088_25263)"/>
    <mask id="mask1_1088_25263" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="1257" y="366" width="548" height="589">
    <path d="M1511.28 373.511C1627.52 342.366 1747.43 411.226 1778.58 527.46L1795.25 589.696C1835.08 738.329 1746.87 891.105 1598.24 930.931L1537.13 947.304C1420.28 978.617 1300.16 909.268 1268.85 792.409C1219.98 610.029 1328.9 422.379 1511.28 373.511Z" fill="url(#paint2_linear_1088_25263)"/>
    </mask>
    <g mask="url(#mask1_1088_25263)">
    <rect x="1221" y="365" width="888" height="591" fill="url(#pattern0)"/>
    <rect x="1221" y="365" width="888" height="591" fill="url(#paint3_linear_1088_25263)" style={{ mixBlendMode: 'overlay' }}/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1504.43 334.974C1626.52 302.261 1752.47 374.589 1785.19 496.677L1802.7 562.046C1844.53 718.164 1751.89 878.633 1595.77 920.465L1531.59 937.662C1408.84 970.551 1282.68 897.71 1249.79 774.967C1198.46 583.403 1312.87 386.304 1504.43 334.974ZM1475.57 938.743C1493.83 938.779 1512.39 936.433 1530.87 931.482L1589.89 915.668C1733.45 877.202 1818.64 729.643 1780.17 586.086L1764.07 525.976C1738.84 431.835 1653.33 369.869 1560.18 370.053C1541.98 370.017 1523.48 372.352 1505.08 377.283C1328.93 424.483 1223.72 605.724 1270.92 781.876C1296.29 876.541 1381.99 938.927 1475.57 938.743Z" fill="url(#paint4_linear_1088_25263)"/>
    <path d="M135.47 1077.95C60.652 1003.13 60.6519 881.828 135.47 807.01L172.903 769.577C262.139 680.34 406.821 680.34 496.057 769.577L521.206 794.725C602.808 876.328 602.808 1008.63 521.206 1090.23L-nan -nanL521.206 1090.23C418.08 1193.36 250.88 1193.36 147.754 1090.23L135.47 1077.95Z" fill="url(#paint5_linear_1088_25263)" fill-opacity="0.08"/>
    <defs>
    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
    <use xlinkHref="#image0_1088_25263" transform="matrix(0.001 0 0 0.00150254 0 -0.0228832)"/>
    </pattern>
    <linearGradient id="paint0_linear_1088_25263" x1="661.728" y1="755.312" x2="453.522" y2="1115.94" gradientUnits="userSpaceOnUse">
    <stop stop-color="#9055A2"/>
    <stop offset="1" stop-color="#FF4A1C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1088_25263" x1="1793.92" y1="587.91" x2="1252.1" y2="733.09" gradientUnits="userSpaceOnUse">
    <stop stop-color="#9055A2"/>
    <stop offset="1" stop-color="#FF4A1C"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1088_25263" x1="1794.77" y1="587.91" x2="1252.95" y2="733.09" gradientUnits="userSpaceOnUse">
    <stop stop-color="#9055A2"/>
    <stop offset="1" stop-color="#FF4A1C"/>
    </linearGradient>
    <linearGradient id="paint3_linear_1088_25263" x1="1665" y1="365" x2="1665" y2="956" gradientUnits="userSpaceOnUse">
    <stop stop-color="#052247"/>
    <stop offset="0.458332" stop-color="#9055A2"/>
    </linearGradient>
    <linearGradient id="paint4_linear_1088_25263" x1="1663.5" y1="343" x2="1312" y2="939" gradientUnits="userSpaceOnUse">
    <stop stop-color="#052247"/>
    <stop offset="0.588545" stop-color="#9055A2"/>
    <stop offset="0.963945" stop-color="#F54B28"/>
    <stop offset="1" stop-color="#FF4A1C"/>
    </linearGradient>
    <linearGradient id="paint5_linear_1088_25263" x1="236.154" y1="729.336" x2="396.23" y2="1177.12" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00001F"/>
    <stop offset="0.5" stop-color="#9055A2"/>
    <stop offset="1" stop-color="#FF4A1C"/>
    </linearGradient>
    <image id="image0_1088_25263" width="1000" height="696" x="-140" y="0" xlinkHref={imageSrc}/>
    </defs>
    </svg>
    

  );
}

export default CaseStudiesSec2Bg;
