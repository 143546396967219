import React from 'react';

const CultureSec5LeftBg = ({ imageSrc }) => {
    return (
        <svg viewBox="0 0 549 561" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="CultureSec5LeftBg">
        <mask id="mask0_1209_8804" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="549" height="561">
        <rect id="Rectangle 1117" width="549" height="561" fill="black"/>
        </mask>
        <g mask="url(#mask0_1209_8804)">
        <g id="Group 197">
        <g id="Mask group">
        <mask id="mask1_1209_8804" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="78" y="136" width="439" height="407">
        <path id="Rectangle 1044" d="M395.773 145.161C482.061 168.282 533.594 257.062 510.473 343.349L498.094 389.55C468.529 499.889 355.114 565.369 244.775 535.804L199.413 523.649C112.662 500.405 61.1805 411.236 84.4253 324.485C120.703 189.093 260.382 108.883 395.773 145.161Z" fill="url(#paint0_linear_1209_8804)"/>
        </mask>
        <g mask="url(#mask1_1209_8804)">
        <g id="Group 100">
        <path id="Rectangle 564" d="M420.833 247.739C420.211 247.572 419.57 247.94 419.403 248.562L419.314 248.895C419.101 249.69 419.573 250.507 420.368 250.72L420.695 250.808C421.32 250.975 421.962 250.604 422.13 249.979C422.391 249.004 421.808 248 420.833 247.739Z" fill="#9055A2"/>
        <path id="Rectangle 565" d="M283.786 485.112C283.165 484.945 282.523 485.314 282.357 485.935L282.267 486.268C282.054 487.063 282.526 487.88 283.321 488.093L283.648 488.181C284.273 488.348 284.915 487.977 285.083 487.352C285.344 486.377 284.762 485.373 283.786 485.112Z" fill="#9055A2"/>
        <path id="Rectangle 566" d="M408.04 241.993C407.418 241.826 406.777 242.194 406.611 242.816L406.521 243.149C406.308 243.944 406.78 244.761 407.575 244.974L407.902 245.061C408.527 245.229 409.169 244.858 409.337 244.233C409.598 243.258 409.015 242.254 408.04 241.993Z" fill="#9055A2"/>
        <path id="Rectangle 567" d="M270.992 479.366C270.371 479.2 269.729 479.568 269.563 480.19L269.473 480.522C269.26 481.317 269.732 482.134 270.527 482.347L270.854 482.435C271.479 482.602 272.121 482.232 272.289 481.607C272.55 480.631 271.968 479.628 270.992 479.366Z" fill="#9055A2"/>
        <path id="Rectangle 568" d="M432.782 229.467C432.161 229.3 431.519 229.668 431.353 230.29L431.264 230.623C431.051 231.418 431.522 232.235 432.317 232.448L432.644 232.536C433.269 232.703 433.911 232.332 434.079 231.707C434.34 230.732 433.758 229.728 432.782 229.467Z" fill="#9055A2"/>
        <path id="Rectangle 569" d="M295.735 466.838C295.114 466.671 294.472 467.04 294.306 467.661L294.217 467.994C294.004 468.789 294.475 469.606 295.27 469.819L295.597 469.907C296.222 470.074 296.864 469.703 297.032 469.078C297.293 468.103 296.711 467.099 295.735 466.838Z" fill="#9055A2"/>
        <path id="Rectangle 570" d="M417.319 204.123C416.698 203.956 416.056 204.325 415.89 204.946L415.801 205.279C415.588 206.074 416.059 206.891 416.854 207.104L417.181 207.192C417.806 207.359 418.448 206.988 418.616 206.363C418.877 205.388 418.295 204.384 417.319 204.123Z" fill="#9055A2"/>
        <path id="Rectangle 571" d="M280.271 441.497C279.649 441.33 279.008 441.698 278.841 442.32L278.752 442.653C278.539 443.448 279.011 444.265 279.805 444.478L280.132 444.565C280.757 444.733 281.4 444.362 281.567 443.737C281.828 442.762 281.246 441.758 280.271 441.497Z" fill="#9055A2"/>
        <path id="Rectangle 572" d="M380.057 216.536C379.435 216.37 378.794 216.738 378.627 217.359L378.538 217.692C378.325 218.487 378.797 219.304 379.592 219.517L379.918 219.605C380.543 219.772 381.186 219.401 381.353 218.776C381.615 217.801 381.032 216.797 380.057 216.536Z" fill="#9055A2"/>
        <path id="Rectangle 573" d="M243.009 453.907C242.387 453.741 241.746 454.109 241.579 454.731L241.49 455.063C241.277 455.858 241.749 456.675 242.544 456.888L242.871 456.976C243.496 457.143 244.138 456.773 244.305 456.148C244.567 455.172 243.984 454.169 243.009 453.907Z" fill="#9055A2"/>
        <path id="Rectangle 574" d="M400.551 175.995C401.172 176.162 401.814 175.793 401.98 175.172L402.069 174.839C402.282 174.044 401.811 173.227 401.016 173.014L400.689 172.926C400.064 172.759 399.422 173.13 399.254 173.755C398.993 174.73 399.575 175.734 400.551 175.995Z" fill="#9055A2"/>
        <path id="Rectangle 575" d="M263.503 413.367C264.125 413.533 264.766 413.165 264.932 412.543L265.022 412.211C265.235 411.416 264.763 410.598 263.968 410.385L263.641 410.298C263.016 410.13 262.374 410.501 262.206 411.126C261.945 412.102 262.528 413.105 263.503 413.367Z" fill="#9055A2"/>
        <path id="Rectangle 576" d="M413.341 181.738C413.962 181.904 414.604 181.536 414.77 180.914L414.86 180.582C415.073 179.787 414.601 178.97 413.806 178.757L413.479 178.669C412.854 178.502 412.212 178.872 412.044 179.497C411.783 180.473 412.365 181.476 413.341 181.738Z" fill="#9055A2"/>
        <path id="Rectangle 577" d="M276.293 419.111C276.915 419.278 277.556 418.91 277.722 418.288L277.812 417.955C278.025 417.16 277.553 416.343 276.758 416.13L276.431 416.043C275.806 415.875 275.164 416.246 274.996 416.871C274.735 417.846 275.318 418.85 276.293 419.111Z" fill="#9055A2"/>
        <path id="Rectangle 578" d="M388.598 194.266C389.219 194.433 389.861 194.064 390.027 193.443L390.116 193.11C390.329 192.315 389.858 191.498 389.063 191.285L388.736 191.197C388.111 191.03 387.469 191.401 387.301 192.026C387.04 193.001 387.622 194.005 388.598 194.266Z" fill="#9055A2"/>
        <path id="Rectangle 579" d="M251.55 431.637C252.171 431.804 252.813 431.436 252.979 430.814L253.069 430.481C253.282 429.686 252.81 428.869 252.015 428.656L251.688 428.568C251.063 428.401 250.421 428.772 250.253 429.397C249.992 430.372 250.574 431.376 251.55 431.637Z" fill="#9055A2"/>
        <path id="Rectangle 580" d="M404.063 219.609C404.685 219.776 405.326 219.408 405.493 218.786L405.582 218.453C405.795 217.658 405.323 216.841 404.529 216.628L404.202 216.541C403.577 216.373 402.934 216.744 402.767 217.369C402.506 218.344 403.088 219.348 404.063 219.609Z" fill="#9055A2"/>
        <path id="Rectangle 581" d="M267.016 456.982C267.637 457.149 268.279 456.781 268.445 456.159L268.534 455.826C268.747 455.031 268.276 454.214 267.481 454.001L267.154 453.914C266.529 453.746 265.886 454.117 265.719 454.742C265.458 455.717 266.04 456.721 267.016 456.982Z" fill="#9055A2"/>
        <path id="Rectangle 582" d="M441.324 207.195C441.946 207.361 442.587 206.993 442.754 206.371L442.843 206.039C443.056 205.244 442.584 204.427 441.789 204.214L441.462 204.126C440.837 203.959 440.195 204.329 440.028 204.954C439.766 205.93 440.349 206.933 441.324 207.195Z" fill="#9055A2"/>
        <path id="Rectangle 583" d="M304.276 444.568C304.898 444.734 305.539 444.366 305.706 443.745L305.795 443.412C306.008 442.617 305.536 441.8 304.741 441.587L304.415 441.499C303.79 441.332 303.147 441.703 302.98 442.328C302.718 443.303 303.301 444.307 304.276 444.568Z" fill="#9055A2"/>
        <path id="Rectangle 584" d="M447.086 139.935C447.708 140.102 448.349 139.733 448.515 139.112L448.605 138.779C448.818 137.984 448.346 137.167 447.551 136.954L447.224 136.866C446.599 136.699 445.957 137.07 445.789 137.695C445.528 138.67 446.111 139.674 447.086 139.935Z" fill="#9055A2"/>
        <path id="Rectangle 585" d="M310.039 377.308C310.661 377.475 311.302 377.106 311.469 376.485L311.558 376.152C311.771 375.357 311.299 374.54 310.504 374.327L310.177 374.239C309.552 374.072 308.91 374.443 308.742 375.068C308.481 376.043 309.064 377.047 310.039 377.308Z" fill="#9055A2"/>
        <path id="Rectangle 586" d="M459.877 145.68C460.499 145.846 461.14 145.478 461.306 144.856L461.396 144.523C461.609 143.729 461.137 142.911 460.342 142.698L460.015 142.611C459.39 142.443 458.748 142.814 458.58 143.439C458.319 144.415 458.902 145.418 459.877 145.68Z" fill="#9055A2"/>
        <path id="Rectangle 587" d="M322.831 383.053C323.453 383.219 324.094 382.851 324.261 382.229L324.35 381.897C324.563 381.102 324.091 380.285 323.296 380.072L322.969 379.984C322.344 379.817 321.702 380.187 321.534 380.812C321.273 381.788 321.856 382.791 322.831 383.053Z" fill="#9055A2"/>
        <path id="Rectangle 588" d="M435.137 158.207C435.758 158.374 436.4 158.005 436.566 157.384L436.655 157.051C436.868 156.256 436.397 155.439 435.602 155.226L435.275 155.138C434.65 154.971 434.008 155.342 433.84 155.967C433.579 156.942 434.161 157.946 435.137 158.207Z" fill="#9055A2"/>
        <path id="Rectangle 589" d="M298.09 395.58C298.711 395.747 299.353 395.378 299.519 394.757L299.609 394.424C299.822 393.629 299.35 392.812 298.555 392.599L298.228 392.511C297.603 392.344 296.961 392.715 296.793 393.34C296.532 394.315 297.114 395.319 298.09 395.58Z" fill="#9055A2"/>
        <path id="Rectangle 590" d="M450.599 183.551C451.22 183.718 451.862 183.35 452.028 182.728L452.117 182.395C452.33 181.6 451.859 180.783 451.064 180.57L450.737 180.483C450.112 180.315 449.469 180.686 449.302 181.311C449.041 182.286 449.623 183.29 450.599 183.551Z" fill="#9055A2"/>
        <path id="Rectangle 591" d="M313.552 420.922C314.173 421.089 314.815 420.721 314.981 420.099L315.07 419.766C315.283 418.971 314.812 418.154 314.017 417.941L313.69 417.854C313.065 417.686 312.423 418.057 312.255 418.682C311.994 419.657 312.576 420.661 313.552 420.922Z" fill="#9055A2"/>
        <path id="Rectangle 592" d="M487.861 171.136C488.483 171.303 489.124 170.935 489.291 170.313L489.38 169.98C489.593 169.185 489.121 168.368 488.326 168.155L488 168.067C487.375 167.9 486.732 168.271 486.565 168.896C486.303 169.871 486.886 170.875 487.861 171.136Z" fill="#9055A2"/>
        <path id="Rectangle 593" d="M350.813 408.51C351.435 408.676 352.076 408.308 352.243 407.686L352.332 407.354C352.545 406.559 352.073 405.742 351.279 405.529L350.952 405.441C350.327 405.274 349.684 405.644 349.517 406.269C349.256 407.245 349.838 408.248 350.813 408.51Z" fill="#9055A2"/>
        <path id="Rectangle 594" d="M467.368 211.681C466.747 211.514 466.105 211.882 465.939 212.504L465.849 212.837C465.636 213.632 466.108 214.449 466.903 214.662L467.23 214.749C467.855 214.917 468.497 214.546 468.665 213.921C468.926 212.946 468.344 211.942 467.368 211.681Z" fill="#9055A2"/>
        <path id="Rectangle 595" d="M330.321 449.054C329.7 448.888 329.058 449.256 328.892 449.878L328.803 450.21C328.59 451.005 329.061 451.822 329.856 452.035L330.183 452.123C330.808 452.29 331.45 451.92 331.618 451.295C331.879 450.319 331.297 449.316 330.321 449.054Z" fill="#9055A2"/>
        <path id="Rectangle 596" d="M454.577 205.937C453.956 205.77 453.314 206.138 453.148 206.76L453.058 207.093C452.845 207.888 453.317 208.705 454.112 208.918L454.439 209.005C455.064 209.173 455.706 208.802 455.874 208.177C456.135 207.201 455.553 206.198 454.577 205.937Z" fill="#9055A2"/>
        <path id="Rectangle 597" d="M317.53 443.307C316.909 443.141 316.267 443.509 316.101 444.13L316.012 444.463C315.799 445.258 316.27 446.075 317.065 446.288L317.392 446.376C318.017 446.543 318.659 446.172 318.827 445.547C319.088 444.572 318.506 443.568 317.53 443.307Z" fill="#9055A2"/>
        <path id="Rectangle 598" d="M479.317 193.407C478.696 193.24 478.054 193.608 477.888 194.23L477.799 194.563C477.586 195.358 478.057 196.175 478.852 196.388L479.179 196.476C479.804 196.643 480.447 196.272 480.614 195.647C480.875 194.672 480.293 193.668 479.317 193.407Z" fill="#9055A2"/>
        <path id="Rectangle 599" d="M342.27 430.78C341.648 430.614 341.007 430.982 340.84 431.604L340.751 431.936C340.538 432.731 341.01 433.548 341.804 433.761L342.131 433.849C342.756 434.017 343.399 433.646 343.566 433.021C343.828 432.045 343.245 431.042 342.27 430.78Z" fill="#9055A2"/>
        <path id="Rectangle 600" d="M463.855 168.065C463.234 167.898 462.593 168.267 462.426 168.888L462.337 169.221C462.124 170.016 462.595 170.833 463.39 171.046L463.717 171.134C464.342 171.301 464.985 170.93 465.152 170.305C465.413 169.33 464.831 168.326 463.855 168.065Z" fill="#9055A2"/>
        <path id="Rectangle 601" d="M326.808 405.438C326.186 405.271 325.545 405.64 325.378 406.261L325.289 406.594C325.076 407.389 325.548 408.206 326.343 408.419L326.669 408.507C327.294 408.674 327.937 408.303 328.104 407.678C328.366 406.703 327.783 405.699 326.808 405.438Z" fill="#9055A2"/>
        <path id="Rectangle 602" d="M426.594 180.479C425.972 180.313 425.331 180.681 425.164 181.303L425.075 181.636C424.862 182.431 425.334 183.248 426.129 183.461L426.456 183.548C427.08 183.716 427.723 183.345 427.89 182.72C428.152 181.744 427.569 180.741 426.594 180.479Z" fill="#9055A2"/>
        <path id="Rectangle 603" d="M289.547 417.851C288.925 417.684 288.284 418.052 288.117 418.674L288.028 419.007C287.815 419.802 288.287 420.619 289.082 420.832L289.409 420.919C290.034 421.087 290.676 420.716 290.843 420.091C291.105 419.116 290.522 418.112 289.547 417.851Z" fill="#9055A2"/>
        <path id="Rectangle 604" d="M475.577 308.348C474.956 308.182 474.314 308.55 474.148 309.171L474.058 309.504C473.845 310.299 474.317 311.116 475.112 311.329L475.439 311.417C476.064 311.584 476.706 311.213 476.874 310.588C477.135 309.613 476.553 308.609 475.577 308.348Z" fill="#9055A2"/>
        <path id="Rectangle 606" d="M462.785 302.604C462.164 302.437 461.522 302.806 461.356 303.427L461.266 303.76C461.053 304.555 461.525 305.372 462.32 305.585L462.647 305.673C463.272 305.84 463.914 305.469 464.082 304.844C464.343 303.869 463.761 302.865 462.785 302.604Z" fill="#9055A2"/>
        <path id="Rectangle 607" d="M325.738 539.975C325.117 539.809 324.475 540.177 324.309 540.798L324.22 541.131C324.007 541.926 324.478 542.743 325.273 542.956L325.6 543.044C326.225 543.211 326.867 542.84 327.035 542.215C327.296 541.24 326.714 540.236 325.738 539.975Z" fill="#9055A2"/>
        <path id="Rectangle 608" d="M487.528 290.078C486.907 289.911 486.265 290.279 486.099 290.901L486.01 291.234C485.797 292.029 486.268 292.846 487.063 293.059L487.39 293.146C488.015 293.314 488.657 292.943 488.825 292.318C489.086 291.343 488.504 290.339 487.528 290.078Z" fill="#9055A2"/>
        <path id="Rectangle 609" d="M350.481 527.451C349.86 527.284 349.218 527.652 349.052 528.274L348.963 528.607C348.75 529.402 349.221 530.219 350.016 530.432L350.343 530.519C350.968 530.687 351.611 530.316 351.778 529.691C352.039 528.716 351.457 527.712 350.481 527.451Z" fill="#9055A2"/>
        <path id="Rectangle 610" d="M472.065 264.734C471.444 264.567 470.802 264.936 470.636 265.557L470.547 265.89C470.334 266.685 470.805 267.502 471.6 267.715L471.927 267.803C472.552 267.97 473.195 267.599 473.362 266.974C473.623 265.999 473.041 264.995 472.065 264.734Z" fill="#9055A2"/>
        <path id="Rectangle 611" d="M335.018 502.105C334.396 501.939 333.755 502.307 333.588 502.929L333.499 503.262C333.286 504.057 333.758 504.874 334.553 505.087L334.879 505.174C335.504 505.342 336.147 504.971 336.314 504.346C336.576 503.37 335.993 502.367 335.018 502.105Z" fill="#9055A2"/>
        <path id="Rectangle 612" d="M434.803 277.145C434.181 276.978 433.54 277.347 433.373 277.968L433.284 278.301C433.071 279.096 433.543 279.913 434.338 280.126L434.664 280.214C435.289 280.381 435.932 280.01 436.099 279.385C436.361 278.41 435.778 277.406 434.803 277.145Z" fill="#9055A2"/>
        <path id="Rectangle 613" d="M297.755 514.518C297.133 514.351 296.492 514.72 296.325 515.341L296.236 515.674C296.023 516.469 296.495 517.286 297.29 517.499L297.617 517.587C298.242 517.754 298.884 517.383 299.051 516.758C299.313 515.783 298.73 514.779 297.755 514.518Z" fill="#9055A2"/>
        <path id="Rectangle 614" d="M455.294 236.601C455.916 236.767 456.557 236.399 456.723 235.777L456.813 235.444C457.026 234.649 456.554 233.832 455.759 233.619L455.432 233.532C454.807 233.364 454.165 233.735 453.997 234.36C453.736 235.336 454.319 236.339 455.294 236.601Z" fill="#9055A2"/>
        <path id="Rectangle 615" d="M318.248 473.974C318.87 474.14 319.511 473.772 319.678 473.15L319.767 472.817C319.98 472.023 319.508 471.205 318.713 470.992L318.386 470.905C317.761 470.737 317.119 471.108 316.951 471.733C316.69 472.709 317.273 473.712 318.248 473.974Z" fill="#9055A2"/>
        <path id="Rectangle 616" d="M468.089 242.348C468.71 242.515 469.352 242.146 469.518 241.525L469.608 241.192C469.821 240.397 469.349 239.58 468.554 239.367L468.227 239.279C467.602 239.112 466.96 239.483 466.792 240.108C466.531 241.083 467.113 242.087 468.089 242.348Z" fill="#9055A2"/>
        <path id="Rectangle 617" d="M331.042 479.721C331.664 479.888 332.305 479.519 332.472 478.898L332.561 478.565C332.774 477.77 332.302 476.953 331.507 476.74L331.18 476.652C330.555 476.485 329.913 476.856 329.745 477.481C329.484 478.456 330.067 479.46 331.042 479.721Z" fill="#9055A2"/>
        <path id="Rectangle 618" d="M443.347 254.874C443.968 255.041 444.61 254.672 444.776 254.051L444.865 253.718C445.078 252.923 444.607 252.106 443.812 251.893L443.485 251.805C442.86 251.638 442.218 252.009 442.05 252.634C441.789 253.609 442.371 254.613 443.347 254.874Z" fill="#9055A2"/>
        <path id="Rectangle 619" d="M306.299 492.245C306.92 492.412 307.562 492.043 307.728 491.422L307.818 491.089C308.031 490.294 307.559 489.477 306.764 489.264L306.437 489.176C305.812 489.009 305.17 489.38 305.002 490.005C304.741 490.98 305.323 491.984 306.299 492.245Z" fill="#9055A2"/>
        <path id="Rectangle 620" d="M458.81 280.218C459.431 280.384 460.073 280.016 460.239 279.394L460.328 279.062C460.541 278.267 460.07 277.45 459.275 277.237L458.948 277.149C458.323 276.982 457.68 277.352 457.513 277.977C457.252 278.953 457.834 279.956 458.81 280.218Z" fill="#9055A2"/>
        <path id="Rectangle 621" d="M321.762 517.591C322.383 517.758 323.025 517.39 323.191 516.768L323.28 516.435C323.493 515.64 323.022 514.823 322.227 514.61L321.9 514.523C321.275 514.355 320.633 514.726 320.465 515.351C320.204 516.326 320.786 517.33 321.762 517.591Z" fill="#9055A2"/>
        <path id="Rectangle 622" d="M496.072 267.807C496.694 267.974 497.335 267.605 497.502 266.984L497.591 266.651C497.804 265.856 497.332 265.039 496.537 264.826L496.211 264.738C495.586 264.571 494.943 264.942 494.776 265.567C494.514 266.542 495.097 267.546 496.072 267.807Z" fill="#9055A2"/>
        <path id="Rectangle 623" d="M359.024 505.178C359.646 505.345 360.287 504.977 360.454 504.355L360.543 504.022C360.756 503.227 360.284 502.41 359.489 502.197L359.163 502.109C358.538 501.942 357.895 502.313 357.728 502.938C357.466 503.913 358.049 504.917 359.024 505.178Z" fill="#9055A2"/>
        <path id="Rectangle 624" d="M501.832 200.542C502.454 200.709 503.095 200.34 503.262 199.719L503.351 199.386C503.564 198.591 503.092 197.774 502.297 197.561L501.97 197.473C501.345 197.306 500.703 197.677 500.535 198.302C500.274 199.277 500.857 200.281 501.832 200.542Z" fill="#9055A2"/>
        <path id="Rectangle 625" d="M364.785 437.915C365.407 438.082 366.048 437.713 366.215 437.092L366.304 436.759C366.517 435.964 366.045 435.147 365.25 434.934L364.923 434.846C364.298 434.679 363.656 435.05 363.489 435.675C363.227 436.65 363.81 437.654 364.785 437.915Z" fill="#9055A2"/>
        <path id="Rectangle 626" d="M514.623 206.286C515.245 206.453 515.886 206.084 516.053 205.463L516.142 205.13C516.355 204.335 515.883 203.518 515.088 203.305L514.761 203.217C514.136 203.05 513.494 203.421 513.326 204.046C513.065 205.021 513.648 206.025 514.623 206.286Z" fill="#9055A2"/>
        <path id="Rectangle 627" d="M377.577 443.659C378.199 443.826 378.84 443.457 379.007 442.836L379.096 442.503C379.309 441.708 378.837 440.891 378.042 440.678L377.715 440.59C377.09 440.423 376.448 440.794 376.281 441.419C376.019 442.394 376.602 443.398 377.577 443.659Z" fill="#9055A2"/>
        <path id="Rectangle 628" d="M489.881 218.814C490.502 218.981 491.144 218.612 491.31 217.991L491.4 217.658C491.613 216.863 491.141 216.046 490.346 215.833L490.019 215.745C489.394 215.578 488.752 215.949 488.584 216.574C488.323 217.549 488.905 218.553 489.881 218.814Z" fill="#9055A2"/>
        <path id="Rectangle 629" d="M352.834 456.188C353.456 456.354 354.097 455.986 354.264 455.364L354.353 455.031C354.566 454.236 354.094 453.419 353.299 453.206L352.972 453.119C352.347 452.951 351.705 453.322 351.537 453.947C351.276 454.923 351.859 455.926 352.834 456.188Z" fill="#9055A2"/>
        <path id="Rectangle 630" d="M505.347 244.159C505.968 244.326 506.61 243.957 506.776 243.336L506.865 243.003C507.078 242.208 506.607 241.391 505.812 241.178L505.485 241.09C504.86 240.923 504.218 241.294 504.05 241.919C503.789 242.894 504.371 243.898 505.347 244.159Z" fill="#9055A2"/>
        <path id="Rectangle 631" d="M368.3 481.53C368.921 481.697 369.563 481.329 369.729 480.707L369.819 480.374C370.032 479.579 369.56 478.762 368.765 478.549L368.438 478.462C367.813 478.294 367.171 478.665 367.003 479.29C366.742 480.265 367.324 481.269 368.3 481.53Z" fill="#9055A2"/>
        <path id="Rectangle 633" d="M405.559 469.119C406.18 469.285 406.822 468.917 406.988 468.295L407.077 467.962C407.29 467.168 406.819 466.35 406.024 466.137L405.697 466.05C405.072 465.882 404.429 466.253 404.262 466.878C404.001 467.854 404.583 468.857 405.559 469.119Z" fill="#9055A2"/>
        <path id="Rectangle 635" d="M385.067 509.661C384.446 509.494 383.804 509.862 383.638 510.484L383.549 510.817C383.336 511.612 383.807 512.429 384.602 512.642L384.929 512.729C385.554 512.897 386.197 512.526 386.364 511.901C386.625 510.926 386.043 509.922 385.067 509.661Z" fill="#9055A2"/>
        <path id="Rectangle 636" d="M509.322 266.545C508.701 266.379 508.059 266.747 507.893 267.369L507.804 267.702C507.591 268.497 508.062 269.314 508.857 269.527L509.184 269.614C509.809 269.782 510.451 269.411 510.619 268.786C510.88 267.81 510.298 266.807 509.322 266.545Z" fill="#9055A2"/>
        <path id="Rectangle 637" d="M372.275 503.918C371.654 503.752 371.012 504.12 370.846 504.742L370.757 505.075C370.544 505.87 371.015 506.687 371.81 506.9L372.137 506.987C372.762 507.155 373.405 506.784 373.572 506.159C373.833 505.183 373.251 504.18 372.275 503.918Z" fill="#9055A2"/>
        <path id="Rectangle 639" d="M397.017 491.389C396.395 491.222 395.754 491.59 395.587 492.212L395.498 492.545C395.285 493.34 395.757 494.157 396.552 494.37L396.878 494.457C397.503 494.625 398.146 494.254 398.313 493.629C398.575 492.654 397.992 491.65 397.017 491.389Z" fill="#9055A2"/>
        <path id="Rectangle 641" d="M381.556 466.046C380.934 465.88 380.293 466.248 380.126 466.87L380.037 467.203C379.824 467.998 380.296 468.815 381.091 469.028L381.417 469.115C382.042 469.283 382.685 468.912 382.852 468.287C383.114 467.311 382.531 466.308 381.556 466.046Z" fill="#9055A2"/>
        <path id="Rectangle 642" d="M481.34 241.088C480.718 240.922 480.077 241.29 479.91 241.912L479.821 242.245C479.608 243.039 480.08 243.857 480.875 244.07L481.202 244.157C481.827 244.325 482.469 243.954 482.636 243.329C482.898 242.353 482.315 241.35 481.34 241.088Z" fill="#9055A2"/>
        <path id="Rectangle 643" d="M344.292 478.459C343.67 478.293 343.029 478.661 342.862 479.283L342.773 479.616C342.56 480.411 343.032 481.228 343.827 481.441L344.154 481.528C344.779 481.696 345.421 481.325 345.589 480.7C345.85 479.724 345.267 478.721 344.292 478.459Z" fill="#9055A2"/>
        <path id="Rectangle 644" d="M340.617 349.713C339.996 349.547 339.354 349.915 339.188 350.537L339.098 350.87C338.885 351.664 339.357 352.482 340.152 352.695L340.479 352.782C341.104 352.95 341.746 352.579 341.914 351.954C342.175 350.978 341.593 349.975 340.617 349.713Z" fill="#9055A2"/>
        <path id="Rectangle 646" d="M327.823 343.97C327.202 343.803 326.56 344.171 326.394 344.793L326.305 345.126C326.092 345.921 326.563 346.738 327.358 346.951L327.685 347.038C328.31 347.206 328.952 346.835 329.12 346.21C329.381 345.235 328.799 344.231 327.823 343.97Z" fill="#9055A2"/>
        <path id="Rectangle 648" d="M352.565 331.441C351.944 331.275 351.302 331.643 351.136 332.265L351.047 332.598C350.834 333.393 351.305 334.21 352.1 334.423L352.427 334.51C353.052 334.678 353.695 334.307 353.862 333.682C354.123 332.706 353.541 331.703 352.565 331.441Z" fill="#9055A2"/>
        <path id="Rectangle 650" d="M337.104 306.1C336.482 305.933 335.841 306.301 335.674 306.923L335.585 307.256C335.372 308.051 335.844 308.868 336.638 309.081L336.965 309.168C337.59 309.336 338.233 308.965 338.4 308.34C338.661 307.365 338.079 306.361 337.104 306.1Z" fill="#9055A2"/>
        <path id="Rectangle 652" d="M299.842 318.512C299.22 318.346 298.579 318.714 298.412 319.336L298.323 319.668C298.11 320.463 298.582 321.28 299.377 321.493L299.704 321.581C300.329 321.748 300.971 321.378 301.138 320.753C301.4 319.777 300.817 318.774 299.842 318.512Z" fill="#9055A2"/>
        <path id="Rectangle 654" d="M320.334 277.97C320.956 278.136 321.597 277.768 321.764 277.146L321.853 276.814C322.066 276.019 321.594 275.202 320.799 274.989L320.472 274.901C319.847 274.733 319.205 275.104 319.037 275.729C318.776 276.705 319.359 277.708 320.334 277.97Z" fill="#9055A2"/>
        <path id="Rectangle 655" d="M183.286 515.341C183.908 515.507 184.549 515.139 184.716 514.517L184.805 514.185C185.018 513.39 184.546 512.573 183.751 512.36L183.424 512.272C182.799 512.105 182.157 512.475 181.99 513.1C181.728 514.076 182.311 515.079 183.286 515.341Z" fill="#9055A2"/>
        <path id="Rectangle 656" d="M333.126 283.714C333.748 283.88 334.389 283.512 334.556 282.891L334.645 282.558C334.858 281.763 334.386 280.946 333.591 280.733L333.264 280.645C332.639 280.478 331.997 280.849 331.829 281.474C331.568 282.449 332.151 283.453 333.126 283.714Z" fill="#9055A2"/>
        <path id="Rectangle 657" d="M196.079 521.085C196.701 521.252 197.342 520.883 197.509 520.262L197.598 519.929C197.811 519.134 197.339 518.317 196.544 518.104L196.217 518.016C195.592 517.849 194.95 518.22 194.782 518.845C194.521 519.82 195.104 520.824 196.079 521.085Z" fill="#9055A2"/>
        <path id="Rectangle 658" d="M308.383 296.242C309.004 296.409 309.646 296.04 309.812 295.419L309.902 295.086C310.115 294.291 309.643 293.474 308.848 293.261L308.521 293.173C307.896 293.006 307.254 293.377 307.086 294.002C306.825 294.977 307.407 295.981 308.383 296.242Z" fill="#9055A2"/>
        <path id="Rectangle 659" d="M171.335 533.616C171.957 533.782 172.598 533.414 172.764 532.792L172.854 532.46C173.067 531.665 172.595 530.848 171.8 530.635L171.473 530.547C170.848 530.379 170.206 530.75 170.038 531.375C169.777 532.351 170.36 533.354 171.335 533.616Z" fill="#9055A2"/>
        <path id="Rectangle 660" d="M323.847 321.584C324.468 321.751 325.11 321.382 325.276 320.761L325.365 320.428C325.578 319.633 325.107 318.816 324.312 318.603L323.985 318.515C323.36 318.348 322.718 318.719 322.55 319.344C322.289 320.319 322.871 321.323 323.847 321.584Z" fill="#9055A2"/>
        <path id="Rectangle 662" d="M361.108 309.171C361.73 309.338 362.371 308.97 362.538 308.348L362.627 308.015C362.84 307.22 362.368 306.403 361.573 306.19L361.247 306.103C360.622 305.935 359.979 306.306 359.812 306.931C359.55 307.906 360.133 308.91 361.108 309.171Z" fill="#9055A2"/>
        <path id="Rectangle 664" d="M366.87 241.912C367.492 242.078 368.133 241.71 368.3 241.088L368.389 240.755C368.602 239.961 368.13 239.143 367.335 238.93L367.008 238.843C366.383 238.675 365.741 239.046 365.574 239.671C365.312 240.647 365.895 241.65 366.87 241.912Z" fill="#9055A2"/>
        <path id="Rectangle 665" d="M229.823 479.285C230.445 479.451 231.086 479.083 231.253 478.461L231.342 478.128C231.555 477.334 231.083 476.516 230.288 476.303L229.961 476.216C229.337 476.048 228.694 476.419 228.527 477.044C228.265 478.02 228.848 479.023 229.823 479.285Z" fill="#9055A2"/>
        <path id="Rectangle 666" d="M379.661 247.654C380.283 247.82 380.924 247.452 381.091 246.83L381.18 246.498C381.393 245.703 380.921 244.886 380.126 244.673L379.799 244.585C379.174 244.418 378.532 244.788 378.365 245.413C378.103 246.389 378.686 247.392 379.661 247.654Z" fill="#9055A2"/>
        <path id="Rectangle 667" d="M242.614 485.027C243.236 485.193 243.877 484.825 244.044 484.204L244.133 483.871C244.346 483.076 243.874 482.259 243.079 482.046L242.753 481.958C242.128 481.791 241.485 482.162 241.318 482.787C241.056 483.762 241.639 484.766 242.614 485.027Z" fill="#9055A2"/>
        <path id="Rectangle 668" d="M354.92 260.184C355.542 260.35 356.183 259.982 356.349 259.36L356.439 259.027C356.652 258.232 356.18 257.415 355.385 257.202L355.058 257.115C354.433 256.947 353.791 257.318 353.623 257.943C353.362 258.919 353.944 259.922 354.92 260.184Z" fill="#9055A2"/>
        <path id="Rectangle 669" d="M217.873 497.555C218.495 497.721 219.136 497.353 219.303 496.731L219.392 496.398C219.605 495.604 219.133 494.786 218.338 494.573L218.011 494.486C217.386 494.318 216.744 494.689 216.576 495.314C216.315 496.29 216.898 497.293 217.873 497.555Z" fill="#9055A2"/>
        <path id="Rectangle 670" d="M370.384 285.525C371.005 285.692 371.647 285.324 371.813 284.702L371.903 284.369C372.116 283.574 371.644 282.757 370.849 282.544L370.522 282.457C369.897 282.289 369.255 282.66 369.087 283.285C368.826 284.26 369.408 285.264 370.384 285.525Z" fill="#9055A2"/>
        <path id="Rectangle 671" d="M233.337 522.898C233.959 523.065 234.6 522.697 234.766 522.075L234.856 521.742C235.069 520.947 234.597 520.13 233.802 519.917L233.475 519.83C232.85 519.662 232.208 520.033 232.04 520.658C231.779 521.633 232.361 522.637 233.337 522.898Z" fill="#9055A2"/>
        <path id="Rectangle 672" d="M407.645 273.113C408.267 273.279 408.908 272.911 409.075 272.289L409.164 271.957C409.377 271.162 408.905 270.345 408.111 270.132L407.784 270.044C407.159 269.877 406.516 270.247 406.349 270.872C406.088 271.848 406.67 272.851 407.645 273.113Z" fill="#9055A2"/>
        <path id="Rectangle 673" d="M270.599 510.486C271.22 510.652 271.862 510.284 272.028 509.662L272.117 509.33C272.33 508.535 271.859 507.718 271.064 507.505L270.737 507.417C270.112 507.25 269.469 507.621 269.302 508.245C269.041 509.221 269.623 510.224 270.599 510.486Z" fill="#9055A2"/>
        <path id="Rectangle 674" d="M387.151 313.656C386.53 313.489 385.888 313.857 385.722 314.479L385.633 314.812C385.42 315.607 385.891 316.424 386.686 316.637L387.013 316.725C387.638 316.892 388.281 316.521 388.448 315.896C388.709 314.921 388.127 313.917 387.151 313.656Z" fill="#9055A2"/>
        <path id="Rectangle 676" d="M374.359 307.911C373.738 307.745 373.096 308.113 372.93 308.734L372.841 309.067C372.628 309.862 373.099 310.679 373.894 310.892L374.221 310.98C374.846 311.147 375.489 310.776 375.656 310.151C375.917 309.176 375.335 308.172 374.359 307.911Z" fill="#9055A2"/>
        <path id="Rectangle 678" d="M399.103 295.383C398.481 295.217 397.84 295.585 397.673 296.207L397.584 296.539C397.371 297.334 397.843 298.152 398.637 298.365L398.964 298.452C399.589 298.62 400.232 298.249 400.399 297.624C400.661 296.648 400.078 295.645 399.103 295.383Z" fill="#9055A2"/>
        <path id="Rectangle 679" d="M262.055 532.756C261.433 532.59 260.792 532.958 260.625 533.58L260.536 533.913C260.323 534.707 260.795 535.525 261.59 535.738L261.916 535.825C262.541 535.993 263.184 535.622 263.351 534.997C263.613 534.021 263.03 533.018 262.055 532.756Z" fill="#9055A2"/>
        <path id="Rectangle 680" d="M383.639 270.04C383.017 269.873 382.376 270.241 382.209 270.863L382.12 271.196C381.907 271.991 382.379 272.808 383.174 273.021L383.5 273.108C384.125 273.276 384.768 272.905 384.935 272.28C385.197 271.304 384.614 270.301 383.639 270.04Z" fill="#9055A2"/>
        <path id="Rectangle 681" d="M246.592 507.413C245.97 507.246 245.329 507.614 245.162 508.236L245.073 508.569C244.86 509.364 245.332 510.181 246.127 510.394L246.454 510.481C247.079 510.649 247.721 510.278 247.888 509.653C248.15 508.678 247.567 507.674 246.592 507.413Z" fill="#9055A2"/>
        <path id="Rectangle 682" d="M346.377 282.454C345.755 282.288 345.114 282.656 344.947 283.277L344.858 283.61C344.645 284.405 345.117 285.222 345.912 285.435L346.239 285.523C346.864 285.69 347.506 285.319 347.674 284.694C347.935 283.719 347.352 282.715 346.377 282.454Z" fill="#9055A2"/>
        <path id="Rectangle 683" d="M209.33 519.825C208.708 519.659 208.067 520.027 207.901 520.649L207.811 520.981C207.598 521.776 208.07 522.593 208.865 522.806L209.192 522.894C209.817 523.061 210.459 522.691 210.627 522.066C210.888 521.09 210.306 520.087 209.33 519.825Z" fill="#9055A2"/>
        <path id="Rectangle 684" d="M395.363 410.322C394.742 410.156 394.1 410.524 393.934 411.146L393.845 411.478C393.632 412.273 394.103 413.09 394.898 413.303L395.225 413.391C395.85 413.558 396.492 413.188 396.66 412.563C396.921 411.587 396.339 410.584 395.363 410.322Z" fill="#9055A2"/>
        <path id="Rectangle 686" d="M382.57 404.578C381.949 404.412 381.307 404.78 381.141 405.401L381.052 405.734C380.839 406.529 381.31 407.346 382.105 407.559L382.432 407.647C383.057 407.814 383.699 407.443 383.867 406.818C384.128 405.843 383.546 404.839 382.57 404.578Z" fill="#9055A2"/>
        <path id="Rectangle 688" d="M407.313 392.054C406.692 391.887 406.051 392.255 405.884 392.877L405.795 393.21C405.582 394.005 406.054 394.822 406.848 395.035L407.175 395.122C407.8 395.29 408.443 394.919 408.61 394.294C408.871 393.319 408.289 392.315 407.313 392.054Z" fill="#9055A2"/>
        <path id="Rectangle 690" d="M391.848 366.707C391.226 366.54 390.585 366.908 390.418 367.53L390.329 367.863C390.116 368.658 390.588 369.475 391.383 369.688L391.709 369.775C392.334 369.943 392.977 369.572 393.144 368.947C393.406 367.971 392.823 366.968 391.848 366.707Z" fill="#9055A2"/>
        <path id="Rectangle 692" d="M354.588 379.121C353.966 378.955 353.325 379.323 353.158 379.944L353.069 380.277C352.856 381.072 353.328 381.889 354.123 382.102L354.45 382.19C355.075 382.357 355.717 381.986 355.885 381.361C356.146 380.386 355.563 379.382 354.588 379.121Z" fill="#9055A2"/>
        <path id="Rectangle 694" d="M375.08 338.579C375.702 338.745 376.343 338.377 376.51 337.755L376.599 337.422C376.812 336.627 376.34 335.81 375.545 335.597L375.218 335.51C374.593 335.342 373.951 335.713 373.783 336.338C373.522 337.314 374.105 338.317 375.08 338.579Z" fill="#9055A2"/>
        <path id="Rectangle 696" d="M387.872 344.323C388.494 344.489 389.135 344.121 389.302 343.499L389.391 343.167C389.604 342.372 389.132 341.555 388.337 341.342L388.01 341.254C387.385 341.087 386.743 341.457 386.575 342.082C386.314 343.058 386.897 344.061 387.872 344.323Z" fill="#9055A2"/>
        <path id="Rectangle 698" d="M363.129 356.849C363.751 357.015 364.392 356.647 364.558 356.025L364.648 355.692C364.861 354.898 364.389 354.08 363.594 353.867L363.267 353.78C362.642 353.612 362 353.983 361.832 354.608C361.571 355.584 362.153 356.587 363.129 356.849Z" fill="#9055A2"/>
        <path id="Rectangle 700" d="M378.593 382.193C379.214 382.359 379.856 381.991 380.022 381.37L380.111 381.037C380.324 380.242 379.853 379.425 379.058 379.212L378.731 379.124C378.106 378.957 377.464 379.328 377.296 379.953C377.035 380.928 377.617 381.932 378.593 382.193Z" fill="#9055A2"/>
        <path id="Rectangle 702" d="M415.854 369.782C416.476 369.948 417.117 369.58 417.284 368.958L417.373 368.626C417.586 367.831 417.114 367.014 416.32 366.801L415.993 366.713C415.368 366.546 414.725 366.916 414.558 367.541C414.297 368.517 414.879 369.52 415.854 369.782Z" fill="#9055A2"/>
        <path id="Rectangle 704" d="M421.617 302.52C422.239 302.687 422.88 302.318 423.047 301.697L423.136 301.364C423.349 300.569 422.877 299.752 422.082 299.539L421.755 299.451C421.13 299.284 420.488 299.655 420.321 300.28C420.059 301.255 420.642 302.259 421.617 302.52Z" fill="#9055A2"/>
        <path id="Rectangle 705" d="M284.57 539.891C285.192 540.058 285.833 539.689 286 539.068L286.089 538.735C286.302 537.94 285.83 537.123 285.035 536.91L284.709 536.822C284.084 536.655 283.441 537.026 283.274 537.651C283.012 538.626 283.595 539.63 284.57 539.891Z" fill="#9055A2"/>
        <path id="Rectangle 706" d="M434.409 308.264C435.031 308.431 435.672 308.062 435.839 307.441L435.928 307.108C436.141 306.313 435.669 305.496 434.874 305.283L434.547 305.195C433.922 305.028 433.28 305.399 433.113 306.024C432.851 306.999 433.434 308.003 434.409 308.264Z" fill="#9055A2"/>
        <path id="Rectangle 707" d="M297.362 545.637C297.984 545.804 298.625 545.436 298.792 544.814L298.881 544.481C299.094 543.686 298.622 542.869 297.827 542.656L297.501 542.568C296.876 542.401 296.233 542.772 296.066 543.397C295.804 544.372 296.387 545.376 297.362 545.637Z" fill="#9055A2"/>
        <path id="Rectangle 708" d="M409.666 320.791C410.288 320.957 410.929 320.589 411.096 319.967L411.185 319.634C411.398 318.839 410.926 318.022 410.131 317.809L409.804 317.722C409.179 317.554 408.537 317.925 408.369 318.55C408.108 319.526 408.691 320.529 409.666 320.791Z" fill="#9055A2"/>
        <path id="Rectangle 710" d="M425.13 346.134C425.752 346.301 426.393 345.933 426.559 345.311L426.649 344.978C426.862 344.183 426.39 343.366 425.595 343.153L425.268 343.066C424.643 342.898 424.001 343.269 423.833 343.894C423.572 344.869 424.154 345.873 425.13 346.134Z" fill="#9055A2"/>
        <path id="Rectangle 712" d="M462.392 333.721C463.013 333.888 463.655 333.519 463.821 332.898L463.91 332.565C464.123 331.77 463.652 330.953 462.857 330.74L462.53 330.652C461.905 330.485 461.262 330.856 461.095 331.481C460.834 332.456 461.416 333.46 462.392 333.721Z" fill="#9055A2"/>
        <path id="Rectangle 714" d="M441.899 374.266C441.278 374.099 440.636 374.467 440.47 375.089L440.381 375.422C440.168 376.217 440.639 377.034 441.434 377.247L441.761 377.334C442.386 377.502 443.029 377.131 443.196 376.506C443.457 375.531 442.875 374.527 441.899 374.266Z" fill="#9055A2"/>
        <path id="Rectangle 716" d="M429.105 368.518C428.483 368.351 427.842 368.72 427.675 369.341L427.586 369.674C427.373 370.469 427.845 371.286 428.639 371.499L428.966 371.587C429.591 371.754 430.234 371.383 430.401 370.758C430.662 369.783 430.08 368.779 429.105 368.518Z" fill="#9055A2"/>
        <path id="Rectangle 718" d="M453.848 355.992C453.226 355.826 452.585 356.194 452.418 356.816L452.329 357.148C452.116 357.943 452.588 358.76 453.383 358.973L453.709 359.061C454.334 359.228 454.977 358.858 455.144 358.233C455.406 357.257 454.823 356.254 453.848 355.992Z" fill="#9055A2"/>
        <path id="Rectangle 720" d="M438.385 330.648C437.763 330.482 437.122 330.85 436.955 331.472L436.866 331.805C436.653 332.6 437.125 333.417 437.92 333.63L438.247 333.717C438.872 333.885 439.514 333.514 439.681 332.889C439.943 331.913 439.36 330.91 438.385 330.648Z" fill="#9055A2"/>
        <path id="Rectangle 722" d="M401.123 343.061C400.501 342.894 399.86 343.263 399.694 343.884L399.604 344.217C399.391 345.012 399.863 345.829 400.658 346.042L400.985 346.13C401.61 346.297 402.252 345.926 402.42 345.301C402.681 344.326 402.098 343.322 401.123 343.061Z" fill="#9055A2"/>
        <path id="Rectangle 732" d="M490.29 343.105C489.668 342.939 489.027 343.307 488.861 343.929L488.771 344.262C488.558 345.057 489.03 345.874 489.825 346.087L490.152 346.174C490.777 346.342 491.419 345.971 491.587 345.346C491.848 344.37 491.265 343.367 490.29 343.105Z" fill="#9055A2"/>
        <path id="Rectangle 734" d="M510.782 302.565C511.404 302.732 512.045 302.363 512.212 301.742L512.301 301.409C512.514 300.614 512.042 299.797 511.247 299.584L510.92 299.496C510.295 299.329 509.653 299.7 509.486 300.325C509.224 301.3 509.807 302.304 510.782 302.565Z" fill="#9055A2"/>
        <path id="Rectangle 735" d="M373.734 539.934C374.356 540.101 374.997 539.732 375.164 539.111L375.253 538.778C375.466 537.983 374.994 537.166 374.199 536.953L373.873 536.865C373.248 536.698 372.605 537.069 372.438 537.694C372.176 538.669 372.759 539.673 373.734 539.934Z" fill="#9055A2"/>
        <path id="Rectangle 737" d="M386.529 545.684C387.151 545.851 387.792 545.482 387.959 544.861L388.048 544.528C388.261 543.733 387.789 542.916 386.994 542.703L386.668 542.615C386.043 542.448 385.4 542.819 385.233 543.444C384.971 544.419 385.554 545.423 386.529 545.684Z" fill="#9055A2"/>
        <path id="Rectangle 738" d="M498.832 320.835C499.454 321.002 500.095 320.633 500.262 320.012L500.351 319.679C500.564 318.884 500.092 318.067 499.297 317.854L498.97 317.766C498.345 317.599 497.703 317.97 497.535 318.595C497.274 319.57 497.857 320.574 498.832 320.835Z" fill="#9055A2"/>
        <path id="Rectangle 740" d="M514.295 346.177C514.917 346.344 515.558 345.976 515.724 345.354L515.814 345.021C516.027 344.226 515.555 343.409 514.76 343.196L514.433 343.108C513.808 342.941 513.166 343.312 512.998 343.937C512.737 344.912 513.319 345.916 514.295 346.177Z" fill="#9055A2"/>
        <path id="Rectangle 413" d="M78.0664 183.851C78.688 184.018 79.3294 183.649 79.4959 183.028L79.5851 182.695C79.7981 181.9 79.3264 181.083 78.5315 180.87L78.2047 180.782C77.5797 180.615 76.9373 180.986 76.7698 181.611C76.5084 182.586 77.091 183.59 78.0664 183.851Z" fill="#9055A2"/>
        <path id="Rectangle 745" d="M420.271 503.878C420.893 504.045 421.534 503.676 421.701 503.055L421.79 502.722C422.003 501.927 421.531 501.11 420.737 500.897L420.41 500.809C419.785 500.642 419.142 501.013 418.975 501.638C418.714 502.613 419.296 503.617 420.271 503.878Z" fill="#9055A2"/>
        <path id="Rectangle 747" d="M433.064 509.622C433.686 509.789 434.327 509.42 434.494 508.799L434.583 508.466C434.796 507.671 434.324 506.854 433.53 506.641L433.203 506.553C432.578 506.386 431.935 506.757 431.768 507.382C431.506 508.357 432.089 509.361 433.064 509.622Z" fill="#9055A2"/>
        <path id="Rectangle 749" d="M408.321 522.148C408.943 522.315 409.584 521.946 409.751 521.325L409.84 520.992C410.053 520.197 409.581 519.38 408.786 519.167L408.46 519.079C407.835 518.912 407.192 519.283 407.025 519.908C406.763 520.883 407.346 521.887 408.321 522.148Z" fill="#9055A2"/>
        <path id="Rectangle 417" d="M87.3418 160.207C87.9634 160.374 88.6048 160.005 88.7713 159.384L88.8605 159.051C89.0735 158.256 88.6018 157.439 87.8068 157.226L87.48 157.138C86.8551 156.971 86.2126 157.342 86.0452 157.967C85.7838 158.942 86.3664 159.946 87.3418 160.207Z" fill="#9055A2"/>
        <path id="Rectangle 418" d="M124.606 147.794C125.228 147.961 125.869 147.592 126.036 146.971L126.125 146.638C126.338 145.843 125.866 145.026 125.071 144.813L124.745 144.725C124.12 144.558 123.477 144.929 123.31 145.554C123.048 146.529 123.631 147.533 124.606 147.794Z" fill="#9055A2"/>
        <path id="Rectangle 753" d="M461.047 535.079C461.669 535.246 462.31 534.877 462.476 534.256L462.566 533.923C462.779 533.128 462.307 532.311 461.512 532.098L461.185 532.01C460.56 531.843 459.918 532.214 459.75 532.839C459.489 533.814 460.071 534.818 461.047 535.079Z" fill="#9055A2"/>
        <path id="Rectangle 419" d="M104.112 188.337C103.491 188.17 102.849 188.539 102.683 189.16L102.594 189.493C102.381 190.288 102.852 191.105 103.647 191.318L103.974 191.406C104.599 191.573 105.241 191.202 105.409 190.577C105.67 189.602 105.088 188.598 104.112 188.337Z" fill="#9055A2"/>
        <path id="Rectangle 420" d="M91.3193 182.593C90.6977 182.426 90.0564 182.794 89.8898 183.416L89.8006 183.749C89.5876 184.544 90.0594 185.361 90.8543 185.574L91.1811 185.662C91.8061 185.829 92.4485 185.458 92.6159 184.833C92.8773 183.858 92.2948 182.854 91.3193 182.593Z" fill="#9055A2"/>
        <path id="Rectangle 421" d="M116.063 170.064C115.441 169.898 114.8 170.266 114.633 170.888L114.544 171.221C114.331 172.016 114.803 172.833 115.597 173.046L115.924 173.133C116.549 173.301 117.192 172.93 117.359 172.305C117.62 171.329 117.038 170.326 116.063 170.064Z" fill="#9055A2"/>
        <path id="Rectangle 422" d="M100.599 144.723C99.977 144.556 99.3357 144.924 99.1691 145.546L99.0799 145.879C98.8669 146.674 99.3387 147.491 100.134 147.704L100.46 147.791C101.085 147.959 101.728 147.588 101.895 146.963C102.157 145.988 101.574 144.984 100.599 144.723Z" fill="#9055A2"/>
        <path id="Rectangle 761" d="M437.042 532.007C436.42 531.841 435.779 532.209 435.612 532.831L435.523 533.164C435.31 533.958 435.782 534.776 436.577 534.989L436.904 535.076C437.529 535.244 438.171 534.873 438.339 534.248C438.6 533.272 438.017 532.269 437.042 532.007Z" fill="#9055A2"/>
        <path id="Rectangle 424" d="M112.32 285.004C111.699 284.838 111.057 285.206 110.891 285.828L110.802 286.161C110.589 286.956 111.06 287.773 111.855 287.986L112.182 288.073C112.807 288.241 113.449 287.87 113.617 287.245C113.878 286.269 113.296 285.266 112.32 285.004Z" fill="#9055A2"/>
        <path id="Rectangle 425" d="M99.5283 279.26C98.9067 279.093 98.2654 279.461 98.0988 280.083L98.0096 280.416C97.7966 281.211 98.2683 282.028 99.0633 282.241L99.3901 282.329C100.015 282.496 100.657 282.125 100.825 281.5C101.086 280.525 100.504 279.521 99.5283 279.26Z" fill="#9055A2"/>
        <path id="Rectangle 426" d="M124.272 266.733C123.651 266.567 123.009 266.935 122.843 267.557L122.754 267.89C122.541 268.685 123.012 269.502 123.807 269.715L124.134 269.802C124.759 269.97 125.402 269.599 125.569 268.974C125.83 267.998 125.248 266.995 124.272 266.733Z" fill="#9055A2"/>
        <path id="Rectangle 427" d="M108.809 241.39C108.187 241.223 107.546 241.591 107.379 242.213L107.29 242.546C107.077 243.341 107.549 244.158 108.344 244.371L108.67 244.458C109.295 244.626 109.938 244.255 110.105 243.63C110.367 242.655 109.784 241.651 108.809 241.39Z" fill="#9055A2"/>
        <path id="Rectangle 429" d="M92.04 213.26C92.6617 213.426 93.303 213.058 93.4696 212.436L93.5588 212.104C93.7718 211.309 93.3 210.492 92.5051 210.279L92.1783 210.191C91.5533 210.024 90.9109 210.394 90.7434 211.019C90.4821 211.995 91.0646 212.998 92.04 213.26Z" fill="#9055A2"/>
        <path id="Rectangle 430" d="M104.833 219.004C105.455 219.17 106.096 218.802 106.263 218.181L106.352 217.848C106.565 217.053 106.093 216.236 105.298 216.023L104.971 215.935C104.346 215.768 103.704 216.139 103.536 216.764C103.275 217.739 103.858 218.743 104.833 219.004Z" fill="#9055A2"/>
        <path id="Rectangle 431" d="M80.0898 231.53C80.7115 231.696 81.3528 231.328 81.5194 230.706L81.6086 230.374C81.8216 229.579 81.3498 228.762 80.5549 228.549L80.2281 228.461C79.6031 228.294 78.9607 228.664 78.7932 229.289C78.5319 230.265 79.1144 231.268 80.0898 231.53Z" fill="#9055A2"/>
        <path id="Rectangle 432" d="M95.5517 256.874C96.1734 257.041 96.8147 256.672 96.9813 256.051L97.0705 255.718C97.2835 254.923 96.8117 254.106 96.0168 253.893L95.69 253.805C95.065 253.638 94.4226 254.009 94.2551 254.634C93.9938 255.609 94.5763 256.613 95.5517 256.874Z" fill="#9055A2"/>
        <path id="Rectangle 433" d="M132.814 244.463C133.436 244.629 134.077 244.261 134.244 243.64L134.333 243.307C134.546 242.512 134.074 241.695 133.28 241.482L132.953 241.394C132.328 241.227 131.685 241.598 131.518 242.223C131.256 243.198 131.839 244.202 132.814 244.463Z" fill="#9055A2"/>
        <path id="Rectangle 434" d="M138.577 177.203C139.199 177.37 139.84 177.001 140.007 176.38L140.096 176.047C140.309 175.252 139.837 174.435 139.042 174.222L138.715 174.134C138.09 173.967 137.448 174.338 137.281 174.963C137.019 175.938 137.602 176.942 138.577 177.203Z" fill="#9055A2"/>
        <path id="Rectangle 435" d="M151.366 182.944C151.988 183.111 152.629 182.743 152.796 182.121L152.885 181.788C153.098 180.993 152.626 180.176 151.831 179.963L151.504 179.876C150.879 179.708 150.237 180.079 150.07 180.704C149.808 181.679 150.391 182.683 151.366 182.944Z" fill="#9055A2"/>
        <path id="Rectangle 436" d="M126.624 195.472C127.246 195.639 127.887 195.27 128.054 194.649L128.143 194.316C128.356 193.521 127.884 192.704 127.089 192.491L126.762 192.403C126.137 192.236 125.495 192.607 125.327 193.232C125.066 194.207 125.649 195.211 126.624 195.472Z" fill="#9055A2"/>
        <path id="Rectangle 437" d="M142.09 220.815C142.711 220.982 143.353 220.614 143.519 219.992L143.609 219.659C143.822 218.864 143.35 218.047 142.555 217.834L142.228 217.747C141.603 217.579 140.961 217.95 140.793 218.575C140.532 219.55 141.114 220.554 142.09 220.815Z" fill="#9055A2"/>
        <path id="Rectangle 438" d="M179.35 208.401C179.971 208.567 180.613 208.199 180.779 207.578L180.868 207.245C181.081 206.45 180.61 205.633 179.815 205.42L179.488 205.332C178.863 205.165 178.22 205.536 178.053 206.161C177.792 207.136 178.374 208.14 179.35 208.401Z" fill="#9055A2"/>
        <path id="Rectangle 439" d="M158.86 248.945C158.239 248.778 157.597 249.147 157.431 249.768L157.342 250.101C157.129 250.896 157.6 251.713 158.395 251.926L158.722 252.014C159.347 252.181 159.989 251.81 160.157 251.185C160.418 250.21 159.836 249.206 158.86 248.945Z" fill="#9055A2"/>
        <path id="Rectangle 440" d="M146.065 243.201C145.444 243.035 144.802 243.403 144.636 244.025L144.547 244.357C144.334 245.152 144.805 245.969 145.6 246.182L145.927 246.27C146.552 246.437 147.195 246.067 147.362 245.442C147.623 244.466 147.041 243.463 146.065 243.201Z" fill="#9055A2"/>
        <path id="Rectangle 441" d="M170.808 230.673C170.186 230.507 169.545 230.875 169.378 231.497L169.289 231.83C169.076 232.624 169.548 233.442 170.343 233.655L170.669 233.742C171.294 233.91 171.937 233.539 172.104 232.914C172.366 231.938 171.783 230.935 170.808 230.673Z" fill="#9055A2"/>
        <path id="Rectangle 442" d="M155.344 205.332C154.722 205.165 154.081 205.533 153.914 206.155L153.825 206.488C153.612 207.283 154.084 208.1 154.879 208.313L155.206 208.4C155.83 208.568 156.473 208.197 156.64 207.572C156.902 206.596 156.319 205.593 155.344 205.332Z" fill="#9055A2"/>
        <path id="Rectangle 443" d="M118.083 217.742C117.461 217.576 116.82 217.944 116.653 218.566L116.564 218.898C116.351 219.693 116.823 220.51 117.618 220.723L117.945 220.811C118.57 220.978 119.212 220.608 119.38 219.983C119.641 219.007 119.058 218.004 118.083 217.742Z" fill="#9055A2"/>
        <path id="Rectangle 204" d="M308.922 170.269C309.544 170.436 310.185 170.067 310.351 169.446L310.441 169.113C310.654 168.318 310.182 167.501 309.387 167.288L309.06 167.2C308.435 167.033 307.793 167.404 307.625 168.029C307.364 169.004 307.946 170.008 308.922 170.269Z" fill="#9055A2"/>
        <path id="Rectangle 444" d="M171.877 407.642C172.499 407.809 173.14 407.44 173.306 406.819L173.396 406.486C173.609 405.691 173.137 404.874 172.342 404.661L172.015 404.573C171.39 404.406 170.748 404.777 170.58 405.402C170.319 406.377 170.902 407.381 171.877 407.642Z" fill="#9055A2"/>
        <path id="Rectangle 205" d="M321.716 176.013C322.337 176.18 322.979 175.811 323.145 175.19L323.235 174.857C323.448 174.062 322.976 173.245 322.181 173.032L321.854 172.944C321.229 172.777 320.587 173.148 320.419 173.773C320.158 174.748 320.74 175.752 321.716 176.013Z" fill="#9055A2"/>
        <path id="Rectangle 445" d="M184.669 413.384C185.291 413.551 185.932 413.183 186.098 412.561L186.188 412.228C186.401 411.433 185.929 410.616 185.134 410.403L184.807 410.316C184.182 410.148 183.54 410.519 183.372 411.144C183.111 412.119 183.694 413.123 184.669 413.384Z" fill="#9055A2"/>
        <path id="Rectangle 206" d="M296.973 188.541C297.594 188.708 298.236 188.339 298.402 187.718L298.491 187.385C298.704 186.59 298.233 185.773 297.438 185.56L297.111 185.472C296.486 185.305 295.844 185.676 295.676 186.301C295.415 187.276 295.997 188.28 296.973 188.541Z" fill="#9055A2"/>
        <path id="Rectangle 446" d="M159.926 425.914C160.547 426.081 161.189 425.712 161.355 425.091L161.444 424.758C161.657 423.963 161.186 423.146 160.391 422.933L160.064 422.845C159.439 422.678 158.797 423.049 158.629 423.674C158.368 424.649 158.95 425.653 159.926 425.914Z" fill="#9055A2"/>
        <path id="Rectangle 207" d="M312.436 213.885C313.057 214.051 313.699 213.683 313.865 213.061L313.954 212.729C314.167 211.934 313.696 211.117 312.901 210.904L312.574 210.816C311.949 210.649 311.306 211.019 311.139 211.644C310.878 212.62 311.46 213.623 312.436 213.885Z" fill="#9055A2"/>
        <path id="Rectangle 447" d="M175.39 451.256C176.011 451.422 176.653 451.054 176.819 450.433L176.908 450.1C177.121 449.305 176.65 448.488 175.855 448.275L175.528 448.187C174.903 448.02 174.261 448.391 174.093 449.016C173.832 449.991 174.414 450.995 175.39 451.256Z" fill="#9055A2"/>
        <path id="Rectangle 208" d="M349.698 201.472C350.32 201.639 350.961 201.27 351.128 200.649L351.217 200.316C351.43 199.521 350.958 198.704 350.163 198.491L349.836 198.403C349.212 198.236 348.569 198.607 348.402 199.232C348.14 200.207 348.723 201.211 349.698 201.472Z" fill="#9055A2"/>
        <path id="Rectangle 448" d="M212.651 438.845C213.273 439.012 213.914 438.644 214.081 438.022L214.17 437.689C214.383 436.894 213.911 436.077 213.116 435.864L212.79 435.776C212.165 435.609 211.522 435.98 211.355 436.605C211.093 437.58 211.676 438.584 212.651 438.845Z" fill="#9055A2"/>
        <path id="Rectangle 209" d="M329.204 242.013C328.582 241.846 327.941 242.214 327.775 242.836L327.685 243.169C327.472 243.964 327.944 244.781 328.739 244.994L329.066 245.081C329.691 245.249 330.333 244.878 330.501 244.253C330.762 243.278 330.18 242.274 329.204 242.013Z" fill="#9055A2"/>
        <path id="Rectangle 449" d="M192.157 479.386C191.536 479.22 190.894 479.588 190.728 480.21L190.639 480.542C190.426 481.337 190.897 482.154 191.692 482.367L192.019 482.455C192.644 482.622 193.286 482.252 193.454 481.627C193.715 480.651 193.133 479.648 192.157 479.386Z" fill="#9055A2"/>
        <path id="Rectangle 210" d="M316.414 236.27C315.792 236.103 315.151 236.472 314.985 237.093L314.895 237.426C314.682 238.221 315.154 239.038 315.949 239.251L316.276 239.339C316.901 239.506 317.543 239.135 317.711 238.51C317.972 237.535 317.389 236.531 316.414 236.27Z" fill="#9055A2"/>
        <path id="Rectangle 450" d="M179.367 473.644C178.746 473.477 178.104 473.845 177.938 474.467L177.848 474.8C177.635 475.595 178.107 476.412 178.902 476.625L179.229 476.712C179.854 476.88 180.496 476.509 180.664 475.884C180.925 474.908 180.343 473.905 179.367 473.644Z" fill="#9055A2"/>
        <path id="Rectangle 211" d="M341.157 223.742C340.536 223.576 339.894 223.944 339.728 224.566L339.639 224.898C339.426 225.693 339.897 226.51 340.692 226.723L341.019 226.811C341.644 226.978 342.286 226.608 342.454 225.983C342.715 225.007 342.133 224.004 341.157 223.742Z" fill="#9055A2"/>
        <path id="Rectangle 451" d="M204.11 461.115C203.489 460.949 202.847 461.317 202.681 461.939L202.592 462.271C202.379 463.066 202.85 463.883 203.645 464.096L203.972 464.184C204.597 464.351 205.239 463.981 205.407 463.356C205.668 462.38 205.086 461.377 204.11 461.115Z" fill="#9055A2"/>
        <path id="Rectangle 212" d="M325.691 198.399C325.07 198.232 324.428 198.601 324.262 199.222L324.173 199.555C323.96 200.35 324.431 201.167 325.226 201.38L325.553 201.468C326.178 201.635 326.821 201.264 326.988 200.639C327.249 199.664 326.667 198.66 325.691 198.399Z" fill="#9055A2"/>
        <path id="Rectangle 452" d="M188.645 435.77C188.023 435.603 187.382 435.972 187.215 436.593L187.126 436.926C186.913 437.721 187.385 438.538 188.179 438.751L188.506 438.839C189.131 439.006 189.774 438.635 189.941 438.01C190.203 437.035 189.62 436.031 188.645 435.77Z" fill="#9055A2"/>
        <path id="Rectangle 213" d="M288.432 210.811C287.81 210.644 287.169 211.013 287.002 211.634L286.913 211.967C286.7 212.762 287.172 213.579 287.967 213.792L288.293 213.88C288.918 214.047 289.561 213.676 289.728 213.051C289.99 212.076 289.407 211.072 288.432 210.811Z" fill="#9055A2"/>
        <path id="Rectangle 453" d="M151.385 448.184C150.763 448.017 150.122 448.386 149.955 449.007L149.866 449.34C149.653 450.135 150.125 450.952 150.92 451.165L151.247 451.253C151.872 451.42 152.514 451.049 152.681 450.424C152.943 449.449 152.36 448.445 151.385 448.184Z" fill="#9055A2"/>
        <path id="Rectangle 214" d="M282.669 278.073C282.047 277.907 281.406 278.275 281.239 278.897L281.15 279.229C280.937 280.024 281.409 280.841 282.204 281.054L282.531 281.142C283.156 281.309 283.798 280.939 283.966 280.314C284.227 279.338 283.644 278.335 282.669 278.073Z" fill="#9055A2"/>
        <path id="Rectangle 454" d="M145.622 515.446C145 515.28 144.359 515.648 144.193 516.27L144.103 516.602C143.89 517.397 144.362 518.214 145.157 518.427L145.484 518.515C146.109 518.683 146.751 518.312 146.919 517.687C147.18 516.711 146.597 515.708 145.622 515.446Z" fill="#9055A2"/>
        <path id="Rectangle 215" d="M269.877 272.329C269.255 272.162 268.614 272.53 268.447 273.152L268.358 273.485C268.145 274.28 268.617 275.097 269.412 275.31L269.739 275.397C270.364 275.565 271.006 275.194 271.174 274.569C271.435 273.594 270.852 272.59 269.877 272.329Z" fill="#9055A2"/>
        <path id="Rectangle 455" d="M132.83 509.702C132.208 509.535 131.567 509.903 131.401 510.525L131.311 510.858C131.098 511.653 131.57 512.47 132.365 512.683L132.692 512.77C133.317 512.938 133.959 512.567 134.127 511.942C134.388 510.967 133.806 509.963 132.83 509.702Z" fill="#9055A2"/>
        <path id="Rectangle 216" d="M294.62 259.801C293.998 259.634 293.357 260.002 293.191 260.624L293.101 260.957C292.888 261.752 293.36 262.569 294.155 262.782L294.482 262.87C295.107 263.037 295.749 262.666 295.917 262.041C296.178 261.066 295.596 260.062 294.62 259.801Z" fill="#9055A2"/>
        <path id="Rectangle 456" d="M157.573 497.176C156.952 497.009 156.31 497.377 156.144 497.999L156.055 498.332C155.842 499.127 156.313 499.944 157.108 500.157L157.435 500.245C158.06 500.412 158.702 500.041 158.87 499.416C159.131 498.441 158.549 497.437 157.573 497.176Z" fill="#9055A2"/>
        <path id="Rectangle 217" d="M279.156 234.457C278.535 234.29 277.893 234.659 277.727 235.28L277.638 235.613C277.425 236.408 277.896 237.225 278.691 237.438L279.018 237.526C279.643 237.693 280.285 237.322 280.453 236.697C280.714 235.722 280.132 234.718 279.156 234.457Z" fill="#9055A2"/>
        <path id="Rectangle 457" d="M142.109 471.83C141.488 471.663 140.846 472.032 140.68 472.653L140.591 472.986C140.378 473.781 140.849 474.598 141.644 474.811L141.971 474.899C142.596 475.066 143.239 474.695 143.406 474.07C143.667 473.095 143.085 472.091 142.109 471.83Z" fill="#9055A2"/>
        <path id="Rectangle 218" d="M241.895 246.87C241.273 246.703 240.632 247.071 240.465 247.693L240.376 248.026C240.163 248.821 240.635 249.638 241.429 249.851L241.756 249.938C242.381 250.106 243.024 249.735 243.191 249.11C243.453 248.135 242.87 247.131 241.895 246.87Z" fill="#9055A2"/>
        <path id="Rectangle 458" d="M104.848 484.243C104.226 484.076 103.585 484.444 103.418 485.066L103.329 485.399C103.116 486.194 103.588 487.011 104.383 487.224L104.709 487.311C105.334 487.479 105.977 487.108 106.144 486.483C106.406 485.508 105.823 484.504 104.848 484.243Z" fill="#9055A2"/>
        <path id="Rectangle 219" d="M262.387 206.327C263.008 206.494 263.65 206.125 263.816 205.504L263.905 205.171C264.118 204.376 263.647 203.559 262.852 203.346L262.525 203.258C261.9 203.091 261.258 203.462 261.09 204.087C260.829 205.062 261.411 206.066 262.387 206.327Z" fill="#9055A2"/>
        <path id="Rectangle 459" d="M125.34 443.7C125.961 443.867 126.603 443.498 126.769 442.877L126.859 442.544C127.072 441.749 126.6 440.932 125.805 440.719L125.478 440.631C124.853 440.464 124.211 440.835 124.043 441.46C123.782 442.435 124.364 443.439 125.34 443.7Z" fill="#9055A2"/>
        <path id="Rectangle 220" d="M275.18 212.073C275.801 212.24 276.443 211.872 276.609 211.25L276.698 210.917C276.911 210.122 276.44 209.305 275.645 209.092L275.318 209.004C274.693 208.837 274.051 209.208 273.883 209.833C273.622 210.808 274.204 211.812 275.18 212.073Z" fill="#9055A2"/>
        <path id="Rectangle 460" d="M138.134 449.444C138.755 449.61 139.397 449.242 139.563 448.621L139.653 448.288C139.866 447.493 139.394 446.676 138.599 446.463L138.272 446.375C137.647 446.208 137.005 446.579 136.837 447.203C136.576 448.179 137.158 449.183 138.134 449.444Z" fill="#9055A2"/>
        <path id="Rectangle 221" d="M250.437 224.601C251.059 224.768 251.7 224.399 251.867 223.778L251.956 223.445C252.169 222.65 251.697 221.833 250.903 221.62L250.576 221.532C249.951 221.365 249.308 221.736 249.141 222.361C248.88 223.336 249.462 224.34 250.437 224.601Z" fill="#9055A2"/>
        <path id="Rectangle 461" d="M113.391 461.972C114.012 462.139 114.654 461.77 114.82 461.149L114.909 460.816C115.122 460.021 114.651 459.204 113.856 458.991L113.529 458.903C112.904 458.736 112.261 459.107 112.094 459.732C111.833 460.707 112.415 461.711 113.391 461.972Z" fill="#9055A2"/>
        <path id="Rectangle 222" d="M265.899 249.943C266.521 250.11 267.162 249.742 267.329 249.12L267.418 248.787C267.631 247.992 267.159 247.175 266.364 246.962L266.038 246.875C265.413 246.707 264.77 247.078 264.603 247.703C264.341 248.678 264.924 249.682 265.899 249.943Z" fill="#9055A2"/>
        <path id="Rectangle 462" d="M128.853 487.314C129.474 487.481 130.116 487.113 130.282 486.491L130.371 486.158C130.584 485.363 130.113 484.546 129.318 484.333L128.991 484.246C128.366 484.078 127.723 484.449 127.556 485.074C127.295 486.05 127.877 487.053 128.853 487.314Z" fill="#9055A2"/>
        <path id="Rectangle 223" d="M303.163 237.53C303.785 237.697 304.426 237.329 304.593 236.707L304.682 236.374C304.895 235.579 304.423 234.762 303.628 234.549L303.301 234.462C302.676 234.294 302.034 234.665 301.866 235.29C301.605 236.265 302.188 237.269 303.163 237.53Z" fill="#9055A2"/>
        <path id="Rectangle 463" d="M166.116 474.903C166.738 475.07 167.379 474.702 167.546 474.08L167.635 473.747C167.848 472.952 167.376 472.135 166.581 471.922L166.254 471.835C165.629 471.667 164.987 472.038 164.82 472.663C164.558 473.638 165.141 474.642 166.116 474.903Z" fill="#9055A2"/>
        <path id="Rectangle 464" d="M117.13 347.033C117.752 347.2 118.393 346.832 118.559 346.21L118.649 345.877C118.862 345.082 118.39 344.265 117.595 344.052L117.268 343.964C116.643 343.797 116.001 344.168 115.833 344.793C115.572 345.768 116.154 346.772 117.13 347.033Z" fill="#9055A2"/>
        <path id="Rectangle 465" d="M129.922 352.777C130.544 352.944 131.185 352.576 131.351 351.954L131.441 351.621C131.654 350.826 131.182 350.009 130.387 349.796L130.06 349.709C129.435 349.541 128.793 349.912 128.625 350.537C128.364 351.512 128.946 352.516 129.922 352.777Z" fill="#9055A2"/>
        <path id="Rectangle 466" d="M105.179 365.304C105.8 365.47 106.442 365.102 106.608 364.48L106.697 364.148C106.91 363.353 106.439 362.536 105.644 362.323L105.317 362.235C104.692 362.067 104.05 362.438 103.882 363.063C103.621 364.039 104.203 365.042 105.179 365.304Z" fill="#9055A2"/>
        <path id="Rectangle 227" d="M257.689 153.274C258.311 153.441 258.952 153.073 259.119 152.451L259.208 152.118C259.421 151.323 258.949 150.506 258.155 150.293L257.828 150.206C257.203 150.038 256.56 150.409 256.393 151.034C256.131 152.009 256.714 153.013 257.689 153.274Z" fill="#9055A2"/>
        <path id="Rectangle 467" d="M120.643 390.647C121.264 390.814 121.906 390.446 122.072 389.824L122.161 389.491C122.374 388.696 121.903 387.879 121.108 387.666L120.781 387.579C120.156 387.411 119.513 387.782 119.346 388.407C119.085 389.383 119.667 390.386 120.643 390.647Z" fill="#9055A2"/>
        <path id="Rectangle 228" d="M294.953 140.863C295.575 141.03 296.216 140.662 296.383 140.04L296.472 139.707C296.685 138.912 296.213 138.095 295.418 137.882L295.091 137.795C294.466 137.627 293.824 137.998 293.657 138.623C293.395 139.598 293.978 140.602 294.953 140.863Z" fill="#9055A2"/>
        <path id="Rectangle 468" d="M157.905 378.234C158.527 378.401 159.168 378.033 159.335 377.411L159.424 377.078C159.637 376.283 159.165 375.466 158.37 375.253L158.044 375.166C157.419 374.998 156.776 375.369 156.609 375.994C156.347 376.969 156.93 377.973 157.905 378.234Z" fill="#9055A2"/>
        <path id="Rectangle 229" d="M274.461 181.405C273.839 181.239 273.198 181.607 273.031 182.229L272.942 182.561C272.729 183.356 273.201 184.173 273.996 184.386L274.323 184.474C274.948 184.642 275.59 184.271 275.758 183.646C276.019 182.67 275.436 181.667 274.461 181.405Z" fill="#9055A2"/>
        <path id="Rectangle 469" d="M137.412 418.777C136.79 418.61 136.149 418.979 135.983 419.6L135.893 419.933C135.68 420.728 136.152 421.545 136.947 421.758L137.274 421.846C137.899 422.013 138.541 421.642 138.709 421.017C138.97 420.042 138.388 419.038 137.412 418.777Z" fill="#9055A2"/>
        <path id="Rectangle 230" d="M261.665 175.66C261.043 175.494 260.402 175.862 260.236 176.484L260.146 176.816C259.933 177.611 260.405 178.428 261.2 178.641L261.527 178.729C262.152 178.896 262.794 178.525 262.962 177.901C263.223 176.925 262.64 175.922 261.665 175.66Z" fill="#9055A2"/>
        <path id="Rectangle 470" d="M124.618 413.033C123.997 412.867 123.355 413.235 123.189 413.857L123.099 414.189C122.886 414.984 123.358 415.801 124.153 416.014L124.48 416.102C125.105 416.269 125.747 415.899 125.915 415.274C126.176 414.298 125.594 413.295 124.618 413.033Z" fill="#9055A2"/>
        <path id="Rectangle 231" d="M286.409 163.134C285.788 162.967 285.146 163.335 284.98 163.957L284.89 164.29C284.677 165.085 285.149 165.902 285.944 166.115L286.271 166.203C286.896 166.37 287.538 165.999 287.706 165.374C287.967 164.399 287.385 163.395 286.409 163.134Z" fill="#9055A2"/>
        <path id="Rectangle 471" d="M149.362 400.507C148.741 400.34 148.099 400.709 147.933 401.33L147.844 401.663C147.631 402.458 148.102 403.275 148.897 403.488L149.224 403.576C149.849 403.743 150.491 403.372 150.659 402.747C150.92 401.772 150.338 400.768 149.362 400.507Z" fill="#9055A2"/>
        <path id="Rectangle 232" d="M270.947 137.79C270.326 137.623 269.684 137.991 269.518 138.613L269.429 138.946C269.216 139.741 269.687 140.558 270.482 140.771L270.809 140.858C271.434 141.026 272.076 140.655 272.244 140.03C272.505 139.054 271.923 138.051 270.947 137.79Z" fill="#9055A2"/>
        <path id="Rectangle 472" d="M133.9 375.163C133.279 374.996 132.637 375.364 132.471 375.986L132.382 376.319C132.169 377.114 132.64 377.931 133.435 378.144L133.762 378.231C134.387 378.399 135.03 378.028 135.197 377.403C135.458 376.428 134.876 375.424 133.9 375.163Z" fill="#9055A2"/>
        <path id="Rectangle 233" d="M233.683 150.201C233.061 150.035 232.42 150.403 232.253 151.025L232.164 151.357C231.951 152.152 232.423 152.969 233.218 153.182L233.544 153.27C234.169 153.437 234.812 153.067 234.979 152.442C235.241 151.466 234.658 150.463 233.683 150.201Z" fill="#9055A2"/>
        <path id="Rectangle 473" d="M96.6358 387.574C96.0141 387.408 95.3728 387.776 95.2062 388.398L95.117 388.73C94.904 389.525 95.3758 390.342 96.1707 390.555L96.4975 390.643C97.1225 390.81 97.7649 390.44 97.9324 389.815C98.1937 388.839 97.6112 387.836 96.6358 387.574Z" fill="#9055A2"/>
        <path id="Rectangle 234" d="M227.923 217.462C227.301 217.296 226.66 217.664 226.493 218.286L226.404 218.619C226.191 219.414 226.663 220.231 227.458 220.444L227.785 220.531C228.41 220.699 229.052 220.328 229.219 219.703C229.481 218.727 228.898 217.724 227.923 217.462Z" fill="#9055A2"/>
        <path id="Rectangle 474" d="M90.875 454.835C90.2534 454.669 89.612 455.037 89.4455 455.659L89.3563 455.992C89.1433 456.787 89.615 457.604 90.4099 457.817L90.7368 457.904C91.3617 458.072 92.0041 457.701 92.1716 457.076C92.433 456.1 91.8504 455.097 90.875 454.835Z" fill="#9055A2"/>
        <path id="Rectangle 235" d="M215.131 211.722C214.509 211.556 213.868 211.924 213.701 212.546L213.612 212.878C213.399 213.673 213.871 214.49 214.666 214.703L214.993 214.791C215.618 214.958 216.26 214.588 216.427 213.963C216.689 212.987 216.106 211.984 215.131 211.722Z" fill="#9055A2"/>
        <path id="Rectangle 475" d="M78.082 449.091C77.4604 448.925 76.8191 449.293 76.6525 449.915L76.5633 450.248C76.3503 451.042 76.8221 451.86 77.617 452.073L77.9438 452.16C78.5688 452.328 79.2112 451.957 79.3786 451.332C79.64 450.356 79.0575 449.353 78.082 449.091Z" fill="#9055A2"/>
        <path id="Rectangle 236" d="M239.874 199.194C239.252 199.027 238.611 199.396 238.444 200.017L238.355 200.35C238.142 201.145 238.614 201.962 239.409 202.175L239.736 202.263C240.361 202.43 241.003 202.059 241.171 201.434C241.432 200.459 240.849 199.455 239.874 199.194Z" fill="#9055A2"/>
        <path id="Rectangle 476" d="M102.827 436.565C102.206 436.398 101.564 436.767 101.398 437.388L101.308 437.721C101.095 438.516 101.567 439.333 102.362 439.546L102.689 439.634C103.314 439.801 103.956 439.43 104.124 438.805C104.385 437.83 103.803 436.826 102.827 436.565Z" fill="#9055A2"/>
        <path id="Rectangle 237" d="M224.41 173.848C223.789 173.682 223.147 174.05 222.981 174.671L222.891 175.004C222.678 175.799 223.15 176.616 223.945 176.829L224.272 176.917C224.897 177.084 225.539 176.713 225.707 176.088C225.968 175.113 225.386 174.109 224.41 173.848Z" fill="#9055A2"/>
        <path id="Rectangle 477" d="M87.3633 411.221C86.7416 411.055 86.1003 411.423 85.9338 412.045L85.8446 412.377C85.6316 413.172 86.1033 413.989 86.8982 414.202L87.225 414.29C87.85 414.457 88.4924 414.087 88.6599 413.462C88.9213 412.486 88.3387 411.483 87.3633 411.221Z" fill="#9055A2"/>
        <path id="Rectangle 238" d="M187.148 186.263C186.527 186.096 185.885 186.464 185.719 187.086L185.63 187.419C185.417 188.214 185.888 189.031 186.683 189.244L187.01 189.331C187.635 189.499 188.278 189.128 188.445 188.503C188.706 187.528 188.124 186.524 187.148 186.263Z" fill="#9055A2"/>
        <path id="Rectangle 239" d="M207.641 145.719C208.262 145.885 208.904 145.517 209.07 144.895L209.159 144.563C209.372 143.768 208.901 142.951 208.106 142.738L207.779 142.65C207.154 142.483 206.511 142.853 206.344 143.478C206.083 144.454 206.665 145.457 207.641 145.719Z" fill="#9055A2"/>
        <path id="Rectangle 240" d="M220.435 151.464C221.056 151.631 221.698 151.263 221.864 150.641L221.953 150.308C222.166 149.513 221.695 148.696 220.9 148.483L220.573 148.396C219.948 148.228 219.305 148.599 219.138 149.224C218.877 150.199 219.459 151.203 220.435 151.464Z" fill="#9055A2"/>
        <path id="Rectangle 480" d="M83.3877 388.835C84.0093 389.002 84.6507 388.634 84.8172 388.012L84.9064 387.679C85.1194 386.884 84.6477 386.067 83.8527 385.854L83.5259 385.767C82.901 385.599 82.2585 385.97 82.0911 386.595C81.8297 387.571 82.4123 388.574 83.3877 388.835Z" fill="#9055A2"/>
        <path id="Rectangle 241" d="M195.691 163.992C196.313 164.159 196.954 163.79 197.121 163.169L197.21 162.836C197.423 162.041 196.951 161.224 196.156 161.011L195.83 160.923C195.205 160.756 194.562 161.127 194.395 161.752C194.133 162.727 194.716 163.731 195.691 163.992Z" fill="#9055A2"/>
        <path id="Rectangle 242" d="M211.155 189.334C211.777 189.501 212.418 189.132 212.585 188.511L212.674 188.178C212.887 187.383 212.415 186.566 211.62 186.353L211.294 186.265C210.669 186.098 210.026 186.469 209.859 187.094C209.597 188.069 210.18 189.073 211.155 189.334Z" fill="#9055A2"/>
        <path id="Rectangle 243" d="M248.415 176.922C249.037 177.088 249.678 176.72 249.845 176.099L249.934 175.766C250.147 174.971 249.675 174.154 248.88 173.941L248.553 173.853C247.928 173.686 247.286 174.057 247.118 174.682C246.857 175.657 247.44 176.661 248.415 176.922Z" fill="#9055A2"/>
        <path id="Rectangle 483" d="M111.368 414.293C111.99 414.46 112.631 414.091 112.798 413.47L112.887 413.137C113.1 412.342 112.628 411.525 111.833 411.312L111.506 411.224C110.881 411.057 110.239 411.428 110.072 412.053C109.81 413.028 110.393 414.032 111.368 414.293Z" fill="#9055A2"/>
        <path id="Rectangle 484" d="M252.094 305.667C252.715 305.834 253.357 305.465 253.523 304.844L253.612 304.511C253.825 303.716 253.354 302.899 252.559 302.686L252.232 302.598C251.607 302.431 250.965 302.802 250.797 303.427C250.536 304.402 251.118 305.406 252.094 305.667Z" fill="#9055A2"/>
        <path id="Rectangle 485" d="M264.886 311.412C265.507 311.578 266.149 311.21 266.315 310.588L266.404 310.255C266.617 309.461 266.146 308.643 265.351 308.43L265.024 308.343C264.399 308.175 263.757 308.546 263.589 309.171C263.328 310.147 263.91 311.15 264.886 311.412Z" fill="#9055A2"/>
        <path id="Rectangle 486" d="M240.143 323.938C240.764 324.104 241.406 323.736 241.572 323.114L241.661 322.781C241.874 321.986 241.403 321.169 240.608 320.956L240.281 320.869C239.656 320.701 239.013 321.072 238.846 321.697C238.585 322.673 239.167 323.676 240.143 323.938Z" fill="#9055A2"/>
        <path id="Rectangle 487" d="M255.606 349.281C256.228 349.448 256.869 349.08 257.036 348.458L257.125 348.125C257.338 347.33 256.866 346.513 256.071 346.3L255.745 346.212C255.12 346.045 254.477 346.416 254.31 347.041C254.048 348.016 254.631 349.02 255.606 349.281Z" fill="#9055A2"/>
        <path id="Rectangle 488" d="M292.866 336.869C293.488 337.036 294.129 336.667 294.296 336.046L294.385 335.713C294.598 334.918 294.126 334.101 293.331 333.888L293.004 333.8C292.379 333.633 291.737 334.004 291.57 334.629C291.308 335.604 291.891 336.608 292.866 336.869Z" fill="#9055A2"/>
        <path id="Rectangle 249" d="M409.421 140.039C408.799 139.872 408.158 140.24 407.991 140.862L407.902 141.195C407.689 141.99 408.161 142.807 408.956 143.02L409.283 143.107C409.908 143.275 410.55 142.904 410.718 142.279C410.979 141.304 410.396 140.3 409.421 140.039Z" fill="#9055A2"/>
        <path id="Rectangle 489" d="M272.374 377.412C271.752 377.245 271.111 377.613 270.944 378.235L270.855 378.568C270.642 379.363 271.114 380.18 271.909 380.393L272.236 380.48C272.861 380.648 273.503 380.277 273.671 379.652C273.932 378.677 273.349 377.673 272.374 377.412Z" fill="#9055A2"/>
        <path id="Rectangle 250" d="M396.629 134.294C396.007 134.127 395.366 134.496 395.199 135.117L395.11 135.45C394.897 136.245 395.369 137.062 396.164 137.275L396.491 137.363C397.116 137.53 397.758 137.159 397.926 136.534C398.187 135.559 397.604 134.555 396.629 134.294Z" fill="#9055A2"/>
        <path id="Rectangle 490" d="M259.582 371.667C258.96 371.501 258.319 371.869 258.153 372.491L258.063 372.824C257.85 373.619 258.322 374.436 259.117 374.649L259.444 374.736C260.069 374.904 260.711 374.533 260.879 373.908C261.14 372.932 260.557 371.929 259.582 371.667Z" fill="#9055A2"/>
        <path id="Rectangle 491" d="M284.325 359.139C283.704 358.973 283.062 359.341 282.896 359.963L282.806 360.295C282.593 361.09 283.065 361.907 283.86 362.12L284.187 362.208C284.812 362.375 285.454 362.004 285.622 361.38C285.883 360.404 285.301 359.401 284.325 359.139Z" fill="#9055A2"/>
        <path id="Rectangle 492" d="M268.861 333.795C268.24 333.629 267.598 333.997 267.432 334.619L267.343 334.952C267.13 335.747 267.601 336.564 268.396 336.777L268.723 336.864C269.348 337.032 269.99 336.661 270.158 336.036C270.419 335.06 269.837 334.057 268.861 333.795Z" fill="#9055A2"/>
        <path id="Rectangle 493" d="M231.6 346.21C230.978 346.043 230.337 346.412 230.17 347.033L230.081 347.366C229.868 348.161 230.34 348.978 231.135 349.191L231.461 349.279C232.086 349.446 232.729 349.075 232.896 348.45C233.158 347.475 232.575 346.471 231.6 346.21Z" fill="#9055A2"/>
        <path id="Rectangle 254" d="M362.884 176.099C362.262 175.932 361.621 176.3 361.454 176.922L361.365 177.255C361.152 178.05 361.624 178.867 362.419 179.08L362.746 179.167C363.371 179.335 364.013 178.964 364.18 178.339C364.442 177.364 363.859 176.36 362.884 176.099Z" fill="#9055A2"/>
        <path id="Rectangle 494" d="M225.837 413.47C225.215 413.304 224.574 413.672 224.407 414.294L224.318 414.626C224.105 415.421 224.577 416.238 225.372 416.451L225.699 416.539C226.324 416.706 226.966 416.336 227.134 415.711C227.395 414.735 226.812 413.732 225.837 413.47Z" fill="#9055A2"/>
        <path id="Rectangle 255" d="M350.095 170.354C349.473 170.187 348.832 170.556 348.665 171.177L348.576 171.51C348.363 172.305 348.835 173.122 349.63 173.335L349.956 173.423C350.581 173.59 351.224 173.219 351.391 172.594C351.653 171.619 351.07 170.615 350.095 170.354Z" fill="#9055A2"/>
        <path id="Rectangle 495" d="M213.048 407.725C212.426 407.559 211.785 407.927 211.618 408.548L211.529 408.881C211.316 409.676 211.788 410.493 212.583 410.706L212.91 410.794C213.535 410.961 214.177 410.59 214.344 409.965C214.606 408.99 214.023 407.986 213.048 407.725Z" fill="#9055A2"/>
        <path id="Rectangle 256" d="M374.837 157.826C374.215 157.66 373.574 158.028 373.407 158.65L373.318 158.982C373.105 159.777 373.577 160.594 374.372 160.807L374.699 160.895C375.324 161.062 375.966 160.692 376.134 160.067C376.395 159.091 375.812 158.088 374.837 157.826Z" fill="#9055A2"/>
        <path id="Rectangle 496" d="M237.79 395.199C237.168 395.033 236.527 395.401 236.361 396.023L236.271 396.355C236.058 397.15 236.53 397.967 237.325 398.18L237.652 398.268C238.277 398.435 238.919 398.065 239.087 397.44C239.348 396.464 238.765 395.461 237.79 395.199Z" fill="#9055A2"/>
        <path id="Rectangle 497" d="M222.324 369.856C221.703 369.689 221.061 370.058 220.895 370.679L220.806 371.012C220.593 371.807 221.064 372.624 221.859 372.837L222.186 372.925C222.811 373.092 223.453 372.721 223.621 372.096C223.882 371.121 223.3 370.117 222.324 369.856Z" fill="#9055A2"/>
        <path id="Rectangle 258" d="M322.111 144.897C321.49 144.73 320.848 145.099 320.682 145.72L320.593 146.053C320.38 146.848 320.851 147.665 321.646 147.878L321.973 147.966C322.598 148.133 323.24 147.762 323.408 147.137C323.669 146.162 323.087 145.158 322.111 144.897Z" fill="#9055A2"/>
        <path id="Rectangle 498" d="M185.064 382.268C184.443 382.101 183.801 382.47 183.635 383.091L183.546 383.424C183.333 384.219 183.804 385.036 184.599 385.249L184.926 385.337C185.551 385.504 186.194 385.133 186.361 384.508C186.622 383.533 186.04 382.529 185.064 382.268Z" fill="#9055A2"/>
        <path id="Rectangle 499" d="M205.554 341.725C206.175 341.891 206.817 341.523 206.983 340.901L207.072 340.568C207.285 339.773 206.814 338.956 206.019 338.743L205.692 338.656C205.067 338.488 204.425 338.859 204.257 339.484C203.996 340.46 204.578 341.463 205.554 341.725Z" fill="#9055A2"/>
        <path id="Rectangle 500" d="M218.349 347.47C218.97 347.637 219.612 347.269 219.778 346.647L219.867 346.314C220.08 345.519 219.609 344.702 218.814 344.489L218.487 344.401C217.862 344.234 217.219 344.605 217.052 345.23C216.791 346.205 217.373 347.209 218.349 347.47Z" fill="#9055A2"/>
        <path id="Rectangle 501" d="M193.604 359.996C194.226 360.163 194.867 359.794 195.034 359.173L195.123 358.84C195.336 358.045 194.864 357.228 194.07 357.015L193.743 356.927C193.118 356.76 192.475 357.131 192.308 357.756C192.047 358.731 192.629 359.735 193.604 359.996Z" fill="#9055A2"/>
        <path id="Rectangle 262" d="M346.116 147.969C346.738 148.135 347.379 147.767 347.546 147.145L347.635 146.813C347.848 146.018 347.376 145.201 346.581 144.988L346.254 144.9C345.629 144.733 344.987 145.103 344.82 145.728C344.558 146.704 345.141 147.707 346.116 147.969Z" fill="#9055A2"/>
        <path id="Rectangle 502" d="M209.069 385.34C209.691 385.506 210.332 385.138 210.499 384.516L210.588 384.184C210.801 383.389 210.329 382.572 209.534 382.359L209.208 382.271C208.583 382.104 207.94 382.475 207.773 383.099C207.511 384.075 208.094 385.078 209.069 385.34Z" fill="#9055A2"/>
        <path id="Rectangle 503" d="M246.331 372.925C246.953 373.091 247.594 372.723 247.761 372.101L247.85 371.769C248.063 370.974 247.591 370.157 246.796 369.944L246.469 369.856C245.844 369.689 245.202 370.059 245.034 370.684C244.773 371.66 245.356 372.663 246.331 372.925Z" fill="#9055A2"/>
        <path id="Rectangle 504" d="M197.345 245.057C197.966 245.224 198.608 244.855 198.774 244.234L198.863 243.901C199.076 243.106 198.605 242.289 197.81 242.076L197.483 241.988C196.858 241.821 196.216 242.192 196.048 242.817C195.787 243.792 196.369 244.796 197.345 245.057Z" fill="#9055A2"/>
        <path id="Rectangle 505" d="M210.137 250.801C210.758 250.968 211.4 250.6 211.566 249.978L211.655 249.645C211.868 248.85 211.397 248.033 210.602 247.82L210.275 247.733C209.65 247.565 209.008 247.936 208.84 248.561C208.579 249.536 209.161 250.54 210.137 250.801Z" fill="#9055A2"/>
        <path id="Rectangle 506" d="M185.396 263.329C186.018 263.496 186.659 263.127 186.826 262.506L186.915 262.173C187.128 261.378 186.656 260.561 185.862 260.348L185.535 260.26C184.91 260.093 184.267 260.464 184.1 261.089C183.839 262.064 184.421 263.068 185.396 263.329Z" fill="#9055A2"/>
        <path id="Rectangle 507" d="M200.86 288.672C201.482 288.839 202.123 288.471 202.29 287.849L202.379 287.516C202.592 286.721 202.12 285.904 201.325 285.691L200.999 285.604C200.374 285.436 199.731 285.807 199.564 286.432C199.302 287.407 199.885 288.411 200.86 288.672Z" fill="#9055A2"/>
        <path id="Rectangle 508" d="M238.122 276.26C238.744 276.426 239.385 276.058 239.552 275.436L239.641 275.104C239.854 274.309 239.382 273.492 238.587 273.279L238.26 273.191C237.635 273.024 236.993 273.394 236.825 274.019C236.564 274.995 237.147 275.998 238.122 276.26Z" fill="#9055A2"/>
        <path id="Rectangle 509" d="M217.627 316.801C217.005 316.634 216.364 317.002 216.197 317.624L216.108 317.957C215.895 318.752 216.367 319.569 217.162 319.782L217.489 319.87C218.114 320.037 218.756 319.666 218.924 319.041C219.185 318.066 218.602 317.062 217.627 316.801Z" fill="#9055A2"/>
        <path id="Rectangle 510" d="M204.836 311.057C204.214 310.89 203.573 311.258 203.406 311.88L203.317 312.213C203.104 313.008 203.576 313.825 204.371 314.038L204.698 314.125C205.323 314.293 205.965 313.922 206.133 313.297C206.394 312.322 205.811 311.318 204.836 311.057Z" fill="#9055A2"/>
        <path id="Rectangle 511" d="M229.577 298.529C228.956 298.362 228.314 298.731 228.148 299.352L228.058 299.685C227.845 300.48 228.317 301.297 229.112 301.51L229.439 301.598C230.064 301.765 230.706 301.394 230.874 300.769C231.135 299.794 230.553 298.79 229.577 298.529Z" fill="#9055A2"/>
        <path id="Rectangle 512" d="M214.115 273.188C213.494 273.022 212.852 273.39 212.686 274.012L212.597 274.345C212.384 275.14 212.855 275.957 213.65 276.17L213.977 276.257C214.602 276.425 215.244 276.054 215.412 275.429C215.673 274.453 215.091 273.45 214.115 273.188Z" fill="#9055A2"/>
        <path id="Rectangle 513" d="M176.854 285.599C176.232 285.433 175.591 285.801 175.424 286.422L175.335 286.755C175.122 287.55 175.594 288.367 176.388 288.58L176.715 288.668C177.34 288.835 177.983 288.464 178.15 287.839C178.411 286.864 177.829 285.86 176.854 285.599Z" fill="#9055A2"/>
        <path id="Rectangle 514" d="M171.094 352.86C170.472 352.694 169.831 353.062 169.664 353.684L169.575 354.017C169.362 354.811 169.834 355.629 170.629 355.842L170.956 355.929C171.58 356.097 172.223 355.726 172.39 355.101C172.652 354.125 172.069 353.122 171.094 352.86Z" fill="#9055A2"/>
        <path id="Rectangle 515" d="M158.299 347.115C157.677 346.949 157.036 347.317 156.869 347.939L156.78 348.271C156.567 349.066 157.039 349.883 157.834 350.096L158.161 350.184C158.786 350.351 159.428 349.981 159.595 349.356C159.857 348.38 159.274 347.377 158.299 347.115Z" fill="#9055A2"/>
        <path id="Rectangle 516" d="M183.042 334.589C182.42 334.422 181.779 334.791 181.612 335.412L181.523 335.745C181.31 336.54 181.782 337.357 182.577 337.57L182.904 337.658C183.529 337.825 184.171 337.454 184.339 336.829C184.6 335.854 184.017 334.85 183.042 334.589Z" fill="#9055A2"/>
        <path id="Rectangle 517" d="M167.578 309.247C166.956 309.08 166.315 309.449 166.149 310.07L166.059 310.403C165.846 311.198 166.318 312.015 167.113 312.228L167.44 312.316C168.065 312.483 168.707 312.112 168.875 311.487C169.136 310.512 168.554 309.508 167.578 309.247Z" fill="#9055A2"/>
        <path id="Rectangle 518" d="M130.315 321.658C129.694 321.492 129.052 321.86 128.886 322.482L128.797 322.814C128.584 323.609 129.055 324.426 129.85 324.639L130.177 324.727C130.802 324.894 131.445 324.524 131.612 323.899C131.873 322.923 131.291 321.92 130.315 321.658Z" fill="#9055A2"/>
        <path id="Rectangle 519" d="M150.811 281.117C151.432 281.284 152.074 280.915 152.24 280.294L152.329 279.961C152.542 279.166 152.071 278.349 151.276 278.136L150.949 278.048C150.324 277.881 149.681 278.252 149.514 278.877C149.253 279.852 149.835 280.856 150.811 281.117Z" fill="#9055A2"/>
        <path id="Rectangle 520" d="M163.603 286.861C164.224 287.028 164.866 286.66 165.032 286.038L165.121 285.705C165.334 284.91 164.863 284.093 164.068 283.88L163.741 283.793C163.116 283.625 162.473 283.996 162.306 284.621C162.045 285.596 162.627 286.6 163.603 286.861Z" fill="#9055A2"/>
        <path id="Rectangle 521" d="M138.858 299.386C139.48 299.552 140.121 299.184 140.288 298.562L140.377 298.23C140.59 297.435 140.118 296.618 139.323 296.405L138.997 296.317C138.372 296.15 137.729 296.52 137.562 297.145C137.3 298.121 137.883 299.124 138.858 299.386Z" fill="#9055A2"/>
        <path id="Rectangle 522" d="M154.323 324.731C154.945 324.898 155.586 324.529 155.753 323.908L155.842 323.575C156.055 322.78 155.583 321.963 154.788 321.75L154.461 321.662C153.837 321.495 153.194 321.866 153.027 322.491C152.765 323.466 153.348 324.47 154.323 324.731Z" fill="#9055A2"/>
        <path id="Rectangle 523" d="M191.585 312.318C192.207 312.485 192.848 312.117 193.014 311.495L193.104 311.162C193.317 310.367 192.845 309.55 192.05 309.337L191.723 309.25C191.098 309.082 190.456 309.453 190.288 310.078C190.027 311.053 190.61 312.057 191.585 312.318Z" fill="#9055A2"/>
        <path id="Rectangle 528" d="M102.418 312.274C103.04 312.441 103.681 312.072 103.847 311.451L103.937 311.118C104.15 310.323 103.678 309.506 102.883 309.293L102.556 309.205C101.931 309.038 101.289 309.409 101.121 310.034C100.86 311.009 101.443 312.013 102.418 312.274Z" fill="#9055A2"/>
        <path id="Rectangle 529" d="M81.9258 352.816C81.3041 352.65 80.6628 353.018 80.4963 353.64L80.4071 353.973C80.1941 354.768 80.6658 355.585 81.4607 355.798L81.7875 355.885C82.4125 356.053 83.0549 355.682 83.2224 355.057C83.4838 354.081 82.9012 353.078 81.9258 352.816Z" fill="#9055A2"/>
        <path id="Rectangle 531" d="M93.875 334.544C93.2534 334.378 92.612 334.746 92.4455 335.368L92.3563 335.701C92.1433 336.496 92.615 337.313 93.4099 337.526L93.7368 337.613C94.3617 337.781 95.0041 337.41 95.1716 336.785C95.433 335.809 94.8504 334.806 93.875 334.544Z" fill="#9055A2"/>
        <path id="Rectangle 532" d="M78.4131 309.202C77.7915 309.036 77.1501 309.404 76.9836 310.025L76.8944 310.358C76.6814 311.153 77.1531 311.97 77.948 312.183L78.2748 312.271C78.8998 312.438 79.5422 312.067 79.7097 311.443C79.9711 310.467 79.3885 309.463 78.4131 309.202Z" fill="#9055A2"/>
        <path id="Rectangle 982" d="M514.642 471.528C514.02 471.362 513.379 471.73 513.212 472.352L513.123 472.685C512.91 473.479 513.382 474.297 514.177 474.51L514.503 474.597C515.128 474.765 515.771 474.394 515.938 473.769C516.2 472.793 515.617 471.79 514.642 471.528Z" fill="#9055A2"/>
        <path id="Rectangle 294" d="M172.435 151.502C171.813 151.335 171.172 151.704 171.005 152.325L170.916 152.658C170.703 153.453 171.175 154.27 171.97 154.483L172.296 154.571C172.921 154.738 173.564 154.367 173.731 153.742C173.993 152.767 173.41 151.763 172.435 151.502Z" fill="#9055A2"/>
        <path id="Rectangle 984" d="M508.879 538.79C508.257 538.623 507.616 538.992 507.449 539.613L507.36 539.946C507.147 540.741 507.619 541.558 508.414 541.771L508.741 541.859C509.366 542.026 510.008 541.655 510.176 541.03C510.437 540.055 509.854 539.051 508.879 538.79Z" fill="#9055A2"/>
        <path id="Rectangle 295" d="M159.643 145.757C159.021 145.591 158.38 145.959 158.213 146.581L158.124 146.914C157.911 147.708 158.383 148.526 159.178 148.739L159.504 148.826C160.129 148.994 160.772 148.623 160.939 147.998C161.201 147.022 160.618 146.019 159.643 145.757Z" fill="#9055A2"/>
        <path id="Rectangle 986" d="M496.087 533.046C495.465 532.879 494.824 533.248 494.657 533.869L494.568 534.202C494.355 534.997 494.827 535.814 495.622 536.027L495.949 536.115C496.574 536.282 497.216 535.911 497.384 535.286C497.645 534.311 497.062 533.307 496.087 533.046Z" fill="#9055A2"/>
        <path id="Rectangle 296" d="M184.387 133.231C183.765 133.065 183.124 133.433 182.957 134.055L182.868 134.388C182.655 135.183 183.127 136 183.922 136.213L184.248 136.3C184.873 136.468 185.516 136.097 185.683 135.472C185.945 134.496 185.362 133.493 184.387 133.231Z" fill="#9055A2"/>
        <path id="Rectangle 990" d="M505.366 495.174C504.745 495.008 504.103 495.376 503.937 495.998L503.847 496.331C503.634 497.125 504.106 497.943 504.901 498.156L505.228 498.243C505.853 498.411 506.495 498.04 506.663 497.415C506.924 496.439 506.342 495.436 505.366 495.174Z" fill="#9055A2"/>
        <path id="Rectangle 992" d="M468.105 507.587C467.483 507.42 466.842 507.789 466.675 508.41L466.586 508.743C466.373 509.538 466.845 510.355 467.639 510.568L467.966 510.656C468.591 510.823 469.234 510.452 469.401 509.827C469.662 508.852 469.08 507.848 468.105 507.587Z" fill="#9055A2"/>
        <path id="Rectangle 994" d="M488.597 467.042C489.218 467.209 489.86 466.841 490.026 466.219L490.115 465.886C490.328 465.091 489.857 464.274 489.062 464.061L488.735 463.974C488.11 463.806 487.468 464.177 487.3 464.802C487.039 465.778 487.621 466.781 488.597 467.042Z" fill="#9055A2"/>
        <path id="Rectangle 996" d="M501.389 472.789C502.01 472.955 502.652 472.587 502.818 471.965L502.907 471.632C503.12 470.837 502.649 470.02 501.854 469.807L501.527 469.72C500.902 469.552 500.26 469.923 500.092 470.548C499.831 471.524 500.413 472.527 501.389 472.789Z" fill="#9055A2"/>
        <path id="Rectangle 998" d="M476.647 485.316C477.269 485.483 477.91 485.115 478.077 484.493L478.166 484.16C478.379 483.365 477.907 482.548 477.113 482.335L476.786 482.248C476.161 482.08 475.518 482.451 475.351 483.076C475.089 484.051 475.672 485.055 476.647 485.316Z" fill="#9055A2"/>
        <path id="Rectangle 1000" d="M492.109 510.659C492.731 510.825 493.372 510.457 493.539 509.835L493.628 509.502C493.841 508.708 493.369 507.89 492.574 507.677L492.248 507.59C491.623 507.422 490.98 507.793 490.813 508.418C490.551 509.394 491.134 510.397 492.109 510.659Z" fill="#9055A2"/>
        <path id="Rectangle 1004" d="M480.387 370.377C481.008 370.544 481.65 370.176 481.816 369.554L481.905 369.221C482.118 368.426 481.647 367.609 480.852 367.396L480.525 367.309C479.9 367.141 479.258 367.512 479.09 368.137C478.829 369.113 479.411 370.116 480.387 370.377Z" fill="#9055A2"/>
        <path id="Rectangle 1006" d="M493.178 376.12C493.799 376.286 494.441 375.918 494.607 375.296L494.696 374.963C494.909 374.169 494.438 373.351 493.643 373.138L493.316 373.051C492.691 372.883 492.049 373.254 491.881 373.879C491.62 374.855 492.202 375.858 493.178 376.12Z" fill="#9055A2"/>
        <path id="Rectangle 1008" d="M468.436 388.648C469.057 388.815 469.699 388.446 469.865 387.825L469.954 387.492C470.167 386.697 469.696 385.88 468.901 385.667L468.574 385.579C467.949 385.412 467.306 385.783 467.139 386.408C466.878 387.383 467.46 388.387 468.436 388.648Z" fill="#9055A2"/>
        <path id="Rectangle 1010" d="M483.899 413.992C484.521 414.158 485.162 413.79 485.329 413.168L485.418 412.836C485.631 412.041 485.159 411.223 484.364 411.01L484.038 410.923C483.413 410.755 482.77 411.126 482.603 411.751C482.341 412.727 482.924 413.73 483.899 413.992Z" fill="#9055A2"/>
        <path id="Rectangle 1014" d="M500.67 442.121C500.048 441.955 499.407 442.323 499.24 442.944L499.151 443.277C498.938 444.072 499.41 444.889 500.205 445.102L500.532 445.19C501.157 445.357 501.799 444.986 501.967 444.361C502.228 443.386 501.645 442.382 500.67 442.121Z" fill="#9055A2"/>
        <path id="Rectangle 1016" d="M487.876 436.375C487.254 436.208 486.613 436.577 486.446 437.198L486.357 437.531C486.144 438.326 486.616 439.143 487.411 439.356L487.738 439.444C488.363 439.611 489.005 439.24 489.173 438.615C489.434 437.64 488.851 436.636 487.876 436.375Z" fill="#9055A2"/>
        <path id="Rectangle 1018" d="M512.619 423.849C511.998 423.683 511.356 424.051 511.19 424.672L511.1 425.005C510.887 425.8 511.359 426.617 512.154 426.83L512.481 426.918C513.106 427.085 513.748 426.714 513.916 426.089C514.177 425.114 513.595 424.11 512.619 423.849Z" fill="#9055A2"/>
        <path id="Rectangle 1020" d="M497.157 398.507C496.536 398.34 495.894 398.709 495.728 399.33L495.639 399.663C495.426 400.458 495.897 401.275 496.692 401.488L497.019 401.576C497.644 401.743 498.286 401.372 498.454 400.747C498.715 399.772 498.133 398.768 497.157 398.507Z" fill="#9055A2"/>
        <path id="Rectangle 1022" d="M459.893 410.918C459.271 410.752 458.63 411.12 458.463 411.742L458.374 412.075C458.161 412.87 458.633 413.687 459.428 413.9L459.754 413.987C460.379 414.155 461.022 413.784 461.189 413.159C461.451 412.183 460.868 411.18 459.893 410.918Z" fill="#9055A2"/>
        <path id="Rectangle 1024" d="M454.13 478.178C453.508 478.012 452.867 478.38 452.7 479.002L452.611 479.334C452.398 480.129 452.87 480.946 453.665 481.159L453.992 481.247C454.617 481.414 455.259 481.044 455.426 480.419C455.688 479.443 455.105 478.44 454.13 478.178Z" fill="#9055A2"/>
        <path id="Rectangle 1026" d="M441.341 472.435C440.719 472.268 440.078 472.637 439.911 473.258L439.822 473.591C439.609 474.386 440.081 475.203 440.876 475.416L441.203 475.504C441.828 475.671 442.47 475.3 442.637 474.675C442.899 473.7 442.316 472.696 441.341 472.435Z" fill="#9055A2"/>
        <path id="Rectangle 1028" d="M466.084 459.909C465.462 459.743 464.821 460.111 464.654 460.733L464.565 461.065C464.352 461.86 464.824 462.677 465.619 462.89L465.946 462.978C466.571 463.145 467.213 462.775 467.381 462.15C467.642 461.174 467.059 460.171 466.084 459.909Z" fill="#9055A2"/>
        <path id="Rectangle 1030" d="M450.618 434.564C449.997 434.397 449.355 434.766 449.189 435.387L449.099 435.72C448.886 436.515 449.358 437.332 450.153 437.545L450.48 437.633C451.105 437.8 451.747 437.429 451.915 436.804C452.176 435.829 451.594 434.825 450.618 434.564Z" fill="#9055A2"/>
        <path id="Rectangle 1032" d="M413.357 446.979C412.736 446.812 412.094 447.18 411.928 447.802L411.839 448.135C411.626 448.93 412.097 449.747 412.892 449.96L413.219 450.047C413.844 450.215 414.487 449.844 414.654 449.219C414.915 448.243 414.333 447.24 413.357 446.979Z" fill="#9055A2"/>
        <path id="Rectangle 1034" d="M433.851 406.436C434.472 406.602 435.114 406.234 435.28 405.612L435.369 405.279C435.582 404.484 435.111 403.667 434.316 403.454L433.989 403.367C433.364 403.199 432.721 403.57 432.554 404.195C432.293 405.171 432.875 406.174 433.851 406.436Z" fill="#9055A2"/>
        <path id="Rectangle 1036" d="M446.642 412.178C447.263 412.345 447.905 411.977 448.071 411.355L448.16 411.022C448.373 410.227 447.902 409.41 447.107 409.197L446.78 409.109C446.155 408.942 445.512 409.313 445.345 409.938C445.084 410.913 445.666 411.917 446.642 412.178Z" fill="#9055A2"/>
        <path id="Rectangle 1038" d="M421.899 424.706C422.521 424.873 423.162 424.504 423.329 423.883L423.418 423.55C423.631 422.755 423.159 421.938 422.364 421.725L422.038 421.637C421.413 421.47 420.77 421.841 420.603 422.466C420.341 423.441 420.924 424.445 421.899 424.706Z" fill="#9055A2"/>
        <path id="Rectangle 1040" d="M437.363 450.048C437.985 450.214 438.626 449.846 438.793 449.225L438.882 448.892C439.095 448.097 438.623 447.28 437.828 447.067L437.502 446.979C436.877 446.812 436.234 447.183 436.067 447.807C435.805 448.783 436.388 449.787 437.363 450.048Z" fill="#9055A2"/>
        <path id="Rectangle 1042" d="M474.625 437.637C475.247 437.804 475.888 437.436 476.055 436.814L476.144 436.481C476.357 435.686 475.885 434.869 475.09 434.656L474.763 434.568C474.138 434.401 473.496 434.772 473.328 435.397C473.067 436.372 473.65 437.376 474.625 437.637Z" fill="#9055A2"/>
        </g>
        </g>
        </g>
        <g id="Mask group_2">
        <mask id="mask2_1209_8804" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="20" y="26" width="439" height="407">
        <path id="Rectangle 1044_2" d="M337.773 35.1612C424.061 58.2818 475.594 147.062 452.473 233.349L440.094 279.55C410.529 389.889 297.114 455.369 186.775 425.804L141.413 413.649C54.6622 390.405 3.18051 301.236 26.4253 214.485C62.7034 79.0933 202.382 -1.11687 337.773 35.1612Z" fill="url(#paint1_linear_1209_8804)"/>
        </mask>
        <g mask="url(#mask2_1209_8804)">
        <g id="Group 100_2">
        <path id="Rectangle 564_2" d="M362.833 137.739C362.211 137.572 361.57 137.94 361.403 138.562L361.314 138.895C361.101 139.69 361.573 140.507 362.368 140.72L362.695 140.808C363.32 140.975 363.962 140.604 364.13 139.979C364.391 139.004 363.808 138 362.833 137.739Z" fill="#FF4A1C"/>
        <path id="Rectangle 565_2" d="M225.786 375.112C225.165 374.945 224.523 375.314 224.357 375.935L224.267 376.268C224.054 377.063 224.526 377.88 225.321 378.093L225.648 378.181C226.273 378.348 226.915 377.977 227.083 377.352C227.344 376.377 226.762 375.373 225.786 375.112Z" fill="#FF4A1C"/>
        <path id="Rectangle 566_2" d="M350.04 131.993C349.418 131.826 348.777 132.194 348.611 132.816L348.521 133.149C348.308 133.944 348.78 134.761 349.575 134.974L349.902 135.061C350.527 135.229 351.169 134.858 351.337 134.233C351.598 133.258 351.015 132.254 350.04 131.993Z" fill="#FF4A1C"/>
        <path id="Rectangle 567_2" d="M212.992 369.366C212.371 369.2 211.729 369.568 211.563 370.19L211.473 370.522C211.26 371.317 211.732 372.134 212.527 372.347L212.854 372.435C213.479 372.602 214.121 372.232 214.289 371.607C214.55 370.631 213.968 369.628 212.992 369.366Z" fill="#FF4A1C"/>
        <path id="Rectangle 568_2" d="M374.782 119.467C374.161 119.3 373.519 119.668 373.353 120.29L373.264 120.623C373.051 121.418 373.522 122.235 374.317 122.448L374.644 122.536C375.269 122.703 375.911 122.332 376.079 121.707C376.34 120.732 375.758 119.728 374.782 119.467Z" fill="#FF4A1C"/>
        <path id="Rectangle 569_2" d="M237.735 356.838C237.114 356.671 236.472 357.04 236.306 357.661L236.217 357.994C236.004 358.789 236.475 359.606 237.27 359.819L237.597 359.907C238.222 360.074 238.864 359.703 239.032 359.078C239.293 358.103 238.711 357.099 237.735 356.838Z" fill="#FF4A1C"/>
        <path id="Rectangle 570_2" d="M359.319 94.123C358.698 93.9565 358.056 94.3247 357.89 94.9464L357.801 95.2792C357.588 96.0742 358.059 96.8912 358.854 97.1042L359.181 97.1918C359.806 97.3593 360.448 96.9884 360.616 96.3634C360.877 95.388 360.295 94.3844 359.319 94.123Z" fill="#FF4A1C"/>
        <path id="Rectangle 571_2" d="M222.271 331.497C221.649 331.33 221.008 331.698 220.841 332.32L220.752 332.653C220.539 333.448 221.011 334.265 221.805 334.478L222.132 334.565C222.757 334.733 223.4 334.362 223.567 333.737C223.828 332.762 223.246 331.758 222.271 331.497Z" fill="#FF4A1C"/>
        <path id="Rectangle 572_2" d="M322.057 106.536C321.435 106.37 320.794 106.738 320.627 107.359L320.538 107.692C320.325 108.487 320.797 109.304 321.592 109.517L321.918 109.605C322.543 109.772 323.186 109.401 323.353 108.776C323.615 107.801 323.032 106.797 322.057 106.536Z" fill="#FF4A1C"/>
        <path id="Rectangle 573_2" d="M185.009 343.907C184.387 343.741 183.746 344.109 183.579 344.731L183.49 345.063C183.277 345.858 183.749 346.675 184.544 346.888L184.871 346.976C185.496 347.143 186.138 346.773 186.305 346.148C186.567 345.172 185.984 344.169 185.009 343.907Z" fill="#FF4A1C"/>
        <path id="Rectangle 574_2" d="M342.551 65.9951C343.172 66.1617 343.814 65.7934 343.98 65.1718L344.069 64.8389C344.282 64.044 343.811 63.2269 343.016 63.0139L342.689 62.9263C342.064 62.7589 341.422 63.1298 341.254 63.7548C340.993 64.7302 341.575 65.7338 342.551 65.9951Z" fill="#FF4A1C"/>
        <path id="Rectangle 575_2" d="M205.503 303.367C206.125 303.533 206.766 303.165 206.932 302.543L207.022 302.211C207.235 301.416 206.763 300.598 205.968 300.385L205.641 300.298C205.016 300.13 204.374 300.501 204.206 301.126C203.945 302.102 204.528 303.105 205.503 303.367Z" fill="#FF4A1C"/>
        <path id="Rectangle 576_2" d="M355.341 71.7378C355.962 71.9044 356.604 71.5361 356.77 70.9144L356.86 70.5816C357.073 69.7867 356.601 68.9696 355.806 68.7566L355.479 68.669C354.854 68.5016 354.212 68.8725 354.044 69.4974C353.783 70.4729 354.365 71.4764 355.341 71.7378Z" fill="#FF4A1C"/>
        <path id="Rectangle 577_2" d="M218.293 309.111C218.915 309.278 219.556 308.91 219.722 308.288L219.812 307.955C220.025 307.16 219.553 306.343 218.758 306.13L218.431 306.043C217.806 305.875 217.164 306.246 216.996 306.871C216.735 307.846 217.318 308.85 218.293 309.111Z" fill="#FF4A1C"/>
        <path id="Rectangle 578_2" d="M330.598 84.2661C331.219 84.4327 331.861 84.0644 332.027 83.4428L332.116 83.1099C332.329 82.315 331.858 81.4979 331.063 81.2849L330.736 81.1973C330.111 81.0299 329.469 81.4008 329.301 82.0258C329.04 83.0012 329.622 84.0048 330.598 84.2661Z" fill="#FF4A1C"/>
        <path id="Rectangle 579_2" d="M193.55 321.637C194.171 321.804 194.813 321.436 194.979 320.814L195.069 320.481C195.282 319.686 194.81 318.869 194.015 318.656L193.688 318.568C193.063 318.401 192.421 318.772 192.253 319.397C191.992 320.372 192.574 321.376 193.55 321.637Z" fill="#FF4A1C"/>
        <path id="Rectangle 580_2" d="M346.063 109.609C346.685 109.776 347.326 109.408 347.493 108.786L347.582 108.453C347.795 107.658 347.323 106.841 346.529 106.628L346.202 106.541C345.577 106.373 344.934 106.744 344.767 107.369C344.506 108.344 345.088 109.348 346.063 109.609Z" fill="#FF4A1C"/>
        <path id="Rectangle 581_2" d="M209.016 346.982C209.637 347.149 210.279 346.781 210.445 346.159L210.534 345.826C210.747 345.031 210.276 344.214 209.481 344.001L209.154 343.914C208.529 343.746 207.886 344.117 207.719 344.742C207.458 345.717 208.04 346.721 209.016 346.982Z" fill="#FF4A1C"/>
        <path id="Rectangle 582_2" d="M383.324 97.1948C383.946 97.3614 384.587 96.9931 384.754 96.3715L384.843 96.0386C385.056 95.2437 384.584 94.4266 383.789 94.2136L383.462 94.1261C382.837 93.9586 382.195 94.3295 382.028 94.9545C381.766 95.9299 382.349 96.9335 383.324 97.1948Z" fill="#FF4A1C"/>
        <path id="Rectangle 583_2" d="M246.276 334.568C246.898 334.734 247.539 334.366 247.706 333.745L247.795 333.412C248.008 332.617 247.536 331.8 246.741 331.587L246.415 331.499C245.79 331.332 245.147 331.703 244.98 332.328C244.718 333.303 245.301 334.307 246.276 334.568Z" fill="#FF4A1C"/>
        <path id="Rectangle 584_2" d="M389.086 29.9351C389.708 30.1016 390.349 29.7334 390.515 29.1117L390.605 28.7789C390.818 27.9839 390.346 27.1669 389.551 26.9539L389.224 26.8663C388.599 26.6988 387.957 27.0697 387.789 27.6947C387.528 28.6701 388.111 29.6737 389.086 29.9351Z" fill="#FF4A1C"/>
        <path id="Rectangle 585_2" d="M252.039 267.308C252.661 267.475 253.302 267.106 253.469 266.485L253.558 266.152C253.771 265.357 253.299 264.54 252.504 264.327L252.177 264.239C251.552 264.072 250.91 264.443 250.742 265.068C250.481 266.043 251.064 267.047 252.039 267.308Z" fill="#FF4A1C"/>
        <path id="Rectangle 586_2" d="M401.877 35.6797C402.499 35.8463 403.14 35.478 403.306 34.8563L403.396 34.5235C403.609 33.7286 403.137 32.9115 402.342 32.6985L402.015 32.6109C401.39 32.4435 400.748 32.8143 400.58 33.4393C400.319 34.4147 400.902 35.4183 401.877 35.6797Z" fill="#FF4A1C"/>
        <path id="Rectangle 587_2" d="M264.831 273.053C265.453 273.219 266.094 272.851 266.261 272.229L266.35 271.897C266.563 271.102 266.091 270.285 265.296 270.072L264.969 269.984C264.344 269.817 263.702 270.187 263.534 270.812C263.273 271.788 263.856 272.791 264.831 273.053Z" fill="#FF4A1C"/>
        <path id="Rectangle 588_2" d="M377.137 48.207C377.758 48.3736 378.4 48.0053 378.566 47.3837L378.655 47.0508C378.868 46.2559 378.397 45.4388 377.602 45.2258L377.275 45.1383C376.65 44.9708 376.008 45.3417 375.84 45.9667C375.579 46.9421 376.161 47.9457 377.137 48.207Z" fill="#FF4A1C"/>
        <path id="Rectangle 589_2" d="M240.09 285.58C240.711 285.747 241.353 285.378 241.519 284.757L241.609 284.424C241.822 283.629 241.35 282.812 240.555 282.599L240.228 282.511C239.603 282.344 238.961 282.715 238.793 283.34C238.532 284.315 239.114 285.319 240.09 285.58Z" fill="#FF4A1C"/>
        <path id="Rectangle 590_2" d="M392.599 73.5513C393.22 73.7179 393.862 73.3496 394.028 72.7279L394.117 72.3951C394.33 71.6002 393.859 70.7831 393.064 70.5701L392.737 70.4825C392.112 70.315 391.469 70.6859 391.302 71.3109C391.041 72.2863 391.623 73.2899 392.599 73.5513Z" fill="#FF4A1C"/>
        <path id="Rectangle 591_2" d="M255.552 310.922C256.173 311.089 256.815 310.721 256.981 310.099L257.07 309.766C257.283 308.971 256.812 308.154 256.017 307.941L255.69 307.854C255.065 307.686 254.423 308.057 254.255 308.682C253.994 309.657 254.576 310.661 255.552 310.922Z" fill="#FF4A1C"/>
        <path id="Rectangle 592_2" d="M429.861 61.1362C430.483 61.3028 431.124 60.9345 431.291 60.3129L431.38 59.98C431.593 59.1851 431.121 58.368 430.326 58.155L430 58.0675C429.375 57.9 428.732 58.2709 428.565 58.8959C428.303 59.8713 428.886 60.8749 429.861 61.1362Z" fill="#FF4A1C"/>
        <path id="Rectangle 593_2" d="M292.813 298.51C293.435 298.676 294.076 298.308 294.243 297.686L294.332 297.354C294.545 296.559 294.073 295.742 293.279 295.529L292.952 295.441C292.327 295.274 291.684 295.644 291.517 296.269C291.256 297.245 291.838 298.248 292.813 298.51Z" fill="#FF4A1C"/>
        <path id="Rectangle 594_2" d="M409.368 101.681C408.747 101.514 408.105 101.882 407.939 102.504L407.849 102.837C407.636 103.632 408.108 104.449 408.903 104.662L409.23 104.749C409.855 104.917 410.497 104.546 410.665 103.921C410.926 102.946 410.344 101.942 409.368 101.681Z" fill="#FF4A1C"/>
        <path id="Rectangle 595_2" d="M272.321 339.054C271.7 338.888 271.058 339.256 270.892 339.878L270.803 340.21C270.59 341.005 271.061 341.822 271.856 342.035L272.183 342.123C272.808 342.29 273.45 341.92 273.618 341.295C273.879 340.319 273.297 339.316 272.321 339.054Z" fill="#FF4A1C"/>
        <path id="Rectangle 596_2" d="M396.577 95.9365C395.956 95.7699 395.314 96.1382 395.148 96.7599L395.058 97.0927C394.845 97.8876 395.317 98.7047 396.112 98.9177L396.439 99.0053C397.064 99.1728 397.706 98.8019 397.874 98.1769C398.135 97.2015 397.553 96.1979 396.577 95.9365Z" fill="#FF4A1C"/>
        <path id="Rectangle 597_2" d="M259.53 333.307C258.909 333.141 258.267 333.509 258.101 334.13L258.012 334.463C257.799 335.258 258.27 336.075 259.065 336.288L259.392 336.376C260.017 336.543 260.659 336.172 260.827 335.547C261.088 334.572 260.506 333.568 259.53 333.307Z" fill="#FF4A1C"/>
        <path id="Rectangle 598_2" d="M421.317 83.4067C420.696 83.2402 420.054 83.6084 419.888 84.2301L419.799 84.5629C419.586 85.3579 420.057 86.1749 420.852 86.3879L421.179 86.4755C421.804 86.643 422.447 86.2721 422.614 85.6471C422.875 84.6717 422.293 83.6681 421.317 83.4067Z" fill="#FF4A1C"/>
        <path id="Rectangle 599_2" d="M284.27 320.78C283.648 320.614 283.007 320.982 282.84 321.604L282.751 321.936C282.538 322.731 283.01 323.548 283.804 323.761L284.131 323.849C284.756 324.017 285.399 323.646 285.566 323.021C285.828 322.045 285.245 321.042 284.27 320.78Z" fill="#FF4A1C"/>
        <path id="Rectangle 600_2" d="M405.855 58.0649C405.234 57.8984 404.593 58.2666 404.426 58.8883L404.337 59.2211C404.124 60.0161 404.595 60.8331 405.39 61.0461L405.717 61.1337C406.342 61.3012 406.985 60.9303 407.152 60.3053C407.413 59.3299 406.831 58.3263 405.855 58.0649Z" fill="#FF4A1C"/>
        <path id="Rectangle 601_2" d="M268.808 295.438C268.186 295.271 267.545 295.64 267.378 296.261L267.289 296.594C267.076 297.389 267.548 298.206 268.343 298.419L268.669 298.507C269.294 298.674 269.937 298.303 270.104 297.678C270.366 296.703 269.783 295.699 268.808 295.438Z" fill="#FF4A1C"/>
        <path id="Rectangle 602_2" d="M368.594 70.4795C367.972 70.3129 367.331 70.6812 367.164 71.3028L367.075 71.6357C366.862 72.4306 367.334 73.2477 368.129 73.4607L368.456 73.5483C369.08 73.7157 369.723 73.3448 369.89 72.7198C370.152 71.7444 369.569 70.7408 368.594 70.4795Z" fill="#FF4A1C"/>
        <path id="Rectangle 603_2" d="M231.547 307.851C230.925 307.684 230.284 308.052 230.117 308.674L230.028 309.007C229.815 309.802 230.287 310.619 231.082 310.832L231.409 310.919C232.034 311.087 232.676 310.716 232.843 310.091C233.105 309.116 232.522 308.112 231.547 307.851Z" fill="#FF4A1C"/>
        <path id="Rectangle 604_2" d="M417.577 198.348C416.956 198.182 416.314 198.55 416.148 199.171L416.058 199.504C415.845 200.299 416.317 201.116 417.112 201.329L417.439 201.417C418.064 201.584 418.706 201.213 418.874 200.588C419.135 199.613 418.553 198.609 417.577 198.348Z" fill="#FF4A1C"/>
        <path id="Rectangle 606_2" d="M404.785 192.604C404.164 192.437 403.522 192.806 403.356 193.427L403.266 193.76C403.053 194.555 403.525 195.372 404.32 195.585L404.647 195.673C405.272 195.84 405.914 195.469 406.082 194.844C406.343 193.869 405.761 192.865 404.785 192.604Z" fill="#FF4A1C"/>
        <path id="Rectangle 607_2" d="M267.738 429.975C267.117 429.809 266.475 430.177 266.309 430.798L266.22 431.131C266.007 431.926 266.478 432.743 267.273 432.956L267.6 433.044C268.225 433.211 268.867 432.84 269.035 432.215C269.296 431.24 268.714 430.236 267.738 429.975Z" fill="#FF4A1C"/>
        <path id="Rectangle 608_2" d="M429.528 180.078C428.907 179.911 428.265 180.279 428.099 180.901L428.01 181.234C427.797 182.029 428.268 182.846 429.063 183.059L429.39 183.146C430.015 183.314 430.657 182.943 430.825 182.318C431.086 181.343 430.504 180.339 429.528 180.078Z" fill="#FF4A1C"/>
        <path id="Rectangle 609_2" d="M292.481 417.451C291.86 417.284 291.218 417.652 291.052 418.274L290.963 418.607C290.75 419.402 291.221 420.219 292.016 420.432L292.343 420.519C292.968 420.687 293.611 420.316 293.778 419.691C294.039 418.716 293.457 417.712 292.481 417.451Z" fill="#FF4A1C"/>
        <path id="Rectangle 610_2" d="M414.065 154.734C413.444 154.567 412.802 154.936 412.636 155.557L412.547 155.89C412.334 156.685 412.805 157.502 413.6 157.715L413.927 157.803C414.552 157.97 415.195 157.599 415.362 156.974C415.623 155.999 415.041 154.995 414.065 154.734Z" fill="#FF4A1C"/>
        <path id="Rectangle 611_2" d="M277.018 392.105C276.396 391.939 275.755 392.307 275.588 392.929L275.499 393.262C275.286 394.057 275.758 394.874 276.553 395.087L276.879 395.174C277.504 395.342 278.147 394.971 278.314 394.346C278.576 393.37 277.993 392.367 277.018 392.105Z" fill="#FF4A1C"/>
        <path id="Rectangle 612_2" d="M376.803 167.145C376.181 166.978 375.54 167.347 375.373 167.968L375.284 168.301C375.071 169.096 375.543 169.913 376.338 170.126L376.664 170.214C377.289 170.381 377.932 170.01 378.099 169.385C378.361 168.41 377.778 167.406 376.803 167.145Z" fill="#FF4A1C"/>
        <path id="Rectangle 613_2" d="M239.755 404.518C239.133 404.351 238.492 404.72 238.325 405.341L238.236 405.674C238.023 406.469 238.495 407.286 239.29 407.499L239.617 407.587C240.242 407.754 240.884 407.383 241.051 406.758C241.313 405.783 240.73 404.779 239.755 404.518Z" fill="#FF4A1C"/>
        <path id="Rectangle 614_2" d="M397.294 126.601C397.916 126.767 398.557 126.399 398.723 125.777L398.813 125.444C399.026 124.649 398.554 123.832 397.759 123.619L397.432 123.532C396.807 123.364 396.165 123.735 395.997 124.36C395.736 125.336 396.319 126.339 397.294 126.601Z" fill="#FF4A1C"/>
        <path id="Rectangle 615_2" d="M260.248 363.974C260.87 364.14 261.511 363.772 261.678 363.15L261.767 362.817C261.98 362.023 261.508 361.205 260.713 360.992L260.386 360.905C259.761 360.737 259.119 361.108 258.951 361.733C258.69 362.709 259.273 363.712 260.248 363.974Z" fill="#FF4A1C"/>
        <path id="Rectangle 616_2" d="M410.089 132.348C410.71 132.515 411.352 132.146 411.518 131.525L411.608 131.192C411.821 130.397 411.349 129.58 410.554 129.367L410.227 129.279C409.602 129.112 408.96 129.483 408.792 130.108C408.531 131.083 409.113 132.087 410.089 132.348Z" fill="#FF4A1C"/>
        <path id="Rectangle 617_2" d="M273.042 369.721C273.664 369.888 274.305 369.519 274.472 368.898L274.561 368.565C274.774 367.77 274.302 366.953 273.507 366.74L273.18 366.652C272.555 366.485 271.913 366.856 271.745 367.481C271.484 368.456 272.067 369.46 273.042 369.721Z" fill="#FF4A1C"/>
        <path id="Rectangle 618_2" d="M385.347 144.874C385.968 145.041 386.61 144.672 386.776 144.051L386.865 143.718C387.078 142.923 386.607 142.106 385.812 141.893L385.485 141.805C384.86 141.638 384.218 142.009 384.05 142.634C383.789 143.609 384.371 144.613 385.347 144.874Z" fill="#FF4A1C"/>
        <path id="Rectangle 619_2" d="M248.299 382.245C248.92 382.412 249.562 382.043 249.728 381.422L249.818 381.089C250.031 380.294 249.559 379.477 248.764 379.264L248.437 379.176C247.812 379.009 247.17 379.38 247.002 380.005C246.741 380.98 247.323 381.984 248.299 382.245Z" fill="#FF4A1C"/>
        <path id="Rectangle 620_2" d="M400.81 170.218C401.431 170.384 402.073 170.016 402.239 169.394L402.328 169.062C402.541 168.267 402.07 167.45 401.275 167.237L400.948 167.149C400.323 166.982 399.68 167.352 399.513 167.977C399.252 168.953 399.834 169.956 400.81 170.218Z" fill="#FF4A1C"/>
        <path id="Rectangle 621_2" d="M263.762 407.591C264.383 407.758 265.025 407.39 265.191 406.768L265.28 406.435C265.493 405.64 265.022 404.823 264.227 404.61L263.9 404.523C263.275 404.355 262.633 404.726 262.465 405.351C262.204 406.326 262.786 407.33 263.762 407.591Z" fill="#FF4A1C"/>
        <path id="Rectangle 622_2" d="M438.072 157.807C438.694 157.974 439.335 157.605 439.502 156.984L439.591 156.651C439.804 155.856 439.332 155.039 438.537 154.826L438.211 154.738C437.586 154.571 436.943 154.942 436.776 155.567C436.514 156.542 437.097 157.546 438.072 157.807Z" fill="#FF4A1C"/>
        <path id="Rectangle 623_2" d="M301.024 395.178C301.646 395.345 302.287 394.977 302.454 394.355L302.543 394.022C302.756 393.227 302.284 392.41 301.489 392.197L301.163 392.109C300.538 391.942 299.895 392.313 299.728 392.938C299.466 393.913 300.049 394.917 301.024 395.178Z" fill="#FF4A1C"/>
        <path id="Rectangle 624_2" d="M443.832 90.542C444.454 90.7086 445.095 90.3403 445.262 89.7186L445.351 89.3858C445.564 88.5909 445.092 87.7738 444.297 87.5608L443.97 87.4732C443.345 87.3058 442.703 87.6767 442.535 88.3016C442.274 89.2771 442.857 90.2806 443.832 90.542Z" fill="#FF4A1C"/>
        <path id="Rectangle 625_2" d="M306.785 327.915C307.407 328.082 308.048 327.713 308.215 327.092L308.304 326.759C308.517 325.964 308.045 325.147 307.25 324.934L306.923 324.846C306.298 324.679 305.656 325.05 305.489 325.675C305.227 326.65 305.81 327.654 306.785 327.915Z" fill="#FF4A1C"/>
        <path id="Rectangle 626_2" d="M456.623 96.2861C457.245 96.4527 457.886 96.0844 458.053 95.4628L458.142 95.1299C458.355 94.335 457.883 93.5179 457.088 93.3049L456.761 93.2174C456.136 93.0499 455.494 93.4208 455.326 94.0458C455.065 95.0212 455.648 96.0248 456.623 96.2861Z" fill="#FF4A1C"/>
        <path id="Rectangle 627_2" d="M319.577 333.659C320.199 333.826 320.84 333.457 321.007 332.836L321.096 332.503C321.309 331.708 320.837 330.891 320.042 330.678L319.715 330.59C319.09 330.423 318.448 330.794 318.281 331.419C318.019 332.394 318.602 333.398 319.577 333.659Z" fill="#FF4A1C"/>
        <path id="Rectangle 628_2" d="M431.881 108.814C432.502 108.981 433.144 108.612 433.31 107.991L433.4 107.658C433.613 106.863 433.141 106.046 432.346 105.833L432.019 105.745C431.394 105.578 430.752 105.949 430.584 106.574C430.323 107.549 430.905 108.553 431.881 108.814Z" fill="#FF4A1C"/>
        <path id="Rectangle 629_2" d="M294.834 346.188C295.456 346.354 296.097 345.986 296.264 345.364L296.353 345.031C296.566 344.236 296.094 343.419 295.299 343.206L294.972 343.119C294.347 342.951 293.705 343.322 293.537 343.947C293.276 344.923 293.859 345.926 294.834 346.188Z" fill="#FF4A1C"/>
        <path id="Rectangle 630_2" d="M447.347 134.159C447.968 134.326 448.61 133.957 448.776 133.336L448.865 133.003C449.078 132.208 448.607 131.391 447.812 131.178L447.485 131.09C446.86 130.923 446.218 131.294 446.05 131.919C445.789 132.894 446.371 133.898 447.347 134.159Z" fill="#FF4A1C"/>
        <path id="Rectangle 631_2" d="M310.3 371.53C310.921 371.697 311.563 371.329 311.729 370.707L311.819 370.374C312.032 369.579 311.56 368.762 310.765 368.549L310.438 368.462C309.813 368.294 309.171 368.665 309.003 369.29C308.742 370.265 309.324 371.269 310.3 371.53Z" fill="#FF4A1C"/>
        <path id="Rectangle 633_2" d="M347.559 359.119C348.18 359.285 348.822 358.917 348.988 358.295L349.077 357.962C349.29 357.168 348.819 356.35 348.024 356.137L347.697 356.05C347.072 355.882 346.429 356.253 346.262 356.878C346.001 357.854 346.583 358.857 347.559 359.119Z" fill="#FF4A1C"/>
        <path id="Rectangle 635_2" d="M327.067 399.661C326.446 399.494 325.804 399.862 325.638 400.484L325.549 400.817C325.336 401.612 325.807 402.429 326.602 402.642L326.929 402.729C327.554 402.897 328.197 402.526 328.364 401.901C328.625 400.926 328.043 399.922 327.067 399.661Z" fill="#FF4A1C"/>
        <path id="Rectangle 636_2" d="M451.322 156.545C450.701 156.379 450.059 156.747 449.893 157.369L449.804 157.702C449.591 158.497 450.062 159.314 450.857 159.527L451.184 159.614C451.809 159.782 452.451 159.411 452.619 158.786C452.88 157.81 452.298 156.807 451.322 156.545Z" fill="#FF4A1C"/>
        <path id="Rectangle 637_2" d="M314.275 393.918C313.654 393.752 313.012 394.12 312.846 394.742L312.757 395.075C312.544 395.87 313.015 396.687 313.81 396.9L314.137 396.987C314.762 397.155 315.405 396.784 315.572 396.159C315.833 395.183 315.251 394.18 314.275 393.918Z" fill="#FF4A1C"/>
        <path id="Rectangle 639_2" d="M339.017 381.389C338.395 381.222 337.754 381.59 337.587 382.212L337.498 382.545C337.285 383.34 337.757 384.157 338.552 384.37L338.878 384.457C339.503 384.625 340.146 384.254 340.313 383.629C340.575 382.654 339.992 381.65 339.017 381.389Z" fill="#FF4A1C"/>
        <path id="Rectangle 641_2" d="M323.556 356.046C322.934 355.88 322.293 356.248 322.126 356.87L322.037 357.203C321.824 357.998 322.296 358.815 323.091 359.028L323.417 359.115C324.042 359.283 324.685 358.912 324.852 358.287C325.114 357.311 324.531 356.308 323.556 356.046Z" fill="#FF4A1C"/>
        <path id="Rectangle 642_2" d="M423.34 131.088C422.718 130.922 422.077 131.29 421.91 131.912L421.821 132.245C421.608 133.039 422.08 133.857 422.875 134.07L423.202 134.157C423.827 134.325 424.469 133.954 424.636 133.329C424.898 132.353 424.315 131.35 423.34 131.088Z" fill="#FF4A1C"/>
        <path id="Rectangle 643_2" d="M286.292 368.459C285.67 368.293 285.029 368.661 284.862 369.283L284.773 369.616C284.56 370.411 285.032 371.228 285.827 371.441L286.154 371.528C286.779 371.696 287.421 371.325 287.589 370.7C287.85 369.724 287.267 368.721 286.292 368.459Z" fill="#FF4A1C"/>
        <path id="Rectangle 644_2" d="M282.617 239.713C281.996 239.547 281.354 239.915 281.188 240.537L281.098 240.87C280.885 241.664 281.357 242.482 282.152 242.695L282.479 242.782C283.104 242.95 283.746 242.579 283.914 241.954C284.175 240.978 283.593 239.975 282.617 239.713Z" fill="#FF4A1C"/>
        <path id="Rectangle 646_2" d="M269.823 233.97C269.202 233.803 268.56 234.171 268.394 234.793L268.305 235.126C268.092 235.921 268.563 236.738 269.358 236.951L269.685 237.038C270.31 237.206 270.952 236.835 271.12 236.21C271.381 235.235 270.799 234.231 269.823 233.97Z" fill="#FF4A1C"/>
        <path id="Rectangle 648_2" d="M294.565 221.441C293.944 221.275 293.302 221.643 293.136 222.265L293.047 222.598C292.834 223.393 293.305 224.21 294.1 224.423L294.427 224.51C295.052 224.678 295.695 224.307 295.862 223.682C296.123 222.706 295.541 221.703 294.565 221.441Z" fill="#FF4A1C"/>
        <path id="Rectangle 650_2" d="M279.104 196.1C278.482 195.933 277.841 196.301 277.674 196.923L277.585 197.256C277.372 198.051 277.844 198.868 278.638 199.081L278.965 199.168C279.59 199.336 280.233 198.965 280.4 198.34C280.661 197.365 280.079 196.361 279.104 196.1Z" fill="#FF4A1C"/>
        <path id="Rectangle 652_2" d="M241.842 208.512C241.22 208.346 240.579 208.714 240.412 209.336L240.323 209.668C240.11 210.463 240.582 211.28 241.377 211.493L241.704 211.581C242.329 211.748 242.971 211.378 243.138 210.753C243.4 209.777 242.817 208.774 241.842 208.512Z" fill="#FF4A1C"/>
        <path id="Rectangle 654_2" d="M262.334 167.97C262.956 168.136 263.597 167.768 263.764 167.146L263.853 166.814C264.066 166.019 263.594 165.202 262.799 164.989L262.472 164.901C261.847 164.733 261.205 165.104 261.037 165.729C260.776 166.705 261.359 167.708 262.334 167.97Z" fill="#FF4A1C"/>
        <path id="Rectangle 655_2" d="M125.286 405.341C125.908 405.507 126.549 405.139 126.716 404.517L126.805 404.185C127.018 403.39 126.546 402.573 125.751 402.36L125.424 402.272C124.799 402.105 124.157 402.475 123.99 403.1C123.728 404.076 124.311 405.079 125.286 405.341Z" fill="#FF4A1C"/>
        <path id="Rectangle 656_2" d="M275.126 173.714C275.748 173.88 276.389 173.512 276.556 172.891L276.645 172.558C276.858 171.763 276.386 170.946 275.591 170.733L275.264 170.645C274.639 170.478 273.997 170.849 273.829 171.474C273.568 172.449 274.151 173.453 275.126 173.714Z" fill="#FF4A1C"/>
        <path id="Rectangle 657_2" d="M138.079 411.085C138.701 411.252 139.342 410.883 139.509 410.262L139.598 409.929C139.811 409.134 139.339 408.317 138.544 408.104L138.217 408.016C137.592 407.849 136.95 408.22 136.782 408.845C136.521 409.82 137.104 410.824 138.079 411.085Z" fill="#FF4A1C"/>
        <path id="Rectangle 658_2" d="M250.383 186.242C251.004 186.409 251.646 186.04 251.812 185.419L251.902 185.086C252.115 184.291 251.643 183.474 250.848 183.261L250.521 183.173C249.896 183.006 249.254 183.377 249.086 184.002C248.825 184.977 249.407 185.981 250.383 186.242Z" fill="#FF4A1C"/>
        <path id="Rectangle 659_2" d="M113.335 423.616C113.957 423.782 114.598 423.414 114.764 422.792L114.854 422.46C115.067 421.665 114.595 420.848 113.8 420.635L113.473 420.547C112.848 420.379 112.206 420.75 112.038 421.375C111.777 422.351 112.36 423.354 113.335 423.616Z" fill="#FF4A1C"/>
        <path id="Rectangle 660_2" d="M265.847 211.584C266.468 211.751 267.11 211.382 267.276 210.761L267.365 210.428C267.578 209.633 267.107 208.816 266.312 208.603L265.985 208.515C265.36 208.348 264.718 208.719 264.55 209.344C264.289 210.319 264.871 211.323 265.847 211.584Z" fill="#FF4A1C"/>
        <path id="Rectangle 662_2" d="M303.108 199.171C303.73 199.338 304.371 198.97 304.538 198.348L304.627 198.015C304.84 197.22 304.368 196.403 303.573 196.19L303.247 196.103C302.622 195.935 301.979 196.306 301.812 196.931C301.55 197.906 302.133 198.91 303.108 199.171Z" fill="#FF4A1C"/>
        <path id="Rectangle 664_2" d="M308.87 131.912C309.492 132.078 310.133 131.71 310.3 131.088L310.389 130.755C310.602 129.961 310.13 129.143 309.335 128.93L309.008 128.843C308.383 128.675 307.741 129.046 307.574 129.671C307.312 130.647 307.895 131.65 308.87 131.912Z" fill="#FF4A1C"/>
        <path id="Rectangle 665_2" d="M171.823 369.285C172.445 369.451 173.086 369.083 173.253 368.461L173.342 368.128C173.555 367.334 173.083 366.516 172.288 366.303L171.961 366.216C171.337 366.048 170.694 366.419 170.527 367.044C170.265 368.02 170.848 369.023 171.823 369.285Z" fill="#FF4A1C"/>
        <path id="Rectangle 666_2" d="M321.661 137.654C322.283 137.82 322.924 137.452 323.091 136.83L323.18 136.498C323.393 135.703 322.921 134.886 322.126 134.673L321.799 134.585C321.174 134.418 320.532 134.788 320.365 135.413C320.103 136.389 320.686 137.392 321.661 137.654Z" fill="#FF4A1C"/>
        <path id="Rectangle 667_2" d="M184.614 375.027C185.236 375.193 185.877 374.825 186.044 374.204L186.133 373.871C186.346 373.076 185.874 372.259 185.079 372.046L184.753 371.958C184.128 371.791 183.485 372.162 183.318 372.787C183.056 373.762 183.639 374.766 184.614 375.027Z" fill="#FF4A1C"/>
        <path id="Rectangle 668_2" d="M296.92 150.184C297.542 150.35 298.183 149.982 298.349 149.36L298.439 149.027C298.652 148.232 298.18 147.415 297.385 147.202L297.058 147.115C296.433 146.947 295.791 147.318 295.623 147.943C295.362 148.919 295.944 149.922 296.92 150.184Z" fill="#FF4A1C"/>
        <path id="Rectangle 669_2" d="M159.873 387.555C160.495 387.721 161.136 387.353 161.303 386.731L161.392 386.398C161.605 385.604 161.133 384.786 160.338 384.573L160.011 384.486C159.386 384.318 158.744 384.689 158.576 385.314C158.315 386.29 158.898 387.293 159.873 387.555Z" fill="#FF4A1C"/>
        <path id="Rectangle 670_2" d="M312.384 175.525C313.005 175.692 313.647 175.324 313.813 174.702L313.903 174.369C314.116 173.574 313.644 172.757 312.849 172.544L312.522 172.457C311.897 172.289 311.255 172.66 311.087 173.285C310.826 174.26 311.408 175.264 312.384 175.525Z" fill="#FF4A1C"/>
        <path id="Rectangle 671_2" d="M175.337 412.898C175.959 413.065 176.6 412.697 176.766 412.075L176.856 411.742C177.069 410.947 176.597 410.13 175.802 409.917L175.475 409.83C174.85 409.662 174.208 410.033 174.04 410.658C173.779 411.633 174.361 412.637 175.337 412.898Z" fill="#FF4A1C"/>
        <path id="Rectangle 672_2" d="M349.645 163.113C350.267 163.279 350.908 162.911 351.075 162.289L351.164 161.957C351.377 161.162 350.905 160.345 350.111 160.132L349.784 160.044C349.159 159.877 348.516 160.247 348.349 160.872C348.088 161.848 348.67 162.851 349.645 163.113Z" fill="#FF4A1C"/>
        <path id="Rectangle 673_2" d="M212.599 400.486C213.22 400.652 213.862 400.284 214.028 399.662L214.117 399.33C214.33 398.535 213.859 397.718 213.064 397.505L212.737 397.417C212.112 397.25 211.469 397.621 211.302 398.245C211.041 399.221 211.623 400.224 212.599 400.486Z" fill="#FF4A1C"/>
        <path id="Rectangle 674_2" d="M329.151 203.656C328.53 203.489 327.888 203.857 327.722 204.479L327.633 204.812C327.42 205.607 327.891 206.424 328.686 206.637L329.013 206.725C329.638 206.892 330.281 206.521 330.448 205.896C330.709 204.921 330.127 203.917 329.151 203.656Z" fill="#FF4A1C"/>
        <path id="Rectangle 676_2" d="M316.359 197.911C315.738 197.745 315.096 198.113 314.93 198.734L314.841 199.067C314.628 199.862 315.099 200.679 315.894 200.892L316.221 200.98C316.846 201.147 317.489 200.776 317.656 200.151C317.917 199.176 317.335 198.172 316.359 197.911Z" fill="#FF4A1C"/>
        <path id="Rectangle 678_2" d="M341.103 185.383C340.481 185.217 339.84 185.585 339.673 186.207L339.584 186.539C339.371 187.334 339.843 188.152 340.637 188.365L340.964 188.452C341.589 188.62 342.232 188.249 342.399 187.624C342.661 186.648 342.078 185.645 341.103 185.383Z" fill="#FF4A1C"/>
        <path id="Rectangle 679_2" d="M204.055 422.756C203.433 422.59 202.792 422.958 202.625 423.58L202.536 423.913C202.323 424.707 202.795 425.525 203.59 425.738L203.916 425.825C204.541 425.993 205.184 425.622 205.351 424.997C205.613 424.021 205.03 423.018 204.055 422.756Z" fill="#FF4A1C"/>
        <path id="Rectangle 680_2" d="M325.639 160.04C325.017 159.873 324.376 160.241 324.209 160.863L324.12 161.196C323.907 161.991 324.379 162.808 325.174 163.021L325.5 163.108C326.125 163.276 326.768 162.905 326.935 162.28C327.197 161.304 326.614 160.301 325.639 160.04Z" fill="#FF4A1C"/>
        <path id="Rectangle 681_2" d="M188.592 397.413C187.97 397.246 187.329 397.614 187.162 398.236L187.073 398.569C186.86 399.364 187.332 400.181 188.127 400.394L188.454 400.481C189.079 400.649 189.721 400.278 189.888 399.653C190.15 398.678 189.567 397.674 188.592 397.413Z" fill="#FF4A1C"/>
        <path id="Rectangle 682_2" d="M288.377 172.454C287.755 172.288 287.114 172.656 286.947 173.277L286.858 173.61C286.645 174.405 287.117 175.222 287.912 175.435L288.239 175.523C288.864 175.69 289.506 175.319 289.674 174.694C289.935 173.719 289.352 172.715 288.377 172.454Z" fill="#FF4A1C"/>
        <path id="Rectangle 683_2" d="M151.33 409.825C150.708 409.659 150.067 410.027 149.901 410.649L149.811 410.981C149.598 411.776 150.07 412.593 150.865 412.806L151.192 412.894C151.817 413.061 152.459 412.691 152.627 412.066C152.888 411.09 152.306 410.087 151.33 409.825Z" fill="#FF4A1C"/>
        <path id="Rectangle 684_2" d="M337.363 300.322C336.742 300.156 336.1 300.524 335.934 301.146L335.845 301.478C335.632 302.273 336.103 303.09 336.898 303.303L337.225 303.391C337.85 303.558 338.492 303.188 338.66 302.563C338.921 301.587 338.339 300.584 337.363 300.322Z" fill="#FF4A1C"/>
        <path id="Rectangle 686_2" d="M324.57 294.578C323.949 294.412 323.307 294.78 323.141 295.401L323.052 295.734C322.839 296.529 323.31 297.346 324.105 297.559L324.432 297.647C325.057 297.814 325.699 297.443 325.867 296.818C326.128 295.843 325.546 294.839 324.57 294.578Z" fill="#FF4A1C"/>
        <path id="Rectangle 688_2" d="M349.313 282.054C348.692 281.887 348.051 282.255 347.884 282.877L347.795 283.21C347.582 284.005 348.054 284.822 348.848 285.035L349.175 285.122C349.8 285.29 350.443 284.919 350.61 284.294C350.871 283.319 350.289 282.315 349.313 282.054Z" fill="#FF4A1C"/>
        <path id="Rectangle 690_2" d="M333.848 256.707C333.226 256.54 332.585 256.908 332.418 257.53L332.329 257.863C332.116 258.658 332.588 259.475 333.383 259.688L333.709 259.775C334.334 259.943 334.977 259.572 335.144 258.947C335.406 257.971 334.823 256.968 333.848 256.707Z" fill="#FF4A1C"/>
        <path id="Rectangle 692_2" d="M296.588 269.121C295.966 268.955 295.325 269.323 295.158 269.944L295.069 270.277C294.856 271.072 295.328 271.889 296.123 272.102L296.45 272.19C297.075 272.357 297.717 271.986 297.885 271.361C298.146 270.386 297.563 269.382 296.588 269.121Z" fill="#FF4A1C"/>
        <path id="Rectangle 694_2" d="M317.08 228.579C317.702 228.745 318.343 228.377 318.51 227.755L318.599 227.422C318.812 226.627 318.34 225.81 317.545 225.597L317.218 225.51C316.593 225.342 315.951 225.713 315.783 226.338C315.522 227.314 316.105 228.317 317.08 228.579Z" fill="#FF4A1C"/>
        <path id="Rectangle 696_2" d="M329.872 234.323C330.494 234.489 331.135 234.121 331.302 233.499L331.391 233.167C331.604 232.372 331.132 231.555 330.337 231.342L330.01 231.254C329.385 231.087 328.743 231.457 328.575 232.082C328.314 233.058 328.897 234.061 329.872 234.323Z" fill="#FF4A1C"/>
        <path id="Rectangle 698_2" d="M305.129 246.849C305.751 247.015 306.392 246.647 306.558 246.025L306.648 245.692C306.861 244.898 306.389 244.08 305.594 243.867L305.267 243.78C304.642 243.612 304 243.983 303.832 244.608C303.571 245.584 304.153 246.587 305.129 246.849Z" fill="#FF4A1C"/>
        <path id="Rectangle 700_2" d="M320.593 272.193C321.214 272.359 321.856 271.991 322.022 271.37L322.111 271.037C322.324 270.242 321.853 269.425 321.058 269.212L320.731 269.124C320.106 268.957 319.464 269.328 319.296 269.953C319.035 270.928 319.617 271.932 320.593 272.193Z" fill="#FF4A1C"/>
        <path id="Rectangle 702_2" d="M357.854 259.782C358.476 259.948 359.117 259.58 359.284 258.958L359.373 258.626C359.586 257.831 359.114 257.014 358.32 256.801L357.993 256.713C357.368 256.546 356.725 256.916 356.558 257.541C356.297 258.517 356.879 259.52 357.854 259.782Z" fill="#FF4A1C"/>
        <path id="Rectangle 704_2" d="M363.617 192.52C364.239 192.687 364.88 192.318 365.047 191.697L365.136 191.364C365.349 190.569 364.877 189.752 364.082 189.539L363.755 189.451C363.13 189.284 362.488 189.655 362.321 190.28C362.059 191.255 362.642 192.259 363.617 192.52Z" fill="#FF4A1C"/>
        <path id="Rectangle 705_2" d="M226.57 429.891C227.192 430.058 227.833 429.689 228 429.068L228.089 428.735C228.302 427.94 227.83 427.123 227.035 426.91L226.709 426.822C226.084 426.655 225.441 427.026 225.274 427.651C225.012 428.626 225.595 429.63 226.57 429.891Z" fill="#FF4A1C"/>
        <path id="Rectangle 706_2" d="M376.409 198.264C377.031 198.431 377.672 198.062 377.839 197.441L377.928 197.108C378.141 196.313 377.669 195.496 376.874 195.283L376.547 195.195C375.922 195.028 375.28 195.399 375.113 196.024C374.851 196.999 375.434 198.003 376.409 198.264Z" fill="#FF4A1C"/>
        <path id="Rectangle 707_2" d="M239.362 435.637C239.984 435.804 240.625 435.436 240.792 434.814L240.881 434.481C241.094 433.686 240.622 432.869 239.827 432.656L239.501 432.568C238.876 432.401 238.233 432.772 238.066 433.397C237.804 434.372 238.387 435.376 239.362 435.637Z" fill="#FF4A1C"/>
        <path id="Rectangle 708_2" d="M351.666 210.791C352.288 210.957 352.929 210.589 353.096 209.967L353.185 209.634C353.398 208.839 352.926 208.022 352.131 207.809L351.804 207.722C351.179 207.554 350.537 207.925 350.369 208.55C350.108 209.526 350.691 210.529 351.666 210.791Z" fill="#FF4A1C"/>
        <path id="Rectangle 710_2" d="M367.13 236.134C367.752 236.301 368.393 235.933 368.559 235.311L368.649 234.978C368.862 234.183 368.39 233.366 367.595 233.153L367.268 233.066C366.643 232.898 366.001 233.269 365.833 233.894C365.572 234.869 366.154 235.873 367.13 236.134Z" fill="#FF4A1C"/>
        <path id="Rectangle 712_2" d="M404.392 223.721C405.013 223.888 405.655 223.519 405.821 222.898L405.91 222.565C406.123 221.77 405.652 220.953 404.857 220.74L404.53 220.652C403.905 220.485 403.262 220.856 403.095 221.481C402.834 222.456 403.416 223.46 404.392 223.721Z" fill="#FF4A1C"/>
        <path id="Rectangle 714_2" d="M383.899 264.266C383.278 264.099 382.636 264.467 382.47 265.089L382.381 265.422C382.168 266.217 382.639 267.034 383.434 267.247L383.761 267.334C384.386 267.502 385.029 267.131 385.196 266.506C385.457 265.531 384.875 264.527 383.899 264.266Z" fill="#FF4A1C"/>
        <path id="Rectangle 716_2" d="M371.105 258.518C370.483 258.351 369.842 258.72 369.675 259.341L369.586 259.674C369.373 260.469 369.845 261.286 370.639 261.499L370.966 261.587C371.591 261.754 372.234 261.383 372.401 260.758C372.662 259.783 372.08 258.779 371.105 258.518Z" fill="#FF4A1C"/>
        <path id="Rectangle 718_2" d="M395.848 245.992C395.226 245.826 394.585 246.194 394.418 246.816L394.329 247.148C394.116 247.943 394.588 248.76 395.383 248.973L395.709 249.061C396.334 249.228 396.977 248.858 397.144 248.233C397.406 247.257 396.823 246.254 395.848 245.992Z" fill="#FF4A1C"/>
        <path id="Rectangle 720_2" d="M380.385 220.648C379.763 220.482 379.122 220.85 378.955 221.472L378.866 221.805C378.653 222.6 379.125 223.417 379.92 223.63L380.247 223.717C380.872 223.885 381.514 223.514 381.681 222.889C381.943 221.913 381.36 220.91 380.385 220.648Z" fill="#FF4A1C"/>
        <path id="Rectangle 722_2" d="M343.123 233.061C342.501 232.894 341.86 233.263 341.694 233.884L341.604 234.217C341.391 235.012 341.863 235.829 342.658 236.042L342.985 236.13C343.61 236.297 344.252 235.926 344.42 235.301C344.681 234.326 344.098 233.322 343.123 233.061Z" fill="#FF4A1C"/>
        <path id="Rectangle 732_2" d="M432.29 233.105C431.668 232.939 431.027 233.307 430.861 233.929L430.771 234.262C430.558 235.057 431.03 235.874 431.825 236.087L432.152 236.174C432.777 236.342 433.419 235.971 433.587 235.346C433.848 234.37 433.265 233.367 432.29 233.105Z" fill="#FF4A1C"/>
        <path id="Rectangle 734_2" d="M452.782 192.565C453.404 192.732 454.045 192.363 454.212 191.742L454.301 191.409C454.514 190.614 454.042 189.797 453.247 189.584L452.92 189.496C452.295 189.329 451.653 189.7 451.486 190.325C451.224 191.3 451.807 192.304 452.782 192.565Z" fill="#FF4A1C"/>
        <path id="Rectangle 735_2" d="M315.734 429.934C316.356 430.101 316.997 429.732 317.164 429.111L317.253 428.778C317.466 427.983 316.994 427.166 316.199 426.953L315.873 426.865C315.248 426.698 314.605 427.069 314.438 427.694C314.176 428.669 314.759 429.673 315.734 429.934Z" fill="#FF4A1C"/>
        <path id="Rectangle 737_2" d="M328.529 435.684C329.151 435.851 329.792 435.482 329.959 434.861L330.048 434.528C330.261 433.733 329.789 432.916 328.994 432.703L328.668 432.615C328.043 432.448 327.4 432.819 327.233 433.444C326.971 434.419 327.554 435.423 328.529 435.684Z" fill="#FF4A1C"/>
        <path id="Rectangle 738_2" d="M440.832 210.835C441.454 211.002 442.095 210.633 442.262 210.012L442.351 209.679C442.564 208.884 442.092 208.067 441.297 207.854L440.97 207.766C440.345 207.599 439.703 207.97 439.535 208.595C439.274 209.57 439.857 210.574 440.832 210.835Z" fill="#FF4A1C"/>
        <path id="Rectangle 740_2" d="M456.295 236.177C456.917 236.344 457.558 235.976 457.724 235.354L457.814 235.021C458.027 234.226 457.555 233.409 456.76 233.196L456.433 233.108C455.808 232.941 455.166 233.312 454.998 233.937C454.737 234.912 455.319 235.916 456.295 236.177Z" fill="#FF4A1C"/>
        <path id="Rectangle 413_2" d="M20.0664 73.8511C20.688 74.0177 21.3294 73.6494 21.4959 73.0277L21.5851 72.6949C21.7981 71.9 21.3264 71.0829 20.5315 70.8699L20.2047 70.7823C19.5797 70.6148 18.9373 70.9857 18.7698 71.6107C18.5084 72.5861 19.091 73.5897 20.0664 73.8511Z" fill="#FF4A1C"/>
        <path id="Rectangle 745_2" d="M362.271 393.878C362.893 394.045 363.534 393.676 363.701 393.055L363.79 392.722C364.003 391.927 363.531 391.11 362.737 390.897L362.41 390.809C361.785 390.642 361.142 391.013 360.975 391.638C360.714 392.613 361.296 393.617 362.271 393.878Z" fill="#FF4A1C"/>
        <path id="Rectangle 747_2" d="M375.064 399.622C375.686 399.789 376.327 399.42 376.494 398.799L376.583 398.466C376.796 397.671 376.324 396.854 375.53 396.641L375.203 396.553C374.578 396.386 373.935 396.757 373.768 397.382C373.506 398.357 374.089 399.361 375.064 399.622Z" fill="#FF4A1C"/>
        <path id="Rectangle 749_2" d="M350.321 412.148C350.943 412.315 351.584 411.946 351.751 411.325L351.84 410.992C352.053 410.197 351.581 409.38 350.786 409.167L350.46 409.079C349.835 408.912 349.192 409.283 349.025 409.908C348.763 410.883 349.346 411.887 350.321 412.148Z" fill="#FF4A1C"/>
        <path id="Rectangle 417_2" d="M29.3418 50.207C29.9634 50.3736 30.6048 50.0053 30.7713 49.3837L30.8605 49.0508C31.0735 48.2559 30.6018 47.4388 29.8068 47.2258L29.48 47.1383C28.8551 46.9708 28.2126 47.3417 28.0452 47.9667C27.7838 48.9421 28.3664 49.9457 29.3418 50.207Z" fill="#FF4A1C"/>
        <path id="Rectangle 418_2" d="M66.6064 37.794C67.2281 37.9605 67.8694 37.5922 68.036 36.9706L68.1252 36.6377C68.3382 35.8428 67.8664 35.0257 67.0715 34.8127L66.7447 34.7252C66.1197 34.5577 65.4773 34.9286 65.3098 35.5536C65.0485 36.529 65.631 37.5326 66.6064 37.794Z" fill="#FF4A1C"/>
        <path id="Rectangle 753_2" d="M403.047 425.079C403.669 425.246 404.31 424.877 404.476 424.256L404.566 423.923C404.779 423.128 404.307 422.311 403.512 422.098L403.185 422.01C402.56 421.843 401.918 422.214 401.75 422.839C401.489 423.814 402.071 424.818 403.047 425.079Z" fill="#FF4A1C"/>
        <path id="Rectangle 419_2" d="M46.1123 78.3369C45.4907 78.1703 44.8493 78.5386 44.6828 79.1603L44.5936 79.4931C44.3806 80.288 44.8523 81.1051 45.6473 81.3181L45.9741 81.4057C46.599 81.5731 47.2415 81.2023 47.4089 80.5773C47.6703 79.6019 47.0877 78.5983 46.1123 78.3369Z" fill="#FF4A1C"/>
        <path id="Rectangle 420_2" d="M33.3193 72.5928C32.6977 72.4262 32.0564 72.7945 31.8898 73.4161L31.8006 73.749C31.5876 74.5439 32.0594 75.361 32.8543 75.574L33.1811 75.6615C33.8061 75.829 34.4485 75.4581 34.6159 74.8331C34.8773 73.8577 34.2948 72.8541 33.3193 72.5928Z" fill="#FF4A1C"/>
        <path id="Rectangle 421_2" d="M58.0625 60.0644C57.4409 59.8979 56.7995 60.2662 56.633 60.8878L56.5438 61.2206C56.3308 62.0156 56.8025 62.8327 57.5974 63.0457L57.9243 63.1332C58.5492 63.3007 59.1916 62.9298 59.3591 62.3048C59.6205 61.3294 59.0379 60.3258 58.0625 60.0644Z" fill="#FF4A1C"/>
        <path id="Rectangle 422_2" d="M42.5986 34.7226C41.977 34.5561 41.3357 34.9244 41.1691 35.546L41.0799 35.8789C40.8669 36.6738 41.3387 37.4909 42.1336 37.7039L42.4604 37.7914C43.0854 37.9589 43.7278 37.588 43.8952 36.963C44.1566 35.9876 43.5741 34.984 42.5986 34.7226Z" fill="#FF4A1C"/>
        <path id="Rectangle 761_2" d="M379.042 422.007C378.42 421.841 377.779 422.209 377.612 422.831L377.523 423.164C377.31 423.958 377.782 424.776 378.577 424.989L378.904 425.076C379.529 425.244 380.171 424.873 380.339 424.248C380.6 423.272 380.017 422.269 379.042 422.007Z" fill="#FF4A1C"/>
        <path id="Rectangle 424_2" d="M54.3203 175.004C53.6987 174.838 53.0574 175.206 52.8908 175.828L52.8016 176.161C52.5886 176.956 53.0603 177.773 53.8553 177.986L54.1821 178.073C54.8071 178.241 55.4495 177.87 55.6169 177.245C55.8783 176.269 55.2957 175.266 54.3203 175.004Z" fill="#FF4A1C"/>
        <path id="Rectangle 425_2" d="M41.5283 169.26C40.9067 169.093 40.2654 169.461 40.0988 170.083L40.0096 170.416C39.7966 171.211 40.2683 172.028 41.0633 172.241L41.3901 172.329C42.0151 172.496 42.6575 172.125 42.8249 171.5C43.0863 170.525 42.5037 169.521 41.5283 169.26Z" fill="#FF4A1C"/>
        <path id="Rectangle 426_2" d="M66.2725 156.733C65.6508 156.567 65.0095 156.935 64.8429 157.557L64.7537 157.89C64.5407 158.685 65.0125 159.502 65.8074 159.715L66.1342 159.802C66.7592 159.97 67.4016 159.599 67.5691 158.974C67.8304 157.998 67.2479 156.995 66.2725 156.733Z" fill="#FF4A1C"/>
        <path id="Rectangle 427_2" d="M50.8086 131.39C50.187 131.223 49.5456 131.591 49.3791 132.213L49.2899 132.546C49.0769 133.341 49.5486 134.158 50.3435 134.371L50.6703 134.458C51.2953 134.626 51.9377 134.255 52.1052 133.63C52.3666 132.655 51.784 131.651 50.8086 131.39Z" fill="#FF4A1C"/>
        <path id="Rectangle 429_2" d="M34.04 103.26C34.6617 103.426 35.303 103.058 35.4696 102.436L35.5588 102.104C35.7718 101.309 35.3 100.492 34.5051 100.279L34.1783 100.191C33.5533 100.024 32.9109 100.394 32.7434 101.019C32.4821 101.995 33.0646 102.998 34.04 103.26Z" fill="#FF4A1C"/>
        <path id="Rectangle 430_2" d="M46.833 109.004C47.4546 109.17 48.096 108.802 48.2625 108.181L48.3517 107.848C48.5647 107.053 48.093 106.236 47.2981 106.023L46.9713 105.935C46.3463 105.768 45.7039 106.139 45.5364 106.764C45.275 107.739 45.8576 108.743 46.833 109.004Z" fill="#FF4A1C"/>
        <path id="Rectangle 431_2" d="M22.0898 121.53C22.7115 121.696 23.3528 121.328 23.5194 120.706L23.6086 120.374C23.8216 119.579 23.3498 118.762 22.5549 118.549L22.2281 118.461C21.6031 118.294 20.9607 118.664 20.7932 119.289C20.5319 120.265 21.1144 121.268 22.0898 121.53Z" fill="#FF4A1C"/>
        <path id="Rectangle 432_2" d="M37.5517 146.874C38.1734 147.041 38.8147 146.672 38.9813 146.051L39.0705 145.718C39.2835 144.923 38.8117 144.106 38.0168 143.893L37.69 143.805C37.065 143.638 36.4226 144.009 36.2551 144.634C35.9938 145.609 36.5763 146.613 37.5517 146.874Z" fill="#FF4A1C"/>
        <path id="Rectangle 433_2" d="M74.8144 134.463C75.4361 134.629 76.0774 134.261 76.244 133.64L76.3332 133.307C76.5462 132.512 76.0744 131.695 75.2795 131.482L74.9527 131.394C74.3277 131.227 73.6853 131.598 73.5178 132.223C73.2565 133.198 73.839 134.202 74.8144 134.463Z" fill="#FF4A1C"/>
        <path id="Rectangle 434_2" d="M80.5771 67.2031C81.1988 67.3697 81.8401 67.0014 82.0067 66.3798L82.0959 66.0469C82.3089 65.252 81.8371 64.4349 81.0422 64.2219L80.7154 64.1344C80.0904 63.9669 79.448 64.3378 79.2805 64.9628C79.0192 65.9382 79.6017 66.9418 80.5771 67.2031Z" fill="#FF4A1C"/>
        <path id="Rectangle 435_2" d="M93.3662 72.9444C93.9878 73.1109 94.6292 72.7426 94.7957 72.121L94.8849 71.7881C95.0979 70.9932 94.6262 70.1761 93.8313 69.9631L93.5045 69.8756C92.8795 69.7081 92.2371 70.079 92.0696 70.704C91.8082 71.6794 92.3908 72.683 93.3662 72.9444Z" fill="#FF4A1C"/>
        <path id="Rectangle 436_2" d="M68.624 85.4722C69.2457 85.6388 69.887 85.2705 70.0536 84.6488L70.1427 84.316C70.3557 83.521 69.884 82.704 69.0891 82.491L68.7623 82.4034C68.1373 82.2359 67.4949 82.6068 67.3274 83.2318C67.0661 84.2072 67.6486 85.2108 68.624 85.4722Z" fill="#FF4A1C"/>
        <path id="Rectangle 437_2" d="M84.0898 110.815C84.7115 110.982 85.3528 110.614 85.5194 109.992L85.6086 109.659C85.8216 108.864 85.3498 108.047 84.5549 107.834L84.2281 107.747C83.6031 107.579 82.9607 107.95 82.7932 108.575C82.5319 109.55 83.1144 110.554 84.0898 110.815Z" fill="#FF4A1C"/>
        <path id="Rectangle 438_2" d="M121.35 98.4009C121.971 98.5675 122.613 98.1992 122.779 97.5775L122.868 97.2447C123.081 96.4498 122.61 95.6327 121.815 95.4197L121.488 95.3321C120.863 95.1646 120.22 95.5355 120.053 96.1605C119.792 97.1359 120.374 98.1395 121.35 98.4009Z" fill="#FF4A1C"/>
        <path id="Rectangle 439_2" d="M100.86 138.945C100.239 138.778 99.5974 139.147 99.4308 139.768L99.3416 140.101C99.1286 140.896 99.6004 141.713 100.395 141.926L100.722 142.014C101.347 142.181 101.989 141.81 102.157 141.185C102.418 140.21 101.836 139.206 100.86 138.945Z" fill="#FF4A1C"/>
        <path id="Rectangle 440_2" d="M88.0654 133.201C87.4438 133.035 86.8025 133.403 86.6359 134.025L86.5467 134.357C86.3337 135.152 86.8055 135.969 87.6004 136.182L87.9272 136.27C88.5522 136.437 89.1946 136.067 89.362 135.442C89.6234 134.466 89.0409 133.463 88.0654 133.201Z" fill="#FF4A1C"/>
        <path id="Rectangle 441_2" d="M112.808 120.673C112.186 120.507 111.545 120.875 111.378 121.497L111.289 121.83C111.076 122.624 111.548 123.442 112.343 123.655L112.669 123.742C113.294 123.91 113.937 123.539 114.104 122.914C114.366 121.938 113.783 120.935 112.808 120.673Z" fill="#FF4A1C"/>
        <path id="Rectangle 442_2" d="M97.3438 95.3315C96.7221 95.165 96.0808 95.5332 95.9142 96.1549L95.825 96.4877C95.612 97.2827 96.0838 98.0997 96.8787 98.3127L97.2055 98.4003C97.8305 98.5678 98.4729 98.1969 98.6404 97.5719C98.9017 96.5965 98.3192 95.5929 97.3438 95.3315Z" fill="#FF4A1C"/>
        <path id="Rectangle 443_2" d="M60.083 107.742C59.4614 107.576 58.82 107.944 58.6535 108.566L58.5643 108.898C58.3513 109.693 58.823 110.51 59.618 110.723L59.9448 110.811C60.5697 110.978 61.2122 110.608 61.3796 109.983C61.641 109.007 61.0584 108.004 60.083 107.742Z" fill="#FF4A1C"/>
        <path id="Rectangle 204_2" d="M250.922 60.2691C251.544 60.4356 252.185 60.0673 252.351 59.4457L252.441 59.1128C252.654 58.3179 252.182 57.5008 251.387 57.2878L251.06 57.2003C250.435 57.0328 249.793 57.4037 249.625 58.0287C249.364 59.0041 249.946 60.0077 250.922 60.2691Z" fill="#FF4A1C"/>
        <path id="Rectangle 444_2" d="M113.877 297.642C114.499 297.809 115.14 297.44 115.306 296.819L115.396 296.486C115.609 295.691 115.137 294.874 114.342 294.661L114.015 294.573C113.39 294.406 112.748 294.777 112.58 295.402C112.319 296.377 112.902 297.381 113.877 297.642Z" fill="#FF4A1C"/>
        <path id="Rectangle 205_2" d="M263.716 66.0132C264.337 66.1798 264.979 65.8115 265.145 65.1898L265.235 64.857C265.448 64.0621 264.976 63.245 264.181 63.032L263.854 62.9444C263.229 62.777 262.587 63.1478 262.419 63.7728C262.158 64.7482 262.74 65.7518 263.716 66.0132Z" fill="#FF4A1C"/>
        <path id="Rectangle 445_2" d="M126.669 303.384C127.291 303.551 127.932 303.183 128.098 302.561L128.188 302.228C128.401 301.433 127.929 300.616 127.134 300.403L126.807 300.316C126.182 300.148 125.54 300.519 125.372 301.144C125.111 302.119 125.694 303.123 126.669 303.384Z" fill="#FF4A1C"/>
        <path id="Rectangle 206_2" d="M238.973 78.541C239.594 78.7076 240.236 78.3393 240.402 77.7177L240.491 77.3848C240.704 76.5899 240.233 75.7728 239.438 75.5598L239.111 75.4722C238.486 75.3048 237.844 75.6757 237.676 76.3007C237.415 77.2761 237.997 78.2797 238.973 78.541Z" fill="#FF4A1C"/>
        <path id="Rectangle 446_2" d="M101.926 315.914C102.547 316.081 103.189 315.712 103.355 315.091L103.444 314.758C103.657 313.963 103.186 313.146 102.391 312.933L102.064 312.845C101.439 312.678 100.797 313.049 100.629 313.674C100.368 314.649 100.95 315.653 101.926 315.914Z" fill="#FF4A1C"/>
        <path id="Rectangle 207_2" d="M254.436 103.885C255.057 104.051 255.699 103.683 255.865 103.061L255.954 102.729C256.167 101.934 255.696 101.117 254.901 100.904L254.574 100.816C253.949 100.649 253.306 101.019 253.139 101.644C252.878 102.62 253.46 103.623 254.436 103.885Z" fill="#FF4A1C"/>
        <path id="Rectangle 447_2" d="M117.39 341.256C118.011 341.422 118.653 341.054 118.819 340.433L118.908 340.1C119.121 339.305 118.65 338.488 117.855 338.275L117.528 338.187C116.903 338.02 116.261 338.391 116.093 339.016C115.832 339.991 116.414 340.995 117.39 341.256Z" fill="#FF4A1C"/>
        <path id="Rectangle 208_2" d="M291.698 91.4722C292.32 91.6388 292.961 91.2705 293.128 90.6488L293.217 90.316C293.43 89.521 292.958 88.704 292.163 88.491L291.836 88.4034C291.212 88.2359 290.569 88.6068 290.402 89.2318C290.14 90.2072 290.723 91.2108 291.698 91.4722Z" fill="#FF4A1C"/>
        <path id="Rectangle 448_2" d="M154.651 328.845C155.273 329.012 155.914 328.644 156.081 328.022L156.17 327.689C156.383 326.894 155.911 326.077 155.116 325.864L154.79 325.776C154.165 325.609 153.522 325.98 153.355 326.605C153.093 327.58 153.676 328.584 154.651 328.845Z" fill="#FF4A1C"/>
        <path id="Rectangle 209_2" d="M271.204 132.013C270.582 131.846 269.941 132.214 269.775 132.836L269.685 133.169C269.472 133.964 269.944 134.781 270.739 134.994L271.066 135.081C271.691 135.249 272.333 134.878 272.501 134.253C272.762 133.278 272.18 132.274 271.204 132.013Z" fill="#FF4A1C"/>
        <path id="Rectangle 449_2" d="M134.157 369.386C133.536 369.22 132.894 369.588 132.728 370.21L132.639 370.542C132.426 371.337 132.897 372.154 133.692 372.367L134.019 372.455C134.644 372.622 135.286 372.252 135.454 371.627C135.715 370.651 135.133 369.648 134.157 369.386Z" fill="#FF4A1C"/>
        <path id="Rectangle 210_2" d="M258.414 126.27C257.792 126.103 257.151 126.472 256.985 127.093L256.895 127.426C256.682 128.221 257.154 129.038 257.949 129.251L258.276 129.339C258.901 129.506 259.543 129.135 259.711 128.51C259.972 127.535 259.389 126.531 258.414 126.27Z" fill="#FF4A1C"/>
        <path id="Rectangle 450_2" d="M121.367 363.644C120.746 363.477 120.104 363.845 119.938 364.467L119.848 364.8C119.635 365.595 120.107 366.412 120.902 366.625L121.229 366.712C121.854 366.88 122.496 366.509 122.664 365.884C122.925 364.908 122.343 363.905 121.367 363.644Z" fill="#FF4A1C"/>
        <path id="Rectangle 211_2" d="M283.157 113.742C282.536 113.576 281.894 113.944 281.728 114.566L281.639 114.898C281.426 115.693 281.897 116.51 282.692 116.723L283.019 116.811C283.644 116.978 284.286 116.608 284.454 115.983C284.715 115.007 284.133 114.004 283.157 113.742Z" fill="#FF4A1C"/>
        <path id="Rectangle 451_2" d="M146.11 351.115C145.489 350.949 144.847 351.317 144.681 351.939L144.592 352.271C144.379 353.066 144.85 353.883 145.645 354.096L145.972 354.184C146.597 354.351 147.239 353.981 147.407 353.356C147.668 352.38 147.086 351.377 146.11 351.115Z" fill="#FF4A1C"/>
        <path id="Rectangle 212_2" d="M267.691 88.3989C267.07 88.2323 266.428 88.6006 266.262 89.2223L266.173 89.5551C265.96 90.35 266.431 91.1671 267.226 91.3801L267.553 91.4677C268.178 91.6352 268.821 91.2643 268.988 90.6393C269.249 89.6639 268.667 88.6603 267.691 88.3989Z" fill="#FF4A1C"/>
        <path id="Rectangle 452_2" d="M130.645 325.77C130.023 325.603 129.382 325.972 129.215 326.593L129.126 326.926C128.913 327.721 129.385 328.538 130.179 328.751L130.506 328.839C131.131 329.006 131.774 328.635 131.941 328.01C132.203 327.035 131.62 326.031 130.645 325.77Z" fill="#FF4A1C"/>
        <path id="Rectangle 213_2" d="M230.432 100.811C229.81 100.644 229.169 101.013 229.002 101.634L228.913 101.967C228.7 102.762 229.172 103.579 229.967 103.792L230.293 103.88C230.918 104.047 231.561 103.676 231.728 103.051C231.99 102.076 231.407 101.072 230.432 100.811Z" fill="#FF4A1C"/>
        <path id="Rectangle 453_2" d="M93.3848 338.184C92.7631 338.017 92.1218 338.386 91.9552 339.007L91.866 339.34C91.653 340.135 92.1248 340.952 92.9197 341.165L93.2465 341.253C93.8715 341.42 94.5139 341.049 94.6814 340.424C94.9427 339.449 94.3602 338.445 93.3848 338.184Z" fill="#FF4A1C"/>
        <path id="Rectangle 214_2" d="M224.669 168.073C224.047 167.907 223.406 168.275 223.239 168.897L223.15 169.229C222.937 170.024 223.409 170.841 224.204 171.054L224.531 171.142C225.156 171.309 225.798 170.939 225.966 170.314C226.227 169.338 225.644 168.335 224.669 168.073Z" fill="#FF4A1C"/>
        <path id="Rectangle 454_2" d="M87.6221 405.446C87.0004 405.28 86.3591 405.648 86.1925 406.27L86.1034 406.602C85.8904 407.397 86.3621 408.214 87.157 408.427L87.4838 408.515C88.1088 408.683 88.7512 408.312 88.9187 407.687C89.18 406.711 88.5975 405.708 87.6221 405.446Z" fill="#FF4A1C"/>
        <path id="Rectangle 215_2" d="M211.877 162.329C211.255 162.162 210.614 162.53 210.447 163.152L210.358 163.485C210.145 164.28 210.617 165.097 211.412 165.31L211.739 165.397C212.364 165.565 213.006 165.194 213.174 164.569C213.435 163.594 212.852 162.59 211.877 162.329Z" fill="#FF4A1C"/>
        <path id="Rectangle 455_2" d="M74.8301 399.702C74.2084 399.535 73.5671 399.903 73.4005 400.525L73.3114 400.858C73.0984 401.653 73.5701 402.47 74.365 402.683L74.6918 402.77C75.3168 402.938 75.9592 402.567 76.1267 401.942C76.3881 400.967 75.8055 399.963 74.8301 399.702Z" fill="#FF4A1C"/>
        <path id="Rectangle 216_2" d="M236.62 149.801C235.998 149.634 235.357 150.002 235.191 150.624L235.101 150.957C234.888 151.752 235.36 152.569 236.155 152.782L236.482 152.87C237.107 153.037 237.749 152.666 237.917 152.041C238.178 151.066 237.596 150.062 236.62 149.801Z" fill="#FF4A1C"/>
        <path id="Rectangle 456_2" d="M99.5733 387.176C98.9516 387.009 98.3103 387.377 98.1437 387.999L98.0545 388.332C97.8415 389.127 98.3133 389.944 99.1082 390.157L99.435 390.245C100.06 390.412 100.702 390.041 100.87 389.416C101.131 388.441 100.549 387.437 99.5733 387.176Z" fill="#FF4A1C"/>
        <path id="Rectangle 217_2" d="M221.156 124.457C220.535 124.29 219.893 124.659 219.727 125.28L219.638 125.613C219.425 126.408 219.896 127.225 220.691 127.438L221.018 127.526C221.643 127.693 222.285 127.322 222.453 126.697C222.714 125.722 222.132 124.718 221.156 124.457Z" fill="#FF4A1C"/>
        <path id="Rectangle 457_2" d="M84.1094 361.83C83.4877 361.663 82.8464 362.032 82.6798 362.653L82.5907 362.986C82.3777 363.781 82.8494 364.598 83.6443 364.811L83.9711 364.899C84.5961 365.066 85.2385 364.695 85.406 364.07C85.6673 363.095 85.0848 362.091 84.1094 361.83Z" fill="#FF4A1C"/>
        <path id="Rectangle 218_2" d="M183.895 136.87C183.273 136.703 182.632 137.071 182.465 137.693L182.376 138.026C182.163 138.821 182.635 139.638 183.429 139.851L183.756 139.938C184.381 140.106 185.024 139.735 185.191 139.11C185.453 138.135 184.87 137.131 183.895 136.87Z" fill="#FF4A1C"/>
        <path id="Rectangle 458_2" d="M46.8477 374.243C46.226 374.076 45.5847 374.444 45.4181 375.066L45.3289 375.399C45.1159 376.194 45.5877 377.011 46.3826 377.224L46.7094 377.311C47.3344 377.479 47.9768 377.108 48.1443 376.483C48.4056 375.508 47.8231 374.504 46.8477 374.243Z" fill="#FF4A1C"/>
        <path id="Rectangle 219_2" d="M204.387 96.3272C205.008 96.4937 205.65 96.1255 205.816 95.5038L205.905 95.171C206.118 94.376 205.647 93.5589 204.852 93.3459L204.525 93.2584C203.9 93.0909 203.258 93.4618 203.09 94.0868C202.829 95.0622 203.411 96.0658 204.387 96.3272Z" fill="#FF4A1C"/>
        <path id="Rectangle 459_2" d="M67.3398 333.7C67.9615 333.867 68.6028 333.498 68.7694 332.877L68.8586 332.544C69.0716 331.749 68.5998 330.932 67.8049 330.719L67.4781 330.631C66.8531 330.464 66.2107 330.835 66.0432 331.46C65.7819 332.435 66.3644 333.439 67.3398 333.7Z" fill="#FF4A1C"/>
        <path id="Rectangle 220_2" d="M217.18 102.073C217.801 102.24 218.443 101.872 218.609 101.25L218.698 100.917C218.911 100.122 218.44 99.305 217.645 99.092L217.318 99.0045C216.693 98.837 216.051 99.2079 215.883 99.8329C215.622 100.808 216.204 101.812 217.18 102.073Z" fill="#FF4A1C"/>
        <path id="Rectangle 460_2" d="M80.1338 339.444C80.7554 339.61 81.3968 339.242 81.5633 338.621L81.6525 338.288C81.8655 337.493 81.3938 336.676 80.5988 336.463L80.272 336.375C79.647 336.208 79.0046 336.579 78.8372 337.203C78.5758 338.179 79.1584 339.183 80.1338 339.444Z" fill="#FF4A1C"/>
        <path id="Rectangle 221_2" d="M192.437 114.601C193.059 114.768 193.7 114.399 193.867 113.778L193.956 113.445C194.169 112.65 193.697 111.833 192.903 111.62L192.576 111.532C191.951 111.365 191.308 111.736 191.141 112.361C190.88 113.336 191.462 114.34 192.437 114.601Z" fill="#FF4A1C"/>
        <path id="Rectangle 461_2" d="M55.3906 351.972C56.0123 352.139 56.6536 351.77 56.8202 351.149L56.9093 350.816C57.1223 350.021 56.6506 349.204 55.8557 348.991L55.5289 348.903C54.9039 348.736 54.2615 349.107 54.094 349.732C53.8327 350.707 54.4152 351.711 55.3906 351.972Z" fill="#FF4A1C"/>
        <path id="Rectangle 222_2" d="M207.899 139.943C208.521 140.11 209.162 139.742 209.329 139.12L209.418 138.787C209.631 137.992 209.159 137.175 208.364 136.962L208.038 136.875C207.413 136.707 206.77 137.078 206.603 137.703C206.341 138.678 206.924 139.682 207.899 139.943Z" fill="#FF4A1C"/>
        <path id="Rectangle 462_2" d="M70.8525 377.314C71.4742 377.481 72.1155 377.113 72.2821 376.491L72.3713 376.158C72.5843 375.363 72.1125 374.546 71.3176 374.333L70.9908 374.246C70.3658 374.078 69.7234 374.449 69.5559 375.074C69.2946 376.05 69.8771 377.053 70.8525 377.314Z" fill="#FF4A1C"/>
        <path id="Rectangle 223_2" d="M245.163 127.53C245.785 127.697 246.426 127.329 246.593 126.707L246.682 126.374C246.895 125.579 246.423 124.762 245.628 124.549L245.301 124.462C244.676 124.294 244.034 124.665 243.866 125.29C243.605 126.265 244.188 127.269 245.163 127.53Z" fill="#FF4A1C"/>
        <path id="Rectangle 463_2" d="M108.116 364.903C108.738 365.07 109.379 364.702 109.546 364.08L109.635 363.747C109.848 362.952 109.376 362.135 108.581 361.922L108.254 361.835C107.629 361.667 106.987 362.038 106.82 362.663C106.558 363.638 107.141 364.642 108.116 364.903Z" fill="#FF4A1C"/>
        <path id="Rectangle 464_2" d="M59.1299 237.033C59.7515 237.2 60.3928 236.832 60.5594 236.21L60.6486 235.877C60.8616 235.082 60.3899 234.265 59.5949 234.052L59.2681 233.964C58.6431 233.797 58.0007 234.168 57.8333 234.793C57.5719 235.768 58.1545 236.772 59.1299 237.033Z" fill="#FF4A1C"/>
        <path id="Rectangle 465_2" d="M71.9219 242.777C72.5435 242.944 73.1848 242.576 73.3514 241.954L73.4406 241.621C73.6536 240.826 73.1818 240.009 72.3869 239.796L72.0601 239.709C71.4351 239.541 70.7927 239.912 70.6253 240.537C70.3639 241.512 70.9464 242.516 71.9219 242.777Z" fill="#FF4A1C"/>
        <path id="Rectangle 466_2" d="M47.1787 255.304C47.8003 255.47 48.4417 255.102 48.6082 254.48L48.6974 254.148C48.9104 253.353 48.4387 252.536 47.6438 252.323L47.317 252.235C46.692 252.067 46.0496 252.438 45.8821 253.063C45.6207 254.039 46.2033 255.042 47.1787 255.304Z" fill="#FF4A1C"/>
        <path id="Rectangle 227_2" d="M199.689 43.2744C200.311 43.441 200.952 43.0727 201.119 42.4511L201.208 42.1182C201.421 41.3233 200.949 40.5062 200.155 40.2932L199.828 40.2056C199.203 40.0382 198.56 40.4091 198.393 41.0341C198.131 42.0095 198.714 43.0131 199.689 43.2744Z" fill="#FF4A1C"/>
        <path id="Rectangle 467_2" d="M62.6426 280.647C63.2642 280.814 63.9055 280.446 64.0721 279.824L64.1613 279.491C64.3743 278.696 63.9026 277.879 63.1076 277.666L62.7808 277.579C62.1558 277.411 61.5134 277.782 61.346 278.407C61.0846 279.383 61.6672 280.386 62.6426 280.647Z" fill="#FF4A1C"/>
        <path id="Rectangle 228_2" d="M236.953 30.8633C237.575 31.0299 238.216 30.6616 238.383 30.0399L238.472 29.7071C238.685 28.9122 238.213 28.0951 237.418 27.8821L237.091 27.7945C236.466 27.627 235.824 27.9979 235.657 28.6229C235.395 29.5983 235.978 30.6019 236.953 30.8633Z" fill="#FF4A1C"/>
        <path id="Rectangle 468_2" d="M99.9053 268.234C100.527 268.401 101.168 268.033 101.335 267.411L101.424 267.078C101.637 266.283 101.165 265.466 100.37 265.253L100.044 265.166C99.4185 264.998 98.7761 265.369 98.6087 265.994C98.3473 266.969 98.9298 267.973 99.9053 268.234Z" fill="#FF4A1C"/>
        <path id="Rectangle 229_2" d="M216.461 71.4053C215.839 71.2387 215.198 71.607 215.031 72.2286L214.942 72.5615C214.729 73.3564 215.201 74.1735 215.996 74.3865L216.323 74.474C216.948 74.6415 217.59 74.2706 217.758 73.6456C218.019 72.6702 217.436 71.6666 216.461 71.4053Z" fill="#FF4A1C"/>
        <path id="Rectangle 469_2" d="M79.4121 308.777C78.7905 308.61 78.1491 308.979 77.9826 309.6L77.8934 309.933C77.6804 310.728 78.1521 311.545 78.9471 311.758L79.2739 311.846C79.8989 312.013 80.5413 311.642 80.7087 311.017C80.9701 310.042 80.3875 309.038 79.4121 308.777Z" fill="#FF4A1C"/>
        <path id="Rectangle 230_2" d="M203.665 65.6601C203.043 65.4936 202.402 65.8619 202.236 66.4835L202.146 66.8164C201.933 67.6113 202.405 68.4284 203.2 68.6414L203.527 68.7289C204.152 68.8964 204.794 68.5255 204.962 67.9005C205.223 66.9251 204.64 65.9215 203.665 65.6601Z" fill="#FF4A1C"/>
        <path id="Rectangle 470_2" d="M66.6182 303.033C65.9965 302.867 65.3552 303.235 65.1886 303.857L65.0994 304.189C64.8864 304.984 65.3582 305.801 66.1531 306.014L66.4799 306.102C67.1049 306.269 67.7473 305.899 67.9148 305.274C68.1761 304.298 67.5936 303.295 66.6182 303.033Z" fill="#FF4A1C"/>
        <path id="Rectangle 231_2" d="M228.409 53.1338C227.788 52.9672 227.146 53.3355 226.98 53.9571L226.89 54.29C226.677 55.0849 227.149 55.902 227.944 56.115L228.271 56.2026C228.896 56.37 229.538 55.9991 229.706 55.3741C229.967 54.3987 229.385 53.3951 228.409 53.1338Z" fill="#FF4A1C"/>
        <path id="Rectangle 471_2" d="M91.3623 290.507C90.7407 290.34 90.0993 290.709 89.9328 291.33L89.8436 291.663C89.6306 292.458 90.1023 293.275 90.8973 293.488L91.2241 293.576C91.849 293.743 92.4915 293.372 92.6589 292.747C92.9203 291.772 92.3377 290.768 91.3623 290.507Z" fill="#FF4A1C"/>
        <path id="Rectangle 232_2" d="M212.947 27.7895C212.326 27.623 211.684 27.9912 211.518 28.6129L211.429 28.9457C211.216 29.7407 211.687 30.5578 212.482 30.7708L212.809 30.8583C213.434 31.0258 214.076 30.6549 214.244 30.0299C214.505 29.0545 213.923 28.0509 212.947 27.7895Z" fill="#FF4A1C"/>
        <path id="Rectangle 472_2" d="M75.9004 265.163C75.2788 264.996 74.6374 265.364 74.4709 265.986L74.3817 266.319C74.1687 267.114 74.6404 267.931 75.4353 268.144L75.7621 268.231C76.3871 268.399 77.0295 268.028 77.197 267.403C77.4584 266.428 76.8758 265.424 75.9004 265.163Z" fill="#FF4A1C"/>
        <path id="Rectangle 233_2" d="M175.683 40.2012C175.061 40.0346 174.42 40.4029 174.253 41.0245L174.164 41.3574C173.951 42.1523 174.423 42.9694 175.218 43.1824L175.544 43.2699C176.169 43.4374 176.812 43.0665 176.979 42.4415C177.241 41.4661 176.658 40.4625 175.683 40.2012Z" fill="#FF4A1C"/>
        <path id="Rectangle 473_2" d="M38.6358 277.574C38.0141 277.408 37.3728 277.776 37.2062 278.398L37.117 278.73C36.904 279.525 37.3758 280.342 38.1707 280.555L38.4975 280.643C39.1225 280.81 39.7649 280.44 39.9324 279.815C40.1937 278.839 39.6112 277.836 38.6358 277.574Z" fill="#FF4A1C"/>
        <path id="Rectangle 234_2" d="M169.923 107.462C169.301 107.296 168.66 107.664 168.493 108.286L168.404 108.619C168.191 109.414 168.663 110.231 169.458 110.444L169.785 110.531C170.41 110.699 171.052 110.328 171.219 109.703C171.481 108.727 170.898 107.724 169.923 107.462Z" fill="#FF4A1C"/>
        <path id="Rectangle 474_2" d="M32.875 344.835C32.2534 344.669 31.612 345.037 31.4455 345.659L31.3563 345.992C31.1433 346.787 31.615 347.604 32.4099 347.817L32.7368 347.904C33.3617 348.072 34.0041 347.701 34.1716 347.076C34.433 346.1 33.8504 345.097 32.875 344.835Z" fill="#FF4A1C"/>
        <path id="Rectangle 235_2" d="M157.131 101.722C156.509 101.556 155.868 101.924 155.701 102.546L155.612 102.878C155.399 103.673 155.871 104.49 156.666 104.703L156.993 104.791C157.618 104.958 158.26 104.588 158.427 103.963C158.689 102.987 158.106 101.984 157.131 101.722Z" fill="#FF4A1C"/>
        <path id="Rectangle 475_2" d="M20.082 339.091C19.4604 338.925 18.8191 339.293 18.6525 339.915L18.5633 340.248C18.3503 341.042 18.8221 341.86 19.617 342.073L19.9438 342.16C20.5688 342.328 21.2112 341.957 21.3786 341.332C21.64 340.356 21.0575 339.353 20.082 339.091Z" fill="#FF4A1C"/>
        <path id="Rectangle 236_2" d="M181.874 89.1938C181.252 89.0273 180.611 89.3955 180.444 90.0172L180.355 90.35C180.142 91.145 180.614 91.962 181.409 92.175L181.736 92.2626C182.361 92.4301 183.003 92.0592 183.171 91.4342C183.432 90.4588 182.849 89.4552 181.874 89.1938Z" fill="#FF4A1C"/>
        <path id="Rectangle 476_2" d="M44.8272 326.565C44.2055 326.398 43.5642 326.767 43.3976 327.388L43.3084 327.721C43.0954 328.516 43.5672 329.333 44.3621 329.546L44.6889 329.634C45.3139 329.801 45.9563 329.43 46.1238 328.805C46.3851 327.83 45.8026 326.826 44.8272 326.565Z" fill="#FF4A1C"/>
        <path id="Rectangle 237_2" d="M166.41 63.8481C165.789 63.6816 165.147 64.0498 164.981 64.6715L164.891 65.0043C164.678 65.7993 165.15 66.6163 165.945 66.8293L166.272 66.9169C166.897 67.0844 167.539 66.7135 167.707 66.0885C167.968 65.1131 167.386 64.1095 166.41 63.8481Z" fill="#FF4A1C"/>
        <path id="Rectangle 477_2" d="M29.3633 301.221C28.7416 301.055 28.1003 301.423 27.9338 302.045L27.8446 302.377C27.6316 303.172 28.1033 303.989 28.8982 304.202L29.225 304.29C29.85 304.457 30.4924 304.087 30.6599 303.462C30.9213 302.486 30.3387 301.483 29.3633 301.221Z" fill="#FF4A1C"/>
        <path id="Rectangle 238_2" d="M129.148 76.2627C128.527 76.0961 127.885 76.4644 127.719 77.086L127.63 77.4189C127.417 78.2138 127.888 79.0309 128.683 79.2439L129.01 79.3315C129.635 79.4989 130.278 79.128 130.445 78.503C130.706 77.5276 130.124 76.524 129.148 76.2627Z" fill="#FF4A1C"/>
        <path id="Rectangle 239_2" d="M149.641 35.7188C150.262 35.8853 150.904 35.5171 151.07 34.8954L151.159 34.5626C151.372 33.7676 150.901 32.9505 150.106 32.7375L149.779 32.65C149.154 32.4825 148.511 32.8534 148.344 33.4784C148.083 34.4538 148.665 35.4574 149.641 35.7188Z" fill="#FF4A1C"/>
        <path id="Rectangle 240_2" d="M162.435 41.4644C163.056 41.6309 163.698 41.2627 163.864 40.641L163.953 40.3082C164.166 39.5132 163.695 38.6962 162.9 38.4832L162.573 38.3956C161.948 38.2281 161.305 38.599 161.138 39.224C160.877 40.1994 161.459 41.203 162.435 41.4644Z" fill="#FF4A1C"/>
        <path id="Rectangle 480_2" d="M25.3877 278.835C26.0093 279.002 26.6507 278.634 26.8172 278.012L26.9064 277.679C27.1194 276.884 26.6477 276.067 25.8527 275.854L25.5259 275.767C24.901 275.599 24.2585 275.97 24.0911 276.595C23.8297 277.571 24.4123 278.574 25.3877 278.835Z" fill="#FF4A1C"/>
        <path id="Rectangle 241_2" d="M137.691 53.9922C138.313 54.1588 138.954 53.7905 139.121 53.1688L139.21 52.836C139.423 52.0411 138.951 51.224 138.156 51.011L137.83 50.9234C137.205 50.756 136.562 51.1268 136.395 51.7518C136.133 52.7272 136.716 53.7308 137.691 53.9922Z" fill="#FF4A1C"/>
        <path id="Rectangle 242_2" d="M153.155 79.334C153.777 79.5006 154.418 79.1323 154.585 78.5106L154.674 78.1778C154.887 77.3829 154.415 76.5658 153.62 76.3528L153.294 76.2652C152.669 76.0978 152.026 76.4686 151.859 77.0936C151.597 78.069 152.18 79.0726 153.155 79.334Z" fill="#FF4A1C"/>
        <path id="Rectangle 243_2" d="M190.415 66.9219C191.037 67.0885 191.678 66.7202 191.845 66.0985L191.934 65.7657C192.147 64.9708 191.675 64.1537 190.88 63.9407L190.553 63.8531C189.928 63.6856 189.286 64.0565 189.118 64.6815C188.857 65.6569 189.44 66.6605 190.415 66.9219Z" fill="#FF4A1C"/>
        <path id="Rectangle 483_2" d="M53.3681 304.293C53.9898 304.46 54.6311 304.091 54.7977 303.47L54.8869 303.137C55.0999 302.342 54.6281 301.525 53.8332 301.312L53.5064 301.224C52.8814 301.057 52.239 301.428 52.0716 302.053C51.8102 303.028 52.3927 304.032 53.3681 304.293Z" fill="#FF4A1C"/>
        <path id="Rectangle 484_2" d="M194.094 195.667C194.715 195.834 195.357 195.465 195.523 194.844L195.612 194.511C195.825 193.716 195.354 192.899 194.559 192.686L194.232 192.598C193.607 192.431 192.965 192.802 192.797 193.427C192.536 194.402 193.118 195.406 194.094 195.667Z" fill="#FF4A1C"/>
        <path id="Rectangle 485_2" d="M206.886 201.412C207.507 201.578 208.149 201.21 208.315 200.588L208.404 200.255C208.617 199.461 208.146 198.643 207.351 198.43L207.024 198.343C206.399 198.175 205.757 198.546 205.589 199.171C205.328 200.147 205.91 201.15 206.886 201.412Z" fill="#FF4A1C"/>
        <path id="Rectangle 486_2" d="M182.143 213.938C182.764 214.104 183.406 213.736 183.572 213.114L183.661 212.781C183.874 211.986 183.403 211.169 182.608 210.956L182.281 210.869C181.656 210.701 181.013 211.072 180.846 211.697C180.585 212.673 181.167 213.676 182.143 213.938Z" fill="#FF4A1C"/>
        <path id="Rectangle 487_2" d="M197.606 239.281C198.228 239.448 198.869 239.08 199.036 238.458L199.125 238.125C199.338 237.33 198.866 236.513 198.071 236.3L197.745 236.212C197.12 236.045 196.477 236.416 196.31 237.041C196.048 238.016 196.631 239.02 197.606 239.281Z" fill="#FF4A1C"/>
        <path id="Rectangle 488_2" d="M234.866 226.869C235.488 227.036 236.129 226.667 236.296 226.046L236.385 225.713C236.598 224.918 236.126 224.101 235.331 223.888L235.004 223.8C234.379 223.633 233.737 224.004 233.57 224.629C233.308 225.604 233.891 226.608 234.866 226.869Z" fill="#FF4A1C"/>
        <path id="Rectangle 249_2" d="M351.421 30.0386C350.799 29.872 350.158 30.2403 349.991 30.8619L349.902 31.1948C349.689 31.9897 350.161 32.8068 350.956 33.0198L351.283 33.1073C351.908 33.2748 352.55 32.9039 352.718 32.2789C352.979 31.3035 352.396 30.2999 351.421 30.0386Z" fill="#FF4A1C"/>
        <path id="Rectangle 489_2" d="M214.374 267.412C213.752 267.245 213.111 267.613 212.944 268.235L212.855 268.568C212.642 269.363 213.114 270.18 213.909 270.393L214.236 270.48C214.861 270.648 215.503 270.277 215.671 269.652C215.932 268.677 215.349 267.673 214.374 267.412Z" fill="#FF4A1C"/>
        <path id="Rectangle 250_2" d="M338.629 24.2939C338.007 24.1274 337.366 24.4956 337.199 25.1173L337.11 25.4501C336.897 26.2451 337.369 27.0621 338.164 27.2751L338.491 27.3627C339.116 27.5302 339.758 27.1593 339.926 26.5343C340.187 25.5589 339.604 24.5553 338.629 24.2939Z" fill="#FF4A1C"/>
        <path id="Rectangle 490_2" d="M201.582 261.667C200.96 261.501 200.319 261.869 200.153 262.491L200.063 262.824C199.85 263.619 200.322 264.436 201.117 264.649L201.444 264.736C202.069 264.904 202.711 264.533 202.879 263.908C203.14 262.932 202.557 261.929 201.582 261.667Z" fill="#FF4A1C"/>
        <path id="Rectangle 491_2" d="M226.325 249.139C225.704 248.973 225.062 249.341 224.896 249.963L224.806 250.295C224.593 251.09 225.065 251.907 225.86 252.12L226.187 252.208C226.812 252.375 227.454 252.004 227.622 251.38C227.883 250.404 227.301 249.401 226.325 249.139Z" fill="#FF4A1C"/>
        <path id="Rectangle 492_2" d="M210.861 223.795C210.24 223.629 209.598 223.997 209.432 224.619L209.343 224.952C209.13 225.747 209.601 226.564 210.396 226.777L210.723 226.864C211.348 227.032 211.99 226.661 212.158 226.036C212.419 225.06 211.837 224.057 210.861 223.795Z" fill="#FF4A1C"/>
        <path id="Rectangle 493_2" d="M173.6 236.21C172.978 236.043 172.337 236.412 172.17 237.033L172.081 237.366C171.868 238.161 172.34 238.978 173.135 239.191L173.461 239.279C174.086 239.446 174.729 239.075 174.896 238.45C175.158 237.475 174.575 236.471 173.6 236.21Z" fill="#FF4A1C"/>
        <path id="Rectangle 254_2" d="M304.884 66.0986C304.262 65.932 303.621 66.3003 303.454 66.922L303.365 67.2548C303.152 68.0498 303.624 68.8668 304.419 69.0798L304.746 69.1674C305.371 69.3349 306.013 68.964 306.18 68.339C306.442 67.3636 305.859 66.36 304.884 66.0986Z" fill="#FF4A1C"/>
        <path id="Rectangle 494_2" d="M167.837 303.47C167.215 303.304 166.574 303.672 166.407 304.294L166.318 304.626C166.105 305.421 166.577 306.238 167.372 306.451L167.699 306.539C168.324 306.706 168.966 306.336 169.134 305.711C169.395 304.735 168.812 303.732 167.837 303.47Z" fill="#FF4A1C"/>
        <path id="Rectangle 255_2" d="M292.095 60.354C291.473 60.1874 290.832 60.5557 290.665 61.1773L290.576 61.5102C290.363 62.3051 290.835 63.1222 291.63 63.3352L291.956 63.4228C292.581 63.5902 293.224 63.2193 293.391 62.5944C293.653 61.6189 293.07 60.6154 292.095 60.354Z" fill="#FF4A1C"/>
        <path id="Rectangle 495_2" d="M155.048 297.725C154.426 297.559 153.785 297.927 153.618 298.548L153.529 298.881C153.316 299.676 153.788 300.493 154.583 300.706L154.91 300.794C155.535 300.961 156.177 300.59 156.344 299.965C156.606 298.99 156.023 297.986 155.048 297.725Z" fill="#FF4A1C"/>
        <path id="Rectangle 256_2" d="M316.837 47.8262C316.215 47.6596 315.574 48.0279 315.407 48.6495L315.318 48.9824C315.105 49.7773 315.577 50.5944 316.372 50.8074L316.699 50.8949C317.324 51.0624 317.966 50.6915 318.134 50.0665C318.395 49.0911 317.812 48.0875 316.837 47.8262Z" fill="#FF4A1C"/>
        <path id="Rectangle 496_2" d="M179.79 285.199C179.168 285.033 178.527 285.401 178.361 286.023L178.271 286.355C178.058 287.15 178.53 287.967 179.325 288.18L179.652 288.268C180.277 288.435 180.919 288.065 181.087 287.44C181.348 286.464 180.765 285.461 179.79 285.199Z" fill="#FF4A1C"/>
        <path id="Rectangle 497_2" d="M164.324 259.856C163.703 259.689 163.061 260.058 162.895 260.679L162.806 261.012C162.593 261.807 163.064 262.624 163.859 262.837L164.186 262.925C164.811 263.092 165.453 262.721 165.621 262.096C165.882 261.121 165.3 260.117 164.324 259.856Z" fill="#FF4A1C"/>
        <path id="Rectangle 258_2" d="M264.111 34.897C263.49 34.7304 262.848 35.0987 262.682 35.7203L262.593 36.0532C262.38 36.8481 262.851 37.6652 263.646 37.8782L263.973 37.9657C264.598 38.1332 265.24 37.7623 265.408 37.1373C265.669 36.1619 265.087 35.1583 264.111 34.897Z" fill="#FF4A1C"/>
        <path id="Rectangle 498_2" d="M127.064 272.268C126.443 272.101 125.801 272.47 125.635 273.091L125.546 273.424C125.333 274.219 125.804 275.036 126.599 275.249L126.926 275.337C127.551 275.504 128.194 275.133 128.361 274.508C128.622 273.533 128.04 272.529 127.064 272.268Z" fill="#FF4A1C"/>
        <path id="Rectangle 499_2" d="M147.554 231.725C148.175 231.891 148.817 231.523 148.983 230.901L149.072 230.568C149.285 229.773 148.814 228.956 148.019 228.743L147.692 228.656C147.067 228.488 146.425 228.859 146.257 229.484C145.996 230.46 146.578 231.463 147.554 231.725Z" fill="#FF4A1C"/>
        <path id="Rectangle 500_2" d="M160.349 237.47C160.97 237.637 161.612 237.269 161.778 236.647L161.867 236.314C162.08 235.519 161.609 234.702 160.814 234.489L160.487 234.401C159.862 234.234 159.219 234.605 159.052 235.23C158.791 236.205 159.373 237.209 160.349 237.47Z" fill="#FF4A1C"/>
        <path id="Rectangle 501_2" d="M135.604 249.996C136.226 250.163 136.867 249.794 137.034 249.173L137.123 248.84C137.336 248.045 136.864 247.228 136.07 247.015L135.743 246.927C135.118 246.76 134.475 247.131 134.308 247.756C134.047 248.731 134.629 249.735 135.604 249.996Z" fill="#FF4A1C"/>
        <path id="Rectangle 262_2" d="M288.116 37.9688C288.738 38.1353 289.379 37.7671 289.546 37.1454L289.635 36.8126C289.848 36.0176 289.376 35.2005 288.581 34.9875L288.254 34.9C287.629 34.7325 286.987 35.1034 286.82 35.7284C286.558 36.7038 287.141 37.7074 288.116 37.9688Z" fill="#FF4A1C"/>
        <path id="Rectangle 502_2" d="M151.069 275.34C151.691 275.506 152.332 275.138 152.499 274.516L152.588 274.184C152.801 273.389 152.329 272.572 151.534 272.359L151.208 272.271C150.583 272.104 149.94 272.475 149.773 273.099C149.511 274.075 150.094 275.078 151.069 275.34Z" fill="#FF4A1C"/>
        <path id="Rectangle 503_2" d="M188.331 262.925C188.953 263.091 189.594 262.723 189.761 262.101L189.85 261.769C190.063 260.974 189.591 260.157 188.796 259.944L188.469 259.856C187.844 259.689 187.202 260.059 187.034 260.684C186.773 261.66 187.356 262.663 188.331 262.925Z" fill="#FF4A1C"/>
        <path id="Rectangle 504_2" d="M139.345 135.057C139.966 135.224 140.608 134.855 140.774 134.234L140.863 133.901C141.076 133.106 140.605 132.289 139.81 132.076L139.483 131.988C138.858 131.821 138.216 132.192 138.048 132.817C137.787 133.792 138.369 134.796 139.345 135.057Z" fill="#FF4A1C"/>
        <path id="Rectangle 505_2" d="M152.137 140.801C152.758 140.968 153.4 140.6 153.566 139.978L153.655 139.645C153.868 138.85 153.397 138.033 152.602 137.82L152.275 137.733C151.65 137.565 151.008 137.936 150.84 138.561C150.579 139.536 151.161 140.54 152.137 140.801Z" fill="#FF4A1C"/>
        <path id="Rectangle 506_2" d="M127.396 153.329C128.018 153.496 128.659 153.127 128.826 152.506L128.915 152.173C129.128 151.378 128.656 150.561 127.862 150.348L127.535 150.26C126.91 150.093 126.267 150.464 126.1 151.089C125.839 152.064 126.421 153.068 127.396 153.329Z" fill="#FF4A1C"/>
        <path id="Rectangle 507_2" d="M142.86 178.672C143.482 178.839 144.123 178.471 144.29 177.849L144.379 177.516C144.592 176.721 144.12 175.904 143.325 175.691L142.999 175.604C142.374 175.436 141.731 175.807 141.564 176.432C141.302 177.407 141.885 178.411 142.86 178.672Z" fill="#FF4A1C"/>
        <path id="Rectangle 508_2" d="M180.122 166.26C180.744 166.426 181.385 166.058 181.552 165.436L181.641 165.104C181.854 164.309 181.382 163.492 180.587 163.279L180.26 163.191C179.635 163.024 178.993 163.394 178.825 164.019C178.564 164.995 179.147 165.998 180.122 166.26Z" fill="#FF4A1C"/>
        <path id="Rectangle 509_2" d="M159.627 206.801C159.005 206.634 158.364 207.002 158.197 207.624L158.108 207.957C157.895 208.752 158.367 209.569 159.162 209.782L159.489 209.87C160.114 210.037 160.756 209.666 160.924 209.041C161.185 208.066 160.602 207.062 159.627 206.801Z" fill="#FF4A1C"/>
        <path id="Rectangle 510_2" d="M146.836 201.057C146.214 200.89 145.573 201.258 145.406 201.88L145.317 202.213C145.104 203.008 145.576 203.825 146.371 204.038L146.698 204.125C147.323 204.293 147.965 203.922 148.133 203.297C148.394 202.322 147.811 201.318 146.836 201.057Z" fill="#FF4A1C"/>
        <path id="Rectangle 511_2" d="M171.577 188.529C170.956 188.362 170.314 188.731 170.148 189.352L170.058 189.685C169.845 190.48 170.317 191.297 171.112 191.51L171.439 191.598C172.064 191.765 172.706 191.394 172.874 190.769C173.135 189.794 172.553 188.79 171.577 188.529Z" fill="#FF4A1C"/>
        <path id="Rectangle 512_2" d="M156.115 163.188C155.494 163.022 154.852 163.39 154.686 164.012L154.597 164.345C154.384 165.14 154.855 165.957 155.65 166.17L155.977 166.257C156.602 166.425 157.244 166.054 157.412 165.429C157.673 164.453 157.091 163.45 156.115 163.188Z" fill="#FF4A1C"/>
        <path id="Rectangle 513_2" d="M118.854 175.599C118.232 175.433 117.591 175.801 117.424 176.422L117.335 176.755C117.122 177.55 117.594 178.367 118.388 178.58L118.715 178.668C119.34 178.835 119.983 178.464 120.15 177.839C120.411 176.864 119.829 175.86 118.854 175.599Z" fill="#FF4A1C"/>
        <path id="Rectangle 514_2" d="M113.094 242.86C112.472 242.694 111.831 243.062 111.664 243.684L111.575 244.017C111.362 244.811 111.834 245.629 112.629 245.842L112.956 245.929C113.58 246.097 114.223 245.726 114.39 245.101C114.652 244.125 114.069 243.122 113.094 242.86Z" fill="#FF4A1C"/>
        <path id="Rectangle 515_2" d="M100.299 237.115C99.6772 236.949 99.0359 237.317 98.8693 237.939L98.7801 238.271C98.5671 239.066 99.0389 239.883 99.8338 240.096L100.161 240.184C100.786 240.351 101.428 239.981 101.595 239.356C101.857 238.38 101.274 237.377 100.299 237.115Z" fill="#FF4A1C"/>
        <path id="Rectangle 516_2" d="M125.042 224.589C124.42 224.422 123.779 224.791 123.612 225.412L123.523 225.745C123.31 226.54 123.782 227.357 124.577 227.57L124.904 227.658C125.529 227.825 126.171 227.454 126.339 226.829C126.6 225.854 126.017 224.85 125.042 224.589Z" fill="#FF4A1C"/>
        <path id="Rectangle 517_2" d="M109.578 199.247C108.956 199.08 108.315 199.449 108.149 200.07L108.059 200.403C107.846 201.198 108.318 202.015 109.113 202.228L109.44 202.316C110.065 202.483 110.707 202.112 110.875 201.487C111.136 200.512 110.554 199.508 109.578 199.247Z" fill="#FF4A1C"/>
        <path id="Rectangle 518_2" d="M72.3154 211.658C71.6938 211.492 71.0525 211.86 70.8859 212.482L70.7967 212.814C70.5837 213.609 71.0555 214.426 71.8504 214.639L72.1772 214.727C72.8022 214.894 73.4446 214.524 73.612 213.899C73.8734 212.923 73.2909 211.92 72.3154 211.658Z" fill="#FF4A1C"/>
        <path id="Rectangle 519_2" d="M92.8105 171.117C93.4322 171.284 94.0735 170.915 94.2401 170.294L94.3293 169.961C94.5423 169.166 94.0705 168.349 93.2756 168.136L92.9488 168.048C92.3238 167.881 91.6814 168.252 91.5139 168.877C91.2526 169.852 91.8351 170.856 92.8105 171.117Z" fill="#FF4A1C"/>
        <path id="Rectangle 520_2" d="M105.603 176.861C106.224 177.028 106.866 176.66 107.032 176.038L107.121 175.705C107.334 174.91 106.863 174.093 106.068 173.88L105.741 173.793C105.116 173.625 104.473 173.996 104.306 174.621C104.045 175.596 104.627 176.6 105.603 176.861Z" fill="#FF4A1C"/>
        <path id="Rectangle 521_2" d="M80.8584 189.386C81.48 189.552 82.1214 189.184 82.2879 188.562L82.3771 188.23C82.5901 187.435 82.1184 186.618 81.3234 186.405L80.9966 186.317C80.3717 186.15 79.7293 186.52 79.5618 187.145C79.3004 188.121 79.883 189.124 80.8584 189.386Z" fill="#FF4A1C"/>
        <path id="Rectangle 522_2" d="M96.3232 214.731C96.9449 214.898 97.5862 214.529 97.7528 213.908L97.842 213.575C98.055 212.78 97.5832 211.963 96.7883 211.75L96.4615 211.662C95.8365 211.495 95.1941 211.866 95.0266 212.491C94.7653 213.466 95.3478 214.47 96.3232 214.731Z" fill="#FF4A1C"/>
        <path id="Rectangle 523_2" d="M133.585 202.318C134.207 202.485 134.848 202.117 135.014 201.495L135.104 201.162C135.317 200.367 134.845 199.55 134.05 199.337L133.723 199.25C133.098 199.082 132.456 199.453 132.288 200.078C132.027 201.053 132.61 202.057 133.585 202.318Z" fill="#FF4A1C"/>
        <path id="Rectangle 528_2" d="M44.418 202.274C45.0396 202.441 45.6809 202.072 45.8475 201.451L45.9367 201.118C46.1497 200.323 45.6779 199.506 44.883 199.293L44.5562 199.205C43.9312 199.038 43.2888 199.409 43.1214 200.034C42.86 201.009 43.4425 202.013 44.418 202.274Z" fill="#FF4A1C"/>
        <path id="Rectangle 529_2" d="M23.9258 242.816C23.3041 242.65 22.6628 243.018 22.4963 243.64L22.4071 243.973C22.1941 244.768 22.6658 245.585 23.4607 245.798L23.7875 245.885C24.4125 246.053 25.0549 245.682 25.2224 245.057C25.4838 244.081 24.9012 243.078 23.9258 242.816Z" fill="#FF4A1C"/>
        <path id="Rectangle 531_2" d="M35.875 224.544C35.2534 224.378 34.612 224.746 34.4455 225.368L34.3563 225.701C34.1433 226.496 34.615 227.313 35.4099 227.526L35.7368 227.613C36.3617 227.781 37.0041 227.41 37.1716 226.785C37.433 225.809 36.8504 224.806 35.875 224.544Z" fill="#FF4A1C"/>
        <path id="Rectangle 532_2" d="M20.4131 199.202C19.7915 199.036 19.1501 199.404 18.9836 200.025L18.8944 200.358C18.6814 201.153 19.1531 201.97 19.948 202.183L20.2748 202.271C20.8998 202.438 21.5422 202.067 21.7097 201.443C21.9711 200.467 21.3885 199.463 20.4131 199.202Z" fill="#FF4A1C"/>
        <path id="Rectangle 982_2" d="M456.642 361.528C456.02 361.362 455.379 361.73 455.212 362.352L455.123 362.685C454.91 363.479 455.382 364.297 456.177 364.51L456.503 364.597C457.128 364.765 457.771 364.394 457.938 363.769C458.2 362.793 457.617 361.79 456.642 361.528Z" fill="#FF4A1C"/>
        <path id="Rectangle 294_2" d="M114.435 41.5019C113.813 41.3354 113.172 41.7037 113.005 42.3253L112.916 42.6581C112.703 43.4531 113.175 44.2702 113.97 44.4832L114.296 44.5707C114.921 44.7382 115.564 44.3673 115.731 43.7423C115.993 42.7669 115.41 41.7633 114.435 41.5019Z" fill="#FF4A1C"/>
        <path id="Rectangle 984_2" d="M450.879 428.79C450.257 428.623 449.616 428.992 449.449 429.613L449.36 429.946C449.147 430.741 449.619 431.558 450.414 431.771L450.741 431.859C451.366 432.026 452.008 431.655 452.176 431.03C452.437 430.055 451.854 429.051 450.879 428.79Z" fill="#FF4A1C"/>
        <path id="Rectangle 295_2" d="M101.643 35.7573C101.021 35.5907 100.38 35.959 100.213 36.5807L100.124 36.9135C99.9109 37.7084 100.383 38.5255 101.178 38.7385L101.504 38.8261C102.129 38.9936 102.772 38.6227 102.939 37.9977C103.201 37.0223 102.618 36.0187 101.643 35.7573Z" fill="#FF4A1C"/>
        <path id="Rectangle 986_2" d="M438.087 423.046C437.465 422.879 436.824 423.248 436.657 423.869L436.568 424.202C436.355 424.997 436.827 425.814 437.622 426.027L437.949 426.115C438.574 426.282 439.216 425.911 439.384 425.286C439.645 424.311 439.062 423.307 438.087 423.046Z" fill="#FF4A1C"/>
        <path id="Rectangle 296_2" d="M126.387 23.2314C125.765 23.0649 125.124 23.4331 124.957 24.0548L124.868 24.3876C124.655 25.1826 125.127 25.9996 125.922 26.2126L126.248 26.3002C126.873 26.4677 127.516 26.0968 127.683 25.4718C127.945 24.4964 127.362 23.4928 126.387 23.2314Z" fill="#FF4A1C"/>
        <path id="Rectangle 990_2" d="M447.366 385.174C446.745 385.008 446.103 385.376 445.937 385.998L445.847 386.331C445.634 387.125 446.106 387.943 446.901 388.156L447.228 388.243C447.853 388.411 448.495 388.04 448.663 387.415C448.924 386.439 448.342 385.436 447.366 385.174Z" fill="#FF4A1C"/>
        <path id="Rectangle 992_2" d="M410.105 397.587C409.483 397.42 408.842 397.789 408.675 398.41L408.586 398.743C408.373 399.538 408.845 400.355 409.639 400.568L409.966 400.656C410.591 400.823 411.234 400.452 411.401 399.827C411.662 398.852 411.08 397.848 410.105 397.587Z" fill="#FF4A1C"/>
        <path id="Rectangle 994_2" d="M430.597 357.042C431.218 357.209 431.86 356.841 432.026 356.219L432.115 355.886C432.328 355.091 431.857 354.274 431.062 354.061L430.735 353.974C430.11 353.806 429.468 354.177 429.3 354.802C429.039 355.778 429.621 356.781 430.597 357.042Z" fill="#FF4A1C"/>
        <path id="Rectangle 996_2" d="M443.389 362.789C444.01 362.955 444.652 362.587 444.818 361.965L444.907 361.632C445.12 360.837 444.649 360.02 443.854 359.807L443.527 359.72C442.902 359.552 442.26 359.923 442.092 360.548C441.831 361.524 442.413 362.527 443.389 362.789Z" fill="#FF4A1C"/>
        <path id="Rectangle 998_2" d="M418.647 375.316C419.269 375.483 419.91 375.115 420.077 374.493L420.166 374.16C420.379 373.365 419.907 372.548 419.113 372.335L418.786 372.248C418.161 372.08 417.518 372.451 417.351 373.076C417.089 374.051 417.672 375.055 418.647 375.316Z" fill="#FF4A1C"/>
        <path id="Rectangle 1000_2" d="M434.109 400.659C434.731 400.825 435.372 400.457 435.539 399.835L435.628 399.502C435.841 398.708 435.369 397.89 434.574 397.677L434.248 397.59C433.623 397.422 432.98 397.793 432.813 398.418C432.551 399.394 433.134 400.397 434.109 400.659Z" fill="#FF4A1C"/>
        <path id="Rectangle 1004_2" d="M422.387 260.377C423.008 260.544 423.65 260.176 423.816 259.554L423.905 259.221C424.118 258.426 423.647 257.609 422.852 257.396L422.525 257.309C421.9 257.141 421.258 257.512 421.09 258.137C420.829 259.113 421.411 260.116 422.387 260.377Z" fill="#FF4A1C"/>
        <path id="Rectangle 1006_2" d="M435.178 266.12C435.799 266.286 436.441 265.918 436.607 265.296L436.696 264.963C436.909 264.169 436.438 263.351 435.643 263.138L435.316 263.051C434.691 262.883 434.049 263.254 433.881 263.879C433.62 264.855 434.202 265.858 435.178 266.12Z" fill="#FF4A1C"/>
        <path id="Rectangle 1008_2" d="M410.436 278.648C411.057 278.815 411.699 278.446 411.865 277.825L411.954 277.492C412.167 276.697 411.696 275.88 410.901 275.667L410.574 275.579C409.949 275.412 409.306 275.783 409.139 276.408C408.878 277.383 409.46 278.387 410.436 278.648Z" fill="#FF4A1C"/>
        <path id="Rectangle 1010_2" d="M425.899 303.992C426.521 304.158 427.162 303.79 427.329 303.168L427.418 302.836C427.631 302.041 427.159 301.223 426.364 301.01L426.038 300.923C425.413 300.755 424.77 301.126 424.603 301.751C424.341 302.727 424.924 303.73 425.899 303.992Z" fill="#FF4A1C"/>
        <path id="Rectangle 1014_2" d="M442.67 332.121C442.048 331.955 441.407 332.323 441.24 332.944L441.151 333.277C440.938 334.072 441.41 334.889 442.205 335.102L442.532 335.19C443.157 335.357 443.799 334.986 443.967 334.361C444.228 333.386 443.645 332.382 442.67 332.121Z" fill="#FF4A1C"/>
        <path id="Rectangle 1016_2" d="M429.876 326.375C429.254 326.208 428.613 326.577 428.446 327.198L428.357 327.531C428.144 328.326 428.616 329.143 429.411 329.356L429.738 329.444C430.363 329.611 431.005 329.24 431.173 328.615C431.434 327.64 430.851 326.636 429.876 326.375Z" fill="#FF4A1C"/>
        <path id="Rectangle 1018_2" d="M454.619 313.849C453.998 313.683 453.356 314.051 453.19 314.672L453.1 315.005C452.887 315.8 453.359 316.617 454.154 316.83L454.481 316.918C455.106 317.085 455.748 316.714 455.916 316.089C456.177 315.114 455.595 314.11 454.619 313.849Z" fill="#FF4A1C"/>
        <path id="Rectangle 1020_2" d="M439.157 288.507C438.536 288.34 437.894 288.709 437.728 289.33L437.639 289.663C437.426 290.458 437.897 291.275 438.692 291.488L439.019 291.576C439.644 291.743 440.286 291.372 440.454 290.747C440.715 289.772 440.133 288.768 439.157 288.507Z" fill="#FF4A1C"/>
        <path id="Rectangle 1022_2" d="M401.893 300.918C401.271 300.752 400.63 301.12 400.463 301.742L400.374 302.075C400.161 302.87 400.633 303.687 401.428 303.9L401.754 303.987C402.379 304.155 403.022 303.784 403.189 303.159C403.451 302.183 402.868 301.18 401.893 300.918Z" fill="#FF4A1C"/>
        <path id="Rectangle 1024_2" d="M396.13 368.178C395.508 368.012 394.867 368.38 394.7 369.002L394.611 369.334C394.398 370.129 394.87 370.946 395.665 371.159L395.992 371.247C396.617 371.414 397.259 371.044 397.426 370.419C397.688 369.443 397.105 368.44 396.13 368.178Z" fill="#FF4A1C"/>
        <path id="Rectangle 1026_2" d="M383.341 362.435C382.719 362.268 382.078 362.637 381.911 363.258L381.822 363.591C381.609 364.386 382.081 365.203 382.876 365.416L383.203 365.504C383.828 365.671 384.47 365.3 384.637 364.675C384.899 363.7 384.316 362.696 383.341 362.435Z" fill="#FF4A1C"/>
        <path id="Rectangle 1028_2" d="M408.084 349.909C407.462 349.743 406.821 350.111 406.654 350.733L406.565 351.065C406.352 351.86 406.824 352.677 407.619 352.89L407.946 352.978C408.571 353.145 409.213 352.775 409.381 352.15C409.642 351.174 409.059 350.171 408.084 349.909Z" fill="#FF4A1C"/>
        <path id="Rectangle 1030_2" d="M392.618 324.564C391.997 324.397 391.355 324.766 391.189 325.387L391.099 325.72C390.886 326.515 391.358 327.332 392.153 327.545L392.48 327.633C393.105 327.8 393.747 327.429 393.915 326.804C394.176 325.829 393.594 324.825 392.618 324.564Z" fill="#FF4A1C"/>
        <path id="Rectangle 1032_2" d="M355.357 336.979C354.736 336.812 354.094 337.18 353.928 337.802L353.839 338.135C353.626 338.93 354.097 339.747 354.892 339.96L355.219 340.047C355.844 340.215 356.487 339.844 356.654 339.219C356.915 338.243 356.333 337.24 355.357 336.979Z" fill="#FF4A1C"/>
        <path id="Rectangle 1034_2" d="M375.851 296.436C376.472 296.602 377.114 296.234 377.28 295.612L377.369 295.279C377.582 294.484 377.111 293.667 376.316 293.454L375.989 293.367C375.364 293.199 374.721 293.57 374.554 294.195C374.293 295.171 374.875 296.174 375.851 296.436Z" fill="#FF4A1C"/>
        <path id="Rectangle 1036_2" d="M388.642 302.178C389.263 302.345 389.905 301.977 390.071 301.355L390.16 301.022C390.373 300.227 389.902 299.41 389.107 299.197L388.78 299.109C388.155 298.942 387.512 299.313 387.345 299.938C387.084 300.913 387.666 301.917 388.642 302.178Z" fill="#FF4A1C"/>
        <path id="Rectangle 1038_2" d="M363.899 314.706C364.521 314.873 365.162 314.504 365.329 313.883L365.418 313.55C365.631 312.755 365.159 311.938 364.364 311.725L364.038 311.637C363.413 311.47 362.77 311.841 362.603 312.466C362.341 313.441 362.924 314.445 363.899 314.706Z" fill="#FF4A1C"/>
        <path id="Rectangle 1040_2" d="M379.363 340.048C379.985 340.214 380.626 339.846 380.793 339.225L380.882 338.892C381.095 338.097 380.623 337.28 379.828 337.067L379.502 336.979C378.877 336.812 378.234 337.183 378.067 337.807C377.805 338.783 378.388 339.787 379.363 340.048Z" fill="#FF4A1C"/>
        <path id="Rectangle 1042_2" d="M416.625 327.637C417.247 327.804 417.888 327.436 418.055 326.814L418.144 326.481C418.357 325.686 417.885 324.869 417.09 324.656L416.763 324.568C416.138 324.401 415.496 324.772 415.328 325.397C415.067 326.372 415.65 327.376 416.625 327.637Z" fill="#FF4A1C"/>
        </g>
        </g>
        </g>
        <path id="Rectangle 1061" d="M149.095 472.938C47.4221 371.265 47.4221 206.421 149.095 104.748L160.731 93.112C217.678 36.1646 310.008 36.1646 366.955 93.1119L442.854 169.011C509.036 235.192 509.036 342.493 442.854 408.675L378.591 472.938C315.218 536.311 212.469 536.311 149.095 472.938V472.938Z" fill="url(#paint2_linear_1209_8804)"/>
        <g id="Mask group_3">
        <mask id="mask3_1209_8804" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="88" y="72" width="396" height="443">
        <path id="Rectangle 1062" d="M159.828 470.14C64.1188 374.431 64.1187 219.256 159.828 123.547L170.781 112.593C224.388 58.9863 311.302 58.9863 364.909 112.593L436.356 184.04C498.656 246.34 498.656 347.347 436.357 409.646L375.863 470.14C316.206 529.796 219.484 529.796 159.828 470.14V470.14Z" fill="url(#paint3_linear_1209_8804)"/>
        </mask>
        <g mask="url(#mask3_1209_8804)">
        <rect id="Rectangle 60" x="12.002" y="56.0005" width="537" height="484" rx="16" fill="url(#pattern0123123)"/>
        </g>
        </g>
        </g>
        </g>
        </g>
        <defs>
        <pattern id="pattern0123123" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_1209_8804" transform="matrix(0.000570445 0 0 0.000632911 -0.175978 0)"/>
        </pattern>
        <linearGradient id="paint0_linear_1209_8804" x1="498.449" y1="388.224" x2="96.2247" y2="280.449" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9055A2"/>
        <stop offset="1" stop-color="#FF4A1C"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1209_8804" x1="440.449" y1="278.224" x2="38.2247" y2="170.449" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9055A2"/>
        <stop offset="1" stop-color="#FF4A1C"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1209_8804" x1="196" y1="61" x2="377" y2="470" gradientUnits="userSpaceOnUse">
        <stop stop-color="#052247"/>
        <stop offset="0.747317" stop-color="#9055A2"/>
        <stop offset="1" stop-color="#FF4A1C"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1209_8804" x1="173.528" y1="460.205" x2="372.702" y2="115.226" gradientUnits="userSpaceOnUse">
        <stop stop-color="#052247"/>
        <stop offset="1" stop-color="#9055A2"/>
        </linearGradient>
        <image id="image0_1209_8804" data-name="image.png" width="2370" height="1580" xlinkHref={imageSrc}/>
        </defs>
        </svg>
        
    );
}

export default CultureSec5LeftBg;
