import React from "react";
import styles from "./PrivacyPolicy.module.scss";


function PrivacyPolicy() {
  return (
    <div className={styles.parentContainer}>
    <div className={styles.secondContainer}>
        <div className={styles.privacyContainer}>
          <h1>Credvest Privacy Policy</h1>
          <p>
            Credvest is committed to protecting your privacy. This Privacy policy
            explains how your personal information is collected and used.
          </p>
          <p>
            By accessing or using our services, you signify that you have read,
            understood, and agree to our collection, storage, use and disclosure of
            your personal information as described in this policy and our terms of
            service.
          </p>
          <h2>What information do we collect?</h2>
          <p>
            We collect information from you when you fill the form on our website.
          </p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Phone no</li>
          </ul>
          <h2>How Do We Use The Information We Collect?</h2>
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ul>
            <li>
              To personalize your experience (your information helps us to better
              respond to your individual needs)
            </li>
            <li>
              To improve our website (we continually strive to improve our website
              offerings based on the information and feedback we receive from you)
            </li>
            <li>
              To improve customer service (your information helps us to more
              effectively respond to your customer service requests and support
              needs)
            </li>
            <li>To send periodic emails</li>
          </ul>
          <h2>How Do We Use Your Email Address?</h2>
          <p>
            By submitting your email address on this website, you agree to receive
            emails from us. You can cancel your participation in any of these email
            lists at any time by clicking on the opt-out link or other unsubscribe
            option that is included in the respective email. We only send emails to
            people who have authorized us to contact them, either directly, or
            through a third party. We do not send unsolicited commercial emails,
            because we hate spam as much as you do. By submitting your email
            address, you also agree to allow us to use your email address for
            customer audience targeting on sites like Facebook, where we display
            custom advertising to specific people who have opted-in to receive
            communications from us.
          </p>
          <h2>How Long Do We Keep Your Information?</h2>
          <p>
            We keep your information only so long as we need it to provide our
            services to you and fulfill the purposes described in this policy. This
            is also the case for anyone that we share your information with and who
            carries out services on our behalf. When we no longer need to use your
            information and there is no need for us to keep it to comply with our
            legal or regulatory obligations, we’ll either remove it from our systems
            or depersonalize it so that we can’t identify you.
          </p>
          <h2>How Do We Protect Your Information?</h2>
          <p>
            We implement a variety of security measures to maintain the safety of
            your personal information.
          </p>
          <h2>Definitions and key terms:</h2>
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by your
              web browser. It is used to identify your browser, provide analytics,
              and remember information about you such as your language preference or
              login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
              refers to Credvest, Indiranagar, Bangalore that is responsible for
              your information under this Privacy Policy.
            </li>
            <li>
              Country: where Credvest or the owners/founders of Credvest are based,
              in this case is India.
            </li>
            <li>
              Customer: refers to the company, organization or person that signs up
              to use the Credvest Service to manage the relationships with your
              consumers or service users.
            </li>
            <li>
              Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit Credvest and
              use the services.
            </li>
            <li>
              IP address: Every device connected to the Internet is assigned a
              number known as an Internet protocol (IP) address. These numbers are
              usually assigned in geographic blocks. An IP address can often be used
              to identify the location from which a device is connected to the
              Internet.
            </li>
            <li>
              Personnel: refers to those individuals who are employed by Credvest or
              are under contract to perform a service on behalf of one of the
              parties.
            </li>
            <li>
              Personal Data: any information that directly, indirectly, or in
              connection with other information — including a personal
              identification number — allows for the identification or
              identifiability of a natural person.
            </li>
            <li>
              Service: refers to the service provided by Credvest as described in
              the relative terms (if available) and on this platform.
            </li>
            <li>
              Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our content
              or whose products or services we think may interest you.
            </li>
            <li>
              Website: Credvest site, which can be accessed via this URL:
              https://credvest.com/.
            </li>
            <li>
              You: a person or entity that is registered with Credvest to use the
              Services.
            </li>
          </ul>
          <h2>Contact Us</h2>
          <p>Do get in touch if you have any questions.</p>
          <ul>
            <li>
              Via Email: <a href="mailto:info@credvest.com">info@credvest.com</a>
            </li>
          </ul>
        </div>
    </div>
    </div>
  );
}

export default PrivacyPolicy;
