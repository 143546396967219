import React from "react";
import styles from "./Section2.module.scss";
import KnowGoodSec2bg from "../../../assets/knowGood/knowGoodSec2bg";
import image from "../../../assets/knowGood/Section2/KnowGoodBg.webp";
import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

function Section2() {
  return (
    <div className={styles.parentContainer}>
      <div className={styles.absoluteImage}>
        <KnowGoodSec2bg imageSrc={image} />
      </div>
      <div className={styles.secondContainer}>
        <h1 className={styles.pageTitle}>
          {" "}
          <FadeInOnScroll> What makes you want to "Know Good"?</FadeInOnScroll>
        </h1>
          <p className={styles.textBody}>
              You want to "Know Good" cuz, there are a dime and dozen that claim
              to be the best. We don't claim to be the best or better than the
              best. We claim, with a sense of quiet confidence and purposefulness
              that we know that we are good at what we do.
          </p>
      </div>
    </div>
  );
}

export default Section2;
