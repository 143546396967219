import React, { useState } from "react";
import styles from "./Section3.module.scss";
import CircleArrow from "../../../assets/knowGood/icons/CircleRight.svg";
import CircleInsideArrow from "../../../assets/knowGood/icons/circleMultipleArrow.svg";
import TalentManagement from "../../../assets/knowGood/icons/TalentManagement.svg";
import ExitStrategies from "../../../assets/knowGood/icons/ExitStrategies.svg";

//ICONS
import Customer from "../../../assets/knowGood/Section3/Icons/Customer.svg"
import GTM from "../../../assets/knowGood/Section3/Icons/GTM.svg"
import Positioning from "../../../assets/knowGood/Section3/Icons/Positioning.svg"
import Pricing from "../../../assets/knowGood/Section3/Icons/Pricing.svg"
import Realisation from "../../../assets/knowGood/Section3/Icons/Realisation.svg"
import Socio from "../../../assets/knowGood/Section3/Icons/Socio.svg"
import Stack from "../../../assets/knowGood/Section3/Icons/Stack.svg"
import Talent from "../../../assets/knowGood/Section3/Icons/Talent.svg"

// ICON2
import Stage from "../../../assets/knowGood/Section3/icon2/Stage.svg"
import Increment from "../../../assets/knowGood/Section3/icon2/Increment.svg"
import New from "../../../assets/knowGood/Section3/icon2/Market.svg"
import CrossSelling from "../../../assets/knowGood/Section3/icon2/Cross.svg"
import Inventory from "../../../assets/knowGood/Section3/icon2/Inventory.svg"


import Low from "../../../assets/knowGood/Section3/icon2/Low.svg"
import Quick from "../../../assets/knowGood/Section3/icon2/Quick.svg"
import Strong from "../../../assets/knowGood/Section3/icon2/Strong.svg"
import Owner from "../../../assets/knowGood/Section3/icon2/Owner.svg"


import GTM2 from "../../../assets/knowGood/Section3/icon2/GTM.svg"

import FadeInOnScroll from "../../../components/fadeInOnScroll/FadeInOnScroll";

const tabsData = {
  lifecycle: {
    icon: CircleInsideArrow,
    label: "Lifecycle management",
    circles: [
      { text: "Project & Pricing Viability", icon: Pricing },
      { text: "Positioning Strategy", icon: Positioning },
      { text: "Stack Evaluation", icon: Stack },
      { text: "Socio-economic Profiling", icon: Socio },
      { text: "GTM", icon: GTM },
      { text: "Talent Deployment", icon: Talent },
      { text: "Customer Success", icon: Customer },
      { text: "Realisation Management", icon: Realisation },
    ],
    
  },
  exit: {
    icon: ExitStrategies,
    label: "Exit strategies",
    circles: [
      { text: "Stagewise pricing", icon: Stage },
      { text: "Incremental volume", icon: Increment },
      { text: "New Market Penetration", icon: New },
      { text: "Cross-Selling", icon: CrossSelling },
      { text: "Inventory Mangagement", icon: Inventory },

    ],
  },
  talent: {
    icon: TalentManagement,
    label: "Talent Management",
    circles: [
      { text: "Low Attrition", icon: Low },
      { text: "Quick Deployment", icon: Quick },
      { text: "Strong Training", icon: Strong },
      { text: "HIgh Ownership", icon: Owner },
      { text: "GTM", icon: GTM2 },

    ],
  },
};


function CirclesRow({ circles }) {
  return (
    <div className={styles.RowCircles}>
      {circles.map((circleData, index) => (
        <React.Fragment key={index}>
          <div className={styles.circle}>
            <img
              src={circleData.icon}
              alt="CircleIcon"
              className={styles.circleInsideArrow}
            />
            <p className={styles.text}>{circleData.text}</p>
          </div>
          {index !== circles.length - 1 && (
            <img src={CircleArrow} alt="CircleArrow" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
function Section3() {
  const [activeTab, setActiveTab] = useState("lifecycle");
  
  const chunkedCircles = [];
  for (let i = 0; i < tabsData[activeTab].circles.length; i += 4) {
    chunkedCircles.push(tabsData[activeTab].circles.slice(i, i + 4));
  }

  return (
    <div className={styles.parentContainer}>
      <div className={styles.secondContainer}>
        <div className={styles.textContainer}>
          <FadeInOnScroll>
            <div className={styles.firstRowText}>
              <span className={styles.key}>Key </span>
              <span className={styles.pageTitle}>Differentiators</span>
            </div>
          </FadeInOnScroll>
          <p className={styles.bodyText}>
              The first block of a successful empire starts by getting the right
              people on board. Critically more important is creating the right
              retention policy by ensuring a culture of happiness, mindfulness,
              and positivity.
          </p>
        </div>
        {/* Circle container */}
        <div className={styles.circleContainer}>
          {chunkedCircles.map((circleGroup, index) => (
            <CirclesRow key={index} circles={circleGroup} />
          ))}
        </div>

        {/* Tabs */}
        <div className={styles.tabContainer}>
          {Object.keys(tabsData).map((tabKey) => (
            <div
              key={tabKey}
              className={`${styles.tabButton} ${
                activeTab === tabKey ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab(tabKey)}
            >
              <img
                src={tabsData[tabKey].icon}
                alt={tabKey}
                className={styles.tabIcon}
              />
              <p className={styles.tabText}>{tabsData[tabKey].label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}


export default Section3;
